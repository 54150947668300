import * as z from 'zod';

export const ProblemFoundSchema = z.object({
  color: z.string(),
  description: z.string(),
  active: z.boolean(),
});

export type ProblemFound = z.infer<typeof ProblemFoundSchema>;

export const ProblemResultSchema = z.object({
  result: z.string(),
  problems: z.array(z.string()),
});

export type ProblemResult = z.infer<typeof ProblemResultSchema>;

export const ProblemsApiResponseSchema = z.object({
  problems_found: z.array(ProblemFoundSchema),
  problems_analysed: z.array(ProblemResultSchema),
});

export type ProblemsApiResponse = z.infer<typeof ProblemsApiResponseSchema>;
