import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import HubTestRepository from '../api/HubTestRepository';
import { Customer } from '../entities/Customer';
import { Test, HubTestWizardInfo, HubTestWizardInfoSchema, TestAPIResponseSchema } from '../entities/HubTest';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';

import { hubTestActions, HubTestState } from '../store/reducers/hubTest';
import { RequestParseState } from '../store/toolkitUtils';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type HubTestsState = RootState['hubTest'];

type UseHubTest = {
  testListByQuery: HubTestsState['byQuery'];
  hubTestById: HubTestsState['byId'];
  nextRequestIdByRequestId: HubTestState['nextRequestIdByRequestId'];
  lastTest: Test | undefined;
  readHubTestList: (customerId: Customer['id'], requestId: string) => Promise<void>;
  readHubTestWizard: (customerId: Customer['id'], testId: Test['request_id']) => Promise<HubTestWizardInfo | undefined>;
  parseReadHubTestListResponse: (customerId: Customer['id'], requestId: string, response: unknown) => Promise<void>;
  readHubTestListRequest: RequestParseState;
  reset: () => void;
};

export function useHubTest(): UseHubTest {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      HubTestRepository.updateAuthToken(accessToken);
      HubTestRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const testListByQuery = useSelector((state: RootState) => state.hubTest.byQuery);
  const hubTestById = useSelector((state: RootState) => state.hubTest.byId);
  // const readHubTestRequest = useSelector((state: RootState) => state.hubTest.requests.readHubTestListParseFailure);
  const readHubTestListRequest = useSelector((state: RootState) => state.hubTest.requests.readList);
  const nextRequestIdByRequestId = useSelector((state: RootState) => state.hubTest.nextRequestIdByRequestId);

  const parseReadHubTestListResponse = useCallback(
    async (customerId, requestId, response) => {
      const parseResult = TestAPIResponseSchema.safeParse(response);

      if (parseResult.success === false) {
        dispatch(
          hubTestActions.readHubTestListParseFailure({
            parseError: new RequestParseError(parseResult.error, 'useHubTest.readHubTestList'),
          })
        );
        return;
      } else {
        dispatch(
          hubTestActions.readHubTestListSuccess({
            response: parseResult.data.historical_requests,
            requestId,
            nextRequestId: parseResult.data.next_pagination_token ?? '',
          })
        );
      }
    },
    [dispatch]
  );

  const readHubTestList = useCallback(
    async (customerId, requestId) => {
      dispatch(hubTestActions.readHubTestListRequest({ customerId, requestId }));
      let response: unknown;

      try {
        response = await HubTestRepository.getList(customerId, requestId);
      } catch (error) {
        dispatch(hubTestActions.readHubTestListFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      return parseReadHubTestListResponse(customerId, requestId, response);
    },
    [dispatch, parseReadHubTestListResponse]
  );

  const lastTest = useMemo(() => {
    const sortedTests = Object.values(hubTestById).sort((a, b) => (b.start_date ?? 0) - (a.start_date ?? 0));

    return sortedTests[0] || undefined;
  }, [hubTestById]);

  const readHubTestWizard = useCallback(
    async (customerId, testId) => {
      dispatch(hubTestActions.readHubTestWizardInfoRequest());
      let response: unknown;

      try {
        response = await HubTestRepository.getTestWizard(customerId, testId);
      } catch (error) {
        dispatch(hubTestActions.readHubTestWizardInfoFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      const parseResult = HubTestWizardInfoSchema.safeParse(response);

      if (parseResult.success === false) {
        dispatch(
          hubTestActions.readHubTestWizardInfoParseFailure({
            parseError: new RequestParseError(parseResult.error, 'useHubTest.readHubTestWizard'),
          })
        );
        return;
      } else {
        dispatch(hubTestActions.readHubTestWizardInfoSuccess({ testId, response: parseResult.data }));
        return parseResult.data;
      }
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch(hubTestActions.reset());
  }, [dispatch]);

  return {
    testListByQuery,
    hubTestById,
    lastTest,
    nextRequestIdByRequestId,
    readHubTestList,
    readHubTestListRequest,
    parseReadHubTestListResponse,
    readHubTestWizard,
    reset,
  };
}
