import * as z from 'zod';

// Retrieve cases
const CaseInteractionSchema = z.object({
  id: z.string(),
  status: z.string(),
  interaction_type: z.string().nullable(),
  appointment_id: z.string(),
});
export type CaseInteraction = z.infer<typeof CaseInteractionSchema>;

const CaseEscalationSchema = z.object({
  id: z.string(),
  team: z.string(),
  category: z.string(),
  impact: z.string(),
  olo_id: z.string(),
  status: z.string(),
  sub_status: z.string(),
});
export type CaseEscalation = z.infer<typeof CaseEscalationSchema>;

export type Case = {
  closed: boolean;
  case_id: string;
  date_opening: number;
  date_closure?: number | null;
  category: string;
  sub_category: string;
  motive: string;
  motive_details: string;
  outcome: string;
  status: string;
  sub_status: string;
  global_status: string;
  interactions?: CaseInteraction[];
  escalations?: CaseEscalation[];
  children?: Case[];
};
const CaseSchema: z.ZodSchema<Case> = z.lazy(() =>
  z.object({
    closed: z.boolean(),
    case_id: z.string(),
    date_opening: z.number(),
    date_closure: z.number().nullable().optional(),
    category: z.string(),
    sub_category: z.string(),
    motive: z.string(),
    motive_details: z.string(),
    outcome: z.string(),
    status: z.string(),
    sub_status: z.string(),
    global_status: z.string(),
    interactions: z.array(CaseInteractionSchema).optional(),
    escalations: z.array(CaseEscalationSchema).optional(),
    children: z.array(CaseSchema).optional(),
  })
);
export const CasesAPIReponseSchema = z.array(CaseSchema);

// Reserve Button
export const CaseReserveButtonSchema = z.object({
  value: z.number().max(2).min(-1),
  name: z.string(),
});

enum CaseMessageSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export const CaseMessageSchema = z.object({
  content: z.string(),
  severity: z.nativeEnum(CaseMessageSeverity).optional(),
});

// Case synth info
export const CaseSynthInfoSchema = z.object({
  case_id: z.string().nullable(),
  reserve_button: CaseReserveButtonSchema,
  message: CaseMessageSchema.optional(),
});
export type CaseSynthInfo = z.infer<typeof CaseSynthInfoSchema>;
