import React from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeft from '@mitch528/mdi-material-ui/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/system/Box';

export type DeviceDetailsProps = {
  mainDetails: string[] | null;
  additionalDetails?: string[] | null;
  openBox?: number; // Refresh hook
};

export default function DeviceDetails(props: DeviceDetailsProps): JSX.Element {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = React.useState<boolean>(true);

  const chevronRotationRef = React.useRef(0);
  const boxRef = React.useRef<HTMLDivElement>();

  const manageChevronClicked = () => {
    if (props.mainDetails?.length) {
      chevronRotationRef.current = chevronRotationRef.current === 0 ? 180 : 0;
      setCollapsed(!collapsed);
    }
  };

  React.useEffect(() => {
    if (props.openBox && collapsed) manageChevronClicked();
    else if (props.openBox === 0) {
      setCollapsed(true);
      chevronRotationRef.current = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openBox]);

  return (
    <Box
      ref={boxRef}
      sx={{
        background: (theme) => theme.palette.background.default,
        paddingY: 3,
        paddingRight: 5,
        border: `1px solid`,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderColor: (theme) => `${theme.palette.text.primary}20`,
        borderBottomLeftRadius: 10,
        position: 'absolute',
        zIndex: 0,
        top: 0,
        right: 0,
        transition: '0.4s',
        maxWidth: 350,
        transform: `translateX(${!collapsed ? 0 : (boxRef.current?.offsetWidth || 350) - 40}px)`,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ marginTop: 'auto', marginBottom: 'auto', paddingInline: 2 }}>
          <Tooltip title="Clicca su un dispositivo per visualizzare i dettagli.">
            <span>
              <IconButton
                disabled={Boolean(!props.mainDetails?.length)}
                onClick={manageChevronClicked}
                sx={{ transform: `rotate(${chevronRotationRef.current}deg)`, transition: '0.3s' }}
              >
                <ChevronLeft color={!props.mainDetails?.length ? 'disabled' : 'primary'} />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <Box>
          <Box sx={{ fontWeight: 'bold' }}>Dettagli Principali</Box>
          {props.mainDetails && props.mainDetails.length ? (
            props.mainDetails.map((label: string, i: number) => <div key={i}>{label}</div>)
          ) : (
            <span style={{ fontStyle: 'italic', opacity: 0.3 }}>
              {t('dashboard:customerInfo:dialogs:connectedDevices:noDetails')}
            </span>
          )}
          {props.additionalDetails && Boolean(props.additionalDetails.length) && (
            <Box sx={{ fontWeight: 'bold', marginTop: 3 }}>Dettagli Addizionali</Box>
          )}
          {props.additionalDetails?.map((label: string, i: number) => (
            <div key={i}>{label}</div>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
