/* eslint-disable no-console */
import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HubActionRepository from '../api/HubActionRepository';
import { WebSocketReceivedMessageSchema } from '../entities/WebSocket';
import { RootState } from '../store/reducers';
import { webSocketActions } from '../store/reducers/webSocket';
import useAuthentication from './useAuthentication';
import { useCustomer } from './useCustomer';
import { useHubActions } from './useHubActions';
import { useHubTest } from './useHubTest';
import { useProblems } from './useProblems';
import { useSuggestion } from './useSuggestion';
import { useWizard } from './useWizard';

const WebsocketContext = createContext(({
  connect: () => {
    //
  },
  disconnect: () => {
    //
  },
} as unknown) as {
  connect: (customerId: string) => Promise<unknown>;
  disconnect: () => void;
});

export function WebsocketProvider(props: { children: React.ReactNode }): JSX.Element {
  const socketRef = useRef<WebSocket | undefined>();
  const { accessToken, refreshToken } = useAuthentication();
  const keepAliveIntervalRef = useRef<undefined | NodeJS.Timeout>(undefined);
  const dispatch = useDispatch();

  const { parseReadCustomerInfo, parseReadLastTestCustomerInfo, parseReadCustomerActionList } = useCustomer();
  const { parseReadHubTestListResponse } = useHubTest();
  const { parseReadActionResultResponse } = useHubActions();
  const { parseReadWizardResponse: parseReadWizardStepResponse } = useWizard();
  const { parseReadProblemsResponse } = useProblems();
  const { parseSuggestionMessage } = useSuggestion();

  const connect = useCallback(
    async (customerId) => {
      if (socketRef.current && socketRef.current.readyState === 1) {
        console.warn('[ws] Attempting to open a connection without explicitly closing the previous');
        console.log(`[ws][closing] Closing connection`);
        socketRef.current.close(1000);
      }

      if (socketRef.current?.readyState === 0) {
        console.warn('[ws] Attempting to open a connection, another connection is in progress');
        return;
      }

      let custom_user_level = '';
      if (window.localStorage.getItem('custom_user_level')) {
        custom_user_level = `&custom_user_level=${window.localStorage.getItem('custom_user_level')}`;
      }
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}?customer_name=${customerId}&access_token=${accessToken}${custom_user_level}`
      );

      socketRef.current.onopen = () => {
        console.log('[ws][open] Connection established');
      };

      socketRef.current.onmessage = (event) => {
        console.log(`[ws][message] Data received from server: ${event.data}`);

        const parseResult = WebSocketReceivedMessageSchema.safeParse(JSON.parse(event.data));

        if (parseResult.success) {
          const { message_type, payload } = parseResult.data;

          switch (message_type) {
            case 'suggestion': {
              parseSuggestionMessage(payload);
              return;
            }
            case 'historical_requests': {
              parseReadHubTestListResponse(customerId, '', payload);
              return;
            }
            case 'action_result': {
              parseReadActionResultResponse(payload, customerId);
              return;
            }
            case 'actions': {
              parseReadCustomerActionList(customerId, payload);
              return;
            }
            case 'wizard': {
              parseReadWizardStepResponse(customerId, payload);
              return;
            }
            case 'problems_states': {
              parseReadProblemsResponse(payload);
              return;
            }
            case 'info': {
              parseReadCustomerInfo(customerId, payload);
              return;
            }
            case 'latest_info': {
              parseReadLastTestCustomerInfo(customerId, payload);
              return;
            }
          }

          dispatch(
            webSocketActions.webSocketUnexpectedMessageError({
              error: new Error(`Unexpected message type: ${message_type}`),
            })
          );
          return;
        }

        dispatch(
          webSocketActions.webSocketUnexpectedMessageError({
            error: new Error(`Unexpected message: ${event.data}`),
          })
        );
      };

      socketRef.current.onclose = (event) => {
        if (event.wasClean) {
          console.log(`[ws][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);

          if (event.code !== 1000) {
            // Attempt reconnection after few seconds
            setTimeout(() => {
              connect(customerId);
            }, 3000);
          }
        } else {
          // e.g. server process killed or network down
          // event.code is usually 1006 in this case
          console.log(`[ws][close] Connection died, code=${event.code} reason=${event.reason}`);

          dispatch(
            webSocketActions.webSocketUnexpectedCloseError({ error: new Error(`Connection closed: ${event.code}`) })
          );

          // Attempt reconnection after few seconds
          setTimeout(() => {
            connect(customerId);
          }, 3000);
        }

        if (keepAliveIntervalRef.current) {
          clearInterval(keepAliveIntervalRef.current);
        }

        socketRef.current = undefined;
      };

      socketRef.current.onerror = function (event) {
        console.log(`[ws][error] ${event.type}`);

        dispatch(webSocketActions.webSocketError({ error: new Error(`Websocket error: ${event.type}`) }));
      };

      if (socketRef.current) {
        keepAliveIntervalRef.current = setInterval(() => {
          if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(`{"action": "keepAlive", "customer_name": "${customerId}"}`);
          }
        }, 120000);
      } else {
        if (keepAliveIntervalRef.current) {
          clearInterval(keepAliveIntervalRef.current);
        }
      }
    },
    [
      accessToken,
      dispatch,
      parseReadActionResultResponse,
      parseReadCustomerInfo,
      parseReadLastTestCustomerInfo,
      parseReadHubTestListResponse,
      parseReadProblemsResponse,
      parseReadWizardStepResponse,
      parseReadCustomerActionList,
      parseSuggestionMessage,
    ]
  );

  const disconnect = useCallback(() => {
    if (socketRef.current && socketRef.current.readyState < 2) {
      console.log(`[ws][closing] Closing connection`);
      socketRef.current.close(1000);
      socketRef.current = undefined;

      if (keepAliveIntervalRef.current) {
        clearInterval(keepAliveIntervalRef.current);
      }
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      HubActionRepository.updateAuthToken(accessToken);
      HubActionRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  return <WebsocketContext.Provider value={{ connect, disconnect }}>{props.children}</WebsocketContext.Provider>;
}

type UseWebSocket = {
  webSocketError: RootState['webSocket']['error'];
  connect: (customerId: string) => Promise<unknown>;
  disconnect: () => void;
};

export function useWebSocket(): UseWebSocket {
  const { connect, disconnect } = useContext(WebsocketContext);

  const webSocketError = useSelector((state: RootState) => state.webSocket.error);

  return {
    webSocketError,
    connect,
    disconnect,
  };
}
