import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '@material-ui/core';
import { GridCellParams, GridColDef, GridRowParams } from '@material-ui/data-grid';
import { createStyles } from '@material-ui/styles';
import { Theme } from '@mui/material/';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { CustomerArea } from '../../../entities/Customer';
import { Status, wizardStatusNumberToStatus } from '../../../entities/Status';
import { IColumn } from '../../../entities/Table';
import useAuthentication from '../../../hooks/useAuthentication';
import { useCustomer } from '../../../hooks/useCustomer';
import { useWebSocket } from '../../../hooks/useWebSocket';
import ApplicationErrorProvider from '../../ApplicationError/ApplicationErrorProvider';
import LoaderView from '../../LoaderView';
import LocalizedDataGrid from '../../LocalizedDataGrid';
import Navbar from '../../Navbar/Navbar';
import StatusCircle from '../../status/StatusCircle';
import FilterSection from './FilterSection';

const PAGE_SIZE = 50;
const PAYLOAD_SIZE = 50;

function StateCell(params: GridCellParams) {
  return params.value === undefined || params.value === null ? (
    <span />
  ) : (
    <StatusCircle status={typeof params.value !== 'number' ? Status.Error : params.value} />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDataGrid-columnsContainer': {
        color: theme.palette.text.primary,
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
          theme.palette.mode === 'light' ? '#f0f0f0' : theme.palette.background.default
        } !important`,
      },
      '& .MuiDataGrid-cell': {
        color: theme.palette.text.primary,
        opacity: 0.9,
        borderBottom: `1px solid ${theme.palette.background.default} !important`,
        fontSize: '12px',
      },
    },
  })
);

export default function SearchCustomer(): JSX.Element {
  const { customerList, readCustomerList, readCustomerListRequest } = useCustomer();
  const [searched, setSearched] = useState(false);
  const [query, setQuery] = React.useState<Record<string, string>>({});
  const [page, setPage] = useState<number>(0);
  const [payloadSectionCount, setPayloadSectionCount] = useState<number>(1);
  const history = useHistory();
  const { disconnect } = useWebSocket();
  const { userTerritory } = useAuthentication();

  const classes = useStyles();

  useEffect(() => {
    disconnect();
  }, [disconnect]);

  const { rows, columnList } = useMemo(() => {
    const columnList = readCustomerListRequest.columns;
    const rows = customerList.map((customer) => {
      const row: Record<string, string | number | undefined> = { id: customer.id };
      for (const customerAttribute of customer.attributes) {
        const { name, value, status } = customerAttribute as {
          name: string;
          value: string | number;
          status?: number;
        };
        if (
          status !== undefined &&
          status !== null &&
          typeof status === 'number' &&
          columnList.find((col) => col.attribute_name === name)?.status
        ) {
          row[name] = wizardStatusNumberToStatus(status);
        } else {
          row[name] = `${value}`;
        }
      }
      return row;
    });
    return { rows, columnList };
  }, [customerList, readCustomerListRequest.columns]);

  // Columns
  const columns: GridColDef[] = useMemo(() => {
    const attributesRows: GridColDef[] = columnList.map((column: IColumn) => ({
      field: column.attribute_name,
      headerName: column.alias,
      flex: 1,
      renderCell: column.status ? StateCell : undefined,
      sortable: false,
    }));

    return attributesRows;
  }, [columnList]);

  const handlePageChange = React.useCallback(
    async (page: number) => {
      setPage(page);
      if ((page + 1) * PAGE_SIZE > PAYLOAD_SIZE * payloadSectionCount) {
        const misalignment = PAYLOAD_SIZE * payloadSectionCount - page * PAGE_SIZE;
        await readCustomerList(query, page, PAGE_SIZE, PAYLOAD_SIZE, misalignment, false);
        setPayloadSectionCount(payloadSectionCount + 1);
      }
    },
    [query, readCustomerList, payloadSectionCount]
  );

  const handleRowClick = React.useCallback(
    (param: GridRowParams) => {
      const area = query.category;
      let product = param.row.product;
      if (param.row.account_id) {
        window.localStorage.setItem('account_id', param.row.account_id);
      }

      if (area === CustomerArea.BB) {
        // For Broadband the product is hardcoded to fastwifi for UK, WIFI for it
        product = userTerritory === 'UK' ? 'FASTWIFI' : 'WIFI';
      }
      let url = `/dashboard/${param.id}`;
      const params = [];
      if (area) {
        params.push(`area=${area}`);
      }
      if (product) {
        params.push(`product=${product}`);
      }
      if (params.length) {
        url += '?' + params.join('&');
      }
      history.push(url);
    },
    [history, query.category, userTerritory]
  );

  const search = React.useCallback(
    async (query: Record<string, string>) => {
      setSearched(false);
      setQuery(query);
      setPage(0);
      setPayloadSectionCount(1);
      await readCustomerList(query, 0, PAGE_SIZE, PAYLOAD_SIZE, 0, true);
      setTimeout(() => {
        setSearched(true);
      }, 0);
    },
    [readCustomerList]
  );

  return (
    <Fragment>
      <Navbar />
      <ApplicationErrorProvider>
        <Box
          sx={{
            padding: 2,
            pt: 0,
            width: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            background: (theme) => theme.palette.background.default,
          }}
        >
          <FilterSection onSubmit={search} />
          {searched && (
            <Card
              sx={{
                width: '100%',
                flex: '1 1 auto',
                maxHeight: 730,
                overflow: 'hidden',
                borderRadius: 1,
                background: (theme) => theme.palette.background.paper,
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-footer': {
                  minHeight: 40,
                  maxHeight: 40,
                  overflow: 'hidden',
                },
              }}
            >
              <LoaderView condition={!readCustomerListRequest.inProgress} minHeight={180}>
                {''}
              </LoaderView>

              <Box
                sx={{
                  minHeight: 180,
                  height: '100%',
                  opacity: readCustomerListRequest.inProgress ? 0 : 1,
                  '& .MuiDataGrid-root': {
                    display: 'flex',
                    border: '0px solid',
                  },
                  '.MuiDataGrid-cell:focus-within': {
                    outline: 'none !important',
                  },
                }}
              >
                <LocalizedDataGrid
                  className={classes.root}
                  rows={rows}
                  columns={columns}
                  pageSize={PAGE_SIZE}
                  onRowClick={handleRowClick}
                  disableSelectionOnClick
                  disableColumnFilter
                  page={page}
                  onPageChange={handlePageChange}
                  paginationMode={'client'}
                  rowCount={readCustomerListRequest.total}
                  rowHeight={38}
                  headerHeight={48}
                  rowsPerPageOptions={[PAGE_SIZE]}
                />
              </Box>
            </Card>
          )}
        </Box>
      </ApplicationErrorProvider>
    </Fragment>
  );
}
