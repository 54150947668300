import Axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

export class Repository {
  protected axios: AxiosInstance;

  constructor(configuration: RepositoryConfiguration) {
    this.axios = Axios.create({
      baseURL: `${configuration.apiURL}${configuration.resourceURL}`,
      headers: {
        'Content-Type': 'application/json',
        ...configuration.axiosConfig?.headers,
      },
      ...configuration.axiosConfig,
    });
  }

  updateAuthToken(token: string): void {
    this.axios.defaults.headers = {
      ...this.axios.defaults.headers,
      Authorization: `Bearer ${token}`,
    };
  }
}

export type RepositoryConfiguration = {
  resourceURL: string;
  apiURL: string;
  axiosConfig?: AxiosRequestConfig;
};
