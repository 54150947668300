import { RequestError } from '../entities/RequestError';
import { RequestState } from '../entities/RequestState';
import { RequestParseError } from '../utils/RequestParseError';

export function mutateRequestState(state: RequestState): void {
  state.inProgress = true;
}

export function mutateSuccessState(state: RequestState): void {
  state.inProgress = false;
  state.error = null;
  state.lastUpdate = new Date().valueOf();
}

export function mutateErrorState(state: RequestState, error: RequestState['error']): void {
  state.inProgress = false;
  state.error = error;
}

export function requestState(): RequestState {
  return {
    inProgress: false,
    error: null,
    lastUpdate: null,
  };
}

export interface RequestFailureAction {
  error: RequestError;
}

export interface RequestParseState extends RequestState {
  parseError: RequestParseError | null;
}

export function requestParseState(): RequestParseState {
  return {
    ...requestState(),
    parseError: null,
  };
}

export interface RequestParseFailureAction {
  parseError: RequestParseState['parseError'];
}

export function mutateParseErrorState(state: RequestParseState, parseError: RequestParseState['parseError']): void {
  state.inProgress = false;
  state.parseError = parseError;
}
