import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Glossary } from '../../entities/Glossary';

import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestParseState,
  RequestParseFailureAction,
  mutateParseErrorState,
} from '../toolkitUtils';

const initialState = {
  glossary: undefined as Glossary | undefined,
  requests: {
    readGlossary: requestParseState(),
  },
};

const glossarySlice = createSlice({
  name: 'glossary',
  initialState,
  reducers: {
    // read glossary
    readGlossaryRequest(state) {
      mutateRequestState(state.requests.readGlossary);
    },
    readGlossarySuccess(state, action: PayloadAction<Glossary>) {
      state.glossary = action.payload;
      mutateSuccessState(state.requests.readGlossary);
    },
    readGlossaryFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readGlossary, action.payload.error);
    },
    readGlossaryParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readGlossary, action.payload.parseError);
    },
  },
});

export const glossaryActions = glossarySlice.actions;

export default glossarySlice.reducer;
