import React from 'react';
import Box from '@mui/material/Box';
import { useCustomerData } from '../../../../../hooks/useCustomerData';
import HubDetailsDialogCharts from '../../HubDetailsDialog/HubDetailsDialogCharts';

interface WizardChartsProps {
  chartList?: {
    chart_name: string;
    chart_type: string;
  }[];
}

export default function WizardCharts(props: WizardChartsProps): JSX.Element | null {
  const { chartList } = props;

  const { customerId } = useCustomerData();

  if (!customerId || !chartList || chartList.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mt: 1 }}>
      {chartList.map((chart) => (
        <HubDetailsDialogCharts
          key={chart.chart_name}
          customerId={customerId}
          type={chart.chart_type}
          inline={true}
          trendline={chart.chart_name}
          visible={true}
          size={{ width: '100%', height: 310 }}
        />
      ))}
    </Box>
  );
}
