import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Test, HubTestWizardInfo } from '../../entities/HubTest';
import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  mutateParseErrorState,
  RequestParseFailureAction,
  requestParseState,
} from '../toolkitUtils';

const initialState = {
  byId: {} as Record<Test['request_id'], Test>,
  byQuery: {} as Record<string, Test['request_id'][]>,
  wizardInfoById: {} as Record<Test['request_id'], HubTestWizardInfo>,
  nextRequestIdByRequestId: {} as Record<string, string>,
  requests: {
    readList: {
      ...requestParseState(),
      customerId: undefined as string | undefined,
    },
    readWizardInfo: requestParseState(),
  },
};

export type HubTestState = typeof initialState;

interface ReadHubTestListRequestAction {
  customerId: string | undefined;
  requestId: string;
}

interface ReadHubTestListSuccessAction {
  requestId: string;
  response: Array<Test>;
  nextRequestId: string;
}

interface ReadHubTestWizardInfoSuccessAction {
  testId: Test['request_id'];
  response: HubTestWizardInfo;
}

const hubTestsSlice = createSlice({
  name: 'hubTests',
  initialState,
  reducers: {
    readHubTestListRequest(state, action: PayloadAction<ReadHubTestListRequestAction>) {
      mutateRequestState(state.requests.readList);

      if (action.payload.customerId !== state.requests.readList.customerId) {
        state.requests.readList.customerId = action.payload.customerId;
        state.requests.readList.lastUpdate = null;
        state.byId = {};
      }
    },
    readHubTestListSuccess(state, action: PayloadAction<ReadHubTestListSuccessAction>) {
      const { requestId, response, nextRequestId } = action.payload;

      state.byId = {};

      for (let i = 0; i < response.length; i++) {
        const hubTest = response[i];
        if (hubTest) {
          state.byId[hubTest.request_id] = hubTest;
        }
      }

      state.byQuery[requestId] = response.map((test) => test.request_id);
      state.nextRequestIdByRequestId[requestId] = nextRequestId;

      mutateSuccessState(state.requests.readList);
    },
    readHubTestListFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readList, action.payload.error);
    },
    readHubTestListParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readList, action.payload.parseError);
    },
    readHubTestWizardInfoRequest(state) {
      mutateRequestState(state.requests.readWizardInfo);
    },
    readHubTestWizardInfoSuccess(state, action: PayloadAction<ReadHubTestWizardInfoSuccessAction>) {
      state.wizardInfoById[action.payload.testId] = action.payload.response;
      mutateSuccessState(state.requests.readWizardInfo);
    },
    readHubTestWizardInfoFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readWizardInfo, action.payload.error);
    },
    readHubTestWizardInfoParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readWizardInfo, action.payload.parseError);
    },
    reset(state) {
      state.byId = initialState.byId;
      state.byQuery = initialState.byQuery;
      state.wizardInfoById = initialState.wizardInfoById;
      state.requests = initialState.requests;
      state.nextRequestIdByRequestId = initialState.nextRequestIdByRequestId;
    },
  },
});

export const hubTestActions = hubTestsSlice.actions;

export default hubTestsSlice.reducer;
