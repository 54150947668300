import * as z from 'zod';

// System (row)
export type System = {
  netktID: string;
  name?: string;
  attributes: Array<{ name: string; value: string | number; status?: string }>;
};

// Attributes
export const SystemAttributeSchema = z.object({
  attribute_name: z.string(),
  possible_values: z.array(z.string()),
  alias: z.string(),
});

export const SystemAttributeAPIResponseSchema = z.object({
  attributes: z.array(SystemAttributeSchema),
});

export type SystemAttribute = z.infer<typeof SystemAttributeSchema>;
