import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import ConfirmButtom from '../../../../ConfirmButton';

interface WizardButtonProps {
  confirm: boolean;
  disabled: boolean;
  loading: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  confirmDialogTitle?: string;
  children: React.ReactNode;
}

export default function WizardButton(props: WizardButtonProps): JSX.Element {
  return props.confirm ? (
    <ConfirmButtom
      ButtonProps={{
        size: 'small',
        disabled: props.disabled,
        onClick: props.onClick,
      }}
      title={props.confirmDialogTitle ?? ''}
      loading={props.loading}
    >
      {props.children}
    </ConfirmButtom>
  ) : (
    <LoadingButton
      size="medium"
      onClick={props.onClick}
      loading={props.loading}
      disabled={props.disabled}
      variant="contained"
    >
      {props.children}
    </LoadingButton>
  );
}
