import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmbeddedService } from '../../entities/DownDetector';

import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestParseState,
  RequestParseFailureAction,
  mutateParseErrorState,
} from '../toolkitUtils';

const initialState = {
  embeddedServiceList: [] as Array<EmbeddedService>,
  embeddedServiceDashboardList: [] as Array<EmbeddedService>,

  requests: {
    readHeatMap: requestParseState(),
    readEmbeddedServiceList: requestParseState(),
    readEmbeddedServiceDashboardList: requestParseState(),
    readService: requestParseState(), //read che memorizza la richiesta e valida il json schema - singola company
  },
};

export type TrendlineState = typeof initialState;

const downDetectorSlice = createSlice({
  name: 'downDetector',
  initialState,
  reducers: {
    // read Heat map
    readHeatMapRequest(state) {
      mutateRequestState(state.requests.readHeatMap);
    },
    readHeatMapSuccess(state) {
      mutateSuccessState(state.requests.readHeatMap);
    },
    readHeatMapFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readHeatMap, action.payload.error);
    },
    readHeatMapParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readHeatMap, action.payload.parseError);
    },
    // read embedded service List
    readEmbeddedServiceListRequest(state) {
      mutateRequestState(state.requests.readEmbeddedServiceList);
    },
    readEmbeddedServiceListSuccess(state, action: PayloadAction<Array<EmbeddedService>>) {
      state.embeddedServiceList = action.payload;
      mutateSuccessState(state.requests.readEmbeddedServiceList);
    },
    readEmbeddedServiceListFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readEmbeddedServiceList, action.payload.error);
    },
    readEmbeddedServiceListParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readEmbeddedServiceList, action.payload.parseError);
    },
    //read embedded Service Dash List
    readEmbeddedServiceDashboardListRequest(state) {
      mutateRequestState(state.requests.readEmbeddedServiceDashboardList);
    },
    readEmbeddedServiceDashboardListSuccess(state, action: PayloadAction<Array<EmbeddedService>>) {
      state.embeddedServiceDashboardList = action.payload;
      mutateSuccessState(state.requests.readEmbeddedServiceDashboardList);
    },
    readEmbeddedServiceDashboardListFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readEmbeddedServiceDashboardList, action.payload.error);
    },
    readEmbeddedServiceDashboardListParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readEmbeddedServiceDashboardList, action.payload.parseError);
    },
    // read embedded Service Company Id
    readServiceRequest(state) {
      mutateRequestState(state.requests.readService);
    },
    readServiceSuccess(state) {
      mutateSuccessState(state.requests.readService);
    },
    readServiceFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readService, action.payload.error);
    },
    readServiceParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readService, action.payload.parseError);
    },
  },
});

export const downDetectorActions = downDetectorSlice.actions;

export default downDetectorSlice.reducer;
