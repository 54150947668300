import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import { BreadcrumbsProvider } from './components/Breadcrumbs/BreadcrumbsProvider';
import JIRAIssueCollector from './components/JIRAIssueCollector';
import Dashboard from './components/pages/Dashboard';
import DownDetector from './components/pages/DownDetector';
import L2Dashboard from './components/pages/L2Dashboard';
import LandingPage from './components/pages/LandingPage';
import Mso from './components/pages/Mso';
import useAuthentication from './hooks/useAuthentication';
import { i18n } from './i18n';
import { RootState } from './store/reducers';
import { darkModeActions } from './store/reducers/darkMode';
import { theme } from './theme';

export default function Navigation(): JSX.Element {
  const dispatch = useDispatch();
  const { user, userTerritory } = useAuthentication();
  const darkMode = useSelector((state: RootState) => state.darkMode.activated);

  if (userTerritory === 'UK' && i18n.language !== 'en-GB') {
    i18n.changeLanguage('en-GB');
  }

  React.useEffect(() => {
    const storedDarkMode = window.localStorage.getItem('@DarkMode') as string;
    if (storedDarkMode) {
      dispatch(darkModeActions.setDarkMode(storedDarkMode === 'true' ? true : false));
    }
  }, [dispatch]);

  const localizedTheme = createTheme(theme(darkMode));

  return (
    <BreadcrumbsProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={localizedTheme}>
          <CssBaseline />
          <JIRAIssueCollector></JIRAIssueCollector>
          <Box
            sx={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Switch>
              <Route exact path="/landing-page" component={LandingPage} />
              {(user && !user.level) ||
                (user && user.level && user.level >= 2 && <AuthenticatedRoute exact path={`/mso`} component={Mso} />)}
              {(user && !user.level) ||
                (user && user.level && user.level >= 2 && (
                  <AuthenticatedRoute path={`/down-detector`} component={DownDetector} />
                ))}
              {(user && !user.level) ||
                (user && user.level && user.level >= 2 && (
                  <AuthenticatedRoute path="/l2-dashboard/:customer" component={L2Dashboard} />
                ))}
              <AuthenticatedRoute path="/" component={Dashboard} />
              <Redirect to="/search-customer" />
            </Switch>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </BreadcrumbsProvider>
  );
}
