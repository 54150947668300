import React, { SyntheticEvent, useState } from 'react';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import Box from '@mui/system/Box';
import { ValueFilter, RangeFilter, TimeFilter, FilterType } from '../../../../entities/L2Dashboard';
import { useL2Dashboard } from '../../../../hooks/useL2Dashboard';

export type RequestValueFilter = {
  id: string;
  name: string;
  type: FilterType;
  allowedValues: string[];
};
export type RequestRangeAndTimeFilter = {
  id: string;
  name: string;
  type: FilterType;
  start: number | null;
  end: number | null;
};

type L2ControllerSectionProps = {
  element: {
    title?: string | null;
    orientation: 'horizontal' | 'vertical';
    filters: Array<ValueFilter | RangeFilter | TimeFilter>;
  };
  onFilterValueChanged?: (
    affected_element_ids: string[],
    request_state_filter: RequestValueFilter | RequestRangeAndTimeFilter
  ) => void;
};
export default function L2ControllerSection(props: L2ControllerSectionProps): JSX.Element {
  const { reloadingElementIds } = useL2Dashboard();
  const theme = useTheme();

  const onValueFilterValueChanged = (values: string[], filterData: ValueFilter) => {
    if (props.onFilterValueChanged)
      props.onFilterValueChanged(filterData.element_ids_control, {
        id: filterData.id,
        name: filterData.name,
        type: filterData.type,
        allowedValues: values,
      });
  };
  const onRangeAndTimeFilterValueChanged = (
    values: { start: number | null; end: number | null },
    filterData: RangeFilter | TimeFilter
  ) => {
    if (props.onFilterValueChanged)
      props.onFilterValueChanged(filterData.element_ids_control, {
        id: filterData.id,
        name: filterData.name,
        type: FilterType.RANGE,
        start: values.start,
        end: values.end,
      });
  };

  const isFilterControllingReloadingElements = (controllingElements: string[]) => {
    let isControlling = false;
    for (let i = 0; i < controllingElements.length && !isControlling; i++) {
      const elId = controllingElements[i];
      if (elId && reloadingElementIds.includes(elId)) {
        isControlling = true;
      }
    }
    return isControlling;
  };

  const renderFilter = (f: ValueFilter | RangeFilter | TimeFilter) => {
    switch (f.type) {
      case FilterType.VALUE:
        return (
          <L2ValueFilter
            key={f.name}
            filterData={f}
            orientation={props.element.orientation}
            numOfFilters={
              props.element.filters.length + props.element.filters.filter((f) => f.type === FilterType.TIME).length
            }
            disabled={isFilterControllingReloadingElements(f.element_ids_control)}
            onValueChange={onValueFilterValueChanged}
          />
        );
      case FilterType.RANGE:
        return (
          <L2RangeFilter
            key={f.name}
            filterData={f}
            orientation={props.element.orientation}
            numOfFilters={
              props.element.filters.length + props.element.filters.filter((f) => f.type === FilterType.TIME).length
            }
            disabled={isFilterControllingReloadingElements(f.element_ids_control)}
            onValueChange={onRangeAndTimeFilterValueChanged}
          />
        );
      case FilterType.TIME:
        return (
          <L2TimeFilter
            key={f.name}
            filterData={f}
            orientation={props.element.orientation}
            numOfFilters={
              props.element.filters.length + props.element.filters.filter((f) => f.type === FilterType.TIME).length
            }
            disabled={isFilterControllingReloadingElements(f.element_ids_control)}
            onValueChange={onRangeAndTimeFilterValueChanged}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: props.element.orientation === 'vertical' ? 'flex-start' : 'space-evenly',
        height: '100%',
        overflowY: 'auto',
        pb: 3,
      }}
    >
      {props.element.title && (
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: 16,
            color: theme.palette.primary.main,
            mt: 2,
            pl: props.element.orientation === 'vertical' ? 0 : 3,
          }}
        >
          {props.element.title}
        </Box>
      )}
      <Box
        sx={{
          px: 3,
          display: 'flex',
          height: `calc(100% - ${props.element.title ? 30 : 0})`,
          flexDirection: props.element.orientation === 'vertical' ? 'column' : 'row',
          flexWrap: props.element.orientation === 'vertical' ? 'nowrap' : 'wrap',
        }}
      >
        {props.element.filters.map((f) => renderFilter(f))}
      </Box>
    </Box>
  );
}

type L2ValueFilterProps = {
  filterData: ValueFilter;
  orientation: 'vertical' | 'horizontal';
  numOfFilters: number;
  disabled?: boolean;
  onValueChange?: (values: string[], filterData: ValueFilter) => void;
};
function L2ValueFilter({ filterData, orientation, numOfFilters, disabled, onValueChange }: L2ValueFilterProps) {
  const [values, setValues] = useState<string[]>([]);
  const [edited, setEdited] = useState<boolean>(false);

  return filterData.possible_values ? (
    <FormControl
      sx={{
        pr: orientation === 'vertical' ? 0 : 3,
        flex: `0 1 ${100 / numOfFilters}%`,
        minWidth: orientation === 'vertical' ? '100%' : 200,
        width: orientation === 'vertical' ? '100%' : 'auto',
        mt: 3,
      }}
    >
      <InputLabel>{filterData.alias}</InputLabel>
      <Select
        multiple={filterData.multiple_selection}
        value={values}
        input={<OutlinedInput label={filterData.alias} />}
        onChange={(e) => {
          const newValues = typeof e.target.value === 'string' ? [e.target.value] : e.target.value;
          setValues(newValues);
          setEdited(true);
          if (onValueChange && !filterData.multiple_selection) {
            onValueChange(newValues, filterData);
            setEdited(false);
          }
        }}
        onClose={() => {
          if (onValueChange && filterData.multiple_selection && edited) {
            onValueChange(values, filterData);
            setEdited(false);
          }
        }}
        renderValue={(selected) =>
          filterData.multiple_selection ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} color="primary" style={{ marginBlock: -6, fontSize: 13 }} />
              ))}
            </Box>
          ) : (
            selected
          )
        }
      >
        {filterData.possible_values.map((v) => (
          <MenuItem key={v} value={v}>
            {!filterData.multiple_selection ? (
              v
            ) : (
              <>
                <Checkbox checked={values.indexOf(v) > -1} />
                <ListItemText primary={v} />
              </>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <TextField
      value={values}
      label={filterData.alias}
      select={Boolean(filterData.possible_values)}
      disabled={disabled}
      onChange={(e) => {
        setEdited(true);
        setValues([e.target.value]);
      }}
      onBlur={() => {
        if (onValueChange && edited) {
          onValueChange(values, filterData);
          setEdited(false);
        }
      }}
      sx={{
        pr: orientation === 'vertical' ? 0 : 3,
        flex: `0 1 ${100 / numOfFilters}%`,
        minWidth: orientation === 'vertical' ? '100%' : 200,
        width: orientation === 'vertical' ? '100%' : 'auto',
        mt: 3,
      }}
    />
  );
}

type L2RangeFilterProps = {
  filterData: RangeFilter;
  orientation: 'vertical' | 'horizontal';
  numOfFilters: number;
  disabled?: boolean;
  onValueChange?: (values: { start: number; end: number }, filterData: RangeFilter) => void;
};
function L2RangeFilter({
  filterData,
  orientation,
  numOfFilters,
  disabled,
  onValueChange,
}: L2RangeFilterProps): JSX.Element {
  const [initialValues, setInitialValues] = React.useState<number[]>([filterData.start, filterData.end]);
  const [values, setValues] = React.useState<number[]>([filterData.start, filterData.end]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValues(newValue as number[]);
  };
  const handleFilterChanged = (event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    const startValue = (newValue as number[])[0];
    const endValue = (newValue as number[])[1];
    if (
      onValueChange &&
      startValue !== undefined &&
      endValue !== undefined &&
      (startValue !== initialValues[0] || endValue !== initialValues[1])
    ) {
      onValueChange(
        {
          start: startValue,
          end: endValue,
        },
        filterData
      );
    }
    setInitialValues(newValue as number[]);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        px: 3,
        flex: `0 1 ${100 / numOfFilters}%`,
        minWidth: orientation === 'vertical' ? '100%' : 200,
        width: orientation === 'vertical' ? '100%' : 'auto',
        mt: 3,
      }}
    >
      <Slider
        value={values}
        onChange={handleChange}
        onChangeCommitted={handleFilterChanged}
        max={filterData.end}
        min={filterData.start}
        valueLabelDisplay="auto"
        step={filterData.step}
        disabled={disabled}
        marks={[
          {
            value: filterData.start,
            label: `${filterData.start}${filterData.helper_string ? ` ${filterData.helper_string}` : ''}`,
          },
          {
            value: filterData.end,
            label: `${filterData.end}${filterData.helper_string ? ` ${filterData.helper_string}` : ''}`,
          },
        ]}
        valueLabelFormat={(v) => (
          <span>
            {v} {filterData.helper_string}
          </span>
        )}
      />
      <Typography
        fontSize={14}
        sx={{
          opacity: 0.5,
          position: 'absolute',
          bottom: 8,
          left: '50%',
        }}
      >
        <div style={{ position: 'relative', left: '-50%' }}>{filterData.alias}</div>
      </Typography>
    </Box>
  );
}

type L2TimeFilterProps = {
  filterData: TimeFilter;
  orientation: 'vertical' | 'horizontal';
  numOfFilters: number;
  disabled?: boolean;
  onValueChange?: (values: { start: number | null; end: number | null }, filterData: TimeFilter) => void;
};
function L2TimeFilter({
  filterData,
  orientation,
  numOfFilters,
  disabled,
  onValueChange,
}: L2TimeFilterProps): JSX.Element {
  const maxTimestamp = new Date().getTime() - filterData.offset;
  const minTimestamp = new Date().getTime() - filterData.offset - filterData.interval;
  const [startTime, setStartTime] = React.useState<Date | null>(null);
  const [endTime, setEndTime] = React.useState<Date | null>(null);
  const [edited, setEdited] = useState(false);

  const handleStartTimeChanged = (newValue: Date | null) => {
    if (!edited) setEdited(true);
    setStartTime(newValue);
  };
  const handleEndTimeChanged = (newValue: Date | null) => {
    if (!edited) setEdited(true);
    setEndTime(newValue);
  };
  const handleFilterChanged = () => {
    if (onValueChange && edited) {
      onValueChange(
        {
          start: startTime?.getTime() ?? null,
          end: endTime?.getTime() ?? null,
        },
        filterData
      );
      setEdited(false);
    }
  };

  return (
    <Box
      sx={{
        pr: orientation === 'vertical' ? 0 : 3,
        flex: `0 1 ${200 / numOfFilters}%`,
        minWidth: orientation === 'vertical' ? '100%' : 400,
        width: orientation === 'vertical' ? '100%' : 'auto',
        mt: 3,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <DateTimePicker
        label={`Inizio ${filterData.alias}`}
        value={startTime}
        minDateTime={new Date(minTimestamp)}
        maxDateTime={new Date(Math.min(maxTimestamp, endTime?.getTime() ?? Number.MAX_SAFE_INTEGER))}
        disabled={disabled}
        onChange={handleStartTimeChanged}
        onClose={handleFilterChanged}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            onBlur={handleFilterChanged}
            {...params}
            sx={{
              width: '100%',
              '& >div': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
            }}
          />
        )}
      />
      <DateTimePicker
        label={`Fine ${filterData.alias}`}
        value={endTime}
        minDateTime={new Date(Math.max(minTimestamp, startTime?.getTime() ?? 0))}
        maxDateTime={new Date(maxTimestamp)}
        disabled={disabled}
        onChange={handleEndTimeChanged}
        onClose={handleFilterChanged}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            onBlur={handleFilterChanged}
            {...params}
            sx={{
              marginLeft: '-1px',
              width: '100%',
              '& >div': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
              '& fieldset': {
                borderLeftColor: 'transparent',
              },
            }}
          />
        )}
      />
    </Box>
  );
}
