import React, { useEffect, useMemo, useState } from 'react';
import ClipboardIcon from '@mitch528/mdi-material-ui/Clipboard';
import CloseWindowIcon from '@mitch528/mdi-material-ui/CloseOctagon';
import HintIcon from '@mitch528/mdi-material-ui/LightbulbOnOutline';
import copy from 'copy-to-clipboard';
import Alert, { AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { useSuggestion } from '../../hooks/useSuggestion';
import { WizardMarkdown } from '../pages/Dashboard/Wizard/components/WizardFeedbackContent';
import { Suggestion } from '../../entities/Suggestion';
import './animation.css';

export default function ApplicationSuggestions(): JSX.Element {
  const { suggestion, clearSuggestion } = useSuggestion();
  const [opened, setOpened] = useState(false);
  const [clipboardEmpty, setClipboardEmpty] = useState(true);
  const [localSuggestions, setLocalSuggestions] = useState<Suggestion[]>([]);

  useEffect(() => {
    if (suggestion) {
      setLocalSuggestions([...localSuggestions, suggestion]);
      setOpened(true);
    }
  }, [suggestion]);

  const thereAreClipboardButtons = useMemo(() => {
    if (!suggestion?.buttons) return false;
    let found = false;
    let i = 0;
    while (!found && i < suggestion?.buttons.length) {
      const btn = suggestion.buttons[i];
      if (btn && btn.type === 'copy-to-clipboard') {
        found = true;
      }
      i++;
    }
    return found;
  }, [suggestion]);

  const notRelatedToCaseId = (markdown_text: string) => !markdown_text.toLowerCase().includes('caseid');

  const getTheColor = (suggestion: Suggestion) =>
    suggestion?.severity === 'success' ? 'green' : suggestion?.severity === 'error' ? 'red' : 'info';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        paddingX: 2,
      }}
    >
      <Collapse in={opened}>
        {localSuggestions.map(
          (suggestion) =>
            suggestion.severity && (
              <Box
                className="linear-animation"
                sx={{
                  mt: 2,
                  p: 0.8,
                  borderRadius: 1,
                  background: (theme) =>
                    `linear-gradient(90deg, ${theme.palette.background.default}, ${getTheColor(suggestion)}, 
                  ${theme.palette.background.default})`,
                }}
              >
                <Alert
                  icon={<HintIcon />}
                  variant="standard"
                  severity={(suggestion?.severity as AlertColor | undefined) ?? 'info'}
                  onClose={() => {
                    setOpened(false);
                    setTimeout(() => {
                      clearSuggestion();
                    }, 1000);
                  }}
                >
                  <AlertTitle>Suggerimenti:</AlertTitle>
                  <WizardMarkdown sx={{ fontSize: 13 }}>{suggestion?.markdown_text ?? ''}</WizardMarkdown>
                  {suggestion.buttons && (
                    <Box>
                      {suggestion.buttons.map((button, i) => {
                        switch (button.type) {
                          case 'close-window':
                            return (
                              <Button
                                key={`button-${i}`}
                                color={suggestion?.severity ?? 'info'}
                                variant="outlined"
                                size="small"
                                disabled={
                                  thereAreClipboardButtons &&
                                  clipboardEmpty &&
                                  notRelatedToCaseId(suggestion.markdown_text)
                                }
                                onClick={() => window.close()}
                                startIcon={<CloseWindowIcon />}
                                sx={{ mr: 2 }}
                              >
                                {button.label}
                              </Button>
                            );
                          case 'copy-to-clipboard':
                            return (
                              <Button
                                key={`button-${i}`}
                                color={suggestion?.severity ?? 'info'}
                                variant="outlined"
                                size="small"
                                startIcon={<ClipboardIcon />}
                                onClick={() => {
                                  copy(button.content ?? '');
                                  setClipboardEmpty(false);
                                }}
                                sx={{ mr: 2 }}
                              >
                                {button.label}
                              </Button>
                            );
                          default:
                            return null;
                        }
                      })}
                    </Box>
                  )}
                </Alert>
              </Box>
            )
        )}
      </Collapse>
    </Box>
  );
}
