import React, { createContext, useContext } from 'react';
import { Customer } from '../entities/Customer';

export interface UseCustomerDataContextPayload {
  customerId: Customer['id'] | undefined;
}

export const UseCustomerDataContext: React.Context<UseCustomerDataContextPayload> = createContext<UseCustomerDataContextPayload>(
  {
    customerId: undefined,
  }
);

export const useCustomerData: () => UseCustomerDataContextPayload = () => useContext(UseCustomerDataContext);
