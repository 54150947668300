import React, { useRef, useEffect } from 'react';
import { ChartConfiguration, Chart, ChartDataset } from 'chart.js';
import { format } from 'date-fns';

type ServiceChartProps = {
  data: {
    x: number;
    y: number;
  }[];
  averageData: {
    x: number;
    y: number;
  }[];
};

export default function ServiceChart(props: ServiceChartProps): JSX.Element {
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });

  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    if (ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        const minX = Math.min(...props.data.map((item) => item.x));
        const maxX = Math.max(...props.data.map((item) => item.x));

        const mainDataset = {
          data: props.data,
          borderColor: '#f15757',
          backgroundColor: '#f15757',
          borderWidth: 2,
          fill: true,
          label: 'Segnalazioni di interruzione servizio',
          type: 'line' as const,
        } as ChartDataset;

        const averageDataset = {
          data: props.averageData,
          borderColor: 'grey',
          backgroundColor: 'grey',
          borderWidth: 1,
          borderDash: [10, 5],
          label: 'Baseline',
          type: 'line' as const,
        } as ChartDataset;

        const chartOptions = {
          type: 'line',
          data: {
            datasets: [averageDataset, mainDataset],
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            pointHitRadius: 20,
            elements: {
              point: {
                radius: 0,
              },
            },
            plugins: {
              legend: {
                display: true,
              },
              tooltip: {
                callbacks: {
                  title: ([context]) =>
                    `${format(Number(context?.parsed.x ? context.parsed.x * 1000 : 0), 'dd/MM/yyyy HH:mm')}`,
                },
              },
            },
            scales: {
              x: {
                type: 'linear' as const,
                ticks: {
                  callback: (value) => {
                    return `${format(Number((typeof value === 'string' ? parseInt(value) : value) * 1000), 'HH:mm')}`;
                  },
                },
                min: minX,
                max: maxX,
              },
              y: {
                type: 'linear' as const,
                min: 0,
              },
            },
          },
        } as ChartConfiguration<'line'>;

        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart(ctx, chartOptions);
      }
    }
  }, [props.averageData, props.data]);

  return <canvas ref={ref} style={{ maxHeight: '100%' }}></canvas>;
}
