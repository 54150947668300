import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import DownDetectorRepository from '../api/DownDetectorRepository';
import { EmbeddedService, Service, ServiceAPIResponseSchema, HeatMap, HeatMapSchema } from '../entities/DownDetector';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';
import { downDetectorActions } from '../store/reducers/downDetector';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type DownDetectorState = RootState['downDetector'];

type UseDownDetector = {
  readHeatMap: (id: number, slug: string) => Promise<HeatMap | void>;
  readHeatMapRequest: DownDetectorState['requests']['readHeatMap'];

  embeddedServiceList: DownDetectorState['embeddedServiceList'];
  readEmbeddedServiceList: () => Promise<void | EmbeddedService>;
  readEmbeddedServiceListRequest: DownDetectorState['requests']['readEmbeddedServiceList'];

  embeddedServiceDashboardList: DownDetectorState['embeddedServiceDashboardList'];
  readEmbeddedServiceDashboardList: () => Promise<void>;
  readEmbeddedServiceDashboardListRequest: DownDetectorState['requests']['readEmbeddedServiceDashboardList'];

  readService: (id: number) => Promise<void | Service>; //dichiaro il metodo che chiama l'api
  readServiceRequest: DownDetectorState['requests']['readService'];
};

export function useDownDetector(): UseDownDetector {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      DownDetectorRepository.updateAuthToken(accessToken);
      DownDetectorRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const embeddedServiceList = useSelector((state: RootState) => state.downDetector.embeddedServiceList);
  const readEmbeddedServiceListRequest = useSelector(
    (state: RootState) => state.downDetector.requests.readEmbeddedServiceList
  );
  const embeddedServiceDashboardList = useSelector(
    (state: RootState) => state.downDetector.embeddedServiceDashboardList
  );
  const readEmbeddedServiceDashboardListRequest = useSelector(
    (state: RootState) => state.downDetector.requests.readEmbeddedServiceDashboardList
  );
  const readServiceRequest = useSelector((state: RootState) => state.downDetector.requests.readService);
  const readHeatMapRequest = useSelector((state: RootState) => state.downDetector.requests.readHeatMap);

  const readHeatMap: UseDownDetector['readHeatMap'] = useCallback(
    async (id: number, slug: string) => {
      dispatch(downDetectorActions.readHeatMapRequest());
      let response: HeatMap;

      try {
        response = await DownDetectorRepository.getHeatMap('IT', id, slug);
      } catch (error) {
        dispatch(downDetectorActions.readHeatMapFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      const parseResult = HeatMapSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          downDetectorActions.readHeatMapParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseDownDetector.readHeatMap'),
          })
        );
      } else {
        dispatch(downDetectorActions.readHeatMapSuccess());
        return response;
      }
    },
    [dispatch]
  );

  const readEmbeddedServiceList: UseDownDetector['readEmbeddedServiceList'] = useCallback(async () => {
    dispatch(downDetectorActions.readEmbeddedServiceListRequest());
    let response: { companies: Array<EmbeddedService> };

    try {
      response = await DownDetectorRepository.getEmbeddedServiceList(undefined, undefined, [
        'id',
        'name',
        'slug',
        'logos',
        '_heatmap_available',
      ]);
    } catch (error) {
      dispatch(downDetectorActions.readEmbeddedServiceListFailure({ error: new RequestError(error as AxiosError) }));
      return;
    }

    dispatch(downDetectorActions.readEmbeddedServiceListSuccess(response.companies));
  }, [dispatch]);

  const readEmbeddedServiceDashboardList: UseDownDetector['readEmbeddedServiceDashboardList'] = useCallback(async () => {
    dispatch(downDetectorActions.readEmbeddedServiceDashboardListRequest());
    let response: { companies: Array<EmbeddedService> };

    try {
      response = await DownDetectorRepository.getEmbeddedServiceList('IT', 'preview');
    } catch (error) {
      dispatch(
        downDetectorActions.readEmbeddedServiceDashboardListFailure({ error: new RequestError(error as AxiosError) })
      );
      return;
    }

    dispatch(downDetectorActions.readEmbeddedServiceDashboardListSuccess(response.companies));
  }, [dispatch]);

  const readService: UseDownDetector['readService'] = useCallback(
    async (id: number) => {
      dispatch(downDetectorActions.readServiceRequest());
      let response: { companies: Service };

      try {
        response = await DownDetectorRepository.getService('IT', id);
      } catch (error) {
        dispatch(downDetectorActions.readServiceFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      const parseResult = ServiceAPIResponseSchema.safeParse(response);
      if (parseResult.success === false) {
        dispatch(
          downDetectorActions.readServiceParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseDownDetector.readService'),
          })
        );
      } else {
        dispatch(downDetectorActions.readServiceSuccess());
        return response.companies;
      }
    },
    [dispatch]
  );

  return {
    readHeatMap,
    readHeatMapRequest,
    embeddedServiceList,
    readEmbeddedServiceList,
    readEmbeddedServiceListRequest,
    embeddedServiceDashboardList,
    readEmbeddedServiceDashboardList,
    readEmbeddedServiceDashboardListRequest,
    readService,
    readServiceRequest,
  };
}
