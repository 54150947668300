import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChevronDown from '@mitch528/mdi-material-ui/ChevronDown';
import ChevronUp from '@mitch528/mdi-material-ui/ChevronUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CustomerInfoSection } from '../../../entities/Customer';
import { useCustomer } from '../../../hooks/useCustomer';
import KeyValueList from '../../KeyValueList';
import LoaderView from '../../LoaderView';

interface CustomerInfoCardProps {
  section: CustomerInfoSection;
  sections: Array<CustomerInfoSection>;
}

function CustomerInfoCard({ section, sections }: CustomerInfoCardProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const currentDate = new Date();

  //function for calculating the difference btw dates
  function intervalTimeCalculator(currentDate: Date, alert_modification_data: string | null | undefined) {
    if (alert_modification_data !== null && alert_modification_data !== undefined) {
      const alert_data = new Date(alert_modification_data).getTime();

      if (currentDate.getTime() <= alert_data) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <Card
      sx={{
        margin: 0,
        marginRight: 1,
        flex: '2 1 auto',
        display: 'flex',
        maxWidth: '33vw',
        flexDirection: 'column',
        '&:last-of-type': {
          marginRight: 0,
        },
        '& .MuiCardContent-root': {
          paddingBottom: 2,
        },
        '& .MuiButtonBase-root': {
          marginTop: 'auto',
          height: 32,
          padding: 0,
          borderRadius: 0,
          flexGrow: section.rules.length < 2 ? 1 : 2,
          flexBasis: `${100 / sections.length}%`,
        },
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <CardContent>
        <Box
          component="h5"
          key={section.section_name}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            typography: 'h6',
            margin: 0,
            marginBottom: 1,
            lineHeight: '16px',
            height: '16px',
          }}
        >
          {section.section_name}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            minHeight: 0,
            '&>*': {
              flex: '1 1 calc(50% - 8px)',
              padding: 0,
              paddingRight: 1,
              marginRight: 1,
              borderRight: `1px solid`,
              borderRightColor: `grey.200`,
              '&:last-of-type': {
                borderRight: 0,
                marginRight: 0,
                paddingRight: 0,
              },
            },
          }}
        >
          {section.rules.map((rule) => {
            return (
              <div key={`${section.section_name}-${rule.rule_name}-title`}>
                <Typography
                  key={`${section.section_name}-${rule.rule_name}-title`}
                  variant="body1"
                  component="h6"
                  fontWeight="bold"
                  gutterBottom
                >
                  {rule.rule_name}
                </Typography>
                {rule.value.find((value) => value.collapsed === false) && (
                  <KeyValueList
                    key={`${section.section_name}-${rule.rule_name}-values`}
                    entries={rule.value
                      .filter((v) => (expanded ? true : v.collapsed === false))
                      .map((v) => {
                        return {
                          key: v.variable,
                          value: v.value as string,
                          warning: intervalTimeCalculator(currentDate, v.alert_modification_data),
                          //warning: Boolean(v.alert_modification_data),
                        };
                      })}
                  />
                )}
              </div>
            );
          })}
        </Box>
      </CardContent>
      {section.rules.find((rule) => rule.value.find((value) => value.collapsed === true)) && (
        <Button size="small" onClick={() => setExpanded((expanded) => !expanded)} title="Mostra tutto">
          {expanded ? <ChevronUp /> : <ChevronDown />}
        </Button>
      )}
    </Card>
  );
}

export default function CustomerInfo(): JSX.Element {
  const { customer: customerId } = useParams() as { customer?: string | undefined };
  const { customerInfo } = useCustomer();

  const sections = useMemo(() => {
    if (!customerInfo || !customerInfo.info) {
      return [];
    }
    if (customerInfo.customer_name !== customerId) {
      // clean up after change customer
      return [];
    }

    return customerInfo?.info.map((section, sectionIndex, sections) => (
      <CustomerInfoCard key={section.section_name} section={section} sections={sections} />
    ));
  }, [customerId, customerInfo]);

  return (
    <Box
      sx={{
        height: '100%',
        paddingBottom: 1,
      }}
    >
      <LoaderView condition={customerInfo?.info !== undefined} minHeight="10vh">
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            position: 'relative',
            flexDirection: 'row',
            '&>h5': {
              breakAfter: 'avoid',
            },
          }}
        >
          {sections}
        </Box>
      </LoaderView>
    </Box>
  );
}
