import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import ChevronLeft from '@mitch528/mdi-material-ui/ChevronLeft';
import ChevronRight from '@mitch528/mdi-material-ui/ChevronRight';
import merge from 'lodash/merge';
import Box, { BoxProps } from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import { Test, TestCategory, TestRule, TestSection } from '../../../../entities/HubTest';
import { colorStringToStatus, worstStatus } from '../../../../entities/Status';
import { useCustomerData } from '../../../../hooks/useCustomerData';
import { useHubTest } from '../../../../hooks/useHubTest';
import { formatDate } from '../../../../utils/formatDate';
import ApplicationErrorProvider from '../../../ApplicationError/ApplicationErrorProvider';
import { useBreadcrumbs } from '../../../Breadcrumbs';
import SplitStatusCircle from '../../../status/SplitStatusCircle';

const categoryUid = (category: TestCategory) => `c-${category.id}`;
const sectionUid = (category: TestCategory, section: TestSection) => `${categoryUid(category)}-s-${section.id}`;
const ruleUid = (category: TestCategory, section: TestSection, rule: TestRule) =>
  `${categoryUid(category)}-s-${section.id}-u-${rule.id}`;

function NestedTableCell(props: BoxProps & { nesting: number }): JSX.Element {
  return (
    <Box
      className={`nested-${props.nesting}`}
      component="td"
      sx={{
        px: 0,
        py: 0.5,
        borderColor: 'grey.300',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        fontSize: '0.75rem',
      }}
      {...props}
    >
      {props.nesting === 0 ? (
        props.children
      ) : (
        <Box
          className="nested-1-box"
          sx={{
            backgroundColor: `grey.100`,
            px: 0,
            py: 2,
            minHeight: 48,
          }}
        >
          {props.nesting === 1 ? (
            props.children
          ) : (
            <Box
              className="nested-2-box"
              sx={{
                backgroundColor: `grey.300`,
                px: 1.5,
                py: 0,
                minHeight: 30,
              }}
            >
              {props.children}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

function LightTooltip(props: TooltipProps) {
  return (
    <Tooltip
      {...props}
      sx={{
        '.MuiTooltip-tooltip': {
          backgroundColor: 'white',
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: 11,
          padding: 0,
        },
        ...props.sx,
      }}
    />
  );
}

const StatusRow = React.forwardRef(function StatusRow(props: BoxProps, ref: React.Ref<unknown>): JSX.Element {
  return (
    <Box
      ref={ref}
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        '.SplitStatusCircle-root': {
          fontSize: '1.75rem',
          mr: 2,
          '&:last-child': {
            mr: 0,
          },
        },
        whiteSpace: 'nowrap',
        ...props.sx,
      }}
    />
  );
});

const StatusButton = React.forwardRef(function StatusButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
): JSX.Element {
  return (
    <Button
      ref={ref}
      {...props}
      size="small"
      sx={{
        display: 'flex',
        alignItems: 'center',
        '.SplitStatusCircle-root': {
          fontSize: '1.75rem',
          mr: 2,
        },
        whiteSpace: 'nowrap',
        textTransform: 'none',
        mx: 1,
        my: 0.5,
        color: 'inherit',
        fontWeight: 'inherit',
        ...props.sx,
      }}
    />
  );
});

interface TableTestSection extends Omit<TestSection, 'rules'> {
  rules: Record<string, TestRule>;
}

interface TableTestCategory extends Omit<TestCategory, 'sections'> {
  sections: Record<string, TableTestSection>;
}

export default function TestsHistory(): JSX.Element | null {
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { customerId } = useCustomerData();
  const [query, setQuery] = useState({
    page: 0,
    prev: [] as string[],
    current: '',
    next: '',
  });
  const { hubTestById, testListByQuery, nextRequestIdByRequestId, readHubTestList } = useHubTest();

  const testList = useMemo(() => testListByQuery[query.current], [testListByQuery, query]);
  const nextRequestId = useMemo(() => nextRequestIdByRequestId[query.current], [nextRequestIdByRequestId, query]);
  useBreadcrumbs({ label: t('navigation:testsHistory'), path: url, showInTitle: false });

  useEffect(() => {
    if (customerId) {
      readHubTestList(customerId, query.current);
    }
  }, [customerId, readHubTestList, query]);

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const sortedTests = useMemo(() => {
    return (
      testList
        ?.map((testId) => hubTestById[testId])
        .filter((test): test is Test => {
          return Boolean(test);
        })
        .sort((a, b) => -a.request_id.localeCompare(b.request_id)) ?? []
    );
  }, [hubTestById, testList]);

  const categoriesMap = useMemo(() => {
    if (!sortedTests) {
      return {};
    }
    const tests = sortedTests.map((test) => {
      if (!test) {
        return {};
      }

      const categories: Record<string, TableTestCategory> = {};

      for (let i = 0; i < test.categories.length; i++) {
        const category = test.categories[i];
        const sections: Record<string, TableTestSection> = {};

        if (category) {
          for (let j = 0; j < category.sections.length; j++) {
            const section = category.sections[j];
            if (section) {
              const rules: Record<string, TestRule> = {};

              for (let k = 0; k < section.rules.length; k++) {
                const rule = section.rules[k];
                if (rule) {
                  rules[rule.id] = rule;
                }
              }

              sections[section.id] = { ...section, rules };
            }
          }

          categories[category.id] = { ...category, sections };
        }
      }
      return { ...test, categories };
    });

    const merged = merge({}, ...Object.values(tests ?? {})).categories as Record<string, TableTestCategory>;

    return merged ?? {};
  }, [sortedTests]);

  const categories = useMemo(() => {
    const categories = [];

    for (const [_, categoryValue] of Object.entries(categoriesMap)) {
      const sections = [];

      for (const [_, sectionValue] of Object.entries(categoryValue.sections)) {
        const rules = [];

        for (const [_, ruleValue] of Object.entries(sectionValue.rules)) {
          rules.push({
            ...ruleValue,
          });
        }

        sections.push({
          ...sectionValue,
          rules: rules.sort((a, b) => a.id.localeCompare(b.id)),
        });
      }

      categories.push({
        ...categoryValue,
        sections: sections.sort((a, b) => a.id.localeCompare(b.id)),
      });
    }

    return categories.sort((a, b) => a.id.localeCompare(b.id));
  }, [categoriesMap]);

  if (!hubTestById) {
    return null;
  }

  const categoryHeaderCells = [];

  for (let i = 0; i < categories.length; i++) {
    const category = categories[i] as TestCategory;

    categoryHeaderCells.push(<TableCell key={categoryUid(category)}>{category.id}</TableCell>);

    if (expanded[categoryUid(category)]) {
      for (let j = 0; j < category.sections.length; j++) {
        const section = category.sections[j] as TestSection;

        categoryHeaderCells.push(<TableCell key={sectionUid(category, section)}>{section.id}</TableCell>);

        if (expanded[sectionUid(category, section)]) {
          for (let k = 0; k < section.rules.length; k++) {
            const rule = section.rules[k] as TestRule;

            categoryHeaderCells.push(<TableCell key={ruleUid(category, section, rule)}>{rule.id}</TableCell>);
          }
        }
      }
    }
  }

  return (
    <ApplicationErrorProvider>
      <Box sx={{ overflow: 'auto', width: '100%', maxWidth: '100vw', p: 1 }}>
        <Paper>
          <Box sx={{ display: 'flex', 'td,th': { height: 62, whiteSpace: 'nowrap' } }}>
            <TableContainer
              component={Paper}
              sx={{
                border: 0,
                borderRadius: 0,
                flex: '0 0 auto',
                width: 'auto',
                overflowX: 'visible',
                'td,th': { px: 2 },
              }}
            >
              <Table aria-label="simple table" sx={{ width: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{'Id'}</TableCell>
                    <TableCell>{'Data inizio'}</TableCell>
                    <TableCell>{'Data fine'}</TableCell>
                    <Box
                      component={TableCell}
                      sx={{
                        borderColor: 'grey.100',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                      }}
                    >
                      {'Esito'}
                    </Box>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedTests.map((hubTest) => {
                    return (
                      <TableRow key={hubTest.request_id} style={{ position: 'relative' }}>
                        <TableCell>{hubTest.request_id}</TableCell>
                        <TableCell>{formatDate(hubTest.start_date)}</TableCell>
                        <TableCell>{formatDate(hubTest.end_date)}</TableCell>
                        <Box
                          component={TableCell}
                          sx={{
                            borderColor: 'grey.100',
                            borderRightWidth: 1,
                            borderRightStyle: 'solid',
                          }}
                        >
                          <StatusRow>
                            <SplitStatusCircle
                              firstStatus={
                                colorStringToStatus(hubTest.start_color) ??
                                worstStatus(
                                  hubTest.categories.map((category) => colorStringToStatus(category.start_color))
                                )
                              }
                              lastStatus={
                                colorStringToStatus(hubTest.end_color) ??
                                worstStatus(
                                  hubTest.categories.map((category) => colorStringToStatus(category.end_color))
                                )
                              }
                            />
                          </StatusRow>
                        </Box>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component={Paper} sx={{ border: 0, borderRadius: 0 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <Box component={TableRow} sx={{ th: { opacity: 0.01, userSelect: 'none' } }}>
                    {categoryHeaderCells}
                    <Box component={TableCell} width="100%" sx={{ '&.MuiTableCell-root': { p: 0 } }}>
                      <div />
                    </Box>
                  </Box>
                </TableHead>
                <TableBody>
                  {sortedTests.map((hubTest) => {
                    const tableCells = [];

                    for (let i = 0; i < categories.length; i++) {
                      const category = categories[i] as TestCategory;

                      const testCategory = hubTest.categories.find((c) => c.id === category.id);

                      if (testCategory) {
                        tableCells.push(
                          <NestedTableCell key={categoryUid(category)} nesting={0}>
                            <StatusButton
                              onClick={() =>
                                setExpanded((exp) => ({
                                  ...exp,
                                  [categoryUid(category)]: expanded[categoryUid(category)] ? false : true,
                                }))
                              }
                            >
                              <SplitStatusCircle
                                firstStatus={colorStringToStatus(testCategory.start_color)}
                                lastStatus={colorStringToStatus(testCategory.end_color)}
                              />
                              {category?.id}
                            </StatusButton>
                          </NestedTableCell>
                        );

                        if (expanded[categoryUid(category)]) {
                          for (let j = 0; j < category.sections.length; j++) {
                            const section = category.sections[j] as TestSection;

                            const testSection = testCategory.sections.find((s) => s.id === section?.id);

                            if (testSection) {
                              tableCells.push(
                                <NestedTableCell key={sectionUid(category, section)} nesting={1}>
                                  <StatusButton
                                    onClick={() =>
                                      setExpanded((exp) => ({
                                        ...exp,
                                        [sectionUid(category, section)]: expanded[sectionUid(category, section)]
                                          ? false
                                          : true,
                                      }))
                                    }
                                  >
                                    <SplitStatusCircle
                                      firstStatus={colorStringToStatus(testSection.start_color)}
                                      lastStatus={colorStringToStatus(testSection.end_color)}
                                    />
                                    {section?.id}
                                  </StatusButton>
                                </NestedTableCell>
                              );

                              if (expanded[sectionUid(category, section)]) {
                                for (let k = 0; k < section.rules.length; k++) {
                                  const rule = section.rules[k] as TestRule;

                                  const testRule = testSection.rules.find((r) => r.id === rule.id);

                                  if (testRule) {
                                    tableCells.push(
                                      <NestedTableCell key={ruleUid(category, section, rule)} nesting={2}>
                                        <LightTooltip
                                          arrow
                                          title={
                                            <Paper
                                              elevation={8}
                                              variant="elevation"
                                              sx={{ minWidth: 250, minHeight: 150, borderRadius: 1 }}
                                            >
                                              <List dense>
                                                <ListItem>
                                                  <SplitStatusCircle
                                                    firstStatus={colorStringToStatus(testRule.start_color)}
                                                    lastStatus={colorStringToStatus(testRule.end_color)}
                                                    sx={{ fontSize: '1.8em', mr: 2 }}
                                                  />
                                                  <ListItemText>
                                                    <b>{rule.id}</b>
                                                  </ListItemText>
                                                </ListItem>
                                                {rule.rags.map((rag) => (
                                                  <ListItem key={rag.description} sx={{ pl: 8 }}>
                                                    <SplitStatusCircle
                                                      firstStatus={colorStringToStatus(rag.start_color)}
                                                      lastStatus={colorStringToStatus(rag.end_color)}
                                                      sx={{ fontSize: '1.8em', mr: 2 }}
                                                    />
                                                    <ListItemText>{rag.description}</ListItemText>
                                                  </ListItem>
                                                ))}
                                                {rule.rags.length === 0 ? (
                                                  <Typography
                                                    variant="body2"
                                                    sx={{ pl: 8, pr: 4, fontStyle: 'italic', height: 100 }}
                                                  >
                                                    {'Nessuna RAG disponible per questa regola'}
                                                  </Typography>
                                                ) : null}
                                              </List>
                                            </Paper>
                                          }
                                          placement="right"
                                          TransitionComponent={Zoom}
                                        >
                                          <StatusButton sx={{ margin: 0 }}>
                                            <SplitStatusCircle
                                              firstStatus={colorStringToStatus(testRule.start_color)}
                                              lastStatus={colorStringToStatus(testRule.end_color)}
                                            />
                                            {rule.id}
                                          </StatusButton>
                                        </LightTooltip>
                                      </NestedTableCell>
                                    );
                                  } else {
                                    tableCells.push(
                                      <NestedTableCell key={ruleUid(category, section, rule)} nesting={2}>
                                        {/*rule.id*/}
                                      </NestedTableCell>
                                    );
                                  }
                                }
                              }
                            } else {
                              tableCells.push(
                                <NestedTableCell key={sectionUid(category, section)} nesting={1}>
                                  {/*section.id*/}
                                </NestedTableCell>
                              );
                              if (expanded[sectionUid(category, section)]) {
                                section.rules.forEach((r) =>
                                  tableCells.push(
                                    <NestedTableCell key={ruleUid(category, section, r)} nesting={2}>
                                      {/*r.id*/}
                                    </NestedTableCell>
                                  )
                                );
                              }
                            }
                          }
                        }
                      } else {
                        tableCells.push(
                          <NestedTableCell key={categoryUid(category)} nesting={0}>
                            {/*category.id*/}
                          </NestedTableCell>
                        );

                        if (expanded[categoryUid(category)]) {
                          category.sections.forEach((s) => {
                            tableCells.push(
                              <NestedTableCell key={sectionUid(category, s)} nesting={1}>
                                {/*s.id*/}
                              </NestedTableCell>
                            );
                            if (expanded[sectionUid(category, s)]) {
                              s.rules.forEach((r) =>
                                tableCells.push(
                                  <NestedTableCell key={ruleUid(category, s, r)} nesting={2}>
                                    {/*r.id*/}
                                  </NestedTableCell>
                                )
                              );
                            }
                          });
                        }
                      }
                    }

                    return (
                      <TableRow key={hubTest.request_id} style={{ position: 'relative' }}>
                        {tableCells}
                        <Box component={TableCell} width="100%" sx={{ '&.MuiTableCell-root': { p: 0 } }}>
                          <div />
                        </Box>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: 2,
              py: 1,
            }}
          >
            <Box sx={{ ml: 'auto', mr: 2 }}>{`${query.page * 10} - ${query.page * 10 + 10}`}</Box>
            <Button
              onClick={() => {
                setQuery((query) => {
                  const prev = [...query.prev];
                  const current = prev.pop();
                  return {
                    page: query.page - 1,
                    prev,
                    current: current ?? '',
                    next: query.current,
                  };
                });
              }}
              disabled={query.page === 0}
              sx={{ mr: 2 }}
            >
              <ChevronLeft />
            </Button>
            <Button
              onClick={() => {
                setQuery((query) => ({
                  page: query.page + 1,
                  prev: [...query.prev, query.current],
                  current: nextRequestId ?? '',
                  next: '',
                }));
              }}
              disabled={!nextRequestId}
            >
              <ChevronRight />
            </Button>
          </Box>
        </Paper>
      </Box>
    </ApplicationErrorProvider>
  );
}
