import React, { useState } from 'react';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../../entities/Chart';
import { colorStringToStatus } from '../../entities/Status';
import statusColor from '../status/statusColor';
import GaugeChart, { GaugeChartData } from './GaugeChart';
import HistogramChart, { HistogramChartData } from './HistogramChart';
import InterferenceChart, { InterferenceChartSerie } from './InterferenceChart';
import LineFilledChart, { LineFilledData } from './LineFilledChart';
import MultiLineChart, { MultiLineChartSerie } from './MultiLineChart';
import PieChart, { PieChartData } from './PieChart';
import TableChart, { TableChartSeries } from './TableChart';
import TimeHistogramChart, { TimeHistogramChartData } from './TimeHistogramChart';

export type ChartProps = {
  type: string;
  data: Chart | undefined;
  visible: boolean;
  zoomed?: boolean;
  onZoomed?: (value: boolean) => void;
};

export default function ChartSeries(props: ChartProps): JSX.Element | null {
  const theme = useTheme();

  const [warning, setWarning] = useState(false);

  const lineChartSeries: MultiLineChartSerie[] = React.useMemo(() => {
    if (props.type === 'line_chart' && props.data) {
      return props.data.trendlines
        .map((trendline) => {
          if (trendline.chart_type === 'line_chart') {
            const axisMax = trendline.thresholds
              ? Math.max(
                  trendline.values.reduce((max, [, y]) => Math.max(max, y), Number.MIN_VALUE),
                  trendline.thresholds.reduce((max, t) => Math.max(max, Number(t.value)), Number.MIN_VALUE)
                )
              : trendline.values.reduce((max, [, y]) => Math.max(max, y), Number.MIN_VALUE);
            const axisMin = trendline.thresholds
              ? Math.min(
                  trendline.values.reduce((min, [, y]) => Math.min(min, y), Number.MAX_VALUE),
                  trendline.thresholds.reduce((min, t) => Math.min(min, Number(t.value)), Number.MAX_VALUE)
                )
              : trendline.values.reduce((min, [, y]) => Math.min(min, y), Number.MAX_VALUE);
            const deltaAxis = (axisMax - axisMin || 1) * 1;

            return {
              label: `${trendline.label_name}: ${trendline.label_value}`, // trendline.series_name,
              axisId: trendline.y_label || trendline.series_name,
              data: trendline.values
                .map(([x, y]) => ({
                  x,
                  y,
                }))
                .sort((a, b) => a.x - b.x),
              thresholds: trendline.thresholds
                ? trendline.thresholds.map((t) => ({ ...t, value: Number(t.value) }))
                : undefined,
              formatAxisLabel: (value) => `${value} ${trendline.y_unit}`,
              formatTooltipLabel: (value) => `${value} ${trendline.y_unit}`,
              axisUnit: trendline.y_unit,
              x_mappings: trendline.x_mappings ? trendline.x_mappings : undefined,
              y_mappings: trendline.y_mappings ? trendline.y_mappings : undefined,
              y_min: trendline.y_min || trendline.y_min === 0 ? trendline.y_min : axisMin - deltaAxis / 5,
              y_max: trendline.y_max || trendline.y_max === 0 ? trendline.y_max : axisMax + deltaAxis / 5,
              y_unit: trendline.y_unit,
              y_label: trendline.y_label,
            } as MultiLineChartSerie;
          }

          return undefined;
        })
        .filter((t): t is MultiLineChartSerie => typeof t !== 'undefined');
    }
    return [];
  }, [props.data, props.type]);

  const lineFilledChartTrendlines: LineFilledData[] = React.useMemo(() => {
    if (props.type === 'line_filled_chart' && props.data) {
      const lineFilledChartData: LineFilledData[] = [];
      props.data.trendlines.forEach((trendline) => {
        const lineFilledChartTrendline: LineFilledData = {
          chartName: '',
          yUnit: '',
          yMin: null,
          yMax: null,
          series: [],
        };
        if (props.type === 'line_filled_chart' && props.data) {
          if (trendline && trendline.chart_type === 'line_filled_chart') {
            lineFilledChartTrendline.chartName = trendline.chart_name;
            lineFilledChartTrendline.yUnit = trendline.y_unit;
            lineFilledChartTrendline.yMin = trendline.y_min || trendline.y_min === 0 ? trendline.y_min : null;
            lineFilledChartTrendline.yMax = trendline.y_max || trendline.y_min === 0 ? trendline.y_max : null;
            const lineFilledSeries: LineFilledData['series'] = [];
            trendline.series.forEach((serie) => {
              lineFilledSeries.push({
                name: serie.name,
                data: {
                  x_label: serie.data.x_label,
                  y_label: serie.data.y_label,
                  label_name: serie.data.label_name ? serie.data.label_name : 'Soglia',
                  label_value: serie.data.label_value ? serie.data.label_value : 'Soglia',
                  threshold: serie.data.threshold,
                  values: serie.data.values
                    .map(([x, y]) => ({
                      x,
                      y,
                    }))
                    .sort((a, b) => a.x - b.x),
                  fillAreaTo: serie.data.fill_area_to,
                },
              });
              lineFilledChartTrendline.series = lineFilledSeries;
            });
          }
        }
        lineFilledChartData.push(lineFilledChartTrendline);
      });

      return lineFilledChartData;
    }
    return [];
  }, [props.data, props.type]);

  const pieChartSeries: PieChartData = React.useMemo(() => {
    const pieChartData: PieChartData = { data: [], labels: [], chartTitle: '' };
    if (props.type === 'pie_chart' && props.data) {
      const trendline = props.data.trendlines[0];
      if (trendline && trendline.chart_type === 'pie_chart') {
        pieChartData.data = trendline.slices.map((slice) => slice.value);
        pieChartData.labels = trendline.slices.map((slice) => slice.label);
        pieChartData.formatLabel = (value) => `${value}${trendline.unit || ''}`;
        pieChartData.chartTitle = trendline.title;
      }
    }
    return pieChartData;
  }, [props.data, props.type]);

  const gaugeChartSeries: GaugeChartData = React.useMemo(() => {
    const gaugeChartData: GaugeChartData = {
      data: [],
      labels: [],
      colors: [],
      chartTitle: '',
      currentValue: 0,
      minValue: 0,
      maxValue: 1,
    };
    if (props.type === 'gauge_chart' && props.data) {
      const trendline = props.data.trendlines[0];
      if (trendline && trendline.chart_type === 'gauge_chart') {
        const intervals = [...trendline.intervals];

        const firstInterval = intervals[0];
        const lastInterval = intervals[intervals.length - 1];

        if (firstInterval && firstInterval.min_value > trendline.min_value) {
          intervals.unshift({
            label: `${trendline.min_value}${trendline.unit || ''} — ${firstInterval?.min_value}${trendline.unit || ''}`,
            color: '',
            min_value: trendline.min_value,
            max_value: firstInterval?.min_value,
          });
        }

        if (lastInterval && lastInterval.max_value < trendline.max_value) {
          intervals.push({
            label: `${lastInterval.max_value}${trendline.unit || ''} — ${trendline.max_value}${trendline.unit || ''}`,
            color: '',
            min_value: lastInterval.max_value,
            max_value: trendline.max_value,
          });
        }

        gaugeChartData.data = [];
        gaugeChartData.labels = [];
        gaugeChartData.colors = [];

        for (let i = 0; i < intervals.length; i++) {
          const interval = intervals[i];
          if (interval) {
            gaugeChartData.data[i] = (interval.max_value ?? 0) - (interval.min_value ?? 0);
            gaugeChartData.labels[i] = `${interval.label} (${interval.min_value}${trendline.unit || ''} — ${
              interval.max_value
            }${trendline.unit || ''})`;
            gaugeChartData.colors[i] = statusColor(colorStringToStatus(interval.color), theme);
          }
        }

        gaugeChartData.chartTitle = `${trendline.title} (${trendline.current_value} ${trendline.unit || ''})`;
        gaugeChartData.currentValue = trendline.current_value;
        gaugeChartData.minValue = trendline.min_value;
        gaugeChartData.maxValue = trendline.max_value;
      }
    }
    return gaugeChartData;
  }, [props.data, props.type, theme]);

  const histogramChartSeries: HistogramChartData = React.useMemo(() => {
    const histogramChartData: HistogramChartData = { data: [], labels: [], chartTitle: '' };
    if (props.type === 'histogram_chart' && props.data) {
      const trendline = props.data.trendlines[0];
      if (trendline && trendline.chart_type === 'histogram_chart') {
        histogramChartData.data = trendline.categories.map((slice) => slice.value);
        histogramChartData.labels = trendline.categories.map((slice) => slice.label);
        histogramChartData.formatLabel = (value) => `${value}${trendline.unit}`;
        histogramChartData.chartTitle = trendline.title;
        histogramChartData.monochrome = trendline.monochrome;
        histogramChartData.unit = trendline.unit;
        histogramChartData.yLabel = trendline.y_label;
        histogramChartData.thresholds = trendline.thresholds?.map((t) => ({ ...t, value: Number(t.value) }));
        histogramChartData.timeEvents = trendline.events;
      }
    }
    return histogramChartData;
  }, [props.data, props.type]);

  const timeHistogramChartSeries: TimeHistogramChartData = React.useMemo(() => {
    const histogramChartData: TimeHistogramChartData = { data: [], labels: [], chartTitle: '' };
    if (props.type === 'time_histogram_chart' && props.data) {
      const trendline = props.data.trendlines[0];
      if (trendline && trendline.chart_type === 'time_histogram_chart') {
        histogramChartData.data = trendline.series.map((slice) => ({
          x: format(new Date(slice.date_time * 1000), 'yyyy-MM-dd'),
          y: slice.value,
          label: slice.label,
        }));
        histogramChartData.formatLabel = (value) => `${value} ${trendline.unit}`;
        histogramChartData.formatTitle = (value: number) => format(value, trendline.date_time_format ?? 'dd/MM/yyyy');
        histogramChartData.chartTitle = trendline.title;
        histogramChartData.monochrome = trendline.monochrome;
        histogramChartData.unit = trendline.unit;
        histogramChartData.yLabel = trendline.y_label;
        histogramChartData.thresholds = trendline.thresholds?.map((t) => ({ ...t, value: Number(t.value) }));
        histogramChartData.timeEvents = trendline.events;
        histogramChartData.dateTimeUnit = trendline.time_unit;
        histogramChartData.dateTimeFormat = trendline.date_time_format;
      }
    }
    return histogramChartData;
  }, [props.data, props.type]);

  const interferenceChartSeries: InterferenceChartSerie[] = React.useMemo(() => {
    if (props.type === 'interference_chart' && props.data) {
      setWarning(false);
      return (
        props.data.trendlines

          // eslint-disable-next-line array-callback-return
          .map((trendline) => {
            if (trendline.chart_type === 'interference_chart') {
              const data = [];

              const channel = trendline.values[1][0];
              /**
               * Hide networks that are using channels not allowed in Italy (EU)
               * Allowed channels are 1-14 and 32-144 according to
               * https://en.wikipedia.org/wiki/List_of_WLAN_channels#5_GHz_(802.11a/h/j/n/ac/ax)
               */
              // errore
              if (channel < 1) {
                // check if belongs to the user (in case of 2.4G = -1)
                if (trendline.user_ssid) {
                  setWarning(true);
                }
                return undefined;
              }

              // errore
              if ((channel > 14 && channel < 32) || channel > 144) {
                return undefined;
              }

              // channel > 32 && < 144 -> 5ghz
              // channel > 1 && < 14 -> 2.4gh

              const mappedValues = trendline.values;

              // y = ax^2 + bx + c
              const Ax = mappedValues[0][0];
              const Ay = mappedValues[0][1]; //should be always -100
              const Bx = mappedValues[2][0];
              const Vx = mappedValues[1][0];
              const Vy = mappedValues[1][1];
              const a = -(Vy - Ay) / Math.pow(Ax - Vx, 2);
              const b = -2 * a * Vx;
              const c = Ay + a * Ax * (-Ax + 2 * Vx);

              const s = (Bx - Ax) / 8;
              let x = Ax;

              while (x <= Bx) {
                data.push({ x, y: a * Math.pow(x, 2) + b * x + c });
                x = Number((x + s).toFixed(2));
              }

              return {
                label: `${trendline.label_name}: ${trendline.label_value}${
                  trendline.user_ssid ? (channel > 14 ? ' (5 GHz)' : ' (2.4GHz)') : ''
                }`,
                data,
                axisUnit: trendline.y_unit,
                x_label: trendline.x_label,
                y_label: trendline.y_label,
                y_unit: trendline.y_unit,
                chartTitle: trendline.chart_name,
                band_width: trendline.band_width,
                device_type: trendline.device_type,
                thresholds: trendline.thresholds.map((t) => ({ ...t, value: Number(t.value) })),
                customerSSID: trendline.user_ssid ?? false,
              } as InterferenceChartSerie;
            }
          })
          .filter((t): t is InterferenceChartSerie => typeof t !== 'undefined')
      );
    }
    return [];
  }, [props.data, props.type]);
  const tableChartSeries: TableChartSeries[] = React.useMemo(() => {
    if (props.type === 'table_chart' && props.data) {
      return props.data.trendlines
        .map((trendline) => {
          if (trendline.chart_type === 'table_chart') {
            return {
              header: trendline.column_names,
              rows: trendline.entries,
            } as TableChartSeries;
          }

          return undefined;
        })
        .filter((t): t is TableChartSeries => typeof t !== 'undefined');
    }
    return [];
  }, [props.data, props.type]);

  switch (props.type) {
    case 'line_chart':
      return (
        <MultiLineChart
          series={lineChartSeries}
          visible={props.visible}
          reset={!props.zoomed}
          onZoom={props.onZoomed}
        />
      );
    case 'line_filled_chart':
      return <LineFilledChart data={lineFilledChartTrendlines} visible={props.visible} />;
    case 'pie_chart':
      return <PieChart data={pieChartSeries} visible={props.visible} />;
    case 'gauge_chart':
      return <GaugeChart data={gaugeChartSeries} visible={props.visible} />;
    case 'histogram_chart':
      return <HistogramChart data={histogramChartSeries} visible={props.visible} />;
    case 'time_histogram_chart':
      return <TimeHistogramChart data={timeHistogramChartSeries} visible={props.visible} />;
    case 'interference_chart':
      return (
        <InterferenceChart
          series={interferenceChartSeries}
          visible={props.visible}
          reset={!props.zoomed}
          onZoom={props.onZoomed}
          warning={warning}
        />
      );
    case 'table_chart':
      return <TableChart series={tableChartSeries} visible={props.visible} />;
    default:
      return null;
  }
}
