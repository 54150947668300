import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Customer } from '../../../../entities/Customer';
import { useCustomer } from '../../../../hooks/useCustomer';
import LoaderView from '../../../LoaderView';
import { Scroller } from '../../../Scroller';

export interface HubDetailsButtonProps {
  onClick: (e: React.MouseEvent) => void;
}

interface HubDetailsDialogMapTProps {
  customerId?: Customer['id'];
  title?: string;
  size: { width: number; height: number };
  visible: boolean;
  buttonComponent?: (props: HubDetailsButtonProps) => JSX.Element;
}

export default function HubDetailsDialogMapT(props: HubDetailsDialogMapTProps): JSX.Element {
  const [word, setWord] = useState<string>('');
  const { customerMapT, readCustomerMapT, readCustomerMapTRequest } = useCustomer();
  const { t } = useTranslation();

  useEffect(() => {
    // on dialog open
    if (props.visible && props.customerId) {
      //initializers
      readCustomerMapT(props.customerId);
    }
    // clean up
    return () => {
      // destructors
      setWord('');
    };
  }, [props.visible, readCustomerMapT, props.customerId]);

  return (
    <Box width={props.size.width} height={props.size.height}>
      <Box>
        <TextField
          type={'text'}
          variant="filled"
          value={word}
          label="Cerca"
          sx={{ '& >div': { borderRadius: 0 } }}
          fullWidth
          onChange={(e) => {
            setWord(e.target.value);
          }}
        />
      </Box>
      <LoaderView condition={!readCustomerMapTRequest.inProgress}>
        <Scroller sx={{ maxHeight: props.size.height - 53 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('dashboard:hubDetailsDialogMapT:fields:date_time')}</TableCell>
                  <TableCell align="right">{t('dashboard:hubDetailsDialogMapT:fields:ticket_id')}</TableCell>
                  <TableCell align="right">{t('dashboard:hubDetailsDialogMapT:fields:status')}</TableCell>
                  <TableCell align="right">{t('dashboard:hubDetailsDialogMapT:fields:reason_text')}</TableCell>
                  <TableCell align="right">{t('dashboard:hubDetailsDialogMapT:fields:cgnat_state')}</TableCell>
                  <TableCell align="right">{t('dashboard:hubDetailsDialogMapT:fields:caller')}</TableCell>
                  <TableCell align="right">
                    {t('dashboard:hubDetailsDialogMapT:fields:activation_completion_date_time')}
                  </TableCell>
                  <TableCell align="right">{t('dashboard:hubDetailsDialogMapT:fields:mac_address')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerMapT?.events
                  .filter((event) => {
                    const search = word.trim().toLowerCase();
                    if (search === '') return true;
                    return Object.values(event).some((field) => {
                      return field.toLowerCase().includes(search);
                    });
                  })
                  .sort((a, b) => b.date_time.localeCompare(a.date_time))
                  .map((mapTItem) => (
                    <TableRow key={mapTItem.date_time}>
                      <TableCell component="th" scope="row">
                        {mapTItem.date_time}
                      </TableCell>
                      <TableCell align="right">{mapTItem.ticket_id}</TableCell>
                      <TableCell align="right">{mapTItem.status}</TableCell>
                      <TableCell align="right">{mapTItem.reason_text}</TableCell>
                      <TableCell align="right">{mapTItem.cgnat_state}</TableCell>
                      <TableCell align="right">{mapTItem.caller}</TableCell>
                      <TableCell align="right">{mapTItem.activation_completion_date_time}</TableCell>
                      <TableCell align="right">{mapTItem.mac_address}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scroller>
      </LoaderView>
    </Box>
  );
}
