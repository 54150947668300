import React, { useCallback, useEffect, useState } from 'react';
import ArrowLeft from '@mitch528/mdi-material-ui/ArrowLeft';
import CalendarIcon from '@mitch528/mdi-material-ui/Calendar';
import ConnectedDevicesIcon from '@mitch528/mdi-material-ui/Devices';
import Magnify from '@mitch528/mdi-material-ui/Magnify';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useDevicesGraph } from '../../../../hooks/useDevicesGraph';
import AssuranceDateTimePicker from '../../../DateTimePicker';
import HubDetailsDialogDevicesGraph from './HubDetailsDialogDevicesGraph';

const mockedHistory = [] as number[];
const seed = 1647730800000;
for (let i = 0; i < 192; i++) {
  mockedHistory.push(seed + 900000 * i);
}
mockedHistory.pop();

export type HubDetailsDialogTimeMachineProps = {
  customerId: string;
  size: { width: number; height: number };
  visible: boolean;
};

const TIME_SLICES = [
  {
    hFrom: 0,
    hTo: 1,
  },
  {
    hFrom: 1,
    hTo: 2,
  },
  {
    hFrom: 2,
    hTo: 3,
  },
  {
    hFrom: 3,
    hTo: 4,
  },
  {
    hFrom: 4,
    hTo: 5,
  },
  {
    hFrom: 5,
    hTo: 6,
  },
  {
    hFrom: 6,
    hTo: 7,
  },
  {
    hFrom: 7,
    hTo: 8,
  },
  {
    hFrom: 8,
    hTo: 9,
  },
  {
    hFrom: 10,
    hTo: 11,
  },
  {
    hFrom: 11,
    hTo: 12,
  },
  {
    hFrom: 12,
    hTo: 13,
  },
  {
    hFrom: 13,
    hTo: 14,
  },
  {
    hFrom: 14,
    hTo: 15,
  },
  {
    hFrom: 15,
    hTo: 16,
  },
  {
    hFrom: 16,
    hTo: 17,
  },
  {
    hFrom: 17,
    hTo: 18,
  },
  {
    hFrom: 18,
    hTo: 19,
  },
  {
    hFrom: 19,
    hTo: 20,
  },
  {
    hFrom: 20,
    hTo: 21,
  },
  {
    hFrom: 21,
    hTo: 22,
  },
  {
    hFrom: 22,
    hTo: 23,
  },
  {
    hFrom: 23,
    hTo: 24,
  },
];

export default function HubDetailsDialogTimeMachine(props: HubDetailsDialogTimeMachineProps): JSX.Element {
  const { readHistory, readHistoryRequest } = useDevicesGraph();
  const [page, setPage] = useState<'timeline' | 'graph'>('timeline');

  const [startDate, setStartDate] = useState<Date | null | undefined>(null);
  const [endDate, setEndDate] = useState<Date | null | undefined>(null);
  const [calendar, setCalendar] = useState<{ dateTimestamp: number; timestamps: number[] }[]>([]);
  const [selectedDate, setSelectedDate] = useState<{ dateTimestamp: number; timestamps: number[] }>();
  const [selectedTimestamp, setSelectedTimestamp] = useState<number>();

  const [vTabIndex, setVTabIndex] = useState(0);

  useEffect(() => {
    return () => {
      setPage('timeline');
      setCalendar([]);
      setStartDate(null);
      setEndDate(null);
      setVTabIndex(0);
    };
  }, [props.visible]);

  const manageSearchPressed = useCallback(async () => {
    let history = await readHistory(props.customerId, startDate?.getTime(), endDate?.getTime());
    history = history ?? { dates: mockedHistory }; // to remove
    const _calendar: { dateTimestamp: number; timestamps: number[] }[] = [];
    if (history) {
      history.dates.forEach((date) => {
        const dateTimestamp = new Date(date).setHours(0, 0, 0);
        const timestamp = date;
        let alreadyStored = false;
        for (let i = 0; i < _calendar.length; i++) {
          const currentDay = _calendar[i];
          if (currentDay?.dateTimestamp === dateTimestamp) {
            alreadyStored = true;
            currentDay.timestamps.push(timestamp);
          }
        }
        if (!alreadyStored) {
          _calendar.push({ dateTimestamp: dateTimestamp, timestamps: [timestamp] });
        }
      });
    }
    _calendar.sort((a, b) => b.dateTimestamp - a.dateTimestamp);
    _calendar.forEach((date) => {
      date.timestamps.sort((a, b) => a - b);
    });
    setCalendar(_calendar);
  }, [endDate, props.customerId, readHistory, startDate]);

  const manageDatePressed = useCallback((timestamp: number, date: { dateTimestamp: number; timestamps: number[] }) => {
    setSelectedTimestamp(timestamp);
    setSelectedDate(date);
    setPage('graph');
  }, []);

  const renderTimeline = useCallback(
    (date: { dateTimestamp: number; timestamps: number[] }, slot: number) => {
      const timestampList = date.timestamps.filter((timestamp) => {
        const hours = new Date(timestamp).getHours();
        return (TIME_SLICES[slot]?.hFrom ?? 0) <= hours && hours < (TIME_SLICES[slot]?.hTo ?? 24);
      });
      if (!timestampList || !timestampList.length) {
        return (
          <Box
            sx={{
              fontStyle: 'italic',
              opacity: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Nessun dato.
          </Box>
        );
      }

      return timestampList.map((timestamp) => (
        <Box
          key={timestamp}
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ConnectedDevicesIcon fontSize="small" />
          <Button onClick={() => manageDatePressed(timestamp, date)}>
            {new Date(timestamp).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }).replace(/0?/, '')}
          </Button>
        </Box>
      ));
    },
    [manageDatePressed]
  );

  return page === 'timeline' ? (
    <Box
      sx={{
        p: 3,
        width: props.size.width,
        height: props.size.height,
        background: (theme) => theme.palette.background.default,
      }}
    >
      <Paper sx={{ p: 4, mb: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: 12 }}>
            <AssuranceDateTimePicker
              placeholder="Data e ora di inizio"
              date={startDate}
              onChange={(value) => setStartDate(value)}
            />
          </div>
          <div>
            <AssuranceDateTimePicker
              placeholder="Data e ora di fine"
              date={endDate}
              onChange={(value) => setEndDate(value)}
            />
          </div>
        </Box>
        <LoadingButton
          loading={readHistoryRequest.inProgress}
          variant="contained"
          startIcon={<Magnify />}
          onClick={manageSearchPressed}
        >
          Cerca
        </LoadingButton>
      </Paper>
      {calendar.length > 0 && (
        <Paper
          sx={{
            height: props.size.height - 110,
            overflow: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={vTabIndex}
              onChange={(e, newValue) => {
                setVTabIndex(newValue);
              }}
              sx={{
                minWidth: 160,
                width: 160,
                display: 'flex',
                height: '100%',
                '& .MuiTabs-flexContainer': {
                  height: '100%',
                },
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              {calendar.map((day, i) => (
                <Tab
                  key={day.dateTimestamp}
                  sx={{ flex: 1 }}
                  label={
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      {vTabIndex === i && <CalendarIcon sx={{ mr: 2 }} />}
                      {new Date(day.dateTimestamp).toLocaleDateString()}
                    </Box>
                  }
                />
              ))}
            </Tabs>
            {calendar.map((day, i) => (
              <div
                key={day.dateTimestamp}
                role="tabpanel"
                hidden={vTabIndex !== i}
                id={`v-tabpanel-${day.dateTimestamp}`}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                  }}
                >
                  {TIME_SLICES.map((slice, j) => (
                    <Box
                      key={j}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        height: '100%',
                        borderRight: '1px solid rgba(0,0,0,0.15)',
                      }}
                    >
                      <b
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          padding: 8,
                          borderBottom: '1px solid rgba(0,0,0,0.15)',
                        }}
                      >
                        {slice.hFrom}h
                      </b>
                      <Box
                        sx={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {renderTimeline(day, j)}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </div>
            ))}
          </Box>
        </Paper>
      )}
    </Box>
  ) : (
    <HubDetailsDialogDevicesGraph
      customerId={props.customerId}
      size={props.size}
      visible={true}
      backButtonComponent={() => (
        <IconButton sx={{ mr: 4, ml: 1, p: 1 }} onClick={() => setPage('timeline')}>
          <ArrowLeft />
        </IconButton>
      )}
      history={selectedDate?.timestamps}
      defaultHistoryItem={selectedTimestamp}
    />
  );
}
