import { CustomerArea } from '../entities/Customer';
import { InterceptorRepository } from './InterceptorRepository';

class HubActionsRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/customers',
    });
  }

  async request(customerId: string, payload: unknown): Promise<void> {
    const params = new URLSearchParams(window.location.search);
    const area = params.get('area');
    const accountId = window.localStorage.getItem('account_id') ?? null;
    if (area === CustomerArea.Q_LEGACY && accountId) {
      const { data } = await this.axios.post(
        `/${customerId}/actions${customerId ? `?device_id=${customerId}&accountId=${accountId}` : null}`,
        payload
      );
      return data;
    } else {
      const { data } = await this.axios.post(`/${customerId}/actions`, payload);
      return data;
    }
  }

  async getResult(customerId: string, requestId: string): Promise<void> {
    const { data } = await this.axios.get(`/${customerId}/actions/${requestId}`);
    return data;
  }
}

export default new HubActionsRepository();
