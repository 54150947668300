import React from 'react';
//import { ChartConfiguration, ChartDataset, Chart } from 'chart.js';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material/';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { SxProps } from '@mui/system';
import Box from '@mui/system/Box';
import mapBackgroundImage from '../assets/map.png';

const backgroundPositions = ['bottom', 'top', 'center', 'left', 'right'];

const CustomizedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 16,
  },
}));

type DownDetectorCompanyCardProps = {
  sx?: SxProps<Theme>;
  logo?: string;
  name: string;
  slug: string;
  _heatmap_available?: boolean;
  id: number;
};

export default function DownDetectorCompanyCard(props: DownDetectorCompanyCardProps): JSX.Element {
  const history = useHistory();

  // const ref = useRef<HTMLCanvasElement | null>(null);
  // const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });

  // useEffect(() => {
  //   // if (chartRef.current.chart) {
  //   //   chartRef.current.chart.destroy();
  //   // }

  //   if (ref.current && ref.current.getContext) {
  //     const ctx = ref.current.getContext('2d');

  //     if (ctx) {
  //       const dataset = {
  //         data: props.stats_24.map((value, count) => {
  //           return { x: count, y: value };
  //         }),
  //         borderColor: '#f15757',
  //         borderWidth: 2,
  //         fill: false,
  //         type: 'line' as const,
  //       } as ChartDataset;

  //       const chartOptions = {
  //         type: 'line',
  //         data: {
  //           datasets: [dataset],
  //         },
  //         options: {
  //           responsive: true,
  //           maintainAspectRatio: true,
  //           elements: {
  //             point: {
  //               radius: 0,
  //             },
  //           },
  //           plugins: {
  //             legend: {
  //               display: false,
  //             },
  //             tooltip: { enabled: false },
  //           },
  //           scales: {
  //             x: {
  //               type: 'linear' as const,
  //               display: false,
  //               min: 0,
  //               max: props.stats_24.length - 1,
  //             },
  //             y: {
  //               type: 'linear' as const,
  //               display: false,
  //               min: 0,
  //             },
  //           },
  //         },
  //       } as ChartConfiguration<'line'>;

  //       if (chartOptions?.options?.plugins) {
  //         // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //         (chartOptions.options.plugins as any).bottomlegend = {
  //           display: false,
  //         };
  //       }

  //       chartRef.current.chart = new Chart(ctx, chartOptions);
  //     }
  //   }
  // }, [props.stats_24]);

  const redirectToDTDashboard = (id: string) => {
    history.push(`/down-detector/${id}`, { mapDisabled: !props._heatmap_available });
  };

  return (
    <Card
      onClick={() => redirectToDTDashboard(props.id.toString())}
      sx={{
        ...props.sx,
        ...{
          marginBottom: 1,
          transition: '0.2s',
          ':hover': {
            cursor: 'pointer',
            boxShadow: (theme) => theme.shadows[3],
          },
          display: 'flex',
          backgroundImage: props._heatmap_available ? `url(${mapBackgroundImage})` : 'none',
          backgroundPosition: backgroundPositions[Math.floor(Math.random() * backgroundPositions.length)],
        },
      }}
    >
      <CustomizedTooltip title={props.name} followCursor>
        {props.logo ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 100,
              width: 300,
              background: (theme) => `radial-gradient(${theme.palette.background.paper} 45%, transparent 90%)`,
            }}
          >
            <img
              src={props.logo}
              alt="logo"
              loading="lazy"
              style={{ maxWidth: 300, maxHeight: 60, padding: '6px 24px' }}
            ></img>
          </Box>
        ) : (
          <span></span>
        )}
      </CustomizedTooltip>
    </Card>
  );
}
