//import { number } from 'zod';
import { EmbeddedService, Service, HeatMap } from '../entities/DownDetector';
import { InterceptorRepository } from './InterceptorRepository';

class DownDetectorRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '',
    });
  }

  async getEmbeddedServiceList(
    country = 'IT' as string,
    filterName?: string,
    fields?: string[]
  ): Promise<{ companies: Array<EmbeddedService> }> {
    const { data } = await this.axios.get(
      `/sources/dd/countries/${country}/companies${filterName ? `/filter/${filterName}` : ''}${
        fields ? `?fields=${fields.join()} ` : ''
      }`
    );
    return data;
  }

  //chiamata per la singola company
  async getService(country = 'IT' as string, id: number): Promise<{ companies: Service }> {
    const { data } = await this.axios.get(`/sources/dd/countries/${country}/companies/${id}`);
    return data;
  }

  async getHeatMap(country = 'IT' as string, id: number, slug: string): Promise<HeatMap> {
    const { data } = await this.axios.get(
      `/sources/dd/heatmap/countries/${country}/companies/${id}/slug/${slug}?zoom=6`
    );
    return data;
  }
}

export default new DownDetectorRepository();
