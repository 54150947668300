import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Suggestion } from '../../entities/Suggestion';
import { mutateParseErrorState, RequestParseFailureAction, requestParseState } from '../toolkitUtils';

const initialState = {
  suggestion: undefined as Suggestion | undefined,
  requests: {
    readSuggestion: requestParseState(),
  },
};

const suggestionSlice = createSlice({
  name: 'suggestion',
  initialState,
  reducers: {
    readSuggestionSuccess(state, action: PayloadAction<Suggestion>) {
      const response = action.payload;
      state.suggestion = response;
    },
    readSuggestionParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      state.suggestion = undefined;
      mutateParseErrorState(state.requests.readSuggestion, action.payload.parseError);
    },
    clearSuggestion(state) {
      state.suggestion = undefined;
    },
  },
});

export const suggestionActions = suggestionSlice.actions;

export default suggestionSlice.reducer;
