import React from 'react';
import { useTranslation } from 'react-i18next';
import PageColumnTitle from '../../../../PageColumnTitle';

interface WizardPageTitleProps {
  title?: string;
  rightOuterButtons: JSX.Element[];
}

export default function WizardPageTitle(props: WizardPageTitleProps): JSX.Element {
  const { title, rightOuterButtons } = props;
  const { t } = useTranslation();

  return (
    <PageColumnTitle>
      {title ?? t('dashboard:recommendedActions:recommendedActions')}
      {rightOuterButtons}
    </PageColumnTitle>
  );
}
