import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import GlossaryIcon from '@mitch528/mdi-material-ui/BookAlphabet';
import DrawerIcon from '@mitch528/mdi-material-ui/Menu';
import Refresh from '@mitch528/mdi-material-ui/Refresh';
import { LoadingButton } from '@mui/lab';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { CustomerArea } from '../../entities/Customer';
import useAuthentication from '../../hooks/useAuthentication';
import { useCustomer } from '../../hooks/useCustomer';
import { useGlossary } from '../../hooks/useGlossary';
import { TIMELINE_GRANTS } from '../../utils/auth';
import Breadcrumbs from '../Breadcrumbs';
import HubDetailsDialog, { HubDetailsButtonProps } from '../pages/Dashboard/HubDetailsDialog/HubDetailsDialog';
import NavbarDrawer from './NavbarDrawer';

export default function Navbar({ customerId }: { customerId?: string | undefined }): JSX.Element {
  const { readHidden, readHiddenRequest } = useCustomer();
  const { user, hasGrants } = useAuthentication();
  const { glossary } = useGlossary();
  const { t } = useTranslation();
  const history = useHistory();
  const [infoLoading, setInfoLoading] = useState(false);
  const { customerInfo } = useCustomer();
  const [drawerOpen, setDrawerOpen] = useState(false);

  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  const area = params.get('area') ?? CustomerArea.BB;

  useEffect(() => {
    if (customerInfo) {
      setInfoLoading(false);
    }
  }, [customerInfo]);

  const customerIdByPath = useMemo(() => {
    return history.location.pathname.includes('/dashboard/') ? history.location.pathname.slice(11) : undefined;
  }, [history.location.pathname]);

  return (
    <Box
      component={AppBar}
      position="static"
      sx={{
        zIndex: 10,
        borderWidth: 0,
        background: (theme) =>
          `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.tertiary.main} 50%, ${theme.palette.secondary.main} 100%)`,
      }}
    >
      <Toolbar>
        <Box
          sx={{
            marginRight: 2,
            paddingY: 2,
            display: 'flex',
          }}
        >
          <Logo height="24" />
        </Box>
        <Breadcrumbs />
        <Box
          sx={{
            marginLeft: 'auto',
            alignItems: 'baseline',
          }}
        >
          {/* Glossario btn */}
          {(user?.level ?? 0) >= 2 && customerId && glossary && glossary.glossary && Boolean(glossary.glossary.length) && (
            <HubDetailsDialog
              customerId={customerId}
              type="glossary"
              title={t('navbar:glossary')}
              buttonComponent={(props: HubDetailsButtonProps) => (
                <Tooltip title={`${t('navbar:glossary')}`} aria-label={t('navbar:glossary')}>
                  <IconButton onClick={props.onClick}>
                    <GlossaryIcon htmlColor="#fff" />
                  </IconButton>
                </Tooltip>
              )}
            />
          )}
          {/* Aggiorna info cliente btn */}
          {customerInfo.info && (
            <LoadingButton
              loading={infoLoading}
              color="inherit"
              disabled={readHiddenRequest.inProgress}
              onClick={() => {
                if (customerId) {
                  setInfoLoading(true);
                  readHidden(customerId);
                }
              }}
              sx={{
                minWidth: 20,
                minHeight: 20,
                borderRadius: '50%',
                color: 'inherit',
                padding: '12px',
                '.MuiButton-label': {
                  minWidth: 20,
                  minHeight: 20,
                },
                '.MuiTouchRipple-root': {
                  minWidth: 20,
                  minHeight: 20,
                },
                '.MuiCircularProgress-circle': {
                  stroke: '#fff',
                },
              }}
            >
              <Tooltip title={`${t('navbar:reloadButton')}`} aria-label={t('navbar:reloadButton')}>
                <Refresh />
              </Tooltip>
            </LoadingButton>
          )}
          {/* Timeline btn*/}
          {customerIdByPath &&
            area !== CustomerArea.TV_RDKV &&
            area !== CustomerArea.Q_LEGACY &&
            hasGrants(TIMELINE_GRANTS) && (
              <Button
                href={`${process.env.REACT_APP_TIMELINE_URL?.replace('{customer_id}', customerIdByPath)}`}
                target="_blank"
                color="inherit"
              >
                {t('navbar:timeline')}
              </Button>
            )}

          <Fragment>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <DrawerIcon htmlColor="white" />
            </IconButton>
            <Drawer
              open={drawerOpen}
              anchor="right"
              onClose={() => setDrawerOpen(false)}
              sx={{ '& .MuiDrawer-paper': { border: 'none' } }}
            >
              <NavbarDrawer></NavbarDrawer>
            </Drawer>
          </Fragment>
        </Box>
      </Toolbar>
    </Box>
  );
}
