export enum Status {
  Success,
  Warning,
  Error,
  Active,
  Pending,
  Null,
  Grey,
}

export function colorStringToStatus(color: string | null): Status {
  switch (color) {
    case 'red':
      return Status.Error;
    case 'yellow':
      return Status.Warning;
    case 'amber':
      return Status.Warning;
    case 'green':
      return Status.Success;
    case 'grey':
      return Status.Grey;
    default:
      return Status.Null;
  }
}

export function wizardStatusStringToStatus(status: string): Status {
  switch (status) {
    case 'SUCCESS':
      return Status.Success;
    case 'FAILED':
      return Status.Error;
    case 'PENDING':
      return Status.Pending;
    case 'ACTIVE':
      return Status.Active;
    default:
      return Status.Null;
  }
}

export function wizardStatusNumberToStatus(status: number): Status {
  switch (status) {
    case 0:
      return Status.Success;
    case 1:
      return Status.Warning;
    case 2:
      return Status.Error;
    case 3:
      return Status.Active;
    case 4:
      return Status.Pending;
    default:
      return Status.Null;
  }
}

export function worstStatus(statuses: Status[]): Status {
  let errors = 0;
  let warnings = 0;
  let greys = 0;
  let nulls = 0;

  if (statuses.length === 0) {
    return Status.Null;
  }

  for (let i = 0; i < statuses.length; i++) {
    const status = statuses[i];
    if (status === Status.Error) errors++;
    if (status === Status.Warning) warnings++;
    if (status === Status.Grey) greys++;
    if (status === Status.Null) nulls++;
  }

  return errors > 0
    ? Status.Error
    : warnings > 0
    ? Status.Warning
    : greys > 0
    ? Status.Grey
    : nulls > 0
    ? Status.Null
    : Status.Success;
}
