import { Theme } from '@mui/material/styles';
import { Status } from '../../entities/Status';

export default function statusColor(status: Status | undefined, theme?: Theme): string {
  switch (status) {
    case Status.Grey:
      return theme ? theme.palette.grey['600'] : 'grey.600';
    case Status.Success:
      return theme ? theme.palette.success.dark : 'success.dark';
    case Status.Warning:
      return theme ? theme.palette.warning.dark : 'warning.dark';
    case Status.Error:
      return theme ? theme.palette.error.dark : 'error.dark';
    case Status.Active:
      return theme ? theme.palette.primary.main : 'primary.main';
    case Status.Pending:
      return theme ? theme.palette.info.dark : 'info.dark';
  }
  return theme ? theme.palette.grey['700'] : 'grey.700';
}
