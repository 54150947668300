import {
  RequestRangeAndTimeFilter,
  RequestValueFilter,
} from '../components/pages/L2Dashboard/BoardElements/L2ControllerSection';
import { L2DashboardColumn, L2Tab } from '../entities/L2Dashboard';
import { InterceptorRepository } from './InterceptorRepository';

class L2DashboardRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_ASSURANCE_BFF_URL}`,
      resourceURL: '/advanced-dashboard/',
    });
  }

  async getTabs(customer_id: string): Promise<L2Tab[]> {
    const { data } = await this.axios.get<L2Tab[]>(`dashboards?customer_id=${customer_id}`);
    return data;
  }

  async getLayout(customer_id: string, dashboardId: string): Promise<L2DashboardColumn> {
    const { data } = await this.axios.get<L2DashboardColumn>(
      `dashboards/${dashboardId}/layout?customer_id=${customer_id}`
    );
    return data;
  }

  async getElements(
    customer_id: string,
    dashboard_id: string,
    request_body?: {
      element_ids: string[];
      filters: Array<RequestValueFilter | RequestRangeAndTimeFilter>;
    }
  ): Promise<string> {
    const { data } = await this.axios.post<{
      data: string;
    }>(`dashboards/${dashboard_id}/data?customer_id=${customer_id}`, request_body);
    return data.data;
  }
}

export default new L2DashboardRepository();

// mock 1 (some elements test):
// first call: https://run.mocky.io/v3/f3827dde-9349-4833-b838-704d384caf76
// layout: https://run.mocky.io/v3/b068b639-0a49-4f69-9e98-d6b5da180a43
// data: https://run.mocky.io/v3/c495303d-f227-4026-b1d3-9518076f8368

// mock 2 (interference chart test):
// first call: https://run.mocky.io/v3/c5825c33-c00b-45d3-8dc5-4460660a1592
// layout: https://run.mocky.io/v3/0b7df595-ab12-475c-884d-640acc865452
// data: https://run.mocky.io/v3/a9099c00-d549-4ccb-bee9-af1e32955a31
