import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Action, actionId, ActionStatus } from '../../entities/Action';
import { RequestParseError } from '../../utils/RequestParseError';

import { RequestFailureAction, RequestParseState, requestParseState } from '../toolkitUtils';

const initialState = {
  resultsById: {} as Record<string, ActionStatus | undefined>,
  resultsByWizardId: {} as Record<string, string[]>,
  requestsById: {} as Record<
    string,
    RequestParseState & {
      actionId: string;
      wizardId: string | undefined;
      // url: string;
    }
  >,
  requestError: undefined as undefined | RequestParseError,
};

export type HubActionsState = typeof initialState;

interface ActionRequestAction {
  actionId: Action['action_id'];
  wizardId: string | undefined;
}
interface ActionFailureAction extends RequestFailureAction {
  actionId: Action['action_id'];
  wizardId: string | undefined;
}

interface RequestResultSuccessAction {
  result: ActionStatus | undefined;
}

const hubActionsSlice = createSlice({
  name: 'hubActions',
  initialState,
  reducers: {
    actionRequest(state, action: PayloadAction<ActionRequestAction>) {
      const id = actionId(action.payload.actionId, action.payload.wizardId);
      if (state.resultsById[id]) {
        delete state.resultsById[id];
        // mutateRequestState(state.requestsById[id]);
        // state.requestsById[id].givenUpResults = false;
      }

      state.requestsById[id] = {
        ...requestParseState(),
        actionId: action.payload.actionId,
        wizardId: action.payload.wizardId,
        inProgress: true,
      };

      state.requestError = undefined;
    },
    clearRunHubActionError(state, action: PayloadAction<ActionRequestAction>) {
      const id = actionId(action.payload.actionId, action.payload.wizardId);

      const request = state.requestsById[id];

      if (request) {
        request.error = null;
        request.parseError = null;
      }
    },
    // resetActionResult(state, action: PayloadAction<ActionRequestAction>) {
    //   const id = actionId(action.payload.actionId, action.payload.wizardId);
    //   if (state.resultsById[id]) {
    //     delete state.resultsById[id];
    //   }
    // },
    actionSuccess() {
      // const id = actionId(action.payload.actionId, action.payload.wizardId);
      // if (state.requestsById[id]) {
      //   state.requestsById[id]?
      // }
      // mutateSuccessState(state.requests.action);
    },
    autoTestSuccess(state) {
      const id = 'execute-test';
      const request = state.requestsById[id];
      if (request) {
        request.inProgress = false;
      }
    },
    actionFailure(state, action: PayloadAction<ActionFailureAction>) {
      const id = actionId(action.payload.actionId, action.payload.wizardId);
      const request = state.requestsById[id];
      if (request) {
        request.inProgress = false;
        request.error = action.payload.error;
      }
    },
    actionGiveUp(state, action: PayloadAction<ActionRequestAction>) {
      const id = actionId(action.payload.actionId, action.payload.wizardId);
      const request = state.requestsById[id];
      if (request) {
        request.inProgress = false;
      }
    },
    actionResetByWizard(state, action: PayloadAction<{ wizardId: string }>) {
      const resultsByWizardId = state.resultsByWizardId[action.payload.wizardId];
      if (resultsByWizardId) {
        for (let i = 0; i < resultsByWizardId.length; i++) {
          const id = resultsByWizardId[i];
          if (id) {
            delete state.resultsById[id];
          }
        }
        delete state.resultsByWizardId[action.payload.wizardId];
      }
    },
    actionResultSuccess(state, action: PayloadAction<RequestResultSuccessAction>) {
      if (action.payload.result?.action_id) {
        const id = actionId(action.payload.result.action_id, action.payload.result?.wizard_id);
        const request = state.requestsById[id];
        if (request) {
          request.inProgress = false;
          request.error = null;
        }
        state.resultsById[id] = action.payload.result;
        if (action.payload.result?.wizard_id) {
          if (!state.resultsByWizardId[action.payload.result?.wizard_id]) {
            state.resultsByWizardId[action.payload.result?.wizard_id] = [];
          }
          state.resultsByWizardId[action.payload.result?.wizard_id]?.push(id);
        }
      }

      state.requestError = undefined;
    },
    actionResultError(state, action: PayloadAction<{ error: RequestParseError }>) {
      state.requestError = action.payload.error;
    },
    reset(state) {
      state.resultsById = initialState.resultsById;
      state.requestsById = initialState.requestsById;
      state.resultsByWizardId = initialState.resultsByWizardId;
      state.requestError = initialState.requestError;
    },
  },
});

export const hubActionsActions = hubActionsSlice.actions;

export default hubActionsSlice.reducer;
