import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import Box, { BoxProps } from '@mui/material/Box';

export function WizardMarkdown(props: ReactMarkdownOptions & Pick<BoxProps, 'sx'>): JSX.Element {
  const { sx, ...reactMarkdownProps } = props;
  return (
    <Box
      sx={{
        '& p': {
          '&:first-of-type': {
            marginTop: 0,
          },
          '&:last-child': {
            marginBottom: 0,
          },
        },
        '& img': {
          display: 'block',
          marginY: 2,
          marginX: 'auto',
          maxWidth: '100%',
          maxHeight: 512,
        },
        ...sx,
      }}
    >
      <ReactMarkdown {...reactMarkdownProps} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
    </Box>
  );
}
