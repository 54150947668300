import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import ChevronUp from '@mitch528/mdi-material-ui/ChevronUp';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ProblemResult } from '../../../../entities/Problems';
import { Status } from '../../../../entities/Status';
import { CompactAccordion } from '../../../CompactAccordion';
import StatusBadge from '../../../status/StatusBadge';

interface AnalyzedProblemsAccordionProps {
  analyzedProblems: ProblemResult[];
}

export default function AnalyzedProblemsAccordion(props: AnalyzedProblemsAccordionProps): JSX.Element {
  const [expanded, setExpanded] = useState(props.analyzedProblems.length > 0);
  const { t } = useTranslation();

  const alertElements = useMemo(() => {
    const elements = props.analyzedProblems.map(({ result, problems }, i) => (
      <Accordion
        key={i}
        sx={{
          boxShadow: (theme) => `${theme.palette.grey[200]} 0px 0px 0px 2px inset`,
          border: 0,
          padding: 0,
          paddingBottom: 1,
          '&.MuiPaper-root': {
            '&,&.Mui-expanded:last-of-type': {
              marginTop: 0,
              marginBottom: 2,
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronUp />}
          sx={{
            borderRadius: 1,
            fontWeight: 500,
            '&,&.Mui-expanded': {
              minHeight: 24,
              marginBottom: 0,
            },
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              justifyContent: 'space-between',
              height: 10,
            },
            '* p': {
              fontSize: '0.9em',
              margin: 0,
            },
          }}
        >
          <ReactMarkdown>{result}</ReactMarkdown>
          {/* <StatusBadge status={Status.Null} count={problems.length} /> */}
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              pl: 8,
              width: '100%',
              margin: 0,
              '* p': {
                margin: 0,
              },
            }}
            component="ul"
          >
            {problems.map((description) => (
              <li key={description}>
                <ReactMarkdown>{description}</ReactMarkdown>
                {/* <StatusAlert icon status={Status.Null}>
                </StatusAlert> */}
              </li>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    ));

    return elements;
  }, [props.analyzedProblems]);

  const badges = useMemo(() => {
    let result = 0;

    for (let i = 0; i < props.analyzedProblems.length; i++) {
      result += props.analyzedProblems[i]?.problems?.length || 0;
    }

    return [result > 0 ? <StatusBadge key={Status.Success} status={Status.Null} count={result} /> : null];
  }, [props.analyzedProblems]);

  useEffect(() => {
    if (props.analyzedProblems.length > 0) {
      setExpanded(true);
    }
  }, [props.analyzedProblems.length]);

  return (
    <CompactAccordion
      expanded={expanded}
      onChange={() => setExpanded((v) => !v)}
      summary={
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            paddingRight: 1,
            '& .StatusBadge-root': {
              height: 20,
              width: 20,
              lineHeight: '22.5px',
            },
          }}
        >
          <Typography
            fontWeight="bold"
            variant="body1"
            sx={{
              flex: '1 1 auto',
            }}
          >
            {t('dashboard:customerInfo:wizardSteps')}
          </Typography>
          {badges}
        </Box>
      }
    >
      <AccordionDetails
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          '& .MuiListItem-root': {
            paddingBottom: 2,
            paddingTop: 0,
          },
        }}
      >
        {alertElements}
      </AccordionDetails>
      {props.analyzedProblems.length === 0 ? (
        <AccordionDetails>
          <Typography textAlign="center" color={(theme) => theme.palette.text.secondary}>
            {t('dashboard:customerInfo:noAlert')}
          </Typography>
        </AccordionDetails>
      ) : null}
    </CompactAccordion>
  );
}
