import { ChartHistoryItem } from '../entities/Chart';
import { InterceptorRepository } from './InterceptorRepository';

class TrendlineRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/customers/',
    });
  }

  // Fetch last chart runned
  async getLast(customerId: string, trendlineName: string): Promise<unknown> {
    const { data } = await this.axios.get(`${customerId}/trendlines/${trendlineName}`);
    return data;
  }

  // Fetch list of dates corresponding to older charts
  async getDates(customerId: string, trendlineName: string): Promise<{ dates: ChartHistoryItem[] }> {
    const { data } = await this.axios.get(`${customerId}/trendlines/${trendlineName}/dates`);
    return data;
  }

  // Fetch an older chart
  async getByDate(customerId: string, trendlineName: string, requestId: string): Promise<unknown> {
    const { data } = await this.axios.get(`${customerId}/test/${requestId}/trendlines/${trendlineName}`);
    return data;
  }
}

export default new TrendlineRepository();
