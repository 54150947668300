import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export interface TableChartSeries {
  header: Array<string>;
  rows: Array<Record<string, string>>;
}

interface TableChartProps {
  visible: boolean;
  series: TableChartSeries[];
}

export default function TableChart(props: TableChartProps): JSX.Element {
  return (
    <Box sx={{ py: 2, overflow: 'auto', maxHeight: '100%', display: 'flex', flexDirection: 'column', paddingTop: 0 }}>
      {props.series.map((serie, s) => (
        <TableContainer key={s} sx={{ maxHeight: '100%' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {serie.header.map((header) => (
                  <TableCell key={`${header}-i`}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {serie.rows.map((row, i) => (
                <TableRow key={i}>
                  {Object.values(row).map((col) => (
                    <TableCell
                      key={`${col}-i`}
                      style={{
                        minWidth: col.length > 25 ? 280 : 'auto',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'break-spaces',
                      }}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </Box>
  );
}
