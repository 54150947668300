const en = {
  common: {
    languageName: 'English',
    appName: 'Sky Wifi Assurance',
    actions: 'Actions',
    next: 'Next',
    back: 'Back',
    yes: 'Yes',
    no: 'No',
    close: 'Close',
    end: 'End',
    noInfoAvailable: 'No information available.',
    active: 'Active',
    ok: 'Ok',
    ko: 'Ko',
    goBack: 'Go Back',
    genericConfirmMessage: 'Confirm the required action?',
    noData: '--',
    notAvailable: 'N/A',
    noCaseIdFound:
      'A new diagnostic has been launched from Arcadia. <br />Reopen NGAS from “troubleshooting evoluto” case button for completing the management correctly.',
  },
  errorAlert: {
    errorOccured: 'An error occurred',
    tryReloadingPage: 'Try reloading the page.',
    contactSupport: 'If the problem persists, contact support and provide error details:',
    copyErrorDetails: 'Copy error details',
    reloadThePage: 'Reload the page',
  },
  actions: {
    add: 'Add',
    apply: 'Apply',
    cancel: 'Cancel',
    cancelSelection: 'Cancel selection',
    confirm: 'Confirm',
    clone: 'Clone',
    close: 'Close',
    execute: 'Execute',
    confirmChanges: 'Confirm changes',
    deleteSelected: 'Delete selected',
    removeFilter: 'Remove filter',
    search: 'Search',
    selectAll: 'Select all',
    send: 'Send',
    update: 'Update',
    reset: 'Reset',
    inputRequired: 'Input required',
    show: 'Show all',
    startAction: 'Start action',
    selectAction: 'Select action',
  },
  components: {
    dataGrid: {
      noRowsLabel: 'No results',
      errorOverlayDefaultLabel: 'An error occurred.',
      columnHeaderSortIconLabel: 'Sort',
      footerRowSelected: '{{count}} row selected',
      footerRowSelected_plural: '{{count}} rows selected',
      footerTotalRows: 'Total rows:',
      footerPaginationRowsPerPage: 'Rows per page:',
    },
    buttonTooltip: {
      noDescription: '-- Description not available --',
    },
  },
  entities: {
    customer: {
      id: 'Customer code',
    },
    system: {
      id: 'MSO ID',
    },
    wizardStatus: {
      idle: 'Idle',
      active: 'Active',
      success: 'Success',
      suspended: 'Suspended',
      failed: 'Failed',
    },
  },
  navigation: {
    dashboard: 'Customer {{id}} Dashboard',
    testsHistory: 'Test History',
  },
  navbar: {
    glossary: 'Glossary',
    reloadButton: 'Refresh customer information',
    timeline: 'Timeline',
    searchCustomer: 'Search customer',
    downDetector: 'Down Detector',
    l2Dashboard: 'L2 Dashboard',
    mso: 'MSO',
    drawer: {
      signOut: 'Log out',
      level: 'Level',
      selectLevel: 'Change Level',
      selectLevelHelper: 'Select level',
      selectLevelDialog: {
        title: 'User Level',
        text: 'Are you sure you want to proceed with changing the user level?',
      },
      settings: {
        title: 'Settings',
        language: 'Language',
        selectLanguage: 'Select Language',
        darkMode: 'Dark mode',
      },
    },
  },
  authentication: {
    user: 'User',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    invalidUsernameOrPassword: 'Invalid username or password',
  },

  dashboard: {
    suggestions: 'Suggestions:',
    charts: {
      threshold: 'Threshold',
      testDate: 'Test Execution Date',
    },
    customerInfo: {
      customerId: `Customer {{customerId}}`,
      info: 'Info',
      hub: 'HUB',
      pod: 'SPOT',
      configurations: 'Configurations',
      lineType: 'Line Type',
      POP: 'POP',
      OLT: 'OLT',
      KIT: 'KIT',
      BNG: 'BNG',
      activation: 'Activation',
      voice: 'Voice',
      firmwareVersion: 'Firmware Ver.',
      lastUpgrade: 'Last Upgrade',
      lastReboot: 'Last Reboot',
      mac: 'MAC',
      active_plural: 'Active',
      xFiProvisionDevice: 'xFi Provision Device',
      xFiVoice: 'xFi Voice',
      xFiFtue: 'xFi FTUE',
      xFiMeshAgent: 'xFi MESH Agent',
      swap: 'Swap',
      alert: 'Issues Detected',
      wizardSteps: 'Issue Analysis Outcome',
      noAlert: 'No issues detected in the last test',
      customerInfo: 'Customer Information',
      advancedAnalysis: 'Advanced Analysis',
      devices: 'Connected Devices',
      graphPlotting: 'Plotting Graph...',
      actions: 'Device Actions',
      dialogs: {
        connectedDevices: {
          connectedDevices: 'Connected Devices',
          mainDetails: 'Main Details',
          additionalDetails: 'Additional Details',
          noDetails: 'No details to show',
          tooltip: 'Click on a device to view details.',
          signalQuality: {
            title: 'Signal Quality',
            excellent: 'Excellent',
            good: 'Good',
            fair: 'Fair',
            poor: 'Poor',
            none: 'N/A',
          },
          connectionType: {
            title: 'Connection Type',
            cable: 'Cable',
            wifi2: '2.4 GHz Wi-Fi',
            wifi5: '5 GHz Wi-Fi',
          },
          lastUpdate: 'Last Update',
          updateInfo: 'Update Information',
          viewOptions: 'View Options',
          showInactive: 'Show inactive devices',
          warning: 'Wi-Fi channel not visible per uno o more devices',
          disconnectedSpots: 'Disconnected SPOT',
          history: 'History',
          loading: 'Loading devices configuration...',
        },
        hubCpuRamTrend: {
          hubCpuRamTrend: 'CPU & RAM HUB Trend',
        },
        rssiSnrTrend: {
          rssiSnrTrend: 'RSSI / SNR Trend',
        },
        rxTxTrend: {
          rxTxTrend: 'RX / TX Trend',
        },
      },
      actionsButtons: {
        runManualTests: 'Run manual tests',
        restartHub: 'Restart HUB',
        resetHub: 'Reset HUB',
        riavvioMesh: 'Restart MESH',
        upgradeFw: 'Upgrade FW',
      },
    },
    test: {
      lastTest: 'Last test',
      diagnosticsLog: 'Diagnostic history',
      testOk: 'Test OK',
      testFailed: 'Test failed: {{statusMessage}}',
      compare: 'Compare',
      compareTests: 'Compare tests',
      tooManyItemsSelected: 'You can compare a maximum of three tests',
      selectToCompare: 'Select this test to compare it',
      noTestAvailable: 'No tests performed.',
      wizardinfo: 'Wizard information',
      result: 'Result:',
      inProgress: 'In progress',
      end: 'End: ',
      start: 'Start: ',
      test: 'Test: ',
      caseId: 'Case ID: ',
      autoClosingRilascioDialogMessage: 'The operator has successfully released the case in question',
      caseHistory: 'Case history',
      caseDialog: {
        conditionMessage: 'The operator has successfully released the case in question',
        releaseTitle: 'Release result:',
        releaseMessage:
          'The release has been completed successfully! <br />You will be disconnected from the current session in a few seconds...',
        reserveTitle: 'Case reservation result:',
        error: 'Error.',
        success: 'Operation successful.',
        warning: 'Warning.',
      },
    },
    hubDetailsDialogCase: {
      loadingMessage: 'Loading case history...',
      openCases: 'Open Cases',
      closedCases: 'Closed Cases',
      errorMessage: 'An internal problem occurred, please try again.',
      noData: 'No data to show.',
      opening: 'Opening: ',
      closure: 'Closure: ',
      category: 'Category:',
      subCategory: 'Sub-category:',
      motive: 'Motive:',
      motiveDetails: 'Motive details:',
      status: 'Status:',
      subStatus: 'Sub-status:',
      globalStatus: 'Global status:',
      outcome: 'Outcome:',
      escalations: {
        title: 'Escalations',
        team: 'Team:',
        category: 'Category:',
        impact: 'Impacts:',
        oloId: 'Olo id:',
        status: 'Status:',
        subStatus: 'Sub-status:',
      },
      interactions: {
        title: 'Interactions',
        status: 'Status:',
        interactionType: 'Interaction type:',
        appointmentId: 'Appointment:',
      },
    },
    hubTestDetails: {
      config: 'CONFIG',
      hubPod: 'HUB & SPOT',
      wifi: 'Wi-Fi',
      eth: 'ETH',
      runTestAgain: 'Run test again',
      details: 'Details',
      relevantInfo: 'Rilevant info',
      testStatus: 'Test status',
      testDate: 'Test date',
      issueDescription: 'Issue description',
    },
    hubDetailsDialogMapT: {
      title: 'Map T History',
      fields: {
        date_time: 'Time',
        ticket_id: 'Ticket ID',
        status: 'Status',
        reason_text: 'Reason Text',
        cgnat_state: 'CGNAT State',
        caller: 'Caller',
        activation_completion_date_time: 'Activation Completion',
        mac_address: 'MAC Address',
      },
    },
    recommendedActions: {
      recommendedActions: 'Recommended Actions',
      noActionAvailable: 'No recommended actions.',
      oldWizard:
        'This wizard is three or more days old. To avoid potential analysis errors on a changed customer situation, it is recommended to re-run the Diagnostics.',
      wizard: 'Wizard',
    },
    wizard: {
      picklistSelect: 'Select a type',
      confirmButtonDialog: {
        closeTitle: 'Close',
        closeMessage: 'Warning: all resolution procedures will be cancelled. Continue?',
      },
      results: {
        title: 'Results of Analyzed Problems',
        subtitle: 'Wizard Procedure Outcome',
        outcome: 'Outcome',
        description: 'Problem Description',
        caseHesitation: 'Case Hesitation',
        duplicatedCases: 'Duplicated Cases',
        duplicatedEscalations: 'Duplicated Escalations',
      },
      feedback: {
        title: 'Guided Resolution Procedure',
        description: 'Click "Next" to continue or end the guided procedure.',
      },
      wizard0: {
        name: 'Name',
        description: 'Description',
        suggestions: 'Suggestions',
        tooltip: 'You do not have sufficient permissions to uncheck this control.',
      },
    },
  },
  searchCustomer: {
    accessDenied: 'Access Denied',
    broadband: 'Broadband',
    tv: 'Sky TV',
  },
  landingPage: {
    problemOccurred: 'An error occurred',
    stateParameterError: 'The --state-- parameter is invalid, base64 decoding failed.',
    codeParameterError: 'The --code-- parameter was not found.',
    redirectMessage: 'Redirecting in <strong>{{timer}}</strong> seconds',
  },
  downDetector: {
    search: 'Search for a Service',
    loading: 'Loading services...',
    info:
      'Results with an embedded map have <strong>enabled</strong> the <strong>geographic tracking</strong> service of reports.',
    poweredBy: 'Powered by <strong>Downdetector®</strong>',
    chartLabel: 'Service interruption reports',
    heatMapDefaultError: 'Error loading Heatmap data.',
    heatMapDisabled:
      'Geographic tracking of reports is not supported for this service. For a more detailed analysis, please visit the following link:',
  },
  wizard: {
    backButtonAlert: {
      title: 'Alert',
      body: 'It is no longer possible to go back as the dispositive action has already been started',
    },
  },
};

export default en;
