import React from 'react';
import DatePicker from '@mui/lab/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export type DatePickerProps = {
  placeholder: string;
  date: Date | null | undefined;
  onChange: (value: Date | null | undefined) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  disableTime?: boolean;
};

export default function AssuranceDateTimePicker(props: DatePickerProps): JSX.Element {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {props.disableTime ? (
        <DatePicker
          disableFuture
          value={props.date}
          onChange={(newValue) => props.onChange(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={''}
              color="secondary"
              label={props.placeholder}
              size="small"
              sx={{ width: '100%' }}
              variant={props.variant ?? 'outlined'}
              fullWidth
            />
          )}
        />
      ) : (
        <DateTimePicker
          disableFuture
          value={props.date}
          onChange={(newValue) => props.onChange(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={''}
              color="secondary"
              label={props.placeholder}
              size="small"
              sx={{ width: '100%' }}
              variant={props.variant ?? 'outlined'}
              fullWidth
            />
          )}
        />
      )}
    </Box>
  );
}
