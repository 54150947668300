import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { SimulationNodeDatum } from 'd3';
import { format } from 'date-fns';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/system';
import BrowsingTheWebB from '../../assets/browsing-the-web/browsing-the-web-b.svg';
import BrowsingTheWebW from '../../assets/browsing-the-web/browsing-the-web-w.svg';
import CheckEmailB from '../../assets/check-email/check-email-b.svg';
import CheckEmailW from '../../assets/check-email/check-email-w.svg';
import CheckboxBlankOutline from '../../assets/checkbox-blank-outline.svg';
import CheckboxMarked from '../../assets/checkbox-marked.svg';
import ConnectingSmartHomeDeviceB from '../../assets/connecting-smart-home-device/connecting-smart-home-device-b.svg';
import ConnectingSmartHomeDeviceW from '../../assets/connecting-smart-home-device/connecting-smart-home-device-w.svg';
import Ethernet from '../../assets/ethernet.svg';
import OnlineGamingB from '../../assets/online-gaming/online-gaming-b.svg';
import OnlineGamingW from '../../assets/online-gaming/online-gaming-w.svg';
import restore from '../../assets/restore.svg';
import StreamingAudioB from '../../assets/streaming-audio/streaming-audio-b.svg';
import StreamingAudioW from '../../assets/streaming-audio/streaming-audio-w.svg';
import StreamingVideoB from '../../assets/streaming-video/streaming-video-b.svg';
import StreamingVideoW from '../../assets/streaming-video/streaming-video-w.svg';
import UploadFileB from '../../assets/upload-file/upload-file-b.svg';
import UploadFileW from '../../assets/upload-file/upload-file-w.svg';
import VideoChattingB from '../../assets/video-chatting/video-chatting-b.svg';
import VideoChattingW from '../../assets/video-chatting/video-chatting-w.svg';
import { Customer } from '../../entities/Customer';
import { DevicesGraphTree } from '../../entities/DevicesGraph';
import { RequestError } from '../../entities/RequestError';
import { useDevicesGraph } from '../../hooks/useDevicesGraph';
import { RequestParseError } from '../../utils/RequestParseError';

import LoaderView from '../LoaderView';
import DeviceDetails from './DeviceDetails';
import DisconnectedPods from './DisconnectedPods';
import SVGDevice, { DeviceType, Signal } from './SVGDevice';

enum Connection {
  Cable = 0,
  TwoGHz = 1,
  FiveGHz = 2,
}

const CONNECTION_COLORS: Record<Connection, string> = {
  [Connection.Cable]: '#424242',
  [Connection.TwoGHz]: '#f9a825',
  [Connection.FiveGHz]: '#7b1fa2',
};

const CONNECTION_PERIODS: Record<Connection, number> = {
  [Connection.Cable]: 0,
  [Connection.TwoGHz]: 2,
  [Connection.FiveGHz]: 1,
};

const LEGEND_ICON_SIZE = 48;

type Node = d3.HierarchyNode<DevicesGraphTree> & SimulationNodeDatum;
type Link = d3.HierarchyLink<DevicesGraphTree> & {
  source: Node;
  target: Node;
};

const nodeKey = (n: Node) => n.data.id;
const linkKey = (l: Link) => `${l.source.data.id}->${l.target.data.id}`;

/**
 * Draw a sinusoidal line
 * @param dx the length of the line
 * @param period the period of the sinusoid
 */
const drawWavyLine = (dx: number, period: number) => {
  let values: [number, number][] = [];

  if (period === 0) {
    values = d3.range(0, dx).map((i) => [i, 0]);
  } else {
    values = d3.range(0, dx).map((i) => [i, Math.sin(i / (2 * period)) * 6]);
  }

  return d3.line()(values);
};

/**
 * Returns the Connection enum value from a connection_type
 */
const getConnectionType = (data: DevicesGraphTree): Connection => {
  if (data.connection_type === 'wifi_24ghz') {
    return Connection.TwoGHz;
  }

  if (data.connection_type === 'wifi_5ghz') {
    return Connection.FiveGHz;
  }

  return Connection.Cable;
};

/**
 * Return the distance between two nodes
 */
const distance = (source: Node, target: Node): number => {
  return Math.abs(
    Math.sqrt(Math.pow((source.x || 0) - (target.x || 0), 2) + Math.pow((source.y || 0) - (target.y || 0), 2))
  );
};

/**
 * Returns the angle of the line connecting two nodes
 */
const rotation = (source: Node, target: Node): number => {
  return (Math.atan2((target.y || 0) - (source.y || 0), (target.x || 0) - (source.x || 0)) / Math.PI) * 180;
};

/**
 * Returns if the node is a "generic" device (not Hub or Pod)
 */
const isGenericDeviceNode = (d: Node) =>
  d.data.type === DeviceType.HUB_DEVICE || d.data.type === DeviceType.POD_DEVICE ? false : true;

// Define the sizes of the elements of the chart
// Note: sized depends also by the zoom level
const ICON_RADIUS = 128;
const ICON_DEVICE_RADIUS = 96;
const FONT_SIZE = ICON_RADIUS * 0.12;
const DEVICE_NAME_FONT_SIZE = ICON_RADIUS * 0.12;
const CHANNEL_FONT_SIZE = ICON_RADIUS * 0.16;

const NODE_RADIUS = ICON_RADIUS * Math.sqrt(2) * 1.3;
const NODE_RADIUS_HUB = NODE_RADIUS * 1.6;
const NODE_RADIUS_POD = NODE_RADIUS * 1.2;

const PADDING_HEIGHT = 2;
const DEVICE_NAME_HEIGHT = DEVICE_NAME_FONT_SIZE * 1.4;
const CAPABILITIES_ICON_SIZE = 24;
const CAPABILITIES_HEIGHT = 32;
const CHANNEL_RADIUS = ICON_RADIUS / 5;

const CHART_WIDTH = 8192;

interface DevicesForceGraphProps {
  graph: DevicesGraphTree[];
  customerIdValue: Customer['id'] | undefined;
  timeStampvalue: number | undefined;
  onRefresh: () => void;
  reset?: boolean;
  onZoom?: (value: boolean) => void;
  size?: { width: string | number; height: number | undefined };
}

// Draws the chart, or a warning in case of error.
export default function DevicesForceGraphWrapper(props: {
  visible: boolean;
  graph: DevicesForceGraphProps['graph'] | undefined;
  timeStampValue: DevicesForceGraphProps['timeStampvalue'] | undefined;
  customerIdValue: Customer['id'] | undefined;
  error: RequestError | RequestParseError | null;
  size: DevicesForceGraphProps['size'];
  reset: DevicesForceGraphProps['reset'];
  onZoom: DevicesForceGraphProps['onZoom'];
  onRefresh: () => void;
}): JSX.Element | null {
  if (!props.visible) {
    return null;
  }

  if (!props.graph) {
    return props.error ? (
      <Box sx={{ py: 2, px: 4 }}>
        <Alert severity="warning">{props.error.message}</Alert>
      </Box>
    ) : null;
  }

  if (!props.timeStampValue) {
    return props.error ? (
      <Box sx={{ py: 2, px: 4 }}>
        <Alert severity="warning">{props.error.message}</Alert>
      </Box>
    ) : null;
  }

  return (
    <DevicesForceGraph
      graph={props.graph}
      timeStampvalue={props.timeStampValue}
      size={props.size}
      customerIdValue={props.customerIdValue}
      onRefresh={props.onRefresh}
      reset={props.reset}
      onZoom={props.onZoom}
    />
  );
}

function DevicesForceGraph(props: DevicesForceGraphProps): JSX.Element {
  const { graph, size, onRefresh, reset, onZoom } = props;
  // Ref to the root element
  const rootRef = useRef<HTMLDivElement>(null);

  // Ref to the chart <svg /> element
  const svgRef = useRef<SVGSVGElement>(null);

  // Ref to the legend <div /> element
  const legendSvgRef = useRef<HTMLDivElement>(null);

  // Chart opacity state - to hide the chart elements moving until the simulation is stabilized
  const [op, setOp] = useState(0);

  // Show or hide the devices with `connection_active = false`
  const [showInactiveDevices, setShowInactiveDevices] = useState(false);

  // Details information do display on right panel
  const [mainDetails, setMainDetails] = useState<string[] | null>([]);
  const [additionalDetails, setAdditionalDetails] = useState<string[] | null>([]);
  const [openBoxCount, setOpenBoxCount] = useState<number>(0);

  // Show or hide warning if `channel = null`
  const [warning, setWarning] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();

  const { readDevicesGraphRequest } = useDevicesGraph();

  const [isLoading, setisLoading] = useState(false);

  const capabilities = React.useMemo(() => {
    return [
      { name: 'Video Chatting', icon: theme.palette.mode === 'light' ? VideoChattingB : VideoChattingW },
      { name: 'Online Gaming', icon: theme.palette.mode === 'light' ? OnlineGamingB : OnlineGamingW },
      { name: 'Uploading a File', icon: theme.palette.mode === 'light' ? UploadFileB : UploadFileW },
      { name: 'Streaming Video', icon: theme.palette.mode === 'light' ? StreamingVideoB : StreamingVideoW },
      { name: 'Streaming Audio', icon: theme.palette.mode === 'light' ? StreamingAudioB : StreamingAudioW },
      { name: 'Checking Email', icon: theme.palette.mode === 'light' ? CheckEmailB : CheckEmailW },
      { name: 'Browsing the Web', icon: theme.palette.mode === 'light' ? BrowsingTheWebB : BrowsingTheWebW },
      {
        name: 'Connecting a Smart Home Device',
        icon: theme.palette.mode === 'light' ? ConnectingSmartHomeDeviceB : ConnectingSmartHomeDeviceW,
      },
    ];
  }, [theme.palette.mode]);

  // The nuber of labels to be shown under each node
  const nodeLabelsCount = useMemo(() => {
    let maxLabels = 8;
    // const deleteLabel = (node: DevicesGraphTree) => {
    //   for (let i = 0; i < node.children.length; i++) {
    //     const child = node.children[i];
    //     if (child) {
    //       // arrayMainDetails.map((mainDetail) => {
    //       //   mainDetail.splice(5, 1);
    //       //});
    //     }
    //     return arrayMainDetails;
    //   }
    //   return deleteLabel;
    // };

    const walkLabels = (node: DevicesGraphTree) => {
      // Create an array of Channels for warning
      const arrayChannels = [];

      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        if (child) {
          maxLabels = Math.max(maxLabels, child.labels.length);
          //if connection is active & channel is null, push the value into the array
          if (child && child.connection_active === true && child.connection_type !== 'ethernet') {
            arrayChannels.push(child.channel);
          }
        }
      }
      arrayChannels.includes(null) ? setWarning(true) : setWarning(false);
      return arrayChannels;
    };

    for (let i = 0; i < graph.length; i++) {
      const subtree = graph[i];
      if (subtree) {
        walkLabels(subtree);
      }
    }
    return maxLabels;
  }, [graph]);

  const getDetailsBoxHeight = useCallback(
    (d: Node) =>
      (FONT_SIZE + PADDING_HEIGHT) * d.data.labels.length +
      (isGenericDeviceNode(d) ? ICON_DEVICE_RADIUS : ICON_RADIUS) +
      DEVICE_NAME_HEIGHT +
      CAPABILITIES_HEIGHT,
    []
  );

  const getScaleExtent = useCallback((): [number, number] => {
    if (d3SvgRef.current) {
      const nodesBBox = d3SvgRef.current.select<SVGGElement>('.nodes').node()?.getBBox();

      if (nodesBBox && d3SvgRef.current && zoomRef.current) {
        const longestBBoxSide = Math.max(nodesBBox.width, nodesBBox.height);
        return [(CHART_WIDTH / longestBBoxSide) * 0.5, (5 * longestBBoxSide) / NODE_RADIUS_HUB];
      }
    }
    return [0, 0];
  }, []);

  // Removes inactive devices from the chart tree
  const getFilteredGraph = useCallback(
    (showInactiveDevices: boolean) => {
      const removeInactive = (subTrees: DevicesGraphTree[]): DevicesGraphTree[] => {
        const newSubTrees: DevicesGraphTree[] = [];

        for (let i = 0; i < subTrees.length; i++) {
          const subTree = subTrees[i];

          if (subTree) {
            if (showInactiveDevices || subTree.connection_active === true) {
              const deviceIsInactivePod = subTree.type?.toLowerCase() === 'pod' && subTree.connection_active === false;

              if (!deviceIsInactivePod) {
                newSubTrees.push({
                  ...subTree,
                  children: removeInactive(subTree.children),
                });
              }
            }
          }
        }

        return newSubTrees;
      };

      return removeInactive(graph);
    },
    [graph]
  );

  const d3SvgRef = useRef<d3.Selection<SVGElement, Node, null, undefined>>();
  const zoomRef = useRef<d3.ZoomBehavior<SVGElement, Node>>();

  // Ref to the nodes array
  const nRef = useRef<Array<Node>>();

  // Ref to the links array
  const lRef = useRef<Array<Link>>();

  // Ref to the D3 nodes array
  const nodesRef = useRef<d3.Selection<SVGGElement, Node, SVGGElement, Node>>();

  // Ref to the D3 links array
  const linksRef = useRef<d3.Selection<SVGGElement, Link, SVGGElement, unknown>>();

  const linksLinesRef = useRef<d3.Selection<SVGPathElement, Link, SVGGElement, unknown>>();
  const linkChannelGroupRef = useRef<d3.Selection<SVGGElement, Link, SVGGElement, unknown>>();

  // Ref to the D3 simulation
  const simulationRef = useRef<d3.Simulation<d3.SimulationNodeDatum, undefined>>();
  const activeCheckboxRef = useRef<d3.Selection<HTMLDivElement, unknown, null, undefined>>();
  const actRestoreRef = useRef<d3.Selection<HTMLDivElement, unknown, null, undefined>>();

  // Zoom in / out and fit the whole chartinto the container
  const overview = useCallback(
    (t = 750) => {
      if (d3SvgRef.current) {
        const nodesSel = d3SvgRef.current.select<SVGGElement>('.nodes').node();
        const nodesBBox = nodesSel?.getBBox();

        if (nodesBBox && nodesSel && d3SvgRef.current && zoomRef.current) {
          const longestBBoxSide = Math.max(nodesBBox.width, nodesBBox.height);

          const scaleExtent = getScaleExtent();

          if (scaleExtent[0] < Number.MAX_SAFE_INTEGER) {
            d3SvgRef.current
              .transition()
              .duration(t)
              .call(
                zoomRef.current.transform,

                d3.zoomIdentity
                  .translate(CHART_WIDTH / 2, CHART_WIDTH / 2)
                  .scale(Math.max(scaleExtent[0], Math.min(scaleExtent[1], CHART_WIDTH / longestBBoxSide)))
                  .translate(-nodesBBox.x - nodesBBox.width / 2, -nodesBBox.y - nodesBBox.height / 2)
              );
          }
        }
      }
    },
    [getScaleExtent]
  );

  // Zoom to a node when the node is clicked
  const focusNode = useCallback(
    (x: number, y: number, t = 750) => {
      if (d3SvgRef.current && zoomRef.current) {
        const scaleExtent = getScaleExtent();
        const nodesSel = d3SvgRef.current.select<SVGGElement>('.nodes').node();
        const nodesBBox = nodesSel?.getBBox();
        if (nodesBBox && nodesSel && scaleExtent[0] < Number.MAX_SAFE_INTEGER) {
          d3SvgRef.current
            .transition()
            .duration(t)
            .call(
              zoomRef.current.transform,

              d3.zoomIdentity
                .translate(CHART_WIDTH / 2, CHART_WIDTH / 2)
                .scale(
                  3 *
                    Math.max(
                      scaleExtent[0],
                      Math.min(scaleExtent[1], CHART_WIDTH / Math.max(nodesBBox.width, nodesBBox.height))
                    )
                )
                .translate(-x, -y - 76)
            );
        }
      }
    },
    [getScaleExtent]
  );

  // This runs on each animation tick.
  const ticked = useCallback(() => {
    if (!linksRef.current || !nodesRef.current || !linksLinesRef.current || !linkChannelGroupRef.current) {
      return;
    }

    // Keep the hub stickerd to the chart center
    const hub = nodesRef.current.filter(`#${DeviceType.HUB_DEVICE}`).node();
    if (hub) {
      overview(0);
    }

    // Move *each* node to the position held in the varibale d (the animation keep d updated)
    nodesRef.current.attr('transform', (d) => {
      return 'translate(' + d.x + ',' + d.y + ')';
    });

    // Move each line
    linksLinesRef.current
      .style('opacity', (d) => (d.source.data.id === '__root' || d.target.data.id === '__root' ? 0 : 1))
      .attr('d', (d) => {
        const dist = distance(d.source, d.target);
        if (dist) {
          return drawWavyLine(dist, CONNECTION_PERIODS[getConnectionType(d.target.data)]);
        }
        return null;
      })
      .attr('transform', (d) => {
        return `rotate(${rotation(d.source, d.target)} ${d.source.x} ${d.source.y}) translate(${d.source.x},${
          d.source.y
        })`;
      });
    linkChannelGroupRef.current.attr(
      'transform',
      (d) => `translate(${((d.source.x || 0) + (d.target.x || 0)) / 2},${((d.source.y || 0) + (d.target.y || 0)) / 2})`
    );
  }, [overview]);

  // Draw the chart
  useEffect(() => {
    if (svgRef.current) {
      const svg = d3
        .select<SVGElement, Node>(svgRef.current)
        .attr('viewBox', [0, 0, CHART_WIDTH, CHART_WIDTH].join(' '));
      d3SvgRef.current = svg;
      const g = svg.append('g');

      // Create a fake root node in order to use d3.hierarchy.
      // d3.hierarchy needs a root node, but devices might not have a common ancestor.
      const root = d3.hierarchy<DevicesGraphTree>({
        id: '__root',
        children: getFilteredGraph(true),
        labels: [],
        details_labels: [],
        type: null,
        device_category: null,
        connection_active: false,
        connection_strength: null,
        connection_type: null,
        channel: null,
        capabilities: [],
      });

      // D3 helps us to extract the nodes array from the tree
      const nodes = root.descendants() as Array<Node>;
      // D3 helps us to extract the links array from the tree
      const links = root.links() as Array<Link>;

      // Keep a reference to both of the arrays
      nRef.current = nodes;
      lRef.current = links;

      linksRef.current = g.append('g').attr('stroke', '#000').attr('stroke-width', 1.5).selectAll('g');
      linksLinesRef.current = linksRef.current.append('path');
      linkChannelGroupRef.current = linksRef.current.append('g');

      nodesRef.current = g.append('g').attr('class', 'nodes').selectAll('g');

      const zoom = d3.zoom<SVGElement, Node>().on('zoom', (e) => {
        g.attr('transform', e.transform);
        if (onZoom) onZoom(true);
      });

      zoomRef.current = zoom;

      // Configure the simulation
      // Numeric variables are often decided after painful hours of trieal-and-error
      const simulation = d3
        .forceSimulation()
        .force('charge', d3.forceManyBody().strength(-50))
        // Prevents nodes overlaps
        .force(
          'collision',
          d3.forceCollide().radius((node) => {
            if ((node as Node).data.type === DeviceType.HUB_DEVICE) {
              return NODE_RADIUS_HUB;
            }
            if ((node as Node).data.type === DeviceType.POD_DEVICE) {
              return NODE_RADIUS_POD;
            }
            return NODE_RADIUS;
          })
        )
        .force(
          'link',
          d3
            .forceLink()
            .id((d) => (d as Node).data.id)
            .strength((link) => {
              if (
                (link.source as Node).data.type === DeviceType.HUB_DEVICE &&
                (link.target as Node).data.type === DeviceType.POD_DEVICE
              ) {
                return 0.1;
              }
              return 1;
            })
        )
        .force('x', d3.forceX())
        .force('y', d3.forceY())

        .alphaDecay(0.15)
        .velocityDecay(0.5)
        .on('tick', ticked);

      simulationRef.current = simulation;

      svg.call(zoom).call(zoom.transform, d3.zoomIdentity);

      simulation.on('end', () => {
        setOp(1);
        const gBBox = g.node()?.getBBox();

        if (gBBox) {
          const padding = gBBox.width;

          zoom
            .translateExtent([
              [gBBox.x - padding, gBBox.y - padding],
              [gBBox.x + gBBox.width + padding, gBBox.y + gBBox.height + padding],
            ])
            .scaleExtent(getScaleExtent());
        }
      });
    }
  }, [getFilteredGraph, getScaleExtent, graph, onZoom, ticked]);

  useEffect(() => {
    // Zoom Reset
    if (reset === true) {
      setOpenBoxCount(0);
      setAdditionalDetails(null);
      setMainDetails(null);
      if (d3SvgRef.current) {
        const nodesSel = d3SvgRef.current.select<SVGGElement>('.nodes').node();
        const nodesBBox = nodesSel?.getBBox();
        if (nodesBBox && nodesSel && d3SvgRef.current && zoomRef.current) {
          const longestBBoxSide = Math.max(nodesBBox.width, nodesBBox.height);
          const scaleExtent = getScaleExtent();

          if (scaleExtent[0] < Number.MAX_SAFE_INTEGER) {
            d3SvgRef.current
              .transition()
              .duration(500)
              .call(
                zoomRef.current.transform,

                d3.zoomIdentity
                  .translate(CHART_WIDTH / 2, CHART_WIDTH / 2)
                  .scale(Math.max(scaleExtent[0], Math.min(scaleExtent[1], CHART_WIDTH / longestBBoxSide)))
                  .translate(-nodesBBox.x - nodesBBox.width / 2, -nodesBBox.y - nodesBBox.height / 2)
              );
          }
        }
      }
    }
  }, [reset, getScaleExtent]);

  const updateData = useCallback(
    (showHiddenDevices: boolean) => {
      if (
        !isLoading &&
        nRef.current &&
        lRef.current &&
        nodesRef.current &&
        linksRef.current &&
        linksLinesRef.current &&
        linkChannelGroupRef.current &&
        simulationRef.current &&
        props.timeStampvalue
      ) {
        /**
         * Create a fake root node in order to use d3.hierarchy.
         * d3.hierarchy needs a root node, but devices might not have a common ancestor.
         */
        const root = d3.hierarchy({
          id: '__root',
          children: getFilteredGraph(showHiddenDevices),
          labels: [],
          details_labels: [],
          type: '__root',
          device_category: 'root',
          connection_active: false,
          connection_strength: null,
          connection_type: null,
          channel: null,
          capabilities: [],
        });

        let nodes = root.descendants() as Array<Node>;
        let links = root.links() as Array<Link>;

        const old = new Map(nodesRef.current.data().map((d) => [d.data.id, d]));

        nodes = nodes.map((n) => Object.assign(n, old.get(n.data.id) || {}));
        links = links.map((d) => Object.assign({}, d));

        const hubNode = nodes.find((n) => n.data.type === DeviceType.HUB_DEVICE);

        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];

          if (node) {
            if (!node.x && !node.y) {
              if (node.parent) {
                node.x = node.parent.x;
                node.y = node.parent.y;
              }
            }
          }
        }

        if (hubNode) {
          hubNode.fx = 0;
          hubNode.fy = 0;
          hubNode.x = 0;
          hubNode.y = 0;
        }

        nodesRef.current = nodesRef.current.data(nodes, nodeKey).join((enter) => {
          const nodeGroup = enter.append('g');

          nodeGroup.style('opacity', (d) => (d.data.id === '__root' ? 0 : 1));

          nodeGroup.attr('id', (d) => (d.data.type === DeviceType.HUB_DEVICE ? DeviceType.HUB_DEVICE : d.data.id));

          const clicked = (event: Event, d: Node) => {
            event.stopPropagation();
            setMainDetails(d.data.labels);
            setAdditionalDetails(d.data.details_labels ?? null);
            setOpenBoxCount((openBoxCount) => openBoxCount + 1);
            const nodeData = nodesRef.current?.data().find((node) => node.data.id === d.data.id);
            const nX = nodeData?.x;
            const nY = nodeData?.y;
            if (typeof nX !== 'undefined' && typeof nY !== 'undefined') focusNode(nX, nY);
          };
          const parseLabelToValidSelector = (label: string) => {
            return label.replaceAll(' ', '-').replaceAll(':', '-').replaceAll('*', '-');
          };
          const onMouseLeave = (e: Event, d: Node) => {
            detailsBox
              .filter(`#details-group-${parseLabelToValidSelector(d.data.id)}`)
              .transition()
              .duration(200)
              .style('opacity', 0);
          };

          const detailsBox = nodeGroup
            .append('g')
            .attr('id', (d) => `details-group-${parseLabelToValidSelector(d.data.id)}`)
            .style('opacity', 0);

          // Details box
          detailsBox
            .append('rect')
            .attr('id', (d) => `rect-${d.data.id}`)
            .text(function (d) {
              return d.data.device_label ?? d.data.id;
            })
            .attr('x', -ICON_RADIUS * 1.6)
            .attr('y', (d) => -(isGenericDeviceNode(d) ? ICON_DEVICE_RADIUS : ICON_RADIUS) / 2 - PADDING_HEIGHT)
            .attr('rx', 3)
            .attr('ry', 3)
            .attr('fill', theme.palette.background.paper)
            .attr('stroke', theme.palette.text.primary)
            .attr('stroke-width', 1)
            .attr('width', ICON_RADIUS * 3.2)
            .attr('height', (d) => 20 + getDetailsBoxHeight(d))
            .on('mouseleave', onMouseLeave);

          // Device name box
          nodeGroup
            .append('rect')
            .text(function (d) {
              return d.data.device_label ?? d.data.id;
            })
            .attr('x', -ICON_RADIUS * 0.95)
            .attr('y', (d) => (isGenericDeviceNode(d) ? ICON_DEVICE_RADIUS : ICON_RADIUS) / 2 - DEVICE_NAME_HEIGHT / 2)
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('fill', theme.palette.background.default)
            .attr('stroke', '#607d8b')
            .attr('stroke-width', (d) => (isGenericDeviceNode(d) ? 1 : 2))
            .attr('width', ICON_RADIUS * 0.95 * 2)
            .attr('height', DEVICE_NAME_FONT_SIZE * 1.6)
            .on('mouseleave', onMouseLeave);

          // Device name text
          nodeGroup
            .append('text')
            .text((d) => {
              return d.data.device_label ?? d.data.id ?? '-';
            })
            .attr('x', 0)
            .attr('y', (d) => (isGenericDeviceNode(d) ? ICON_DEVICE_RADIUS : ICON_RADIUS) / 2 + DEVICE_NAME_HEIGHT / 4)
            .attr('width', ICON_RADIUS)
            .attr('text-anchor', 'middle')
            .attr('font-size', DEVICE_NAME_FONT_SIZE)
            .attr('font-family', 'monospace')
            .style('fill', theme.palette.text.primary)
            .on('click', clicked)
            .on('mouseleave', onMouseLeave);

          // Main labels
          detailsBox.each((d: Node) => {
            const currentDetailsBox = detailsBox.filter(`#details-group-${parseLabelToValidSelector(d.data.id)}`);
            for (let i = 0; i < d.data.labels.length; i++) {
              currentDetailsBox
                .append('text')
                .attr('class', (d) => `text-${d.data.id}`)
                .text((d) => d.data.labels[i] || '')
                .attr('x', 0)
                .attr(
                  'y',
                  (d) =>
                    (isGenericDeviceNode(d) ? ICON_DEVICE_RADIUS : ICON_RADIUS) / 2 +
                    (i + 1) * (FONT_SIZE * 1.1) +
                    PADDING_HEIGHT +
                    DEVICE_NAME_HEIGHT / 2 +
                    PADDING_HEIGHT / 2
                )
                .attr('width', ICON_RADIUS)
                .attr('text-anchor', 'middle')
                .attr('font-size', FONT_SIZE)
                .style('fill', theme.palette.text.primary)
                .on('mouseleave', onMouseLeave);
            }
          });

          // Capabilities icons
          for (let i = 0; i < capabilities.length; i++) {
            const capability = capabilities[i];

            if (capability) {
              const capabilityBox = detailsBox.append('g');
              capabilityBox
                .append('image')
                .attr('x', (i - 4) * (CAPABILITIES_ICON_SIZE + 4))
                .attr(
                  'y',
                  (d) =>
                    20 +
                    getDetailsBoxHeight(d) -
                    (isGenericDeviceNode(d) ? ICON_DEVICE_RADIUS : ICON_RADIUS) / 2 -
                    DEVICE_NAME_HEIGHT / 2 -
                    DEVICE_NAME_FONT_SIZE * 1.6
                )
                .attr('xlink:href', capability.icon)
                .attr('width', CAPABILITIES_ICON_SIZE + 4)
                .attr('height', CAPABILITIES_ICON_SIZE)
                .style('opacity', (d) => (d.data.capabilities.includes(capability.name) ? 1 : 0.2));
              capabilityBox.append('title').text(capability.name);
            }
          }

          // Devices Icons
          nodeGroup
            .append('g')
            .attr(
              'transform',
              (d) => `translate(${isGenericDeviceNode(d) ? -ICON_DEVICE_RADIUS / 2 : -ICON_RADIUS / 2},
               ${(isGenericDeviceNode(d) ? -ICON_DEVICE_RADIUS : -ICON_RADIUS) / 2 - 2 * PADDING_HEIGHT})`
            )
            .html((d) => SVGDevice(d.data.type, d.data.device_category ?? null, d.data.connection_strength))
            .attr('cursor', 'pointer')
            .on('click', clicked)
            .on('mouseover', (e, d) => {
              if (simulationRef.current && simulationRef.current.alpha() < 0.001) {
                detailsBox
                  .filter(`#details-group-${parseLabelToValidSelector(d.data.id)}`)
                  .transition()
                  .duration(200)
                  .style('opacity', 1);
              }
            })
            .on('mouseleave', onMouseLeave);

          nodeGroup.on('mouseleave', onMouseLeave);

          return nodeGroup;
        });

        linksRef.current = linksRef.current.data(links, linkKey).join((enter) => {
          const lineGroup = enter.append('g');
          return lineGroup;
        });

        linksLinesRef.current = linksLinesRef.current.data(links, linkKey).join((enter) => {
          const path = enter.append('path');
          path
            .attr('fill', 'none')
            .attr('stroke', (d) => CONNECTION_COLORS[getConnectionType(d.target.data)])
            .attr('stroke-width', 2);
          return path;
        });

        linkChannelGroupRef.current = linkChannelGroupRef.current.data(links, linkKey).join((enter) => {
          const group = enter.append('g');
          group.style('opacity', (d) =>
            !d.target.data.connection_type === null ||
            d.target.data.connection_type === 'ethernet' || //show the circle if connection_type is not ethernet
            d.source.data.id === '__root' ||
            d.target.data.id === '__root'
              ? 0
              : 1
          );

          group
            .append('circle')
            .attr('fill', 'none')
            .attr('stroke', (d) => CONNECTION_COLORS[getConnectionType(d.target.data)])
            .attr('stroke-width', 3)
            .attr('fill', '#fff')
            .attr('r', CHANNEL_RADIUS);

          group
            .append('text')
            .text((d) => (d.target.data.channel === null ? `N/A` : d.target.data.channel))
            .attr('text-anchor', 'middle')
            .attr('transform-box', 'fill-box')
            .attr('font-size', CHANNEL_FONT_SIZE)
            .attr('transform', `translate(0,${CHANNEL_FONT_SIZE * 0.4})`)
            .style('opacity', (d) => (d.target.data.connection_type === null ? 0 : 1));

          group
            .append('image')
            .attr('x', -CHANNEL_RADIUS / 2)
            .attr('y', -CHANNEL_RADIUS / 2)
            .attr('xlink:href', Ethernet)
            .attr('width', CHANNEL_RADIUS)
            .attr('height', CHANNEL_RADIUS)
            .style('opacity', (d) => (d.target.data.connection_type === null ? 0.8 : 0));
          return group;
        });

        simulationRef.current.nodes(nodes);
        (simulationRef.current.force('link') as d3.ForceLink<Node, Link>)?.links(links);
        simulationRef.current.alpha(1).restart();

        nRef.current = nodes;
        lRef.current = links;
      }
    },
    [
      capabilities,
      getDetailsBoxHeight,
      getFilteredGraph,
      theme.palette.background.default,
      theme.palette.background.paper,
      theme.palette.text.primary,
      isLoading,
      focusNode,
      props.timeStampvalue,
    ]
  );

  // const removeElement = useMemo(() => {
  //   const walkLabels = (node: DevicesGraphTree) => {
  //     let newMainDetails = [];
  //     for (let i = 0; i < node.children.length; i++) {
  //       const child = node.children[i];
  //       if (child) {
  //         newMainDetails = mainDetails?.splice(indexOf('Attivo: Si'));
  //       }
  //     }
  //     return newMainDetails;
  //   };
  // });

  // const newMainDetails = mainDetails?.splice(indexOf('Attivo: SI'));

  // Show graph for the first time
  useEffect(() => {
    updateData(false);
  }, [updateData]);

  const activeCheckImageRef = useRef<d3.Selection<HTMLImageElement, unknown, null, undefined>>();

  useEffect(() => {
    if (
      !isLoading &&
      legendSvgRef.current &&
      legendSvgRef.current.children.length === 0 &&
      props.timeStampvalue !== undefined
    ) {
      const svg = d3.select(legendSvgRef.current);

      const devicesGroup = svg.append('div').attr('class', 'column');

      devicesGroup.append('div').attr('class', 'title').text('Qualità del segnale');
      const devicesGroupElements = devicesGroup.append('div').attr('class', 'elements');

      const excellent = devicesGroupElements.append('div');
      excellent.attr('class', 'row');
      excellent.append('g').html(SVGDevice(null, null, Signal.Excellent));
      excellent.append('div').text('Eccellente');

      const good = devicesGroupElements.append('div');
      good.attr('class', 'row');
      good.append('g').html(SVGDevice(null, null, Signal.Good));
      good.append('div').text('Buona');

      const warning = devicesGroupElements.append('div');
      warning.attr('class', 'row');
      warning.append('g').html(SVGDevice(null, null, Signal.Fair));
      warning.append('div').text('Debole');

      const bad = devicesGroupElements.append('div');
      bad.attr('class', 'row');
      bad.append('g').html(SVGDevice(null, null, Signal.Poor));
      bad.append('div').text('Pessima');

      const na = devicesGroupElements.append('div');
      na.attr('class', 'row');
      na.append('g').html(SVGDevice(null, null, Signal.None));
      na.append('div').text('N/A');

      const connectionGroup = svg.append('div').attr('class', 'column');
      connectionGroup.append('div').attr('class', 'title').text('Tipo di connessione');
      const connectionGroupElements = connectionGroup.append('div').attr('class', 'elements');

      const cableLine = connectionGroupElements.append('div');
      cableLine.attr('class', 'row');
      cableLine
        .append('svg')
        .append('line')
        .attr('stroke', CONNECTION_COLORS[Connection.Cable] as string)
        .attr('stroke-width', 2)
        .attr('x1', 0)
        .attr('y1', LEGEND_ICON_SIZE / 2 - 1)
        .attr('x2', 64)
        .attr('y2', LEGEND_ICON_SIZE / 2 - 1);
      cableLine.append('div').text('Cavo');

      const twoGhzLine = connectionGroupElements.append('div');
      twoGhzLine.attr('class', 'row');
      twoGhzLine
        .append('svg')
        .append('g')
        .attr('transform', `translate(0,${LEGEND_ICON_SIZE / 2 - 1})`)
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', CONNECTION_COLORS[Connection.TwoGHz] as string)
        .attr('stroke-width', 2)
        .attr('d', () => {
          return drawWavyLine(64, CONNECTION_PERIODS[Connection.TwoGHz]);
        });
      twoGhzLine.append('div').text('2.4 GHz Wi-Fi');

      const fiveGhzline = connectionGroupElements.append('div');
      fiveGhzline.attr('class', 'row');
      fiveGhzline
        .append('svg')
        .append('g')
        .attr('transform', `translate(0,${LEGEND_ICON_SIZE / 2 - 1})`)
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', CONNECTION_COLORS[Connection.FiveGHz] as string)
        .attr('stroke-width', 2)
        .attr('d', () => {
          return drawWavyLine(64, CONNECTION_PERIODS[Connection.FiveGHz]);
        });
      fiveGhzline.append('div').text('5 GHz Wi-Fi');

      const dateOfTimeStamp = svg.append('div').attr('class', 'column');
      dateOfTimeStamp.append('div').attr('class', 'title').text('Ultimo aggiornamento');

      const timeStampAndDate = dateOfTimeStamp.append('div');
      if (props.timeStampvalue) {
        timeStampAndDate.append('div').text(`${format(Number(props.timeStampvalue), 'dd/MM/yyyy HH:mm:ss')}`);
      }
      timeStampAndDate.attr('class', 'row');

      const restoreGroup = svg.append('div').attr('class', 'column');
      restoreGroup.append('div').attr('class', 'textIconRestore').text('Aggiorna informazioni');
      const restoreGroupElements = restoreGroup.append('div').attr('class', 'elements');

      actRestoreRef.current = restoreGroupElements.append('div');
      actRestoreRef.current.attr('class', 'option-row');

      activeCheckImageRef.current = actRestoreRef.current
        .append('img')
        .attr('src', restore)
        .attr('class', 'restoreIcon')
        .style('cursor', 'pointer')
        .on('click', () => {
          onRefresh();
        });

      const filterGroup = svg.append('div').attr('class', 'column');
      filterGroup.append('div').attr('class', 'title').text('Opzioni di visualizzazione');
      const filterGroupElements = filterGroup.append('div').attr('class', 'elements');

      activeCheckboxRef.current = filterGroupElements.append('div');
      activeCheckboxRef.current.attr('class', 'option-row');

      activeCheckImageRef.current = activeCheckboxRef.current
        .append('img')
        .attr('src', CheckboxBlankOutline)
        .style('cursor', 'pointer');
      activeCheckboxRef.current.append('div').text('Mostra device non attivi');
    }
  }, [graph, onRefresh, nodeLabelsCount, updateData, props.timeStampvalue, isLoading, props]);

  useEffect(() => {
    //This if condition means ==> if all nodes of graph is exist (page is loaded) &&
    // refresh btn exist && page is not in the middle of loading process, ==>
    // We are allowed to click on refresh btn.
    if (
      legendSvgRef.current?.children.length !== 0 &&
      actRestoreRef.current &&
      !isLoading &&
      activeCheckboxRef.current
    ) {
      actRestoreRef.current.on('click', () => {
        //By click on refresh btn state of btn for fetching new data will change to true.
        setisLoading(true);
      });
    }
    updateData(showInactiveDevices);
  }, [isLoading, showInactiveDevices, updateData]);

  useEffect(() => {
    if (legendSvgRef.current && activeCheckboxRef.current) {
      activeCheckboxRef.current.on('click', () => {
        setShowInactiveDevices(!showInactiveDevices);
      });
    }
    updateData(showInactiveDevices);
  }, [graph, nodeLabelsCount, updateData, showInactiveDevices]);

  useEffect(() => {
    if (activeCheckImageRef.current && op === 1) {
      activeCheckImageRef.current.attr('src', showInactiveDevices ? CheckboxMarked : CheckboxBlankOutline);
    }
  }, [op, showInactiveDevices]);

  return (
    <>
      <LoaderView
        condition={op === 1 && !readDevicesGraphRequest.inProgress}
        text={t('dashboard:customerInfo:graphPlotting')}
        minHeight={size?.height}
      >
        {''}
      </LoaderView>
      {warning && warning === true ? (
        <Box sx={{ py: 2, px: 4, borderBottom: '1px solid #00000020' }}>
          <Alert severity="warning">{`Canale Wi-Fi non visualizzabile per uno o più dispositivi`}</Alert>
        </Box>
      ) : null}

      <Box
        ref={rootRef}
        sx={{
          width: '100%',
          height: warning === true && size?.height ? size?.height - 66 : size?.height,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ opacity: op }}>
          <Box
            component="svg"
            ref={svgRef}
            sx={{
              opacity: op,
              transitionProperty: 'opacity',
              transitionDuration: (theme) => `${theme.transitions.duration.shorter}ms`,
              transitionTimingFunction: (theme) => theme.transitions.easing.easeInOut,
              position: 'absolute',
              zIndex: 0,
              top: 0,
              left: 0,
              height: 'calc(100% - 64px)',
              width: '100%',
            }}
          />
          <DisconnectedPods graph={graph} error={null} />
          <DeviceDetails
            mainDetails={mainDetails}
            additionalDetails={additionalDetails ?? undefined}
            openBox={openBoxCount}
          />
          <Box
            ref={legendSvgRef}
            sx={{
              transitionProperty: 'opacity',
              transitionDuration: (theme) => `${theme.transitions.duration.shorter}ms`,
              transitionTimingFunction: (theme) => theme.transitions.easing.easeInOut,
              background: (theme) => theme.palette.background.default,
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              paddingY: 1,
              paddingX: 4,
              borderTop: (theme) => `1px solid ${theme.palette.text.primary}20`,
              typography: 'caption',
              display: 'flex',
              flexDirection: 'row',
              flexShrink: 1,
              justifyContent: 'center',
              '& .column': {
                flexDirection: 'column',
                flex: `0 1 auto`,
                flexWrap: 'wrap',
                justifyContent: 'center',
                marginRight: 4,
                display: 'flex',
                flexShrink: 1,
                '&:last-child': {
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginLeft: props.size?.width && props.size.width < 1100 ? 0 : 'auto',
                  marginRight: 0,
                },
              },
              '& .title': {
                typography: 'caption',
                fontWeight: 'bold',
              },
              '& .elements': {
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                paddingLeft: 3,
              },
              '& .row': {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                height: LEGEND_ICON_SIZE,
                flex: `0 1 auto`,
                paddingRight: 4,
                '& img, & svg': {
                  width: LEGEND_ICON_SIZE,
                  height: LEGEND_ICON_SIZE,
                  flex: `0 0 ${LEGEND_ICON_SIZE}px`,
                },
                '& div': {
                  flex: '1 1 auto',
                  flexWrap: 'wrap',
                  marginLeft: 2,
                },
              },
              '& .option-row': {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                height: LEGEND_ICON_SIZE,
                flex: `0 1 170px`,
                '& img, & svg': {
                  width: 32,
                  height: 32,
                  flex: `0 0 32px`,
                  flexWrap: 'wrap',
                },
                '& div': {
                  flex: '1 1 auto',
                  flexWrap: 'wrap',
                  marginLeft: 2,
                },
              },
              '& .restoreIcon': {
                alignContent: 'center',
                marginLeft: 42,
                marginRight: 10,
                typography: 'caption',
                fontWeight: 'bold',
              },
              '& .textIconRestore': {
                alignContent: 'center',
                marginLeft: 35,
                typography: 'caption',
                fontWeight: 'bold',
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
}
