import format from 'date-fns/format';

const EURO_FORMAT = 'yyyy/MM/dd HH:mm:ss';

export function formatDate(dateString: string | number | null | undefined): string {
  if (!dateString) {
    return '—';
  }

  let date = new Date(dateString);

  if (typeof dateString === 'number') {
    date = new Date(dateString * 1000);
  }

  return format(date, EURO_FORMAT);
}
