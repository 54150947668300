import React, { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ChevronRight from '@mitch528/mdi-material-ui/ChevronRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { CustomerArea } from '../../entities/Customer';
import useAuthentication from '../../hooks/useAuthentication';
import { SEARCH_CUSTOMER_GRANTS } from '../../utils/auth';
import { BreadcrumbsContext } from './BreadcrumbsProvider';

export default function Breadcrumbs(): JSX.Element {
  const { breadcrumbs } = useContext(BreadcrumbsContext);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { hasGrants, isMyTeamAndTVStd } = useAuthentication();
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  const area = params.get('area') ?? CustomerArea.BB;

  const currentBreadcrumbs = useMemo(
    () =>
      Object.keys(breadcrumbs)
        .filter((path) => history.location.pathname.indexOf(path) === 0)
        .sort((a, b) => a.length - b.length)
        .map((path) => {
          const breadcrumbPath = breadcrumbs[path];
          if (breadcrumbPath) {
            return {
              ...breadcrumbPath,
              label: breadcrumbPath?.label ? breadcrumbPath.label : '',
            };
          }

          return null;
        })
        .filter((b) => b !== null),
    [breadcrumbs, history.location.pathname]
  );

  const fullPath = useMemo(
    () => [
      {
        label: t('navbar:searchCustomer'),
        path: '/search-customer',
        bold: false,
        showInTitle: false,
      },
      ...currentBreadcrumbs,
    ],
    [currentBreadcrumbs, t]
  );

  const baseCrumbPath = useMemo(
    () => [
      {
        label: t('navbar:searchCustomer'),
        path: '/search-customer',
        bold: false,
        showInTitle: false,
      },
      // ...currentBreadcrumbs,
    ],
    [t]
  );

  const msoCrumbPath = useMemo(
    () => [
      {
        label: t('navbar:mso'),
        path: '/mso',
        bold: false,
        showInTitle: false,
      },
    ],
    [t]
  );

  const downDetectorCrumbPath = useMemo(
    () => [
      {
        label: t('navbar:downDetector'),
        path: '/down-detector',
        bold: false,
        showInTitle: false,
      },
      ...currentBreadcrumbs,
    ],
    [currentBreadcrumbs, t]
  );

  const breadcrumbsWithFullPath = useMemo(() => {
    let path = fullPath;
    if (location.pathname.includes('/search-customer')) {
      path = baseCrumbPath;
    }
    if (location.pathname === '/mso') {
      path = msoCrumbPath;
    }
    if (location.pathname.includes('/down-detector')) {
      path = downDetectorCrumbPath;
    }
    // Removing search custmer if user not allowed.
    if (!hasGrants(SEARCH_CUSTOMER_GRANTS)) {
      path = path.filter((p) => p?.path !== '/search-customer');
    }
    // Gestione temporanea casistica myteam + tvstd. Ideale: Dividere ruoli per area e fare check su quello
    if (location.pathname !== '/search-customer' && area === CustomerArea.BB && isMyTeamAndTVStd()) {
      path = path.filter((p) => p?.path !== '/search-customer');
    }
    return path;
  }, [
    fullPath,
    location.pathname,
    hasGrants,
    area,
    isMyTeamAndTVStd,
    baseCrumbPath,
    msoCrumbPath,
    downDetectorCrumbPath,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {(currentBreadcrumbs.filter((b) => b?.showInTitle !== false).reverse() as Array<{ label: string }>)
            .concat([{ label: t('common:appName') }])
            .map((breadcrumb) => {
              return breadcrumb.label;
            })
            .join(' - ')}
        </title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      >
        {breadcrumbsWithFullPath.map((breadcrumb, i) => {
          if (!breadcrumb) {
            return null;
          }

          return (
            <React.Fragment key={breadcrumb.path}>
              <Button
                component={Link}
                to={breadcrumb.path}
                sx={{
                  color: 'inherit',
                  fontSize: '1.1rem',
                  fontWeight: breadcrumb.bold ? 'bold' : 'normal',
                }}
              >
                {breadcrumb.label}
              </Button>
              {i !== breadcrumbsWithFullPath.length - 1 && <ChevronRight />}
            </React.Fragment>
          );
        })}
      </Box>
      <Box
        sx={{
          width: '100%',
          display: {
            xs: 'block',
            sm: 'none',
          },
        }}
      >
        <Select variant="outlined" value={''} fullWidth color="primary">
          {breadcrumbsWithFullPath
            .slice()
            .reverse()
            .map((breadcrumb, i) => {
              if (!breadcrumb) {
                return null;
              }

              return (
                <MenuItem
                  key={breadcrumb.path}
                  value={i}
                  onClick={() => history.push(breadcrumb.path)}
                  sx={{
                    color: 'inherit',
                    fontSize: '1.1rem',
                    fontWeight: breadcrumb.bold ? 'bold' : 'normal',
                  }}
                >
                  {breadcrumb.label}
                </MenuItem>
              );
            })}
        </Select>
      </Box>
    </>
  );
}
