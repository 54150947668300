import * as z from 'zod';
import { ActionSchema, ActionStatusSchema } from './Action';

const WizardStepIdSchema = z.string();

export const WizardSuggestionSchema = z.object({
  description: z.string(),
  icon: z.enum(['info', 'warning', 'stop']).optional(),
});

export type WizardSuggestion = z.infer<typeof WizardSuggestionSchema>;

export const WizardButtonSchema = z.object({
  name: z.string(),
  type: z.literal('button'),
  value: z.union([z.number(), z.string()]),
  side: z.union([z.undefined(), z.string()]),
  confirm: z.union([z.undefined(), z.boolean()]),
  disabled: z.boolean().nullable().optional(),
});

// Pick List
export type PickList = {
  name: string;
  picklist?: PickList[];
};
export const PickListSchema: z.ZodSchema<PickList> = z.lazy(() =>
  z.object({
    name: z.string(),
    picklist: z.array(PickListSchema).optional(),
  })
);

export const WizardPickListSchema = z.object({
  name: z.string(),
  type: z.literal('picklist'),
  value: z.array(PickListSchema),
});
export type WizardPickList = z.infer<typeof WizardPickListSchema>;

//
export const WizardTextBoxSchema = z.object({
  name: z.string(),
  type: z.literal('textbox'),
  value: z.object({
    description: z.string(),
    required: z.boolean(),
  }),
});

export const WizardCheckBoxSchema = z.object({
  name: z.string(),
  type: z.literal('checkbox'),
  value: z.object({
    description: z.string(),
    checked: z.boolean(),
  }),
});

export const WizardResultSchema = z.object({
  option: z
    .object({
      name: z.string(),
      type: z.string(),
      value: z.number(),
    })
    .partial(),
});

export const WizardStatusEntrySchema = z.object({
  description: z.string(),
  url: z.string(),
  method: z.string(),
});

export type WizardStatusEntry = z.infer<typeof WizardStatusEntrySchema>;

export const WizardBaseStepSchema = z.object({
  wizard_id: z.string(),
  timestamp: z.number(),
  title: z.string(),
  title_step: z.string(),
  type: z.union([z.string(), z.undefined()]),
  description: z.union([z.undefined(), z.string()]),
  suggestions: z.union([z.undefined(), z.array(WizardSuggestionSchema)]),
  actions: z.union([z.undefined(), z.array(ActionSchema)]),
  options: z.union([
    z.undefined(),
    z.array(z.union([WizardButtonSchema, WizardPickListSchema, WizardTextBoxSchema, WizardCheckBoxSchema])),
  ]),
  results: z.union([
    z.undefined(),
    z.object({ option: z.object({ name: z.string(), type: z.string(), value: z.number() }).partial() }),
  ]),
  actions_results: z.union([z.undefined(), z.null(), z.record(ActionStatusSchema)]),
  chart_list: z.array(
    z.object({
      chart_name: z.string(),
      chart_type: z.string(),
    })
  ),
  elapsed_steps: z.onumber(),
  total_steps: z.onumber(),
});

export const DefaultWizardStepSchema = WizardBaseStepSchema;

export type DefaultWizardStep = z.infer<typeof DefaultWizardStepSchema>;

export const StatusesStepSchema = WizardBaseStepSchema.merge(
  z.object({
    statuses: z.array(WizardStatusEntrySchema),
    actionNext: z.union([z.undefined(), WizardStepIdSchema]),
  })
);
export type StatusesWizardStep = z.infer<typeof StatusesStepSchema>;

export interface WizardFeedback {
  solved: boolean | undefined;
  rating: number;
  result: string | undefined;
  note: string;
}

export const WizardStepResultSchema = z.object({
  wizard_id: z.string(),
  result: z.array(
    z.object({
      name: z.string(),
      type: z.string(),
      value: z.union([z.number(), z.string(), z.array(z.string()), z.boolean()]),
    })
  ),
});

export type WizardStepResult = z.infer<typeof WizardStepResultSchema>;

export interface WizardStepResultResponse {
  step: string;
}

// ################################

export enum WizardTypes {
  WIZARD_STEP = 'wizard_step',
  NO_WIZARDS = 'no_wizards',
  WIZARD_FEEDBACK = 'wizard_feedback',
  CLOSELOOP = 'closeloop',
  RESULTS = 'results',
  WIZARD0_VIEW = 'wizard0_view',
  WIZARD_PICKLIST_VIEW = 'picklist_view',
}

export const WizardStepSchema = z.object({
  type: z.literal(WizardTypes.WIZARD_STEP),
  message: z.string(),
  data: z.object({
    wizard_id: z.string(),
    title: z.string(),
    title_step: z.string(),
    description: z.string(),
    actions: z.array(ActionSchema),
    options: z.array(z.union([WizardButtonSchema, WizardPickListSchema, WizardTextBoxSchema, WizardCheckBoxSchema])),
    results: WizardResultSchema.optional(),
    chart_list: z.array(
      z.object({
        chart_name: z.string(),
        chart_type: z.string(),
      })
    ),
    actions_results: z.array(ActionStatusSchema),
    suggestions: z.array(WizardSuggestionSchema),
    elapsed_steps: z.onumber(),
    total_steps: z.onumber(),
    timestamp: z.number(),
    timer_timeout_seconds: z.number().optional().nullable(),
    timer_timeout_start: z.string().optional().nullable(),
    timer_timeout_end: z.string().optional().nullable(),
    pseudo_result: z.array(z.any()).optional().nullable(),
    override: z.boolean().nullable().optional(),
  }),
});
export type WizardStep = z.infer<typeof WizardStepSchema>;

export const WizardPicklistViewSchema = z.object({
  type: z.literal(WizardTypes.WIZARD_PICKLIST_VIEW),
  message: z.string(),
  data: z.object({
    // wizard_id: z.string(),
    // timestamp: z.number(),
    title: z.string(),
    title_step: z.string().optional(),
    description: z.string(),
    options: z.array(z.union([WizardButtonSchema, WizardPickListSchema])),
    elapsed_steps: z.onumber(),
    total_steps: z.onumber(),
    superuser: z.boolean(),
  }),
});
export type WizardPicklistView = z.infer<typeof WizardPicklistViewSchema>;

export const WizardNoWizardsSchema = z.object({ type: z.literal(WizardTypes.NO_WIZARDS) });

export const WizardFeedbackSchema = z.object({ type: z.literal(WizardTypes.WIZARD_FEEDBACK) });

export const WizardCloseTheLoopSchema = z.object({ type: z.literal(WizardTypes.CLOSELOOP) });

export const WizardResultsSchema = z.object({
  type: z.literal(WizardTypes.RESULTS),
  data: z.object({
    problems_not_solved: z.array(
      z.object({
        description: z.string(),
        result: z.string(),
        status: z.string(),
        problems: z.array(z.string()),
      })
    ),
    cases: z
      .object({
        columns: z.array(
          z.object({
            column_name: z.string(),
            alias: z.string(),
          })
        ),
        rows: z.array(
          z.object({
            rule_id: z.string(),
            entries: z.array(
              z.object({
                column_name: z.string(),
                value: z.string().optional(),
                possible_values: z.array(z.string()).optional(),
              })
            ),
          })
        ),
        hesitation_result: z
          .object({
            severity: z.union([z.literal('success'), z.literal('error'), z.literal('loading')]),
            text: z.string(),
          })
          .optional(),
      })
      .optional(),
    duplicated_cases: z
      .object({
        columns: z.array(
          z.object({
            column_name: z.string(),
            alias: z.string(),
          })
        ),
        rows: z.array(
          z.object({
            entries: z.array(
              z.object({
                column_name: z.string(),
                value: z.string(),
              })
            ),
          })
        ),
      })
      .optional(),
    escalations: z
      .object({
        columns: z.array(
          z.object({
            column_name: z.string(),
            alias: z.string(),
          })
        ),
        rows: z.array(
          z.object({
            entries: z.array(
              z.object({
                column_name: z.string(),
                value: z.string(),
              })
            ),
          })
        ),
      })
      .optional(),
  }),
});

export const WizardZeroViewSchema = z.object({
  type: z.literal(WizardTypes.WIZARD0_VIEW),
  data: z.object({
    title: z.string(),
    description: z.string(),
    wizard0_list: z.array(
      z.object({
        wizard_id: z.string(),
        name: z.string(),
        description: z.string(),
        hint: z.string(),
        checked: z.boolean().optional(),
      })
    ),
    button_text: z.string(),
    superuser: z.boolean().optional(),
  }),
});

export const WizardSchema = z.union([
  WizardStepSchema,
  WizardPicklistViewSchema,
  WizardNoWizardsSchema,
  WizardFeedbackSchema,
  WizardCloseTheLoopSchema,
  WizardResultsSchema,
  WizardZeroViewSchema,
]);

export type Wizard = z.infer<typeof WizardSchema>;
