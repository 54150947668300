import { useMemo } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import * as dateFnsLocales from 'date-fns/locale';
import i18next from 'i18next';

// import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en';
import enFlag from './i18n/en-GB.svg';
import it from './i18n/it';
import itFlag from './i18n/it.svg';

i18next
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Uncomment to detect browser language
    // fallbackLng: (code) => {
    //   if (code.indexOf('en') === 0) {
    //     return ['en-GB'];
    //   }
    //   return ['it'];
    // },
    fallbackLng: localStorage.getItem('locale') ?? 'it',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: { it, 'en-GB': en },
  });

export const i18n = i18next;

export const availableLanguages = {
  it: {
    name: it.common.languageName,
    flag: itFlag,
  },
  'en-GB': {
    name: en.common.languageName,
    flag: enFlag,
  },
} as { [id: string]: { name: string; flag: string } };

export function useDateFnsI18n(): Locale {
  const { i18n } = useTranslation();

  return useMemo(() => {
    switch (i18n.language) {
      case 'it':
        return dateFnsLocales.it;
      case 'en-GB':
        return dateFnsLocales.enGB;
      default:
        return dateFnsLocales.it;
    }
  }, [i18n.language]);
}
