import React, { useCallback, useState } from 'react';

export type Breadcrumb = {
  path: string;
  label: string;
  bold: boolean;
  showInTitle: boolean;
};

export type BreadcrumbsContextType = {
  breadcrumbs: Record<string, Breadcrumb>;
  add: (breadcrumb: Breadcrumb) => void;
};

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextType>({
  breadcrumbs: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  add: () => {},
});

type BreadcrumbsProviderProps = {
  children: React.ReactNode | React.ReactNodeArray;
};

export const BreadcrumbsProvider = (props: BreadcrumbsProviderProps): JSX.Element => {
  const [breadcrumbs, setBreadcrumbs] = useState({});

  const add = useCallback((breadcrumb: Breadcrumb) => {
    setBreadcrumbs((breadcrumbs) => ({ ...breadcrumbs, [breadcrumb.path]: breadcrumb }));
  }, []);

  return <BreadcrumbsContext.Provider value={{ breadcrumbs, add }}>{props.children}</BreadcrumbsContext.Provider>;
};
