import React, { useRef, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/system';

import BrowsingTheWebB from '../../assets/browsing-the-web/browsing-the-web-b.svg';
import BrowsingTheWebW from '../../assets/browsing-the-web/browsing-the-web-w.svg';

import CheckEmailB from '../../assets/check-email/check-email-b.svg';
import CheckEmailW from '../../assets/check-email/check-email-w.svg';

import ConnectingSmartHomeDeviceB from '../../assets/connecting-smart-home-device/connecting-smart-home-device-b.svg';
import ConnectingSmartHomeDeviceW from '../../assets/connecting-smart-home-device/connecting-smart-home-device-w.svg';

import OnlineGamingB from '../../assets/online-gaming/online-gaming-b.svg';
import OnlineGamingW from '../../assets/online-gaming/online-gaming-w.svg';

import { ReactComponent as PodIcon } from '../../assets/pod.svg';
import { ReactComponent as SpotIcon } from '../../assets/spot.svg';

import StreamingAudioB from '../../assets/streaming-audio/streaming-audio-b.svg';
import StreamingAudioW from '../../assets/streaming-audio/streaming-audio-w.svg';

import StreamingVideoB from '../../assets/streaming-video/streaming-video-b.svg';
import StreamingVideoW from '../../assets/streaming-video/streaming-video-w.svg';
import { ReactComponent as SuperSpotIcon } from '../../assets/super-spot.svg';

import UploadFileB from '../../assets/upload-file/upload-file-b.svg';
import UploadFileW from '../../assets/upload-file/upload-file-w.svg';

import VideoChattingB from '../../assets/video-chatting/video-chatting-b.svg';
import VideoChattingW from '../../assets/video-chatting/video-chatting-w.svg';

import { DevicesGraphTree } from '../../entities/DevicesGraph';
import { RequestError } from '../../entities/RequestError';
import { RequestParseError } from '../../utils/RequestParseError';
import { DeviceCategory } from './SVGDevice';

interface DisconnectedPodsProps {
  graph?: DevicesGraphTree[];
}

export default function DisconnectedPods(props: {
  graph: DisconnectedPodsProps['graph'] | undefined;
  error: RequestError | RequestParseError | null;
}): JSX.Element | null {
  if (!props.graph) {
    return props.error ? (
      <Box sx={{ py: 2, px: 4 }}>
        <Alert severity="warning">{props.error.message}</Alert>
      </Box>
    ) : null;
  }
  return <BoxDisconnectedPods graph={props.graph} />;
}

function DeviceIcon({ device, height }: Readonly<{ device: DevicesGraphTree; height: string | number }>) {
  switch (device.device_category) {
    case DeviceCategory.SkySpot:
      return <SpotIcon height={height} />;
    case DeviceCategory.SkySuperSpot:
      return <SuperSpotIcon height={height} />;
  }
  return <PodIcon height={height} />;
}

function BoxDisconnectedPods(props: DisconnectedPodsProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const [device, setDevice] = useState<null | DevicesGraphTree>(null);
  const pod_legendSvgRef = useRef<HTMLDivElement>(null);
  const pod_labelsPod = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const capabilities = React.useMemo(() => {
    return [
      { name: 'Video Chatting', icon: theme.palette.mode === 'light' ? VideoChattingB : VideoChattingW },
      { name: 'Online Gaming', icon: theme.palette.mode === 'light' ? OnlineGamingB : OnlineGamingW },
      { name: 'Uploading a File', icon: theme.palette.mode === 'light' ? UploadFileB : UploadFileW },
      { name: 'Streaming Video', icon: theme.palette.mode === 'light' ? StreamingVideoB : StreamingVideoW },
      { name: 'Streaming Audio', icon: theme.palette.mode === 'light' ? StreamingAudioB : StreamingAudioW },
      { name: 'Checking Email', icon: theme.palette.mode === 'light' ? CheckEmailB : CheckEmailW },
      { name: 'Browsing the Web', icon: theme.palette.mode === 'light' ? BrowsingTheWebB : BrowsingTheWebW },
      {
        name: 'Connecting a Smart Home Device',
        icon: theme.palette.mode === 'light' ? ConnectingSmartHomeDeviceB : ConnectingSmartHomeDeviceW,
      },
    ];
  }, [theme.palette.mode]);

  return (
    <>
      <Box
        ref={pod_legendSvgRef}
        sx={{
          background: (theme) => theme.palette.background.default,
          position: 'absolute',
          zIndex: 1,
          top: 0,
          maxHeight: 320,
          overflowY: 'auto',
          paddingY: 1,
          paddingX: 4,
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          border: `1px solid`,
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderColor: (theme) => `${theme.palette.text.primary}20`,
          borderBottomRightRadius: 10,
        }}
      >
        <Box
          component="p"
          sx={{
            marginY: 1,
            fontWeight: 'bold',
          }}
        >
          {'SPOT Disconnessi'}
        </Box>
        {(props.graph ?? [])
          .filter((d) => d.type?.toLowerCase() === 'pod')
          .map((deviceObj) => {
            return (
              <Box
                key={deviceObj.id}
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onMouseEnter={(event) => {
                  setAnchorEl(event.currentTarget);
                  setDevice(deviceObj);
                }}
                onMouseLeave={() => {
                  setAnchorEl(null);
                }}
              >
                <DeviceIcon device={deviceObj} height="36"></DeviceIcon>
                <Box sx={{ marginLeft: 2 }}>{deviceObj.id}</Box>
              </Box>
            );
          })}
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            '&.MuiPopover-paper': {
              border: 0,
              overflowX: 'visible',
              overflowY: 'visible',
              backgroundColor: 'transparent',
            },
          },
        }}
        style={{
          pointerEvents: 'none',
        }}
      >
        <Card
          ref={pod_labelsPod}
          sx={{
            transitionProperty: 'opacity',
            transitionDuration: (theme) => `${theme.transitions.duration.shorter}ms`,
            transitionTimingFunction: (theme) => theme.transitions.easing.easeInOut,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            bgcolor: 'background.paper',
            width: 250,
            paddingY: 2,
            paddingX: 4,
            border: `1px solid`,
            minHeight: 250,
          }}
        >
          {device && (
            <>
              <DeviceIcon device={device} height="48"></DeviceIcon>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginY: 1 }}>
                {device.labels.map((label) => (
                  <Box key={label} component="p" sx={{ margin: 0.5 }}>
                    {label}
                  </Box>
                ))}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: 1 }}>
                {capabilities.map((capability) => (
                  <Box
                    key={capability.name}
                    component="img"
                    src={capability.icon}
                    sx={{ opacity: device.capabilities.includes(capability.name) ? 1 : 0.2 }}
                  />
                ))}
              </Box>
            </>
          )}
        </Card>
      </Popover>
    </>
  );
}
