import React, { Fragment, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';

import { EmbeddedService, Service } from '../../../entities/DownDetector';
import { useDownDetector } from '../../../hooks/useDownDetector';
import { useApplicationError } from '../../ApplicationError';
import ApplicationErrorProvider from '../../ApplicationError/ApplicationErrorProvider';
import { useBreadcrumbs } from '../../Breadcrumbs';
import LoaderView from '../../LoaderView';
import Navbar from '../../Navbar/Navbar';
import ServiceChart from '../../pages/DownDetector/ServiceChart';
import ServiceHeatMap from './ServiceHeatMap';

export default function ServiceDashboard(): JSX.Element {
  const {
    embeddedServiceList,
    readService,
    readServiceRequest,
    readEmbeddedServiceList,
    readEmbeddedServiceListRequest,
  } = useDownDetector();
  const { id } = useParams() as { id?: string | undefined };
  const { url } = useRouteMatch();
  const [service, setService] = useState<Service | undefined>();
  const history = useHistory();

  useBreadcrumbs({ label: service?.name ?? '', path: url, showInTitle: false });

  const applicationErrors = useMemo(() => [readServiceRequest.error, readServiceRequest.parseError], [
    readServiceRequest,
  ]);
  useApplicationError('DiagnosticsLog', applicationErrors);

  useEffect(() => {
    if (!embeddedServiceList || !embeddedServiceList.length) {
      readEmbeddedServiceList();
    }
  }, [embeddedServiceList, readEmbeddedServiceList]);

  useEffect(() => {
    const fetchServiceData = async () => {
      if (id) {
        const serviceData = await readService(parseInt(id));
        if (serviceData) setService(serviceData);
      }
    };
    fetchServiceData();
  }, [id, readEmbeddedServiceList, readService]);

  return (
    <Fragment>
      <LoaderView condition={!readServiceRequest.inProgress} minHeight={'100vh'}>
        <Navbar />
        <ApplicationErrorProvider>
          <Box sx={{ height: 'calc(100vh - 62px)', display: 'flex', flex: 1, flexDirection: 'column' }}>
            <Card
              sx={{
                mx: 2,
                p: 2,
                px: 4,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexShrink: 0,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img src={service?.logos.header} alt="logo" loading="lazy" style={{ maxWidth: 300, height: 60 }}></img>
              </Box>
              <LoaderView minWidth={300} minHeight={25} condition={!readEmbeddedServiceListRequest.inProgress}>
                <Autocomplete
                  defaultValue={embeddedServiceList.find((item) => item.slug === service?.slug)}
                  options={embeddedServiceList}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={(event: SyntheticEvent<Element, Event>, newValue: EmbeddedService | null) => {
                    if (newValue?.id)
                      history.replace(`/down-detector/${newValue?.id}`, { mapDisabled: !newValue._heatmap_available });
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img loading="lazy" width="20" src={option.logos.xsmall} alt="" />
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} size="small" label={service?.name} />}
                  sx={{ width: 300 }}
                />
              </LoaderView>
            </Card>
            <Box sx={{ flex: 1, m: 2, display: 'flex', flexDirection: 'row', minHeight: 0 }}>
              {/* Map */}
              <Card sx={{ flex: 1 }}>
                <ServiceHeatMap serviceSlug={service?.slug} serviceId={service?.id} />
              </Card>

              {/* Chart */}
              <Card sx={{ px: 2, flex: 2, marginLeft: 2, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ fontSize: 20, fontWeight: 'bold', p: 2 }}>
                  Segnalazioni giornaliere di interruzione servizio:
                </Box>
                <Box
                  sx={{
                    flex: '1 1 auto',
                    my: 2,
                  }}
                >
                  <LoaderView condition={!readServiceRequest.inProgress}>
                    {!service?.stats_24 ? (
                      <Box>Dati non presenti</Box>
                    ) : (
                      <ServiceChart data={service.stats_24} averageData={service.baseline} />
                    )}
                  </LoaderView>
                </Box>

                <Box sx={{ my: 3, mx: 2, fontSize: 12, overflow: 'auto' }}>{service?.description}</Box>
              </Card>
            </Box>
          </Box>
        </ApplicationErrorProvider>
      </LoaderView>
    </Fragment>
  );
}
