import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
//import Box, { BoxProps } from '@mui/material/Box';
// import FormLabel from '@mui/material/FormLabel';
// import Paper from '@mui/material/Paper';
// import Rating from '@mui/material/Rating';
// import TextField from '@mui/material/TextField';
import { WizardTypes } from '../../../../../entities/Wizard';
import { useCustomerData } from '../../../../../hooks/useCustomerData';
import { useWizard } from '../../../../../hooks/useWizard';
import { Scroller } from '../../../../Scroller';
import WizardButton from '../components/WizardButton';
import WizardCardActions from '../components/WizardCardActions';
import { WizardInnerCard, WizardLoader, WizardPrimaryCard } from '../components/WizardCommonComponents';
import { WizardDescription } from '../components/WizardDescription';
import WizardHeader from '../components/WizardHeader';
import WizardPageTitle from '../components/WizardPageTitle';
import WizardTitle from '../components/WizardTitle';

// function Control(props: BoxProps) {
//   return (
//     <Box
//       component="fieldset"
//       sx={{
//         marginY: 1,
//         marginX: 0,
//         border: 0,
//         '&:last-child': {
//           marginBottom: 0,
//         },
//       }}
//       {...props}
//     />
//   );
// }

export function WizardFeedbackStep(): JSX.Element | null {
  const {
    wizardStep: wizard,
    readWizardStepRequest,
    readWizardStep,
    createFeedback,
    createFeedbackRequest,
  } = useWizard();
  const { customerId } = useCustomerData();
  const { t } = useTranslation();

  const [feedback] = useState({
    solved: undefined as boolean | undefined,
    rating: null as number | null,
    result: undefined as string | undefined,
    note: '',
  });

  // const title = 'Feedback';
  const title = 'Procedura risoluzione guidata';
  const description = 'Premere "Avanti" per continuare o terminare la procedura guidata.';

  if (!wizard || wizard.type !== WizardTypes.WIZARD_FEEDBACK) {
    return null;
  }

  return (
    <WizardPrimaryCard>
      <Scroller>
        <WizardHeader wizard={wizard} />
        <WizardPageTitle rightOuterButtons={[]} />
        <WizardLoader>
          <WizardInnerCard
            actions={
              <WizardCardActions
                left={[]}
                right={[
                  <WizardButton
                    key="button_text"
                    disabled={typeof feedback.rating === 'undefined'}
                    confirm={false}
                    onClick={async () => {
                      if (customerId && typeof feedback.rating !== 'undefined') {
                        await createFeedback(customerId, {
                          type: 'wizard_feedback',
                          data: {
                            ...feedback,
                          },
                        });
                        readWizardStep(customerId, {
                          type: 'wizard_feedback',
                          data: {
                            ...feedback,
                          },
                        });
                      }
                    }}
                    loading={readWizardStepRequest.inProgress || createFeedbackRequest.inProgress}
                  >
                    {t('common:next')}
                  </WizardButton>,
                ]}
              />
            }
          >
            <WizardTitle title={title} />
            <WizardDescription description={description} />

            {/* <Paper sx={{ my: 3, py: 4, px: 2 }}>
              <Control>
                <FormLabel component="legend">{'Come valuti il procedimento?'}</FormLabel>
                <Rating
                  name="simple-controlled"
                  value={feedback.rating}
                  onChange={(event, newValue) => setFeedback((feedback) => ({ ...feedback, rating: newValue }))}
                />
              </Control>
              <Control>
                <FormLabel component="legend">{'Aggiungi una nota:'}</FormLabel>
                <TextField
                  multiline
                  fullWidth
                  rows={6}
                  value={feedback.note}
                  onChange={(e) => setFeedback((feedback) => ({ ...feedback, note: e.target.value }))}
                />
              </Control>
            </Paper> */}
          </WizardInnerCard>
        </WizardLoader>
      </Scroller>
    </WizardPrimaryCard>
  );
}
