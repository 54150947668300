import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration, ChartDataset } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import pattern from 'patternomaly';
import { useTheme } from '@mui/system';
import { L2BellsTrendline } from '../../../../entities/L2Dashboard';

export type L2BellsChartSerie = {
  data_class: string;
  values: L2BellsTrendline['values'];
  color: string;
  hidden?: boolean;
};

export type L2BellsChartArea = {
  data_class: string;
  from?: number;
  to?: number;
  color: string;
  hidden?: boolean;
};

interface L2BellsChartProps {
  series: L2BellsChartSerie[];
  areas?: L2BellsChartArea[];
  x_label?: string;
  y_label?: string;
}

export default function L2BellsChart(props: L2BellsChartProps): JSX.Element {
  const { series, areas, x_label, y_label } = props;
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });

  const theme = useTheme();

  useEffect(() => {
    let _animate = true;
    if (chartRef.current.chart) {
      _animate = false;
      chartRef.current.chart.destroy();
    }

    if (ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        // Series
        const datasets: ChartDataset[] = [];
        for (let i = 0; i < series.length; i++) {
          const serie = series[i];
          if (serie) {
            const interpolatedValues = [];
            const channel = serie.values.summit.x;
            /**
             * Hide networks that are using channels not allowed in Italy (EU)
             * Allowed channels are 1-14 and 32-144 according to
             * https://en.wikipedia.org/wiki/List_of_WLAN_channels#5_GHz_(802.11a/h/j/n/ac/ax)
             */
            // errore
            if (channel < 1) {
              return undefined;
            }
            // errore
            if ((channel > 14 && channel < 32) || channel > 144) {
              return undefined;
            }
            // Bell construction: y = ax^2 + bx + c
            const Ax = serie.values.left_point.x;
            const Ay = serie.values.left_point.y;
            const Bx = serie.values.right_point.x;
            const Vx = serie.values.summit.x;
            const Vy = serie.values.summit.y;
            const a = -(Vy - Ay) / Math.pow(Ax - Vx, 2);
            const b = -2 * a * Vx;
            const c = Ay + a * Ax * (-Ax + 2 * Vx);

            const s = (Bx - Ax) / 8;
            let x = Ax;

            while (x <= Bx) {
              interpolatedValues.push({ x, y: a * Math.pow(x, 2) + b * x + c });
              x = Number((x + s).toFixed(2));
            }

            // Filtering values if an area is hidden
            let filteredValues = interpolatedValues;
            if (filteredValues && areas) {
              for (let i = 0; i < areas.length; i++) {
                const area = areas[i];
                if (area?.hidden) {
                  filteredValues = filteredValues.filter((value) => {
                    let keepValue = true;
                    if (area.from && value.x >= area.from) keepValue = false;
                    if (area.to && value.x <= area.to) keepValue = false;
                    if (area.from && area.to && (value.x > area.to || value.x < area.from)) keepValue = true;
                    return keepValue;
                  });
                }
              }
            }
            datasets[i] = {
              data: [...filteredValues].sort((a, b) => a.x - b.x),
              label: serie.data_class,
              fill: 'start',
              hidden: serie.hidden,
              borderColor: serie.color,
              backgroundColor: serie.color + '4a',
              borderWidth: 2,
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
              pointHitRadius: 30,
              type: 'line' as const,
            };
          }
        }

        // Areas
        const annotations: Record<string, AnnotationOptions> = {};
        if (areas) {
          for (let i = 0; i < areas.length; i++) {
            const area = areas[i];
            if (area && !area.hidden) {
              annotations[area.data_class] = {
                type: 'box',
                backgroundColor: pattern.draw('square', 'transparent', area.color + '2f'),
                borderWidth: 0.2,
                xMin: area.from,
                xMax: area.to,
                drawTime: 'beforeDatasetsDraw',
              };
            }
          }
        }

        const chartOptions = {
          type: 'line',
          data: {
            datasets,
          },
          options: {
            animation: _animate,
            responsive: true,
            maintainAspectRatio: false,
            pointHitRadius: 20,
            layout: {
              padding: {
                left: 14,
                right: 40,
                top: 20,
                bottom: 8,
              },
            },
            plugins: {
              tooltip: {
                enabled: true,
                mode: 'x',
                footerMarginTop: 10,
                footerFont: { weight: 'bold' },
                callbacks: {
                  title: (tooltipItems) => {
                    const channels = tooltipItems.map((item) => item.label);
                    let titleString = 'Canale: ';
                    channels.forEach((channel, index) => {
                      if (!titleString.includes(channel.toString()))
                        titleString = titleString.concat(`${index !== 0 ? ', ' : ' '}` + channel.toString());
                    });
                    return titleString;
                  },
                  label: (item) => {
                    return `${item.dataset.label} (${item.parsed.y.toFixed(1)}db)`;
                  },
                },
                filter: (tooltipItem) => {
                  // removes tooltip trigger from not-summit points
                  return tooltipItem.dataIndex === 4;
                },
              },
              legend: {
                display: false,
              },
              annotation: {
                annotations: annotations,
              },
            },
            scales: {
              x: {
                type: 'linear' as const,
                min: 0,
                max: 144,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                  stepSize: 5,
                  precision: 1,
                },
                title: {
                  text: x_label,
                  font: { weight: 'bold' },
                  display: true,
                },
              },
              y: {
                min: -100,
                max: 0,
                type: 'linear' as const,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                title: {
                  text: y_label,
                  font: { weight: 'bold' },
                  display: true,
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                },
              },
            },
          },
        } as ChartConfiguration<'line'>;
        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart(ctx, chartOptions);
      }
    }
  }, [areas, series, theme.palette.text.primary, x_label, y_label]);

  return <canvas ref={ref} />;
}
