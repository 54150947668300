import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CustomerActions } from '../../entities/Action';
import { Customer, CustomerAttribute, CustomerInfo, CustomerLastTestInfo, CustomerMapT } from '../../entities/Customer';
import { IColumn } from '../../entities/Table';
import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestState,
  RequestParseFailureAction,
  requestParseState,
  mutateParseErrorState,
} from '../toolkitUtils';

const initialState = {
  id: undefined as undefined | Customer['id'],
  list: [] as Customer[],
  attributes: [] as CustomerAttribute[],
  actions: {} as CustomerActions,
  info: {} as CustomerInfo,
  lastTestInfo: {} as CustomerLastTestInfo,
  mapT: undefined as CustomerMapT | undefined,
  requests: {
    read: requestState(),
    readInfo: requestParseState(),
    readLastTestInfo: requestParseState(),
    readList: {
      ...requestState(),
      total: 0 as number,
      columns: [] as IColumn[],
    },
    readAttributes: requestParseState(),
    readActionList: requestParseState(),
    readHidden: requestState(),
    readMapT: requestParseState(),
  },
};

export type CustomerState = typeof initialState;

interface ReadCustomerSuccessAction {
  response: Customer;
}

interface ReadCustomerListSuccessAction {
  response: Array<Customer>;
  total: number;
  columns: IColumn[];
}

interface ReadCustomerAttributesSuccessAction {
  response: Array<CustomerAttribute>;
}

interface ReadInfoSuccessAction {
  response: CustomerInfo;
  customerId: Customer['id'];
}

interface ReadLastTestInfoSuccessAction {
  response: CustomerLastTestInfo;
  customerId: Customer['id'];
}

interface ReadActionListSuccessAction {
  response: CustomerActions;
  customerId: Customer['id'];
}

// MapT
interface ReadCustomerMapTSuccessAction {
  response: CustomerMapT;
  customerId: Customer['id'];
}

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    readCustomerRequest(state) {
      mutateRequestState(state.requests.read);
    },
    readCustomerSuccess(state, action: PayloadAction<ReadCustomerSuccessAction>) {
      const { response } = action.payload;
      state.id = response.id;
      state.actions = initialState.actions;
      mutateSuccessState(state.requests.read);
    },
    readCustomerFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.read, action.payload.error);
    },
    readCustomerListRequest(state) {
      mutateRequestState(state.requests.readList);
    },
    readCustomerListSuccess(state, action: PayloadAction<ReadCustomerListSuccessAction>) {
      const { response, total, columns } = action.payload;

      state.list = [...state.list, ...response];

      mutateSuccessState(state.requests.readList);
      state.requests.readList.total = total;
      state.requests.readList.columns = columns;
    },
    readCustomerListFailure(state, action: PayloadAction<RequestFailureAction>) {
      state.list = [];
      mutateErrorState(state.requests.readList, action.payload.error);
    },
    clearList(state) {
      state.list = [];
    },

    readCustomerAttributesRequest(state) {
      mutateRequestState(state.requests.readAttributes);
    },
    readCustomerAttributesSuccess(state, action: PayloadAction<ReadCustomerAttributesSuccessAction>) {
      const { response } = action.payload;

      state.attributes = [...response];

      mutateSuccessState(state.requests.readAttributes);
    },
    readCustomerAttributesParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readAttributes, action.payload.parseError);
    },
    readCustomerAttributesFailure(state, action: PayloadAction<RequestFailureAction>) {
      state.attributes = [];
      mutateErrorState(state.requests.readAttributes, action.payload.error);
    },
    readInfoRequest(state) {
      mutateRequestState(state.requests.readInfo);
    },
    readInfoSuccess(state, action: PayloadAction<ReadInfoSuccessAction>) {
      const { response } = action.payload;

      state.info = response;

      mutateSuccessState(state.requests.readInfo);
    },
    readInfoParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readInfo, action.payload.parseError);
    },
    readInfoFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readInfo, action.payload.error);
    },
    readLastTestInfoRequest(state) {
      mutateRequestState(state.requests.readLastTestInfo);
    },
    readLastTestInfoSuccess(state, action: PayloadAction<ReadLastTestInfoSuccessAction>) {
      const { response } = action.payload;

      state.lastTestInfo = response;

      mutateSuccessState(state.requests.readLastTestInfo);
    },
    readLastTestInfoParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readLastTestInfo, action.payload.parseError);
    },
    readLastTestInfoFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readLastTestInfo, action.payload.error);
    },
    readActionListRequest(state) {
      mutateRequestState(state.requests.readActionList);
    },
    readActionListSuccess(state, action: PayloadAction<ReadActionListSuccessAction>) {
      const { response } = action.payload;

      state.actions = response;

      mutateSuccessState(state.requests.readActionList);
    },
    readActionListParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readActionList, action.payload.parseError);
    },
    readActionListFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readActionList, action.payload.error);
    },
    readHiddenRequest(state) {
      mutateRequestState(state.requests.readHidden);
    },
    readHiddenSuccess(state) {
      mutateSuccessState(state.requests.readHidden);
    },
    readHiddenFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readHidden, action.payload.error);
    },
    // Mapt
    readMapTRequest(state) {
      mutateRequestState(state.requests.readMapT);
    },
    readMapTSuccess(state, action: PayloadAction<ReadCustomerMapTSuccessAction>) {
      const { response } = action.payload;
      state.mapT = response;
      mutateSuccessState(state.requests.readMapT);
    },
    readMapTParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readMapT, action.payload.parseError);
    },
    readMapTFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readMapT, action.payload.error);
    },
    //reset
    reset(state) {
      state.id = initialState.id;
      state.list = initialState.list;
      state.attributes = initialState.attributes;
      state.actions = initialState.actions;
      state.info = initialState.info;
      state.lastTestInfo = initialState.lastTestInfo;
      state.requests = initialState.requests;
    },
  },
});

export const customerActions = customersSlice.actions;

export default customersSlice.reducer;
