import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { isAfter } from 'date-fns';
import add from 'date-fns/add';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import sub from 'date-fns/sub';
import _ from 'lodash';
import CognitoRepository from '../api/CognitoRepository';
import { CognitoUser } from '../entities/CognitoUser';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';
import { authenticationActions } from '../store/reducers/authentication';
import { authGroups, RequiredGrants } from '../utils/auth';

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'sky_broadband.cognito.accessToken.token';
const LOCAL_STORAGE_ID_TOKEN_KEY = 'sky_broadband.cognito.idToken.token';
const LOCAL_STORAGE_TOKEN_EXPIRE_KEY = 'sky_broadband.cognito.token.expire';
const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'sky_broadband.cognito.refreshToken.token';
const LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY = 'sky_broadband.cognito.refreshToken.expire';

type UseAuthentication = {
  clearTokens: () => void;
  signOut: () => void;
  signedIn: RootState['authentication']['signedIn'];
  user: null | CognitoUser;
  signInRequest: RootState['authentication']['requests']['signIn'];
  accessToken: string | null;
  idToken: string | null;
  tokenExpire: string | null;
  checkToken: () => Promise<void>;
  refreshToken: () => Promise<{ accessToken: string; idToken: string } | undefined>;
  retrieveTokenFromCode: (code: string, clientId: string, redirect_uri: string) => Promise<void>;
  hasGrants: (grants: RequiredGrants) => boolean;
  isMyTeamAndTVStd: () => boolean;
  userTerritory: string;
};

export function AuthenticationProvider(props: { children: React.ReactNode }): JSX.Element {
  const { checkToken, refreshToken, tokenExpire } = useAuthentication();
  const refreshTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  useEffect(() => {
    if (tokenExpire) {
      if (refreshTimeoutRef.current) {
        window.clearTimeout(refreshTimeoutRef.current);
      }

      const refreshDate = sub(new Date(tokenExpire), { minutes: 5 });

      if (differenceInMilliseconds(refreshDate, new Date()) > 10) {
        // eslint-disable-next-line no-console
        // console.log(`Expecting token refresh on ${refreshDate.toISOString()}`);

        refreshTimeoutRef.current = setTimeout(() => {
          refreshToken();
        }, differenceInMilliseconds(refreshDate, new Date()));
      }
    }
    checkToken();
  }, [tokenExpire, checkToken, refreshToken]);

  return <>{props.children}</>;
}

export default function useAuthentication(): UseAuthentication {
  const dispatch = useDispatch();
  const authentication = useSelector((state: RootState) => state.authentication);
  const accessToken = useSelector((state: RootState) => state.authentication.accessToken);
  const idToken = useSelector((state: RootState) => state.authentication.idToken);
  const tokenExpire = useSelector((state: RootState) => state.authentication.tokenExpire);
  const loginType = useSelector((state: RootState) => state.authentication.login_type);

  const verifyValidSession = useCallback((accessToken: string) => {
    const accessTokenJSONPayload = parseJwt(accessToken);
    let cognitoActiveSessions: string[] | undefined = accessTokenJSONPayload['cognito:groups'];
    if (cognitoActiveSessions) {
      cognitoActiveSessions = cognitoActiveSessions.map((item) => {
        if (item.includes('lv')) {
          return item.replace('lv', 'live');
        } else return item;
      });
    }
    const validSessions = cognitoActiveSessions?.filter((session) => {
      return session.match(/it-sky-assurance+\|[a-z0-9]+\|[a-z0-9]+\|[0-9]+/);
    });
    if (!validSessions || !validSessions.length) {
      throw new Error(
        `Non risultano sessioni cognito valide per effettuare correttamente l'autenticazione. Riprova più tardi..`
      );
    }

    const validation = validSessions?.toString().match(new RegExp(`${process.env.REACT_APP_ENV}`));
    if (!validation) {
      throw new Error(
        `Non risulti abilitato ad accedere a questa piattaforma per il seguente ambiente: <i>${process.env.REACT_APP_ENV}</i>`
      );
    }
  }, []);

  const retrieveUserInfo = useCallback(
    (idToken: string) => {
      try {
        const parsedIdToken = parseJwt(idToken);

        // calculate userID
        let username = 'Utente Sconosciuto';
        const cognitoIdentities: Array<{ userId: string }> | undefined = parsedIdToken['identities'];
        if (cognitoIdentities) {
          const userId: string | undefined = cognitoIdentities[0]?.userId;
          if (userId) username = userId;
        } else {
          const cognitoUsername: string = parsedIdToken['cognito:username'];
          username = cognitoUsername;
        }

        // calculate user level & group
        let cognitoActiveSessions: string[] | undefined = parsedIdToken['cognito:groups'];
        if (cognitoActiveSessions) {
          cognitoActiveSessions = cognitoActiveSessions.map((item) => {
            if (item.includes('lv')) {
              return item.replace('lv', 'live');
            } else return item;
          });
        }
        const assuranceSessions: string[] | undefined = cognitoActiveSessions?.filter((s) =>
          s.includes('it-sky-assurance|' + process.env.REACT_APP_ENV)
        );

        // creating a groups record with the max level for each role
        const groups: Record<string, number> =
          assuranceSessions?.reduce((groupMap: Record<string, number>, session: string) => {
            const findMyGroup = session.split('|');
            const group = findMyGroup[2] ?? '';
            const level = parseInt(findMyGroup[3] ?? '0');
            if ((groupMap[group] ?? 0) < level) {
              groupMap[group] = level;
            }
            return groupMap;
          }, {}) ?? {};
        // getting the role with highest level
        let maxRole = '';
        let maxLevel = 0;
        Object.entries(groups).forEach(([role, level]: [string, number]) => {
          if (level > maxLevel) {
            maxRole = role;
            maxLevel = level;
          }
        });

        const storedUserRole = window.localStorage.getItem('custom_user_role');
        const storedUserLevel = window.localStorage.getItem('custom_user_level');
        // If level already stored level is greater, delete it
        if ((storedUserLevel ?? 0) > maxLevel) {
          window.localStorage.removeItem('custom_user_level');
        }
        let role = maxRole;
        let level = maxLevel;
        if (storedUserRole) {
          const group = authGroups.find((group) => group.role === storedUserRole);
          if (group) {
            level = group.level;
            role = group.role;
          }
        }

        dispatch(
          authenticationActions.setUserInfo({
            user: { username, maxLevel, maxRole, level, role, groups },
          })
        );

        // store informations
      } catch (error) {
        throw new Error(
          `Il processo di recupero delle informazioni dell' utente è fallito. Motivo: <i>"${`${error}`.replace(
            'Error: ',
            ''
          )}"</i>`
        );
      }
    },
    [dispatch]
  );

  const refreshToken = useCallback(async () => {
    dispatch(authenticationActions.refreshTokenRequest());

    let accessToken: string;
    let idToken: string;
    let tokenExpire: string;

    const refreshToken = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    const refreshTokenExpireString = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY);

    if (refreshToken === null || refreshTokenExpireString === null) {
      const error = new RequestError('Refresh token not stored locally');
      dispatch(authenticationActions.refreshTokenFailure({ error }));
      return;
    }

    if (isAfter(new Date(), new Date(refreshTokenExpireString))) {
      const error = new RequestError('Refresh token expired');
      window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
      window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY);
      dispatch(authenticationActions.refreshTokenFailure({ error }));
      return;
    }

    try {
      const response = await CognitoRepository.refreshToken(
        refreshToken,
        loginType === 'standalone'
          ? `${process.env.REACT_APP_COGNITO_STANDALONE_CLIENT_ID}`
          : `${process.env.REACT_APP_COGNITO_FEDERATED_CLIENT_ID}`
      );

      accessToken = response.access_token;
      idToken = response.id_token;
      tokenExpire = add(new Date(), { seconds: response.expires_in - 10 }).toISOString();
    } catch (error) {
      window.localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
      window.localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN_KEY);
      window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRE_KEY);
      dispatch(authenticationActions.refreshTokenFailure({ error: error as RequestError }));
      return;
    }

    window.localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
    window.localStorage.setItem(LOCAL_STORAGE_ID_TOKEN_KEY, idToken);
    window.localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRE_KEY, tokenExpire);
    dispatch(authenticationActions.refreshTokenSuccess({ accessToken, idToken, tokenExpire }));
    return { accessToken, idToken };
  }, [dispatch, loginType]);

  const checkToken = useCallback(async () => {
    dispatch(authenticationActions.checkTokenRequest());

    const storageAccessToken = window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    const storageIdToken = window.localStorage.getItem(LOCAL_STORAGE_ID_TOKEN_KEY);
    const storageTokenExpireString = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRE_KEY);

    if (storageAccessToken === null || storageIdToken === null || storageTokenExpireString == null) {
      const error = new RequestError('Access (or id) Token not stored locally');
      dispatch(authenticationActions.checkTokenFailure({ error }));
      return;
    }

    const tokenExpire = new Date(storageTokenExpireString);

    if (isAfter(tokenExpire, new Date())) {
      dispatch(
        authenticationActions.checkTokenSuccess({
          accessToken: storageAccessToken,
          idToken: storageIdToken,
          tokenExpire: storageTokenExpireString,
        })
      );
      retrieveUserInfo(storageIdToken);
    } else {
      const error = new RequestError('Token expired');
      dispatch(authenticationActions.checkTokenExpiredFailure({ error }));
      const idToken = (await refreshToken())?.idToken;
      if (idToken) {
        retrieveUserInfo(idToken);
      }
    }
  }, [dispatch, refreshToken, retrieveUserInfo]);

  const retrieveTokenFromCode = useCallback(
    async (code: string, clientId: string, redirect_uri: string) => {
      dispatch(authenticationActions.signInRequest());

      let accessToken: string;
      let idToken: string;
      let refreshToken: string;
      let tokenExpire: string;

      try {
        const response = await CognitoRepository.getToken(code, clientId, redirect_uri);

        accessToken = response.access_token;
        idToken = response.id_token;
        refreshToken = response.refresh_token;
        tokenExpire = add(new Date(), { seconds: response.expires_in - 10 }).toISOString();

        window.localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
        window.localStorage.setItem(LOCAL_STORAGE_ID_TOKEN_KEY, idToken);
        window.localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRE_KEY, tokenExpire);

        window.localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
        window.localStorage.setItem(
          LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY,
          add(new Date(), { days: 30 }).toISOString()
        );
      } catch (error) {
        dispatch(authenticationActions.signInFailure({ error: new RequestError(error as AxiosError) }));
        throw new Error(
          `Il processo di recupero del token di autenticazione è fallito. Motivo: <i>"${`${error}`.replace(
            'Error: ',
            ''
          )}"</i>`
        );
      }
      retrieveUserInfo(idToken);
      verifyValidSession(accessToken);

      dispatch(authenticationActions.signInSuccess({ accessToken, idToken, tokenExpire }));
    },
    [dispatch, retrieveUserInfo, verifyValidSession]
  );

  const clearTokens = useCallback(() => {
    window.localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRE_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY);
  }, []);

  const signOut = useCallback(async () => {
    clearTokens();
    dispatch(authenticationActions.signOut());
  }, [clearTokens, dispatch]);

  const hasGrants = useCallback(
    (grants: RequiredGrants): boolean => {
      let allowed = true;

      // if a role is impersonated checks the selected role / level, otherwise checks all roles.
      if (authentication.user?.role !== authentication.user?.maxRole) {
        // selected role is different, then check impersonated role
        const userRole = (authentication.user?.role ?? '').toLowerCase();
        allowed = allowed && checkImpersonatedRole(userRole, grants);
      } else {
        const userRoles = Object.keys(authentication.user?.groups ?? {});
        allowed = allowed && checkallRoles(userRoles, grants);
      }
      const level = authentication.user?.level ?? -1;

      if (grants.exactLevel) {
        allowed = allowed && grants.exactLevel === level;
      }
      if (grants.minLevel) {
        allowed = allowed && level >= grants.minLevel;
      }
      if (grants.maxLevel) {
        allowed = allowed && level <= grants.maxLevel;
      }
      return allowed;
    },
    [authentication.user?.groups, authentication.user?.level, authentication.user?.maxRole, authentication.user?.role]
  );

  const isMyTeamAndTVStd = useCallback(() => {
    const myTeamTV = ['MYTEAMEXT', 'MYTEAM', 'TVSTD'];
    const userRoles = Object.keys(authentication.user?.groups ?? {});
    return _.differenceBy(userRoles, myTeamTV, toLowerCase).length === 0;
  }, [authentication.user?.groups]);

  const userTerritory = useMemo(() => {
    // If the user has the group UKTRIAL the territory is UK
    const userRoles = Object.keys(authentication.user?.groups ?? {}).map(toLowerCase);
    if (userRoles.includes('uktrial')) {
      return 'UK';
    }
    // Check impersonated role
    if (authentication.user?.role === 'uktrial') {
      return 'UK';
    }
    return 'IT';
  }, [authentication.user?.groups, authentication.user?.role]);

  return {
    clearTokens,
    signOut,
    signedIn: authentication.signedIn,
    user: authentication.user,
    signInRequest: authentication.requests.signIn,
    accessToken,
    idToken,
    tokenExpire,
    checkToken,
    refreshToken,
    retrieveTokenFromCode,
    hasGrants,
    isMyTeamAndTVStd,
    userTerritory,
  };
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  if (base64) {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  return undefined;
}

function checkImpersonatedRole(userRole: string, grants: RequiredGrants) {
  let allowed = true;
  if (grants.allowedRoles?.length) {
    allowed = allowed && grants.allowedRoles.some((role: string) => role.toLowerCase() === userRole);
  }
  if (grants.deniedRoles?.length) {
    allowed = allowed && !grants.deniedRoles.some((role: string) => role.toLowerCase() === userRole);
  }
  if (grants.redFlags?.length) {
    allowed = allowed && !grants.redFlags.some((role: string) => role.toLowerCase() === userRole);
  }
  return allowed;
}

function checkallRoles(userRoles: string[], grants: RequiredGrants) {
  let allowed = true;
  if (grants.allowedRoles?.length) {
    // checks if the user has at least a role in allowedRoles
    allowed = allowed && _.intersectionBy(userRoles, grants.allowedRoles, toLowerCase).length > 0;
  }
  if (grants.deniedRoles?.length) {
    // checks if the user has at least a role not in deniedRoles
    allowed = allowed && _.differenceBy(userRoles, grants.deniedRoles, toLowerCase).length > 0;
  }
  if (grants.redFlags?.length) {
    // checks if the user has at least a role in allowedRoles
    allowed = allowed && _.intersectionBy(userRoles, grants.redFlags, toLowerCase).length === 0;
  }
  return allowed;
}

// Static implementation of toLowerCase, used by intersection.
function toLowerCase(str: string) {
  return str.toLowerCase();
}
