import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertTitle from '@material-ui/core/AlertTitle';
import AccountCircle from '@mitch528/mdi-material-ui/AccountCircle';
import AccountSearch from '@mitch528/mdi-material-ui/AccountSearch';
import AccountSwitch from '@mitch528/mdi-material-ui/AccountSwitch';
import DownDetectorIcon from '@mitch528/mdi-material-ui/AlertOctagon';
import Analytics from '@mitch528/mdi-material-ui/ChartTimelineVariantShimmer';
import Logout from '@mitch528/mdi-material-ui/Logout';
import Systems from '@mitch528/mdi-material-ui/ScatterPlot';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/system';
import { CustomerArea } from '../../entities/Customer';
import useAuthentication from '../../hooks/useAuthentication';
import { AuthGroup, authGroups, MSO_GRANTS, RequiredGrants, SEARCH_CUSTOMER_GRANTS, AuthRoles } from '../../utils/auth';
import Settings from './Settings';

export default function NavbarDrawer(): JSX.Element {
  const theme = useTheme();
  const { user, signOut, isMyTeamAndTVStd, hasGrants } = useAuthentication();
  const [openDialog, setOpenDialog] = useState(false);
  const levels = useMemo(() => authGroups, []);
  const [selectedGroup, setSelectedGroup] = useState(undefined as AuthGroup | undefined);

  const { t } = useTranslation();

  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  const area = params.get('area') ?? CustomerArea.BB;

  //update calls with new user group
  const handleConfirmUserGroup = useCallback(() => {
    window.localStorage.setItem('custom_user_level', selectedGroup?.level + '');
    window.localStorage.setItem('custom_user_role', selectedGroup?.role + '');
    setSelectedGroup(undefined);
    setOpenDialog(false);
    window.location.reload();
  }, [selectedGroup]);

  const handleCloseDialog = useCallback(() => {
    setSelectedGroup(undefined);
    setOpenDialog(false);
  }, []);

  // sets the selected group and opens a confirmation dialog.
  const handleGroupChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGroup(authGroups.find((group) => group.role === event.target.value));
    setOpenDialog(true);
  }, []);

  const handleSignOut = () => {
    window.localStorage.removeItem('custom_user_level');
    window.localStorage.removeItem('custom_user_role');
    signOut();
  };

  const { customer } = useParams() as { customer?: string | undefined };

  const filteredLevels = useMemo(
    () => levels.filter((group) => group.visible && group.level <= (user?.maxLevel ?? -1)),
    [user, levels]
  );

  return (
    <>
      <Box
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <AccountCircle sx={{ width: 50, height: 50, boxShadow: 3, borderRadius: 50, color: 'white' }} />
        <span style={{ padding: 8, textAlign: 'center', fontWeight: 'bold', color: 'white', fontSize: 18 }}>
          {user?.username}
        </span>
        <span style={{ padding: 6, textAlign: 'center', fontWeight: 'bold', color: 'white', fontSize: 15 }}>
          {levels.find((level) => level.role === user?.maxRole)?.label ?? ''}
        </span>
        <Button size="small" endIcon={<Logout />} sx={{ color: 'white' }} onClick={handleSignOut}>
          {t('navbar:drawer:signOut')}
        </Button>
      </Box>
      {/* Elementi principali */}
      <DrawerSection
        baseKey="main"
        currentArea={area}
        items={[
          {
            label: t('navbar:searchCustomer'),
            path: 'search-customer',
            icon: <AccountSearch />,
            userGrants: SEARCH_CUSTOMER_GRANTS,
            extraCondition: !(
              // eslint-disable-next-line no-restricted-globals
              (location.pathname !== '/search-customer' && area === CustomerArea.BB && isMyTeamAndTVStd())
            ),
          },
          {
            label: t('navbar:mso'),
            path: 'mso',
            icon: <Systems />,
            areaCondition: [CustomerArea.BB],
            deniedRoles: [AuthRoles.QLEGACY],
            extraCondition: hasGrants(MSO_GRANTS),
          },
          {
            label: t('navbar:downDetector'),
            path: 'down-detector',
            userLevelCondition: 3,
            icon: <DownDetectorIcon />,
          },
          {
            label: t('navbar:l2Dashboard'),
            path: `l2-dashboard/${customer}`,
            icon: <Analytics />,
            userLevelCondition: 100,
            deniedRoles: [AuthRoles.QLEGACY],
          },
        ]}
      />

      {/* Livello Utenza */}
      {user?.maxRole === 'mng' || user?.maxRole === 'bsnaass' ? (
        <div style={{ height: '55px' }}>
          <DrawerSection
            title={t('navbar:drawer:selectLevel')}
            baseKey="change_level"
            divider
            items={[
              {
                label: (
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '18ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="select-level"
                      select
                      variant="standard"
                      onChange={handleGroupChange}
                      helperText={t('navbar:drawer:selectLevelHelper')}
                      label={t('navbar:drawer:level')}
                      defaultValue={user.role}
                    >
                      {filteredLevels
                        .filter((x) => (user?.maxRole === 'bsnaass' ? x.role !== 'skyq' : true))
                        .map((l) => {
                          return (
                            <MenuItem key={l.role} value={l.role}>
                              {l.label}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Box>
                ),
                icon: <AccountSwitch sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
              },
            ]}
          />
        </div>
      ) : null}
      <Dialog open={openDialog} sx={{ paddingBottom: 20 }}>
        <DialogTitle>{t('navbar:drawer:selectLevelDialog:title')}</DialogTitle>
        <DialogContent>
          <Alert severity="info" sx={{ alignItems: 'center' }}>
            <AlertTitle>{t('navbar:drawer:selectLevelDialog:text')}</AlertTitle>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmUserGroup} autoFocus>
            {t('common:ok')}
          </Button>
          <Button onClick={handleCloseDialog} autoFocus>
            {t('common:goBack')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Impostazioni */}
      <Box sx={{ marginTop: 'auto' }}>
        <DrawerSection title={t('navbar:drawer:settings:title')} baseKey="settings" divider>
          <Settings></Settings>
        </DrawerSection>
      </Box>
    </>
  );
}

export type DrawerSectionProps = {
  baseKey: string;
  title?: string;
  currentArea?: string | null;
  items?: Array<{
    label: string | ReactElement;
    path?: string;
    icon: ReactElement;
    userLevelCondition?: number;
    userGrants?: RequiredGrants;
    areaCondition?: Array<CustomerArea>;
    extraCondition?: boolean;
    deniedRoles?: Array<string>;
  }>;
  children?: React.ReactNode;
  divider?: boolean;
};
export function DrawerSection(props: DrawerSectionProps): JSX.Element {
  const theme = useTheme();
  const history = useHistory();
  const { user, hasGrants } = useAuthentication();

  return (
    <Box>
      {props.divider && <Divider sx={{ marginY: 1 }} />}
      {props.title && (
        <Box
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 'bold',
            marginLeft: 2,
            padding: 2,
            pb: 0,
            minHeight: 24,
            fontSize: 16,
          }}
        >
          {props.title}
        </Box>
      )}
      {props.items ? (
        <List>
          {props.items.map((item, index) => {
            if (
              !user?.level ||
              user.level < (item.userLevelCondition || 0) ||
              (item.userGrants && !hasGrants(item.userGrants)) ||
              (item.areaCondition?.length && !item.areaCondition.includes(props.currentArea as CustomerArea)) ||
              item.extraCondition === false ||
              (item.deniedRoles?.length &&
                item.deniedRoles.includes(window.localStorage.getItem('custom_user_role')?.toUpperCase() ?? ''))
            ) {
              return null;
            }
            const listItem = (
              <ListItem sx={{ paddingInline: 32 }} button>
                <ListItemIcon
                  sx={{
                    color: history.location.pathname.includes(`/${item.path}`) ? theme.palette.primary.main : '',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <Box
                  sx={{
                    fontWeight: history.location.pathname.includes(`/${item.path}`) ? 'bold' : 'normal',
                    fontSize: 16,
                    color: history.location.pathname.includes(`/${item.path}`)
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                    paddingRight: 4,
                  }}
                >
                  {item.label}
                </Box>
              </ListItem>
            );
            return item.path ? (
              <Link
                key={`${props.baseKey}_${index}`}
                href={`/${item.path}`}
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  margin: 'auto',
                  textDecoration: 'none',
                }}
              >
                {listItem}
              </Link>
            ) : (
              <Box key={`${props.baseKey}_${index}`}>{listItem}</Box>
            );
          })}
        </List>
      ) : props.children ? (
        props.children
      ) : null}
    </Box>
  );
}
