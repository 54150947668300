import { useMemo } from 'react';
import { RequestError } from '../../entities/RequestError';
import { useCase } from '../../hooks/useCase';
import { useCustomer } from '../../hooks/useCustomer';
import { useDownDetector } from '../../hooks/useDownDetector';
import { useHubActions } from '../../hooks/useHubActions';
import { useL2Dashboard } from '../../hooks/useL2Dashboard';
import { useWizard } from '../../hooks/useWizard';
import { RequestParseError } from '../../utils/RequestParseError';

export function useGlobalErrorCatcher(): (RequestError | RequestParseError)[] {
  const { readCustomerListRequest } = useCustomer();
  const { readLayoutRequest, readTabsRequest, readElementsRequest } = useL2Dashboard();
  const { runHubActionRequests, requestError } = useHubActions();
  const { readCaseSynthInfoRequest, readCasesRequest } = useCase();
  const { readWizardStepRequest } = useWizard();
  const { readServiceRequest } = useDownDetector();

  return useMemo(() => {
    const errors = [];

    const actionRequests = Object.values(runHubActionRequests);

    for (let i = 0; i < actionRequests.length; i++) {
      const actionRequest = actionRequests[i];

      if (
        actionRequest &&
        actionRequest.error &&
        actionRequest.error.status !== 400 &&
        !/active action found/i.test(actionRequest.error.message)
      ) {
        errors.push(actionRequest.error);
      }
    }

    if (requestError) {
      errors.push(requestError);
    }

    if (readLayoutRequest.parseError) {
      errors.push(readLayoutRequest.parseError);
    }
    if (readTabsRequest.parseError) {
      errors.push(readTabsRequest.parseError);
    }
    if (readElementsRequest.parseError) {
      errors.push(readElementsRequest.parseError);
    }
    // Wizard Step payload parse errors
    if (readWizardStepRequest.parseError) {
      errors.push(readWizardStepRequest.parseError);
    }

    // Cases payload parse errors
    if (readCaseSynthInfoRequest.parseError) {
      errors.push(readCaseSynthInfoRequest.parseError);
    }
    if (readCasesRequest.parseError) {
      errors.push(readCasesRequest.parseError);
    }

    if (readServiceRequest.parseError) {
      errors.push(readServiceRequest.parseError);
    }

    if (readCustomerListRequest.error && readCustomerListRequest.error.status !== 404) {
      errors.push(readCustomerListRequest.error);
    }

    return errors;
  }, [
    runHubActionRequests,
    requestError,
    readLayoutRequest.parseError,
    readTabsRequest.parseError,
    readElementsRequest.parseError,
    readWizardStepRequest.parseError,
    readCaseSynthInfoRequest.parseError,
    readCasesRequest.parseError,
    readServiceRequest.parseError,
    readCustomerListRequest.error,
  ]);
}
