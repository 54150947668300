import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Button } from '@mui/material/';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Chart, ChartHistoryItem } from '../../../../entities/Chart';
import { Customer } from '../../../../entities/Customer';
import { useCharts } from '../../../../hooks/useCharts';
import { useApplicationError } from '../../../ApplicationError';
import ChartConstructor from '../../../charts/ChartConstructor';
import LoaderView from '../../../LoaderView';

export interface HubDetailsDialogChartsProps {
  customerId?: Customer['id'];
  type: string;
  trendline?: string | undefined;
  size?: {
    width: string | number;
    height: string | number;
  };
  visible: boolean;
  inline?: boolean;
}

export default function HubDetailsDialogCharts(props: HubDetailsDialogChartsProps): JSX.Element {
  const { readChart, readHistory, readChartRequest, invalidate } = useCharts();
  const [zoomed, setZoomed] = useState<boolean>(false);
  const [history, setHistory] = useState<ChartHistoryItem[]>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [chartData, setChartData] = useState<Chart | undefined>();
  const theme = useTheme();

  const applicationErrors = useMemo(() => [readChartRequest.error, readChartRequest.parseError], [
    readChartRequest.error,
    readChartRequest.parseError,
  ]);
  useApplicationError('HubDetailsDialog', applicationErrors);

  // This Memo is going to change when the invalidate timestamp changes, but only for inline(wizard) charts
  const inlineInvalidate = useMemo(() => {
    if (props.inline) {
      return invalidate;
    }
    return -1;
  }, [props.inline, invalidate]);

  const loadNewChart = useCallback(
    async (customer_id: string, trendline: string, request_id: string) => {
      const data = await readChart(customer_id, trendline, request_id);
      if (data) setChartData(data);
    },
    [readChart]
  );

  const manageZoom = useCallback((value: boolean) => {
    setZoomed(value);
  }, []);

  useEffect(() => {
    if (props.visible) {
      const fetchData = async () => {
        if (props.customerId) {
          if (
            [
              'line_chart',
              'line_filled_chart',
              'pie_chart',
              'gauge_chart',
              'histogram_chart',
              'time_histogram_chart',
              'interference_chart',
              'table_chart',
            ].includes(props.type) &&
            props.trendline
          ) {
            const data = await readChart(props.customerId, props.trendline);
            if (data) setChartData(data);
            // ----- CHART HISTORY IMPLEMETATION -----
            // const history: ChartHistoryItem[] | void = await readHistory(props.customerId, props.trendline);
            // if (history && history[0]) {
            //   setHistory(history);
            //   setSelectedDate(history[0].request_id);
            //   const data = await readChart(props.customerId, props.trendline, history[0].request_id);
            //   if (data) setChartData(data);
            // } else {
            //   const data = await readChart(props.customerId, props.trendline);
            //   if (data) setChartData(data);
            // }
          }
        }
      };
      fetchData();
    }
    return () => {
      setChartData(undefined);
      setHistory(undefined);
      setSelectedDate(undefined);
    };
  }, [
    props.customerId,
    props.type,
    props.trendline,
    readChart,
    readHistory,
    props.visible,
    inlineInvalidate,
    setZoomed,
    loadNewChart,
  ]);

  return (
    <Box>
      {props.inline && (
        <Typography key={props.trendline} variant="body1" component="h6" fontWeight="bold" gutterBottom>
          {props.trendline}
        </Typography>
      )}
      <Box sx={{ height: Number(props.size?.height) - (props.inline ? 0 : 49) ?? 250 }}>
        {!props.inline && (
          <Box
            sx={{
              padding: 2,
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              background: (theme) => theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.text.primary}20`,
            }}
          >
            <Button variant="contained" disabled={!zoomed} onClick={() => setZoomed(false)}>
              Reset
            </Button>
            {history && (
              <FormControl sx={{ width: 210 }}>
                <InputLabel id="demo-simple-select-label">Data di esecuzione test</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDate ? selectedDate : history[0]?.request_id}
                  label="____________________"
                  onChange={(event) => {
                    setSelectedDate(event.target.value);
                    if (props.customerId && props.trendline)
                      loadNewChart(props.customerId, props.trendline, event.target.value);
                  }}
                  sx={{ maxHeight: 33 }}
                  MenuProps={{
                    sx: { maxHeight: 200, transform: 'translate(0, -11px)' },
                    PaperProps: {
                      style: {
                        width: 210,
                      },
                    },
                  }}
                >
                  {history.map((item: ChartHistoryItem) => (
                    <MenuItem key={item.request_id} value={item.request_id}>
                      {item.date}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )}
        <LoaderView
          minHeight={Number(props.size?.height) - (props.inline ? 0 : 49) ?? 250}
          condition={!readChartRequest.inProgress}
        >
          <ChartConstructor
            type={props.type}
            data={chartData}
            visible={props.visible}
            zoomed={zoomed}
            onZoomed={manageZoom}
          />
        </LoaderView>
      </Box>
    </Box>
  );
}
