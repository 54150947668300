import { Case, CaseSynthInfo } from '../entities/Case';
import { Customer } from '../entities/Customer';
import { InterceptorRepository } from './InterceptorRepository';

class CaseRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/customers/',
    });
  }

  async getCases(customerId: Customer['id']): Promise<Case[] | void> {
    const { data } = await this.axios.get<Case[] | void>(`/${customerId}/case/history`);
    return data;
  }

  async getCaseSynthInfo(
    customerId: Customer['id'],
    username: string,
    userLevel: number
  ): Promise<CaseSynthInfo | void> {
    const params = {
      operator_name: username,
      operator_level: userLevel,
    };
    const { data } = await this.axios.get<CaseSynthInfo | void>(`/${customerId}/case/status`, { params });
    return data;
  }

  async reserveCase(
    customerId: Customer['id'],
    username: string,
    userLevel: number,
    reserveButtonValue: number
  ): Promise<CaseSynthInfo | void> {
    const params = {
      operator_name: username,
      operator_level: userLevel,
    };
    const { data } = await this.axios.post<CaseSynthInfo | void>(
      `/${customerId}/case/status`,
      { value: reserveButtonValue },
      { params }
    );
    return data;
  }
}

export default new CaseRepository();
