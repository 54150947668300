import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/system';
import Box from '@mui/system/Box';

export type L2LegendSectionProps = {
  element: {
    title?: string | null;
    orientation: 'horizontal' | 'vertical';
    chart_ids_control?: string[] | null;
  };
  legendItems: {
    dataClass: string;
    type: 'trendline' | 'area';
    display: boolean;
    color: string;
  }[];
  onLegendItemClick?: (activate: boolean, dataClass: string) => void;
};
export default function L2LegendSection(props: L2LegendSectionProps): JSX.Element {
  const theme = useTheme();

  const manageLegendItemClick = (activate: boolean, dataClass: string) => {
    if (props.onLegendItemClick) props.onLegendItemClick(activate, dataClass);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: props.element.orientation === 'vertical' ? 'flex-start' : 'space-evenly',
        height: '100%',
        overflowY: 'auto',
        alignItems: props.element.orientation === 'vertical' ? 'flex-start' : 'center',
      }}
    >
      {props.element.title && (
        <Box
          sx={{
            alignSelf: 'center',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            fontSize: 16,
            paddingBlock: 4,
            whiteSpace: 'nowrap',
          }}
        >
          {props.element.title}
        </Box>
      )}
      <Box
        sx={{
          pl: 3,
          pr: props.element.orientation === 'vertical' ? 3 : 0,
          display: 'flex',
          flexDirection: props.element.orientation === 'vertical' ? 'column' : 'row',
          flexWrap: props.element.orientation === 'vertical' ? 'nowrap' : 'wrap',
          alignItems: 'flex-start',
        }}
      >
        {props.legendItems.map((item) => (
          <LegendItem
            key={item.dataClass}
            data_class={item.dataClass}
            type={item.type}
            color={item.color}
            active={item.display}
            onItemClick={(activate: boolean) => manageLegendItemClick(activate, item.dataClass)}
          />
        ))}
      </Box>
    </Box>
  );
}

type LegendItemProps = {
  data_class: string;
  type: 'trendline' | 'area';
  color: string;
  active?: boolean;
  onItemClick?: (activate: boolean) => void;
};
function LegendItem(props: LegendItemProps) {
  const [active, setActive] = useState<boolean>(props.active ?? true);

  React.useEffect(() => {
    if (props.active !== undefined) setActive(props.active);
  }, [props.active]);

  const manageClicked = () => {
    if (props.onItemClick) props.onItemClick(!active);
    setActive(!active);
  };
  return (
    <Button onClick={manageClicked} size="small">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          p: 0.5,
          opacity: active ? 1 : 0.3,
          transition: '0.15s',
        }}
      >
        <Box
          sx={{
            height: 18,
            width: 18,
            backgroundColor: props.color,
            marginRight: 2,
            borderRadius: props.type === 'trendline' ? 30 : 0.5,
            minWidth: 18,
          }}
        />
        <Box
          sx={{
            color: (theme) => theme.palette.text.primary,
            whiteSpace: 'nowrap',
          }}
        >
          {props.data_class}
        </Box>
      </Box>
    </Button>
  );
}
