import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration, ChartDataset } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import { format } from 'date-fns';
import { useTheme } from '@mui/system';

Chart.register(zoomPlugin);

export type L2LineChartSerie = {
  data_class: string;
  values: Array<{ x: number; y: number }>;
  color: string;
  hidden?: boolean;
};

export type L2LineChartArea = {
  data_class: string;
  from?: number;
  to?: number;
  color: string;
  hidden?: boolean;
};

interface L2LineChartProps {
  series: L2LineChartSerie[];
  areas?: L2LineChartArea[];
}

export default function L2LineChart(props: L2LineChartProps): JSX.Element {
  const { series, areas } = props;
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });

  const theme = useTheme();

  useEffect(() => {
    let _animate = true;
    if (chartRef.current.chart) {
      _animate = false;
      chartRef.current.chart.destroy();
    }

    if (ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        // Series
        const datasets: ChartDataset[] = [];
        for (let i = 0; i < series.length; i++) {
          const serie = series[i];
          if (serie) {
            // Filtering values if an area is hidden
            let filteredValues = serie.values;
            if (filteredValues && areas) {
              for (let i = 0; i < areas.length; i++) {
                const area = areas[i];
                if (area?.hidden) {
                  filteredValues = filteredValues.filter((value) => {
                    let keepValue = true;
                    if (area.from && value.x >= area.from) keepValue = false;
                    if (area.to && value.x <= area.to) keepValue = false;
                    if (area.from && area.to && (value.x > area.to || value.x < area.from)) keepValue = true;
                    return keepValue;
                  });
                }
              }
            }
            datasets[i] = {
              data: [...filteredValues].sort((a, b) => a.x - b.x),
              hidden: serie.hidden,
              borderColor: serie.color,
              backgroundColor: serie.color,
              borderWidth: 1,
              pointBorderColor: serie.color,
              pointBackgroundColor: serie.color,
              pointBorderWidth: 0,
              pointRadius: 3,
              pointHoverBackgroundColor: 'white',
              pointHoverBorderColor: serie.color,
              pointHoverBorderWidth: 2,
              pointHoverRadius: 4,
              type: 'line' as const,
            };
          }
        }

        // Areas
        const annotations: Record<string, AnnotationOptions> = {};
        if (areas) {
          for (let i = 0; i < areas.length; i++) {
            const area = areas[i];
            if (area && !area.hidden) {
              annotations[area.data_class] = {
                type: 'box',
                backgroundColor: area.color + '3f',
                borderWidth: 0.2,
                xMin: area.from,
                xMax: area.to,
                drawTime: 'beforeDatasetsDraw',
              };
            }
          }
        }

        const chartOptions = {
          type: 'line',
          data: {
            datasets,
          },
          options: {
            animation: _animate,
            responsive: true,
            maintainAspectRatio: false,
            pointHitRadius: 20,
            layout: {
              padding: {
                left: 14,
                right: 40,
                top: 20,
                bottom: 8,
              },
            },
            plugins: {
              tooltip: {
                enabled: true,
                mode: 'index',
                footerMarginTop: 10,
                footerFont: { weight: 'bold' },
                callbacks: {
                  title: ([context]) => {
                    return `${format(Number(context?.parsed.x), 'dd/MM/yyyy HH:mm:ss')}`;
                  },
                },
              },
              legend: {
                display: false,
              },
              annotation: {
                annotations: annotations,
              },
            },
            scales: {
              x: {
                type: 'linear' as const,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                  callback: function (value) {
                    return `${format(Number(value), 'dd/MM/yy')}`;
                  },
                },
              },
              y: {
                type: 'linear' as const,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                },
              },
            },
          },
        } as ChartConfiguration<'line'>;
        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart(ctx, chartOptions);
      }
    }
  }, [areas, series, theme.palette.text.primary]);

  return <canvas ref={ref} />;
}
