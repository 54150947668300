import { AxiosError, Method } from 'axios';

export function isAxiosError(error?: string | AxiosError): error is AxiosError {
  return Boolean(error && (error as AxiosError).isAxiosError);
}

export class RequestError extends Error {
  status: number | null = null;
  code: string | null = null;
  method: Method | null = null;
  url: string | null = null;
  axiosError: AxiosError | null = null;

  constructor(messageOrAxiosError?: string | AxiosError) {
    super(isAxiosError(messageOrAxiosError) ? messageOrAxiosError.message : messageOrAxiosError);

    Object.setPrototypeOf(this, RequestError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    if (isAxiosError(messageOrAxiosError)) {
      this.status = messageOrAxiosError.response?.data?.status || messageOrAxiosError.response?.status || null;
      this.code = messageOrAxiosError.response?.data?.code || `${messageOrAxiosError.response?.status}`;
      this.method = messageOrAxiosError.config?.method || null;
      this.url = messageOrAxiosError.config
        ? `${messageOrAxiosError.config.baseURL}${messageOrAxiosError.config.url}`
        : null;
      this.message = messageOrAxiosError.response?.data?.message || this.message;
      this.axiosError = messageOrAxiosError;
    }
  }
}
