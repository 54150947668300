import { Customer, CustomerAttribute, CustomerMapT } from '../entities/Customer';
import { IColumn } from '../entities/Table';
import { InterceptorRepository } from './InterceptorRepository';

class CustomerRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/',
    });
  }

  async getAttributes(territory: string) {
    const { data } = await this.axios.get<Array<CustomerAttribute>>(
      `/frontend/advanced_search/attributes?territory=${territory}`
    );
    return data;
  }

  async getList(
    query: Record<string, string>,
    page: number,
    pageSize: number,
    payloadSize: number,
    misalignment: number,
    territory: string
  ) {
    const { data } = await this.axios.post<{ rows: Customer[]; total_results: number; columns: IColumn[] }>(
      `/customers?from=${page * pageSize + misalignment}&size=${payloadSize}&territory=${territory}`,
      query
    );
    return data;
  }

  async getAsList(customerId: Customer['id']) {
    const { data } = await this.axios.get<{ customers: Customer; total_results: number; columns: IColumn[] }>(
      `/customers/${customerId}`
    );
    return { rows: [data.customers], columns: data.columns, id: customerId };
  }

  async getInfo(customerId: Customer['id']) {
    const { data } = await this.axios.get<Customer>(`/customers/${customerId}`);
    return data;
  }

  async getLastTestInfo(customerId: Customer['id']) {
    const { data } = await this.axios.get<unknown>(`/customers/${customerId}/latest_test_info`);
    return data;
  }

  async getActions(customerId: Customer['id'], area: string, product: string) {
    const { data } = await this.axios.get<Customer>(`/customers/${customerId}/actions?area=${area}&product=${product}`);
    return data;
  }

  async getHidden(customerId: Customer['id'], area: string | null, product: string | null) {
    let url = `/customers/${customerId}/hidden`;
    const params = [];
    if (area) {
      params.push(`AREA=${area}`);
    }
    if (product) {
      params.push(`PRODUCT=${product}`);
    }
    if (params.length) {
      url += '?' + params.join('&');
    }
    const { data } = await this.axios.post<Customer>(url, {});
    return data;
  }

  //mapT
  async getMapT(customerId: Customer['id']) {
    const { data } = await this.axios.get<CustomerMapT>(`/customers/${customerId}/map-t-events`);
    return data;
  }
}

export default new CustomerRepository();
