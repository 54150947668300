import React from 'react';
import { useTranslation } from 'react-i18next';
import sub from 'date-fns/sub';
import Alert from '@mui/material/Alert';
import { Wizard, WizardTypes } from '../../../../../entities/Wizard';
import { useWizard } from '../../../../../hooks/useWizard';

export default function WizardOutdatedAlert(props: { wizard: Wizard | undefined }): JSX.Element | null {
  const { wizard } = props;
  const { outdatedAlertSuppressed, suppressOutdatedAlert } = useWizard();
  const { t } = useTranslation();

  let wizardId: string | undefined;
  let timestamp = new Date().valueOf();

  if (wizard && wizard.type === WizardTypes.WIZARD_STEP) {
    wizardId = wizard.data.wizard_id;
    timestamp = wizard.data.timestamp;
  }

  if (!wizardId || wizardId === outdatedAlertSuppressed || timestamp >= sub(new Date(), { days: 3 }).valueOf()) {
    return null;
  }

  return (
    <Alert severity="warning" sx={{ mb: 2, fontSize: 14 }} onClose={() => suppressOutdatedAlert(wizardId as string)}>
      {t('dashboard:recommendedActions:oldWizard')}
    </Alert>
  );
}
