import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import SystemRepository from '../api/MSORepository';
import { RequestError } from '../entities/RequestError';
import { System, SystemAttribute, SystemAttributeAPIResponseSchema } from '../entities/System';
import { IColumn } from '../entities/Table';
import { RootState } from '../store/reducers';

import { systemActions } from '../store/reducers/system';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type SystemState = RootState['system'];

type UseSystem = {
  systemsList: SystemState['list'];
  systemAttributes: SystemState['attributes'];
  readSystemsList: (
    query: Record<string, string | number>,
    page: number,
    pageSize: number,
    payloadSize: number,
    misaglignment: number,
    clearListAfterFetch: boolean
  ) => Promise<System[] | void>;
  readSystemAttributes: () => Promise<SystemAttribute[] | undefined>;
  readSystemsListRequest: SystemState['requests']['readList'];
  readSystemAttributesRequest: SystemState['requests']['readAttributes'];
  reset: () => void;
};

export function useSystem(): UseSystem {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      SystemRepository.updateAuthToken(accessToken);
      SystemRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const systemsList = useSelector((state: RootState) => state.system.list);
  const systemAttributes = useSelector((state: RootState) => state.system.attributes);
  const readSystemsListRequest = useSelector((state: RootState) => state.system.requests.readList);
  const readSystemAttributesRequest = useSelector((state: RootState) => state.system.requests.readAttributes);

  const readSystemAttributes = useCallback(async () => {
    dispatch(systemActions.readSystemAttributesRequest());
    let response: unknown[];

    try {
      response = await SystemRepository.getAttributes();
    } catch (error) {
      dispatch(systemActions.readSystemAttributesFailure({ error: new RequestError(error as AxiosError) }));
      return;
    }

    const parseResult = SystemAttributeAPIResponseSchema.safeParse(response);
    if (parseResult.success === false) {
      dispatch(
        systemActions.readSystemAttributesParseFailure({
          parseError: new RequestParseError(parseResult.error, 'UseSystem.readSystemAttributes'),
        })
      );
    } else {
      dispatch(systemActions.readSystemAttributesSuccess({ response: parseResult.data.attributes }));
      return parseResult.data.attributes;
    }
  }, [dispatch]);

  const readSystemsList: UseSystem['readSystemsList'] = useCallback(
    async (query, page, pageSize, payloadSize, misalignment, clearListAfterFetch) => {
      dispatch(systemActions.readSystemListRequest());
      let response: {
        systems: System[];
        total_results: number;
        columns: IColumn[];
      };

      try {
        // API
        response = await SystemRepository.getList(query, page, pageSize, payloadSize, misalignment);
      } catch (error) {
        dispatch(systemActions.readSystemListFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      if (clearListAfterFetch) dispatch(systemActions.clearList());
      dispatch(
        systemActions.readSystemListSuccess({
          response: response.systems,
          total: response.total_results,
          columns: response.columns,
        })
      );
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch(systemActions.reset());
  }, [dispatch]);

  return {
    systemsList,
    systemAttributes,
    readSystemsList,
    readSystemAttributes,
    readSystemsListRequest,
    readSystemAttributesRequest,
    reset,
  };
}
