import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { i18n, availableLanguages } from '../../i18n';
import { RootState } from '../../store/reducers';
import { darkModeActions } from '../../store/reducers/darkMode';
export default function Settings(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.darkMode.activated);
  const handleLanguageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('locale', event.target.value);
    window.location.reload();
  }, []);

  return (
    <>
      {/*Lingua*/}
      <ListItem button sx={{ paddingInline: 16, marginBlock: 8 }}>
        <ListItemIcon sx={{ padding: '7px' }}>
          <img src={availableLanguages[i18n.language]?.flag} alt={i18n.language} width="28px" />
        </ListItemIcon>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '18ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            disabled
            id="select-language"
            select
            variant="standard"
            onChange={handleLanguageChange}
            helperText={t('navbar:drawer:settings:selectLanguage')}
            label={t('navbar:drawer:settings:language')}
            defaultValue={i18n.language}
          >
            {Object.entries(availableLanguages).map(([key, { name }]) => {
              return (
                <MenuItem key={key} value={key}>
                  {name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      </ListItem>

      <ListItem
        button
        disabled //TODO: to remove in future releases
        onClick={() => dispatch(darkModeActions.setDarkMode(!darkMode))}
        sx={{ paddingInline: 16, marginBlock: 8 }}
      >
        <ListItemIcon>
          <Switch checked={darkMode} size="small" sx={{ margin: 0 }} />
        </ListItemIcon>
        <Box sx={{ paddingRight: 4, fontSize: 15 }}>{t('navbar:drawer:settings:darkMode')}</Box>
      </ListItem>
    </>
  );
}
