import React, { useEffect, useRef } from 'react';
import { Chart, ChartDataset, ChartConfiguration } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { useTheme } from '@mui/system';

export type L2BarChartSerie = {
  data_class: string;
  values: number[];
  color: string;
  hidden?: boolean;
};

export type L2BarChartArea = {
  data_class: string;
  label_from?: string;
  label_to?: string;
  color: string;
  hidden?: boolean;
};

interface L2BarChartProps {
  series: L2BarChartSerie[];
  areas?: L2BarChartArea[];
  labels: string[];
  stacked?: boolean;
  orientation?: 'horizontal' | 'vertical';
}

export default function L2BarChart(props: L2BarChartProps): JSX.Element {
  const { series, labels, stacked, orientation, areas } = props;
  const filteredLabels = React.useRef(labels);
  const filteredSeries = React.useRef(series);

  const theme = useTheme();
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });

  useEffect(() => {
    if (areas) {
      filteredLabels.current = labels.filter((label, index) => {
        let showLabel = true;
        areas.forEach((area) => {
          if (area.hidden) {
            if (area.label_from && !area.label_to && index >= labels.indexOf(area.label_from)) showLabel = false;
            if (area.label_to && !area.label_from && index <= labels.indexOf(area.label_to)) showLabel = false;
            if (
              area.label_from &&
              area.label_to &&
              index <= labels.indexOf(area.label_to) &&
              index >= labels.indexOf(area.label_from)
            )
              showLabel = false;
          }
        });
        return showLabel;
      });
    }
  }, [areas, labels]);

  useEffect(() => {
    filteredSeries.current = series;
    filteredSeries.current.forEach((serie) => {
      if (areas) {
        serie.values = serie.values.filter((value, index) => {
          let keepValue = true;
          areas.forEach((area) => {
            if (area.hidden) {
              if (area.label_from && !area.label_to && index >= labels.indexOf(area.label_from)) keepValue = false;
              if (area.label_to && !area.label_from && index <= labels.indexOf(area.label_to)) keepValue = false;
              if (
                area.label_from &&
                area.label_to &&
                index <= labels.indexOf(area.label_to) &&
                index >= labels.indexOf(area.label_from)
              )
                keepValue = false;
            }
          });
          return keepValue;
        });
      }
    });
  }, [areas, labels, series]);

  useEffect(() => {
    let _animate = true;
    if (chartRef.current.chart) {
      _animate = false;
      chartRef.current.chart.destroy();
    }

    if (ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        const data = {
          labels: filteredLabels.current,
          datasets: [] as ChartDataset[],
        };
        for (let i = 0; i < filteredSeries.current.length; i++) {
          const filteredSerie = filteredSeries.current[i];
          if (filteredSerie) {
            data.datasets[i] = {
              hidden: filteredSerie.hidden,
              data: filteredSerie?.values,
              label: filteredSerie.data_class,
              minBarLength: 5,
              backgroundColor: filteredSerie.color,
              type: 'bar' as const,
            };
          }
        }

        // Areas
        const annotations: Record<string, AnnotationOptions> = {};
        if (areas) {
          for (let i = 0; i < areas.length; i++) {
            const area = areas[i];
            if (area && !area.hidden) {
              annotations[area.data_class] = {
                type: 'box',
                backgroundColor: area.color + '3f',
                borderWidth: 0.2,
                drawTime: 'beforeDatasetsDraw',
                xMin:
                  orientation !== 'horizontal'
                    ? area.label_from
                      ? filteredLabels.current.indexOf(area.label_from) - 0.5
                      : undefined
                    : undefined,
                xMax:
                  orientation !== 'horizontal'
                    ? area.label_to
                      ? filteredLabels.current.indexOf(area.label_to) + 0.5
                      : undefined
                    : undefined,
                yMin:
                  orientation === 'horizontal'
                    ? area.label_from
                      ? filteredLabels.current.indexOf(area.label_from) - 0.5
                      : undefined
                    : undefined,
                yMax:
                  orientation === 'horizontal'
                    ? area.label_to
                      ? filteredLabels.current.indexOf(area.label_to) + 0.5
                      : undefined
                    : undefined,
              };
            }
          }
        }

        // Chart config
        const chartOptions = {
          type: 'bar',
          data: data,
          options: {
            animation: _animate,
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: orientation === 'horizontal' ? 'y' : 'x',
            layout: {
              padding: {
                left: 14,
                right: 40,
                top: 20,
                bottom: 8,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              annotation: {
                annotations: annotations,
              },
            },
            scales: {
              x: {
                stacked: stacked,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                },
              },
              y: {
                stacked: stacked,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                },
              },
            },
          },
        } as ChartConfiguration<'bar'>;

        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart<'bar'>(ctx, chartOptions);
      }
    }
  }, [labels, theme.palette.text.primary, stacked, series, orientation, areas]);

  return <canvas ref={ref} />;
}
