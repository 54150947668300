import { setInterval } from 'timers';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import useAuthentication from '../../../hooks/useAuthentication';
import { authenticationActions } from '../../../store/reducers/authentication';
import { WizardMarkdown } from '../Dashboard/Wizard/components/WizardFeedbackContent';

export default function LadingPage(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { retrieveTokenFromCode, clearTokens } = useAuthentication();
  const [errorMsg, setErrorMsg] = useState<string>();

  React.useEffect(() => {
    const redirect = async (
      login_type = 'standalone' as string,
      code: string,
      base64state?: string,
      client_id?: string,
      redirect_uri?: string,
      customer_name?: string
    ) => {
      const imInLocalhost = window.location.hostname === 'localhost';
      try {
        await retrieveTokenFromCode(
          code,
          client_id ??
            `${
              login_type === 'standalone'
                ? `${process.env.REACT_APP_COGNITO_STANDALONE_CLIENT_ID}`
                : `${process.env.REACT_APP_COGNITO_FEDERATED_CLIENT_ID}`
            }`,
          redirect_uri ??
            `${imInLocalhost ? 'http://localhost:3000/landing-page' : process.env.REACT_APP_USER_POOL_DIRECT_DOMAIN}`
        );
        history.push(customer_name ? `dashboard/${customer_name}` : `/search-customer`);
      } catch (error) {
        let timer = 20;
        setInterval(() => {
          timer--;
          setErrorMsg(`${`${error}`.replace('Error: ', '')}.\n\r  Ridirezionamento in <b>${timer}</b> secondi`);
          if (timer <= 0) {
            const imInLocalhost = window.location.hostname === 'localhost';
            window.location.replace(
              `${process.env.REACT_APP_COGNITO_OAUTH_DOMAIN}/logout?client_id=${
                client_id ??
                `${
                  login_type === 'standalone'
                    ? `${process.env.REACT_APP_COGNITO_STANDALONE_CLIENT_ID}`
                    : `${process.env.REACT_APP_COGNITO_FEDERATED_CLIENT_ID}`
                }`
              }${
                base64state ? `&state=${base64state}` : ''
              }&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${
                redirect_uri ??
                `${
                  imInLocalhost ? 'http://localhost:3000/landing-page' : process.env.REACT_APP_USER_POOL_DIRECT_DOMAIN
                }`
              }&logout_uri=${process.env.REACT_APP_COGNITO_OAUTH_DOMAIN}/login?client_id=${
                client_id ??
                `${
                  login_type === 'standalone'
                    ? `${process.env.REACT_APP_COGNITO_STANDALONE_CLIENT_ID}`
                    : `${process.env.REACT_APP_COGNITO_FEDERATED_CLIENT_ID}`
                }`
              }`
            );
          }
        }, 1000);
      }
    };

    if (!location) {
      return;
    }
    clearTokens();

    const params = new URLSearchParams(location.search);
    const base64state = params.get('state');
    const code = params.get('code');
    if (!code) {
      setErrorMsg('Il parametro --code-- non è stato trovato.');
      return;
    }
    let stringfiedState;
    let state;
    let customerName;
    let client_id;
    let redirect_uri;
    let login_type;
    if (base64state) {
      try {
        stringfiedState = atob(base64state);
        state = JSON.parse(stringfiedState);
      } catch (error) {
        setErrorMsg('Il parametro --state-- è invalido, la decodifica in base64 è fallita.');
        return;
      }
      customerName = state.customer_name;
      login_type = state.login_type;
      if (login_type) {
        dispatch(authenticationActions.setLoginType(login_type as 'standalone' | 'federated'));
      }
      client_id = state.client_id;
      redirect_uri = state.redirect_uri;
    }
    redirect(login_type, code, base64state ?? undefined, client_id, redirect_uri, customerName);
  }, [clearTokens, dispatch, history, location, retrieveTokenFromCode]);

  return (
    <Box
      sx={{
        background: (theme) =>
          `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.tertiary.main} 50%, ${theme.palette.secondary.main} 100%)`,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pb: '20vh',
      }}
    >
      <Box
        component={Logo}
        sx={{
          color: '#fff',
          marginBottom: 6,
          width: 350,
          maxWidth: '100%',
        }}
      />
      <Box sx={{ width: '25%', color: 'white' }}>
        {errorMsg ? (
          <Alert color="error" severity="error">
            <AlertTitle>Si è verificato un problema</AlertTitle>
            <WizardMarkdown>{errorMsg}</WizardMarkdown>
          </Alert>
        ) : (
          <LinearProgress color="inherit" />
        )}
      </Box>
    </Box>
  );
}
