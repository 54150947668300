import { CustomerArea } from '../entities/Customer';
import { Repository, RepositoryConfiguration } from '../entities/Repository';
import { i18n } from '../i18n';

let _ATTEMPTS = 0;

export class InterceptorRepository extends Repository {
  public onRefreshToken: undefined | (() => Promise<{ accessToken: string; idToken: string } | undefined>);
  constructor(config: RepositoryConfiguration) {
    super(config);

    this.axios.interceptors.response.use(
      (r) => r,
      async (error) => {
        const { status } = error.response;
        if (status === 401 && _ATTEMPTS < 10) {
          // Check if a refresh token function is avaialble
          if (this.onRefreshToken) {
            let tokens;
            try {
              // Get new tokens
              _ATTEMPTS++;
              tokens = await this.onRefreshToken();

              // No new token obtained, give up, something will redirect to the login page
              if (!tokens) {
                return Promise.reject(error);
              }

              // Re-run the request with the new token
              const res = this.axios.request({
                ...error.config,
                headers: {
                  ...error.config.headers,
                  Authorization: `Bearer ${tokens.accessToken}`,
                },
              });

              // Pretend nothing happened
              return Promise.resolve(res);
            } catch (_refreshError) {
              return Promise.reject(error);
            }
          }
        }
        _ATTEMPTS = 0;
        return Promise.reject(error);
      }
    );

    //add custom_user_level params to all calls
    this.axios.interceptors.request.use(function (config) {
      const params = new URLSearchParams(window.location.search);
      const area = params.get('area');
      config.params = {
        ...config.params,
        custom_user_level: window.localStorage.getItem('custom_user_level'),
        locale: i18n.language ?? 'it',
      };
      if (area === CustomerArea.Q_LEGACY) {
        config.params = {
          ...config.params,
          custom_user_level: window.localStorage.getItem('custom_user_level'),
          locale: i18n.language ?? 'it',
        };
      }
      return config;
    });
  }
}
