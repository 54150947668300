import { Repository } from '../entities/Repository';

class CognitoRepository extends Repository {
  constructor() {
    super({
      apiURL: `https://cognito-idp.${process.env.REACT_APP_COGNITO_IDENTITY_POOL_REGION}.amazonaws.com/`,
      resourceURL: '',
    });
  }

  async getToken(
    code: string,
    clientId: string,
    redirect_uri: string
  ): Promise<{
    id_token: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: 'Bearer';
  }> {
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('client_id', clientId);
    params.append('redirect_uri', redirect_uri);
    const { data } = await this.axios.post<{
      id_token: string;
      access_token: string;
      refresh_token: string;
      expires_in: number;
      token_type: 'Bearer';
    }>(`${process.env.REACT_APP_COGNITO_OAUTH_DOMAIN}/oauth2/token`, params, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  }

  async refreshToken(
    refresh_token: string,
    clientId: string
  ): Promise<{
    id_token: string;
    access_token: string;
    expires_in: number;
    token_type: 'Bearer';
  }> {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('client_id', clientId);
    params.append('refresh_token', refresh_token);
    const { data } = await this.axios.post<{
      id_token: string;
      access_token: string;
      expires_in: number;
      token_type: 'Bearer';
    }>(`${process.env.REACT_APP_COGNITO_OAUTH_DOMAIN}/oauth2/token`, params, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  }
}

export default new CognitoRepository();
