import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { WizardSuggestion } from '../../../../../entities/Wizard';
import { WizardMarkdown } from './WizardFeedbackContent';

interface WizardSuggestionsProps {
  suggestions: Array<WizardSuggestion>;
}

export default function WizardSuggestions(props: WizardSuggestionsProps): JSX.Element {
  const { suggestions } = props;

  const suggestionAlerts = [];

  for (let i = 0; i < suggestions.length; i++) {
    const suggestion = suggestions[i];
    if (suggestion?.description) {
      suggestionAlerts.push(
        <Alert
          key={suggestion?.description}
          sx={{ mt: 2 }}
          variant="outlined"
          severity={
            suggestion.icon === 'info'
              ? 'info'
              : suggestion.icon === 'warning'
              ? 'warning'
              : suggestion.icon === 'stop'
              ? 'error'
              : 'info'
          }
        >
          <WizardMarkdown>{suggestion.description}</WizardMarkdown>
        </Alert>
      );
    }
  }

  return (
    <Box
      sx={{
        '& > .MuiAlert-root': {
          '&:first-of-type': {
            mt: 3,
            mx: 0,
            mb: 0,
          },
        },
      }}
    >
      {suggestionAlerts}
    </Box>
  );
}
