import React, { useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { ResizableBox } from 'react-resizable';
import WindowClose from '@mitch528/mdi-material-ui/WindowClose';
import WindowMaximize from '@mitch528/mdi-material-ui/WindowMaximize';
import WindowMinimize from '@mitch528/mdi-material-ui/WindowMinimize';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Customer } from '../../../../entities/Customer';
import { RootState } from '../../../../store/reducers';
import { trendlineActions } from '../../../../store/reducers/charts';
import ButtonTooltip from '../../../ButtonTooltip';
import HubDetailsDialogCase from './HubDetailsDialogCase';
import HubDetailsDialogCharts from './HubDetailsDialogCharts';
import HubDetailsDialogDevicesGraph from './HubDetailsDialogDevicesGraph';
import HubDetailsDialogGlossary from './HubDetailsDialogGlossary';
import HubDetailsDialogMapT from './HubDetailsDialogMapT';
import HubDetailsDialogTimeMachine from './HubDetailsDialogTimeMachine';

export interface HubDetailsButtonProps {
  onClick: (e: React.MouseEvent) => void;
}

type DialogType =
  | 'line_chart'
  | 'line_filled_chart'
  | 'pie_chart'
  | 'histogram_chart'
  | 'time_histogram_chart'
  | 'gauge_chart'
  | 'interference_chart'
  | 'table_chart'
  | 'devicesGraph'
  | 'time-machine'
  | 'retrieve_case'
  | 'glossary'
  | 'mapT';

export interface HubDetailsDialogProps {
  customerId: Customer['id'];
  type: DialogType;
  title: string;
  description?: string;
  buttonComponent?: (props: HubDetailsButtonProps) => JSX.Element;
}

export default function HubDetailsDialog(props: HubDetailsDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 24, y: 24 });
  const [dragging, setDragging] = useState(false);
  const [size, setSize] = useState({ width: window.innerWidth - 48, height: window.innerHeight - 48 });
  const dispatch = useDispatch();
  const reducedToIconCharts = useSelector((state: RootState) => state.charts.reducedToIconCharts);

  useEffect(() => {
    function handleResize() {
      setSize((oldSize) => ({
        width: Math.min(oldSize.width, window.innerWidth - 48),
        height: Math.min(oldSize.height, window.innerHeight - 48),
      }));
      setPosition((oldPosition) => ({
        x: oldPosition.x > window.innerWidth - 480 ? window.innerWidth - 480 : oldPosition.x,
        y: oldPosition.y > window.innerHeight - 320 ? window.innerHeight - 320 : oldPosition.y,
      }));
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClickOpen = async () => {
    if (open && minimized) {
      dispatch(trendlineActions.removeChartReducedToIcon({ chart: props.title }));
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      dispatch(trendlineActions.removeChartReducedToIcon({ chart: props.title }));
    }, 500);
  };

  const minimized = useMemo(() => reducedToIconCharts.indexOf(props.title) >= 0, [props.title, reducedToIconCharts]);

  useEffect(() => {
    if (minimized) {
      setPosition({
        x: 24 + reducedToIconCharts.indexOf(props.title) * (400 + 24),
        y: window.innerHeight - 24 - 44,
      });
    } else {
      setPosition({ x: 24, y: 24 });
    }
  }, [props.title, minimized, reducedToIconCharts]);

  const renderDialogContent = (type: DialogType) => {
    switch (type) {
      case 'retrieve_case':
        return (
          <HubDetailsDialogCase
            customerId={props.customerId}
            size={{ width: size.width, height: size.height - 45 }}
            visible={open}
          />
        );
      case 'time-machine':
        return (
          <HubDetailsDialogTimeMachine
            customerId={props.customerId}
            size={{ width: size.width, height: size.height - 45 }}
            visible={open}
          />
        );
      case 'glossary':
        return <HubDetailsDialogGlossary visible={open} size={{ width: size.width, height: size.height - 45 }} />;
      case 'mapT':
        return (
          <HubDetailsDialogMapT
            visible={open}
            size={{ width: size.width, height: size.height - 45 }}
            customerId={props.customerId}
          />
        );
      case 'devicesGraph':
        return (
          <HubDetailsDialogDevicesGraph
            customerId={props.customerId}
            visible={open}
            size={{ width: size.width, height: size.height - 45 }}
          />
        );
      case 'line_chart':
      case 'line_filled_chart':
      case 'pie_chart':
      case 'histogram_chart':
      case 'time_histogram_chart':
      case 'gauge_chart':
      case 'interference_chart':
      case 'table_chart':
        return (
          <HubDetailsDialogCharts
            customerId={props.customerId}
            type={props.type}
            trendline={props.title}
            visible={open}
            size={{ width: size.width, height: size.height - 44 }}
          />
        );
    }
  };
  return (
    <>
      {props.buttonComponent ? (
        <props.buttonComponent onClick={handleClickOpen} />
      ) : (
        <Button onClick={handleClickOpen} sx={{ maxHeight: 48 }}>
          {props.title}
        </Button>
      )}

      <Draggable
        handle=".handle"
        defaultPosition={position}
        position={position}
        grid={[1, 1]}
        scale={1}
        onStop={(_e, data) => {
          setPosition({ x: data.x, y: data.y });
          setDragging(false);
        }}
        onDrag={() => setDragging(true)}
      >
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: minimized ? 9 : 10,
            pointerEvents: open ? undefined : 'none',
            transitionProperty: dragging ? undefined : 'transform !important',
            transitionDuration: (theme) => (dragging ? undefined : `${theme.transitions.duration.shorter}ms`),
            transitionTimingFunction: (theme) => (dragging ? undefined : theme.transitions.easing.easeInOut),
          }}
        >
          <Grow in={open} timeout={200}>
            <Paper
              variant="elevation"
              elevation={24}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: minimized ? 400 : size.width,
                height: minimized ? 43 : size.height,
                overflow: 'hidden',
                background: (theme) => theme.palette.background.paper,
                transitionProperty: 'width, height, opacity, transform !important',
                transitionDuration: (theme) => `${theme.transitions.duration.shorter}ms`,
                transitionTimingFunction: (theme) => theme.transitions.easing.easeInOut,
                '.react-resizable-handle': {
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: 24,
                  height: 24,
                  overflow: 'hidden',
                  borderRadius: 1,
                  '&::after': {
                    content: '""',
                    display: 'block',
                    position: 'relative',
                    top: 12,
                    left: 12,
                    width: 24,
                    height: 24,
                    backgroundColor: 'grey.700',
                    transform: 'rotate(45deg)',
                    cursor: 'se-resize',
                  },
                },
              }}
            >
              <Box
                className="handle"
                onDoubleClick={() => {
                  setPosition({ x: 24, y: 24 });
                  setSize({ width: window.innerWidth - 48, height: window.innerHeight - 48 });
                }}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'grey.300',
                  py: 2,
                  px: 4,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'drag',
                }}
              >
                <Typography variant="h6" sx={{ flex: '1 1 auto' }}>
                  {props.title}
                </Typography>

                {props.description && (
                  <ButtonTooltip
                    title={props.title}
                    description={props.description}
                    maxWidth={size.width}
                    maxHeight={size.height}
                  />
                )}

                {!minimized && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (props.title) {
                        dispatch(trendlineActions.addChartReducedToIcon({ chart: props.title }));
                      }
                    }}
                    sx={{
                      color: 'grey.700',
                      mr: 2,
                    }}
                  >
                    <WindowMinimize />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  onClick={() => {
                    if (minimized) {
                      if (props.title) {
                        dispatch(trendlineActions.removeChartReducedToIcon({ chart: props.title }));
                      }
                    } else {
                      setPosition({ x: 24, y: 24 });
                      setSize({ width: window.innerWidth - 48, height: window.innerHeight - 48 });
                    }
                  }}
                  sx={{
                    color: 'grey.700',
                    mr: 2,
                  }}
                >
                  <WindowMaximize />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={handleClose}
                  sx={{
                    color: 'grey.700',
                  }}
                >
                  <WindowClose />
                </IconButton>
              </Box>
              <ResizableBox
                height={minimized ? 0 : size.height - 44}
                width={minimized ? 0 : size.width}
                onResize={(event, { size }) => {
                  setSize({ width: size.width, height: size.height + 44 });
                }}
                onResizeStart={() => setDragging(true)}
                onResizeStop={() => setDragging(false)}
              >
                {renderDialogContent(props.type)}
              </ResizableBox>
            </Paper>
          </Grow>
        </Box>
      </Draggable>
    </>
  );
}
