import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch, Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material/';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CustomerArea } from '../../../entities/Customer';
import { colorStringToStatus, Status } from '../../../entities/Status';
import useAuthentication from '../../../hooks/useAuthentication';
import { useCustomer } from '../../../hooks/useCustomer';
import { useHubTest } from '../../../hooks/useHubTest';
import { formatDate } from '../../../utils/formatDate';
import { useApplicationError } from '../../ApplicationError';
import LoaderView from '../../LoaderView';
import RunTestsDialog, { RunTestsButtonProps } from '../../RunTestsDialog';
import StatusText from '../../status/StatusText';

const ValueItem = (props: BoxProps) => (
  <Box
    sx={{
      '&': {
        typography: 'body1',
        margin: 0,
        height: 32,
        lineHeight: '32px',
      },
      '& b': {
        marginLeft: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingRight: 1,
      },
    }}
    {...props}
  />
);

const RunTestsButton = (props: RunTestsButtonProps) => {
  const { lastTest } = useHubTest();
  const { user } = useAuthentication();
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  const area = params.get('area') ?? CustomerArea.BB;

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={props.onClick}
      sx={{ ml: 1, whiteSpace: 'nowrap' }}
      disabled={lastTest?.in_progress || ((user?.level ?? 0) < 2 && area === CustomerArea.BB)}
      disableElevation
    >
      {props.children}
    </Button>
  );
};

export default function LastTestSyntheticInfo(): JSX.Element {
  const { customer: customerId } = useParams() as { customer?: string | undefined };
  const { lastTest, readHubTestList, readHubTestListRequest } = useHubTest();
  const { customerActionList: actions } = useCustomer();
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  const applicationErrors = useMemo(() => [readHubTestListRequest.error, readHubTestListRequest.parseError], [
    readHubTestListRequest,
  ]);
  useApplicationError('DiagnosticsLog', applicationErrors);

  useEffect(() => {
    if (customerId) {
      readHubTestList(customerId, '');
    }
  }, [customerId, readHubTestList]);

  const runTestsButton = useMemo(() => {
    if (!actions?.actions) {
      return [];
    }

    const runTestsAction = actions.actions.find(({ action_id }) => action_id === 'execute-test');

    if (customerId) {
      if (runTestsAction) {
        return (
          <RunTestsDialog
            key={`${runTestsAction.action_id}-${runTestsAction.action_title}`}
            customerId={customerId}
            wizardId={undefined}
            action={runTestsAction}
            ButtonComponent={RunTestsButton}
          />
        );
      }
    }
    return null;
  }, [actions, customerId]);

  const testsHistoryButton = useMemo(() => {
    if (!actions?.actions) {
      return [];
    }

    const testsHistoryActions = actions.actions.find(({ action_id }) => action_id === 'request-history');

    if (testsHistoryActions) {
      return (
        <Button
          to={`${url}/history`}
          color="secondary"
          variant="contained"
          disableElevation
          component={Link}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {'Storico test'}
        </Button>
      );
    }
    return null;
  }, [actions.actions, url]);

  const workInProgress = useMemo(() => {
    const status = lastTest?.color
      ? colorStringToStatus(lastTest.color)
      : lastTest?.start_color
      ? colorStringToStatus(lastTest?.start_color)
      : lastTest?.result;

    if (typeof status === 'undefined') {
      return (
        <ValueItem>
          <span>{t('dashboard:test:result')}</span>
          <b>
            <StatusText status={Status.Null}>{t('common:noData')}</StatusText>
          </b>
        </ValueItem>
      );
    }

    return lastTest?.in_progress ? (
      <ValueItem>
        <CircularProgress thickness={2} size={15} sx={{ marginRight: 2 }} />
        <Typography color="primary" variant="body1" sx={{ textTransform: 'uppercase', marginY: 1 }}>
          {t('dashboard:test:inProgress')}
        </Typography>
      </ValueItem>
    ) : (
      <ValueItem>
        <span>{t('dashboard:test:result')}</span>
        <b>
          <StatusText status={status} sx={{ textTransform: 'uppercase' }}>
            {status === Status.Success ? t('common:ok') : t('common:ko')}
          </StatusText>
        </b>
      </ValueItem>
    );
  }, [lastTest, t]);

  const endTime = useMemo(() => {
    return !lastTest?.in_progress ? (
      <ValueItem>
        <span>{t('dashboard:test:end')}</span>
        <b>{lastTest?.end_date ? formatDate(lastTest?.end_date) : '-'}</b>
      </ValueItem>
    ) : null;
  }, [lastTest?.end_date, lastTest?.in_progress, t]);

  return (
    <Box
      sx={{
        p: 1,
        flex: 3,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 1,
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <LoaderView condition={Boolean(actions?.actions) && !readHubTestListRequest.inProgress} minHeight={18}>
        {lastTest ? (
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row wrap',
              flex: 4,
              '&>div': {
                display: 'flex',
                flexFlow: 'row nowrap',
                alignItems: 'center',
                flex: 1,
                marginLeft: 2,
              },
            }}
          >
            <ValueItem>
              <span>{t('dashboard:test:test')}</span>
              <b>{lastTest.request_id}</b>
            </ValueItem>
            {workInProgress}
            <ValueItem>
              <span>{t('dashboard:test:start')}</span>
              <b>{formatDate(lastTest.start_date)}</b>
            </ValueItem>
            {endTime}
          </Box>
        ) : (
          <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
            {t('dashboard:test:noTestAvailable')}
          </Typography>
        )}
        <Box sx={{ width: 'auto', ml: 'auto', display: 'flex', flexWrap: 'wrap' }}>
          {testsHistoryButton}
          {runTestsButton}
        </Box>
      </LoaderView>
    </Box>
  );
}
