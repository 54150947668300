import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface WizardTitleProps {
  title?: string;
  buttons?: React.ReactNodeArray;
}

export default function WizardTitle(props: WizardTitleProps): JSX.Element | null {
  const { buttons, title } = props;

  if (!title) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Typography variant="h6" gutterBottom sx={{ flex: '1 1 auto' }}>
        {title}
      </Typography>
      {buttons}
    </Box>
  );
}
