import { Customer } from '../entities/Customer';
import { Test } from '../entities/HubTest';
import { InterceptorRepository } from './InterceptorRepository';

class HubTestRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/customers/',
    });
  }

  async getList(customerId: Customer['id'], requestId: string): Promise<unknown> {
    const { data } = await this.axios.get<unknown>(
      `/${customerId}/test${requestId ? `?next_pagination_token=${requestId}` : ''}`
    );
    const hrs = (data as { historical_requests: { categories: Array<{ id: string | null }> }[] }).historical_requests;

    for (let i = 0; i < hrs.length; i++) {
      const hr = hrs[i];
      if (hr) {
        hr.categories = hr.categories.filter((cat) => Boolean(cat.id));
      }
    }
    return data;
  }

  async getTestWizard(customerId: Customer['id'], id: Test['request_id']): Promise<unknown> {
    const { data } = await this.axios.get<unknown>(`/${customerId}/test/${id}/wizard`);
    return data;
  }
}

export default new HubTestRepository();
