import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mitch528/mdi-material-ui/ChevronDown';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useGlossary } from '../../../../hooks/useGlossary';
import LoaderView from '../../../LoaderView';
import { Scroller } from '../../../Scroller';

interface HubDetailsDialogGlossaryProps {
  size: { width: number; height: number };
  visible: boolean;
}

export default function HubDetailsDialogGlossary(props: HubDetailsDialogGlossaryProps): JSX.Element {
  const { glossary, readGlossary, readGlossaryRequest } = useGlossary();
  const [word, setWord] = useState<string>();

  useEffect(() => {
    // on dialog open
    if (props.visible) {
      //initializers
    }

    // clean up
    return () => {
      // destructors
      setWord('');
    };
  }, [props.visible, readGlossary]);

  return (
    <Box width={props.size.width} height={props.size.height}>
      <Box>
        <TextField
          type={'text'}
          variant="filled"
          value={word}
          label="Filtra per nome"
          sx={{ '& >div': { borderRadius: 0 } }}
          fullWidth
          onChange={(e) => {
            setWord(e.target.value);
          }}
        />
      </Box>
      <LoaderView condition={!readGlossaryRequest.inProgress}>
        <Scroller sx={{ maxHeight: props.size.height - 53 }}>
          <ul style={{ paddingRight: 32 }}>
            {glossary?.glossary
              .filter((glossaryItem) => (word ? glossaryItem.name.toLowerCase().includes(word.toLowerCase()) : true))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((glossaryItem, i, items) => (
                <React.Fragment key={glossaryItem.name}>
                  {glossaryItem.name.charAt(0) !== items[i - 1]?.name.charAt(0) && (
                    <div
                      style={{
                        marginTop: 18,
                        paddingLeft: 8,
                        [`${
                          glossaryItem.name.charAt(0) !== items[i - 1]?.name.charAt(0) && 'borderBottom'
                        }`]: '1px solid rgba(0, 0, 0, 0.12)',
                      }}
                    >
                      <Typography fontWeight="bold" color="#969696">
                        {glossaryItem.name.charAt(0).toUpperCase()}
                      </Typography>
                    </div>
                  )}
                  <li>
                    <Accordion
                      sx={{
                        border: 0,
                      }}
                      disableGutters
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          ':hover': {
                            backgroundColor: (theme) => theme.palette.primary.main + '10',
                          },
                        }}
                      >
                        <Typography style={{ fontWeight: 'bold' }} fontSize={15}>
                          {glossaryItem.name.charAt(0).toUpperCase() + glossaryItem.name.slice(1)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{glossaryItem.description}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                </React.Fragment>
              ))}
          </ul>
        </Scroller>
      </LoaderView>
    </Box>
  );
}
