import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmButtomProps extends PropsWithChildren<unknown> {
  ButtonProps: ButtonProps;
  title: string;
  loading?: LoadingButtonProps['loading'];
}

export default function ConfirmButtom(props: ConfirmButtomProps): JSX.Element {
  const { children, title, ButtonProps } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(false);

    if (ButtonProps.onClick) {
      ButtonProps.onClick(e);
    }
  };

  return (
    <div>
      <Button {...ButtonProps} onClick={handleClickOpen}>
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmbutton-title"
        aria-describedby="confirmbutton-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="confirmbutton-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmbutton-description">
            {title === 'Chiudi'
              ? 'Attenzione: tutte le procedure di risoluzione verranno annulate. Continuare?'
              : t('common:genericConfirmMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('actions:cancel')}</Button>
          <LoadingButton onClick={handleConfirm} loading={props.loading ?? false} autoFocus>
            {t('actions:confirm')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
