import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Case, CaseSynthInfo } from '../../entities/Case';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  RequestParseFailureAction,
  requestParseState,
} from '../toolkitUtils';

const initialState = {
  caseSynthInfo: undefined as CaseSynthInfo | undefined,
  cases: [] as Case[],
  requests: {
    readCaseSynthInfo: requestParseState(),
    readCases: requestParseState(),
  },
};

const caseSlice = createSlice({
  name: 'caseInfo',
  initialState,
  reducers: {
    // Case synth info
    readCaseSynthInfoRequest(state) {
      mutateRequestState(state.requests.readCaseSynthInfo);
    },
    readCaseSynthInfoSuccess(state, action: PayloadAction<CaseSynthInfo>) {
      const response = action.payload;
      state.caseSynthInfo = response;
      mutateSuccessState(state.requests.readCaseSynthInfo);
    },
    readCaseSynthInfoFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readCaseSynthInfo, action.payload.error);
    },
    readCaseSynthInfoParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readCaseSynthInfo, action.payload.parseError);
    },
    // Cases list
    readCasesRequest(state) {
      mutateRequestState(state.requests.readCases);
    },
    readCasesSuccess(state, action: PayloadAction<Case[]>) {
      const response = action.payload;
      state.cases = response;
      mutateSuccessState(state.requests.readCases);
    },
    readCasesFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readCases, action.payload.error);
    },
    readCasesParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readCases, action.payload.parseError);
    },
  },
});

export const caseActions = caseSlice.actions;

export default caseSlice.reducer;
