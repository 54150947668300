import React from 'react';
import Typography from '@mui/material/Typography';

function PageColumnTitle(props: { className?: string; children: React.ReactNode | React.ReactNodeArray }): JSX.Element {
  return (
    <Typography
      className={props.className}
      variant="h6"
      gutterBottom
      sx={{
        paddingY: 1,
        paddingX: 0,
        display: 'flex',
        height: 40,
        alignItems: 'center',
        marginBottom: 1,
        '& > *:first-of-type': {
          marginLeft: 'auto',
        },
      }}
    >
      {props.children}
    </Typography>
  );
}

export default React.memo(PageColumnTitle);
