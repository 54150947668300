import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Split from 'react-split';
import ReloadIcon from '@mitch528/mdi-material-ui/Reload';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { makeStyles } from '@mui/styles';
import {
  BoardElement,
  BoardElementType,
  L2ChartType,
  L2DashboardColumn,
  L2DashboardRow,
  L2DashboardTab,
  L2Element,
} from '../../../entities/L2Dashboard';
import { useL2Dashboard } from '../../../hooks/useL2Dashboard';
import { l2DashboardActions } from '../../../store/reducers/l2dashboard';
import { getMaterialColors } from '../../../utils/materialColors';
import L2ChartSection from './BoardElements/L2ChartSection';
import L2ControllerSection, {
  RequestRangeAndTimeFilter,
  RequestValueFilter,
} from './BoardElements/L2ControllerSection';
import L2LegendSection from './BoardElements/L2LegendSection';
import L2MarkdownSection from './BoardElements/L2MarkdownSection';
import L2TableSection from './BoardElements/L2TableSection';
import TabSkeleton from './TabSkeleton';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-between',
    '& :last-child': {
      marginRight: 0,
    },
  },
  split: {
    '& .gutter': {
      cursor: 'col-resize',
    },
    '& .gutter-vertical': {
      cursor: 'row-resize',
    },
  },
});

const DASHBOARD_HEIGHT = window.innerHeight - 56 - 48 - 2;

export type L2TabProps = {
  tabId: string;
  currentTabIndex: number;
};
export function L2Tab({ tabId, currentTabIndex }: L2TabProps): JSX.Element {
  const {
    tabs,
    reloadingElementIds,
    readElements,
    readElementsRequest,
    readLayout,
    readLayoutRequest,
  } = useL2Dashboard();
  const tab = tabs.find((t: L2DashboardTab) => t.dashboard.id === tabId);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { customer } = useParams() as { customer: string | undefined };

  const [legendItems, setLegendItems] = React.useState<
    Array<{ dataClass: string; type: 'trendline' | 'area'; chartIds: string[]; display: boolean; color: string }>
  >([]);
  const [filteringState, setFilteringState] = React.useState<
    Record<string, RequestValueFilter | RequestRangeAndTimeFilter>
  >({});

  React.useEffect(() => {
    // Fetch layout
    const fetch = async () => {
      if (tab && tabs.indexOf(tab) === currentTabIndex && !tab?.dashboard.layout && tab.dashboard.id && customer)
        await readLayout(customer, tab.dashboard.id);
    };
    fetch();
  }, [currentTabIndex, readLayout, tab, tabId, tabs, customer]);

  React.useEffect(() => {
    // Fetch elements data
    if (customer && tab && tabs.indexOf(tab) === currentTabIndex && tab?.elements.length === 0) {
      const fetch = async () => {
        if (!tab?.dashboard?.layout) return;
        await readElements(customer, tab.dashboard.id);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readElements, customer, currentTabIndex]);

  React.useEffect(() => {
    // Creates legend items
    const trendlineClasses: Array<{
      dataClass: string;
      type: 'trendline' | 'area';
      color: string | null | undefined;
      chartIds: string[];
    }> = [];
    tab?.elements.forEach((el: L2Element) => {
      if (el.type === BoardElementType.CHART) {
        el.board_element.trendlines.forEach((trendline) => {
          const alreadySavedClass = trendlineClasses.find((t) => t.dataClass === trendline.data_class);
          if (alreadySavedClass) {
            alreadySavedClass.chartIds.push(el.id);
          } else
            trendlineClasses.push({
              dataClass: trendline.data_class,
              color: trendline.color?.color,
              type: 'trendline',
              chartIds: [el.id],
            });
        });
        if (el.board_element.chart_type !== L2ChartType.PIE && el.board_element.areas) {
          el.board_element.areas.forEach((area) => {
            const alreadySavedClass = trendlineClasses.find((t) => t.dataClass === area.data_class);
            if (alreadySavedClass) {
              alreadySavedClass.chartIds.push(el.id);
            } else
              trendlineClasses.push({
                dataClass: area.data_class,
                color: area.color?.color,
                type: 'area',
                chartIds: [el.id],
              });
          });
        }
      }
    });
    const colors = getMaterialColors(trendlineClasses.length);
    setLegendItems(
      trendlineClasses.map((c) => {
        const color = c.color ?? colors.shift() ?? '#FFF'; // if the received color is null / undefined sets the first material color or white.
        return { ...c, display: true, color };
      })
    );
  }, [tab?.elements]);

  const manageLegendItemClicked = useCallback((activate: boolean, dataClass: string) => {
    setLegendItems((legendItems) => {
      legendItems.forEach((item) => {
        if (item.dataClass === dataClass) item.display = activate;
      });
      return [...legendItems]; //TODO: riaggiorna tutti i grafici. Funziona, ma vedi se puoi fixare
    });
  }, []);

  const manageFilterValueChanged = useCallback(
    async (affected_element_ids: string[], altered_filter: RequestValueFilter | RequestRangeAndTimeFilter) => {
      if (!tab?.dashboard?.id) return;
      dispatch(l2DashboardActions.addReloadingElementIds(affected_element_ids));

      const newFilteringState = filteringState ?? {};

      if (!newFilteringState[altered_filter.id]) {
        // add new filter request object if not present in dashboard filtering state
        const hasValue =
          altered_filter.type === 'value' && (altered_filter as RequestValueFilter).allowedValues?.length;
        const hasRangeOrTime =
          (altered_filter.type === 'range' || altered_filter.type === 'time') &&
          ((altered_filter as RequestRangeAndTimeFilter).end || (altered_filter as RequestRangeAndTimeFilter).start);
        if (hasRangeOrTime || hasValue) {
          newFilteringState[altered_filter.id] = altered_filter;
        }
      } else {
        // it's already present...
        if (altered_filter.type === 'value' && !(altered_filter as RequestValueFilter).allowedValues?.length) {
          // remove value filters that have empty array of selected values
          delete newFilteringState[altered_filter.id];
        } else if (
          (altered_filter.type === 'range' || altered_filter.type === 'time') &&
          !(altered_filter as RequestRangeAndTimeFilter).end &&
          !(altered_filter as RequestRangeAndTimeFilter).start
        ) {
          // remove range or time fitlers that have both null start and null end
          delete newFilteringState[altered_filter.id];
        } else {
          // update filter request object
          newFilteringState[altered_filter.id] = altered_filter;
        }
      }

      setFilteringState(newFilteringState);
      await readElements(customer ?? '', tab.dashboard.id, {
        element_ids: affected_element_ids,
        filters: Object.values(newFilteringState),
      });
      dispatch(l2DashboardActions.removeReloadingElementIds(affected_element_ids));
    },
    [dispatch, filteringState, customer, readElements, tab?.dashboard.id]
  );

  const calculateDimension = useCallback(
    (dimension?: 'auto' | number, groupElementSizes?: ('auto' | number | undefined)[]) => {
      if (dimension && typeof dimension === 'number') {
        return dimension;
      } else {
        let remainedSpace = 100;
        if (groupElementSizes) {
          for (let i = 0; i < groupElementSizes.length; i++) {
            const elSize = groupElementSizes[i];
            if (elSize && typeof elSize === 'number') remainedSpace -= elSize;
          }
        }
        if (groupElementSizes) remainedSpace = remainedSpace / groupElementSizes.filter((el) => !el)?.length;
        return remainedSpace;
      }
    },
    []
  );

  const renderBoardElement = React.useCallback(
    (el: BoardElement) => {
      const boardElement = tab?.elements.find((elData: L2Element) => elData.id === el.id);
      if (!boardElement) return;

      switch (boardElement.type) {
        case BoardElementType.CONTROLLER:
          return (
            <L2ControllerSection element={boardElement.board_element} onFilterValueChanged={manageFilterValueChanged} />
          );
        case BoardElementType.TABLE:
          return (
            <L2TableSection
              elementId={boardElement.id}
              data={boardElement.board_element}
              onRowSelected={manageFilterValueChanged}
            />
          );
        case BoardElementType.MARKDOWN:
          return <L2MarkdownSection>{boardElement.board_element.content}</L2MarkdownSection>;
        case BoardElementType.LEGEND: {
          const parsedLegendItems =
            boardElement.board_element.chart_ids_control && boardElement.board_element.chart_ids_control.length
              ? legendItems.filter((item) => {
                  let show = false;
                  item.chartIds.forEach((id) => {
                    if (boardElement.board_element.chart_ids_control?.includes(id)) {
                      if (boardElement.board_element.data_type_control) {
                        if (boardElement.board_element.data_type_control === item.type) show = true;
                      } else show = true;
                    }
                  });
                  return show;
                })
              : legendItems;
          return (
            <L2LegendSection
              element={boardElement.board_element}
              legendItems={parsedLegendItems}
              onLegendItemClick={manageLegendItemClicked}
            />
          );
        }
        case BoardElementType.CHART:
          return (
            <L2ChartSection
              data={boardElement.board_element}
              legendItems={legendItems.filter((item) => item.chartIds.includes(el.id))}
            />
          );
      }
    },
    [legendItems, manageFilterValueChanged, manageLegendItemClicked, tab?.elements]
  );

  const renderElementCard = useCallback(
    (el: BoardElement) => {
      return (
        <Card sx={{ width: '100%' }}>
          {/* Skeleton */}
          <Fade in={!tab?.elements.find((s: L2Element) => s.id === el.id)} unmountOnExit>
            <Box height="100%">
              <Skeleton variant="rectangular" height="100%" />
            </Box>
          </Fade>

          {/* Content */}
          <Fade in={Boolean(tab?.elements.find((s: L2Element) => s.id === el.id))} unmountOnExit timeout={800}>
            <Box height="100%" position="relative">
              <Box height="100%">{renderBoardElement(el)}</Box>

              {/* Realoding Element activity indicator */}
              <Fade in={reloadingElementIds.includes(el.id)}>
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  sx={{ background: (theme) => theme.palette.background.paper + 'd1' }}
                >
                  <Box position="absolute" left="50%" top="50%">
                    <CircularProgress sx={{ position: 'relative', left: '-50%', top: '-50%' }} />
                  </Box>
                </Box>
              </Fade>
            </Box>
          </Fade>
        </Card>
      );
    },
    [reloadingElementIds, renderBoardElement, tab?.elements]
  );

  const renderRow = (row: L2DashboardRow, key: string) => {
    return (
      <Box className={classes.row} key={key}>
        {row.elements.length > 1 ? (
          <Split
            className={classes.split}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
            sizes={row.elements.map((el) =>
              calculateDimension(
                el.width,
                row.elements.map((el) => el.width)
              )
            )}
            gutterSize={5}
            snapOffset={0}
            minSize={30}
          >
            {row.elements.map((el: BoardElement | L2DashboardColumn, jndex: number) => {
              const newKey = `${key}-${el.type === 'column' ? 'col' : el.type}(${jndex})`;
              return el.type !== 'column' ? (
                <Grow key={newKey} in={Boolean(tab?.dashboard?.layout)} timeout={Math.random() * 1000 + 200}>
                  {renderElementCard(el as BoardElement)}
                </Grow>
              ) : el.type === 'column' ? (
                renderColumn(el, newKey)
              ) : null;
            })}
          </Split>
        ) : (
          row.elements.map((el: BoardElement | L2DashboardColumn, jndex: number) => {
            const newKey = `${key}-${el.type === 'column' ? 'col' : el.type}(${jndex})`;
            return el.type !== 'column' ? (
              <Grow key={newKey} in={Boolean(tab?.dashboard?.layout)} timeout={Math.random() * 1000 + 200}>
                {renderElementCard(el as BoardElement)}
              </Grow>
            ) : el.type === 'column' ? (
              renderColumn(el, newKey)
            ) : null;
          })
        )}
      </Box>
    );
  };

  const renderColumn = (col: L2DashboardColumn, key: string) => {
    return col.rows.length > 1 ? (
      <Split
        key={key}
        className={classes.split}
        direction="vertical"
        sizes={col.rows.map((row) =>
          calculateDimension(
            row.height,
            col.rows.map((row) => row.height)
          )
        )}
        style={{
          height: '100%',
        }}
        gutterSize={5}
        snapOffset={0}
        minSize={30}
      >
        {col.rows.map((row: L2DashboardRow, rowIndex: number) => renderRow(row, `${key}-row(${rowIndex})`))}
      </Split>
    ) : (
      col.rows.map((row: L2DashboardRow, rowIndex: number) => renderRow(row, `${key}-row(${rowIndex})`))
    );
  };

  return tab?.dashboard?.layout && !readLayoutRequest.inProgress ? (
    <Box height={DASHBOARD_HEIGHT} paddingY={1.25}>
      {/* Render first column: the entire layout */}
      {renderColumn(tab.dashboard.layout, 'rootCol')}
      <Zoom in={currentTabIndex === tabs.findIndex((t: L2DashboardTab) => t.dashboard.id === tabId)}>
        {readElementsRequest.inProgress ? (
          <Fab color="secondary" size="small" sx={{ position: 'absolute', bottom: '1rem', right: '1rem' }} disabled>
            <Fade in={readElementsRequest.inProgress}>
              <CircularProgress color="secondary" thickness={2} />
            </Fade>
          </Fab>
        ) : (
          <Tooltip title="Ricarica lavagna" placement="left">
            <Fab
              color="secondary"
              size="small"
              sx={{ position: 'absolute', bottom: '1rem', right: '1rem' }}
              onClick={() => {
                dispatch(l2DashboardActions.resetElements({ tabId: tabId }));
                readElements(customer ?? '', tabId);
              }}
            >
              <ReloadIcon />
            </Fab>
          </Tooltip>
        )}
      </Zoom>
    </Box>
  ) : (
    <>{currentTabIndex === tabs.findIndex((t: L2DashboardTab) => t.dashboard.id === tabId) && <TabSkeleton />}</>
  );
}
