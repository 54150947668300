import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import HelpCircleOnline from '@mitch528/mdi-material-ui/HelpCircleOutline';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

type ButtonTooltipProps = {
  title: string;
  description?: string;
  maxWidth?: number;
  maxHeight?: number;
};

export default function ButtonTooltip(props: ButtonTooltipProps): JSX.Element {
  const theme = useTheme();
  const useStyles = makeStyles({
    popper: {
      [`& .${tooltipClasses.tooltip}`]: {
        padding: '12px',
        maxWidth: props.maxWidth ?? window.innerWidth - 48,
        maxHeight: props.maxHeight ?? window.innerHeight - 48,
        marginInline: '30px !important',
        marginTop: '0px !important',
        backgroundColor: theme.palette.background.paper,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[10],
      },
    },
  });
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box>
        <Tooltip
          classes={{ popper: classes.popper }}
          disableHoverListener
          disableFocusListener
          disableTouchListener
          open={open}
          title={
            <Box sx={{ fontSize: 13, fontWeight: 400 }}>
              <Typography fontWeight="bold" fontSize={15}>
                {props.title}
              </Typography>
              <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                {props.description ?? '-- Descrizione non disponibile --'}
              </ReactMarkdown>
            </Box>
          }
        >
          <IconButton onClick={() => setOpen(!open)}>
            <HelpCircleOnline sx={{ color: 'grey.700' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
}
