import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import InfoIcon from '@mitch528/mdi-material-ui/InformationOutline';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useDownDetector } from '../../../hooks/useDownDetector';
import DownDetectorCompanyCard from '../../DownDetectorCompanyCard';
import LoaderView from '../../LoaderView';
import Navbar from '../../Navbar/Navbar';
import ServiceDashboard from './ServiceDashboard';

export default function DownDetectorRouting(): JSX.Element {
  return (
    <Switch>
      <Route exact path={`/down-detector`} component={DownDetector} />
      <Route exact path={`/down-detector/:id`} component={ServiceDashboard} />
      <Redirect to="/search-customer" />
    </Switch>
  );
}

function DownDetector(): JSX.Element {
  const { readEmbeddedServiceList, readEmbeddedServiceListRequest, embeddedServiceList } = useDownDetector();
  const [queryService, setQueryService] = useState<string>();

  useEffect(() => {
    if (!embeddedServiceList.length) readEmbeddedServiceList();
  }, [embeddedServiceList, readEmbeddedServiceList]);

  return (
    <Fragment>
      <Navbar />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 56px)' }}>
        <Card sx={{ p: 3, m: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <TextField
              label="Ricerca un Servizio"
              size="small"
              sx={{ background: 'white' }}
              fullWidth
              disabled={readEmbeddedServiceListRequest.inProgress}
              onChange={(e) => {
                setQueryService(e.target.value);
              }}
            />
          </Box>

          <Box
            padding={2}
            paddingBottom={0}
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ opacity: 0.5 }}
          >
            <InfoIcon />
            <Typography paddingX={2} paddingTop={1.2} fontSize={12} fontStyle="italic">
              I risultati con la mappa in sottoimpressione hanno <strong>abilitato</strong> il servizio di
              <strong> tracciatura geografica</strong> delle segnalazioni.
            </Typography>
          </Box>
        </Card>
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <LoaderView condition={!readEmbeddedServiceListRequest.inProgress} text="Caricamento servizi...">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {embeddedServiceList
                .filter((el) => (queryService ? el.slug.toLowerCase().includes(queryService?.toLowerCase()) : true))
                .sort((companyA, companyB) => {
                  if (companyA._heatmap_available) return -1;
                  if (companyB._heatmap_available) return 1;
                  return 0;
                })
                .map((el) => (
                  <DownDetectorCompanyCard
                    key={el.slug}
                    name={el.name}
                    slug={el.slug}
                    logo={el.logos.header}
                    _heatmap_available={el._heatmap_available}
                    id={el.id}
                    sx={{ m: 2 }}
                  />
                ))}
            </Box>
          </LoaderView>
          <div style={{ opacity: 0.5, textAlign: 'center', margin: '23px 0' }}>
            <i>
              Powered by <b>Downdetector®</b>
            </i>
          </div>
        </Box>
      </Box>
    </Fragment>
  );
}
