import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AlertTitle from '@material-ui/core/AlertTitle';
import OccupiedIcon from '@mitch528/mdi-material-ui/AccountAlert';
import ReserveIcon from '@mitch528/mdi-material-ui/AccountArrowLeft';
import ReleaseIcon from '@mitch528/mdi-material-ui/AccountArrowRight';
import RevokeIcon from '@mitch528/mdi-material-ui/AccountMinus';
import AnalyzeCasesIcon from '@mitch528/mdi-material-ui/TextBoxSearch';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/system/Box';
import useAuthentication from '../../../hooks/useAuthentication';
import { useCase } from '../../../hooks/useCase';
import { useHubTest } from '../../../hooks/useHubTest';
import { useSuggestion } from '../../../hooks/useSuggestion';
import { useApplicationError } from '../../ApplicationError';
import LoaderView from '../../LoaderView';
import HubDetailsDialog from './HubDetailsDialog';
import { HubDetailsButtonProps } from './HubDetailsDialog/HubDetailsDialog';

const ValueItem = (props: BoxProps) => (
  <Box
    sx={{
      '&': {
        typography: 'body1',
        margin: 0,
        height: 32,
        lineHeight: '32px',
      },
      '& b': {
        marginLeft: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingRight: 1,
      },
    }}
    {...props}
  />
);

export default function CaseSyntheticInfo(): JSX.Element {
  const { user } = useAuthentication();
  const { customer: customerId } = useParams() as { customer?: string | undefined };
  const { caseSynthInfo, readCaseSynthInfo, readCaseSynthInfoRequest, readReserveCase } = useCase();
  const { t } = useTranslation();
  const [responseDialogOpened, setResponseDialogOpened] = useState(false);
  const [noCaseIdNotified, setNoCaseIdNotified] = useState(false);
  const { parseSuggestionMessage } = useSuggestion();
  const { lastTest } = useHubTest();

  const applicationErrors = useMemo(() => [readCaseSynthInfoRequest.parseError], [readCaseSynthInfoRequest]);
  useApplicationError('UseCase.readCaseSynthInfoRequest', applicationErrors);

  React.useEffect(() => {
    if (customerId && user?.username && user?.level) readCaseSynthInfo(customerId, user?.username, user?.level);
  }, [customerId, readCaseSynthInfo, user?.level, user?.username]);

  React.useEffect(() => {
    if (!lastTest?.in_progress && customerId && user?.username && user?.level) {
      readCaseSynthInfo(customerId, user?.username, user?.level);
    }
  }, [lastTest, customerId, readCaseSynthInfo, user?.level, user?.username]);

  const onReserveButtonClicked = useCallback(async () => {
    if (customerId && user?.username && user?.level && caseSynthInfo?.reserve_button.value !== undefined) {
      const response = await readReserveCase(
        customerId,
        user?.username,
        user?.level,
        caseSynthInfo?.reserve_button.value
      );
      if (response && response.message) {
        setResponseDialogOpened(true);
        if (response?.message && response?.message?.content === t('dashboard:test:autoClosingRilascioDialogMessage')) {
          autoClosingRilascioDialog();
        }
      }
    }
  }, [caseSynthInfo?.reserve_button.value, customerId, readReserveCase, user?.level, user?.username, t]);

  const closeResponseDialog = () => {
    setResponseDialogOpened(false);
  };

  //function auto-closing modal
  function autoClosingRilascioDialog() {
    setTimeout(function () {
      setResponseDialogOpened(false);
      window.close();
    }, 5000);
  }

  const renderReserveButtonIcon = useCallback((value: number) => {
    switch (value) {
      case -1:
        return <OccupiedIcon />;
      case 0:
        return <ReleaseIcon />;
      case 1:
        return <ReserveIcon />;
      case 2:
        return <RevokeIcon />;
      default:
        return undefined;
    }
  }, []);

  function showCaseIdAlert(inProgress: boolean, defaultMessage: string) {
    if (!inProgress && caseSynthInfo) {
      if (!noCaseIdNotified && !caseSynthInfo?.case_id) {
        const suggestionMessage = {
          message_type: 'suggestion',
          payload: {
            markdown_text: t('common:noCaseIdFound'),
            severity: 'error',
            buttons: [
              {
                type: 'close-window',
                label: 'CHIUDI NGAS',
              },
            ],
          },
        };
        const suggestionMessageParsed = JSON.parse(JSON.stringify(suggestionMessage.payload));
        parseSuggestionMessage(suggestionMessageParsed);
        setNoCaseIdNotified(true);
        return defaultMessage;
      }
      return caseSynthInfo?.case_id ?? defaultMessage;
    }
    return defaultMessage;
  }

  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 1,
        background: (theme) => theme.palette.background.paper,
        flex: 1.3,
        marginLeft: 1.3,
      }}
    >
      <LoaderView condition={Boolean(caseSynthInfo)} minHeight={18}>
        {customerId && user?.username && user.level ? (
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row wrap',
              flex: 1.5,
              '&>div': {
                display: 'flex',
                flexFlow: 'row nowrap',
                alignItems: 'center',
                flex: 1,
                marginLeft: 2,
              },
            }}
          >
            <ValueItem>
              <span style={{ whiteSpace: 'nowrap' }}>{t('dashboard:test:caseId')}</span>
              <b>{showCaseIdAlert(readCaseSynthInfoRequest.inProgress, t('common:noData'))}</b>
            </ValueItem>
          </Box>
        ) : (
          <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
            {t('dashboard:test:noTestAvailable')}
          </Typography>
        )}
        <Box sx={{ width: 'auto', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          {caseSynthInfo?.reserve_button && (
            <LoadingButton
              loading={readCaseSynthInfoRequest.inProgress || responseDialogOpened}
              startIcon={renderReserveButtonIcon(caseSynthInfo.reserve_button.value)}
              variant={caseSynthInfo.reserve_button.value === 1 ? 'outlined' : 'contained'}
              color={caseSynthInfo.reserve_button.value === 2 ? 'warning' : 'primary'}
              disabled={caseSynthInfo.reserve_button.value === -1}
              onClick={onReserveButtonClicked}
              disableElevation
              sx={{ mr: 1 }}
            >
              {caseSynthInfo.reserve_button.name}
            </LoadingButton>
          )}
          <HubDetailsDialog
            customerId={customerId ?? ''}
            type="retrieve_case"
            title={t('dashboard:test:caseHistory').toString()}
            buttonComponent={(props: HubDetailsButtonProps) => (
              <Button
                variant="contained"
                color="secondary"
                onClick={props.onClick}
                disableElevation
                startIcon={<AnalyzeCasesIcon />}
                sx={{ whiteSpace: 'nowrap' }}
              >
                {t('dashboard:test:caseHistory')}
              </Button>
            )}
          />
        </Box>
      </LoaderView>
      {caseSynthInfo?.message?.content === t('dashboard:test:caseDialog:conditionMessage') ? (
        <Dialog open={responseDialogOpened} onClose={closeResponseDialog} sx={{ paddingBottom: 20 }}>
          <DialogTitle> {t('dashboard:test:caseDialog:releaseTitle')}</DialogTitle>
          <DialogContent>
            <Alert severity={caseSynthInfo?.message?.severity ?? 'info'} sx={{ alignItems: 'center' }}>
              <AlertTitle>
                {caseSynthInfo?.message?.severity
                  ? t(`dashboard:test:caseDialog:${caseSynthInfo?.message?.severity}`)
                  : ''}
              </AlertTitle>
              <Trans i18nKey="dashboard:test:caseDialog:releaseMessage"></Trans>
            </Alert>
          </DialogContent>
        </Dialog>
      ) : (
        <>
          <Dialog open={responseDialogOpened} onClose={closeResponseDialog}>
            <DialogTitle>{t('dashboard:test:caseDialog:reserveTitle')}</DialogTitle>
            <DialogContent>
              <Alert severity={caseSynthInfo?.message?.severity ?? 'info'}>
                <AlertTitle>
                  {caseSynthInfo?.message?.severity
                    ? t(`dashboard:test:caseDialog:${caseSynthInfo?.message?.severity}`)
                    : ''}
                </AlertTitle>
                {caseSynthInfo?.message?.content}
              </Alert>
              <DialogActions>
                <Button onClick={closeResponseDialog} autoFocus>
                  {t('common:ok')}
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}
