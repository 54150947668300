import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';

import { Router } from 'react-router-dom';
import { enUS, it } from 'date-fns/locale';
import { createBrowserHistory } from 'history';
import { LocalizationProvider } from '@mui/lab';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';

import { AuthenticationProvider } from './hooks/useAuthentication';
import { WebsocketProvider } from './hooks/useWebSocket';
import Navigation from './Navigation';
import store from './store';

export default function Main(): JSX.Element {
  const { i18n } = useTranslation();

  const datesLocale = useMemo(() => {
    if (i18n.languages.indexOf('en-US') >= 0) {
      return enUS;
    }

    return it;
  }, [i18n.languages]);

  const history = createBrowserHistory();

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={datesLocale}>
      <React.Fragment>
        <Provider store={store}>
          <AuthenticationProvider>
            <WebsocketProvider>
              <Router history={history}>
                <Navigation />
              </Router>
            </WebsocketProvider>
          </AuthenticationProvider>
        </Provider>
      </React.Fragment>
    </LocalizationProvider>
  );
}
