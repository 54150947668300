import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export function Scroller(props: BoxProps): JSX.Element {
  return (
    <Box
      {...props}
      sx={{
        overflow: 'auto',
        paddingBottom: 2,
        flex: '1 1 0',
        minHeight: 'auto',
        height: '100%',
        ...props.sx,
      }}
    />
  );
}
