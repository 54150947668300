import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import ProblemsRepository from '../api/ProblemsRepository';

import { Customer } from '../entities/Customer';
import { ProblemsApiResponse, ProblemsApiResponseSchema } from '../entities/Problems';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';

import { problemsActions } from '../store/reducers/problems';
import { RequestParseState } from '../store/toolkitUtils';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type ProblemsState = RootState['problems'];

type UseProblems = {
  foundProblems: ProblemsState['found'];
  analyzedProblems: ProblemsState['analyzed'];
  readProblems: (customerId: Customer['id']) => Promise<ProblemsApiResponse | void>;
  parseReadProblemsResponse: (response: unknown) => Promise<ProblemsApiResponse | void>;
  readProblemsRequest: RequestParseState;
};

export function useProblems(): UseProblems {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      ProblemsRepository.updateAuthToken(accessToken);
      ProblemsRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const foundProblems = useSelector((state: RootState) => state.problems.found);
  const analyzedProblems = useSelector((state: RootState) => state.problems.analyzed);
  const readProblemsRequest = useSelector((state: RootState) => state.problems.requests.read);

  const parseReadProblemsResponse: UseProblems['parseReadProblemsResponse'] = useCallback(
    async (response) => {
      const parseResult = ProblemsApiResponseSchema.safeParse(response);

      if (parseResult.success === false) {
        dispatch(
          problemsActions.readProblemsParseFailure({
            parseError: new RequestParseError(parseResult.error, 'useProblems.readProblems'),
          })
        );
        return;
      } else {
        dispatch(problemsActions.readProblemsSuccess({ response: parseResult.data }));
        return parseResult.data;
      }
    },
    [dispatch]
  );

  const readProblems: UseProblems['readProblems'] = useCallback(
    async (customerId) => {
      dispatch(problemsActions.readProblemsRequest({ customerId }));
      let response;

      try {
        response = await ProblemsRepository.get(customerId);
      } catch (error) {
        dispatch(problemsActions.readProblemsFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      return parseReadProblemsResponse(response);
    },
    [dispatch, parseReadProblemsResponse]
  );

  return {
    foundProblems,
    analyzedProblems,
    readProblems,
    parseReadProblemsResponse,
    readProblemsRequest,
  };
}
