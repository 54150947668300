import React, { useMemo } from 'react';
import AlertCircleOutline from '@mitch528/mdi-material-ui/AlertCircleOutline';
import Box from '@mui/material/Box';

interface KeyValueListProps {
  entries: Array<{ key: string; value: string; warning: boolean }>;
  flexBasis?: string | undefined;
}

export default function KeyValueList(props: KeyValueListProps): JSX.Element {
  const elements = useMemo(() => {
    const elements = [];

    for (let i = 0; i < props.entries.length; i++) {
      const entry = props.entries[i];

      if (entry) {
        elements.push(
          <Box
            key={`${entry.key}-${i}`}
            sx={{
              flexBasis: props.flexBasis,
              display: 'flex',
              flexWrap: 'wrap',
              '& span': {
                color: (theme) => theme.palette.text.secondary,
                flex: '1 0 0',
              },
              '& b': {
                wordBreak: 'break-word',
              },
            }}
          >
            <span>{entry.key}:</span>
            <Box
              sx={{
                color: (theme) => (entry.warning ? 'orange' : theme.palette.text.primary),
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <b>{entry.value ? entry.value : '-'}</b>
              {entry.warning && <AlertCircleOutline sx={{ fontSize: 17, marginLeft: 2, marginBottom: 1 }} />}
            </Box>
          </Box>
        );
      }
    }
    return elements;
  }, [props.entries, props.flexBasis]);

  return (
    <Box
      sx={{
        width: '100%',
        fontSize: (theme) => theme.typography.body2.fontSize,
      }}
    >
      {elements}
    </Box>
  );
}
