import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Wizard, WizardTypes } from '../../../../../entities/Wizard';
import PageColumnTitle from '../../../../PageColumnTitle';

interface WizardHeaderProps {
  wizard: Wizard | undefined;
}

function WizardProgression(props: WizardHeaderProps) {
  const { wizard } = props;

  if (!wizard) {
    return null;
  }

  let elapsedSteps = 1;
  let totalSteps = 1;
  let progression = 100;

  if (wizard.type === WizardTypes.WIZARD_STEP) {
    elapsedSteps = wizard.data.elapsed_steps ?? 1;
    totalSteps = wizard.data.total_steps ?? 1;
    progression = (elapsedSteps * 100) / totalSteps;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'justify', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1, height: 10 }}>
        <LinearProgress variant="determinate" value={progression} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="textSecondary">{`${elapsedSteps}/${totalSteps}`}</Typography>
      </Box>
    </Box>
  );
}

function WizardHeader(props: WizardHeaderProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <Box minWidth="40%">
        <PageColumnTitle>{t('dashboard:recommendedActions:wizard')}</PageColumnTitle>
      </Box>
      <WizardProgression {...props} />
    </Box>
  );
}

export default React.memo(WizardHeader);
