import React from 'react';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
// import Typography from '@mui/material/Typography';
// import LoadingButton from '@mui/lab/LoadingButton';
// import { Wizard, WizardTypes } from '../../../../../entities/Wizard';
// import { useCustomerData } from '../../../../../hooks/useCustomerData';
// import { useWizard } from '../../../../../hooks/useWizard';
// import ConfirmButtom from '../../../../ConfirmButton';
// import WizardButton from './WizardButton';

interface WizardCardActionsProps {
  // wizard: Wizard | undefined;
  // enableRightNavigation: boolean;
  // onClick: (value: string | number, name: string, type: string) => Promise<void>
  left: React.ReactNodeArray;
  right: React.ReactNodeArray;
}

export default function WizardCardActions(props: WizardCardActionsProps): JSX.Element | null {
  const { /*wizard, enableRightNavigation,*/ left, right } = props;
  // const [clickedButtonValue, setClickedButtonValue] = useState<string | number | undefined>();

  // const {    readWizardStepRequest,  } = useWizard();

  // const leftButtons = [];
  // const rightButtons = [];

  // const handleOptionButtonClick = async (value: string | number, name: string, type: string) => {
  //     setClickedButtonValue(value);
  //     await props.onClick(value,        name,        type);
  //     // setActionChecks({});
  // };

  // if (wizard) {
  //   switch (wizard.type) {
  //     case WizardTypes.CLOSELOOP: {
  //       title = 'Chiusura procedura risoluzione guidata';
  //       break;
  //     }
  //     case WizardTypes.RESULTS: {
  //       title = 'Esiti Problemi Analizzati';
  //       break;
  //     }
  //     case WizardTypes.WIZARD0_VIEW: {
  //       title = 'Controlli a Richiesta';
  //       break;
  //     }
  //     case WizardTypes.WIZARD_FEEDBACK: {
  //       rightButtons.push(
  //         <LoadingButton
  //           size="small"
  //           onClick={async () => {
  //             if (customerId && feedback) {
  //               await createFeedback(customerId, feedback);
  //               await readWizardStep(customerId);
  //             }
  //           }}
  //           disabled={typeof feedback?.rating === 'undefined' /* || typeof feedback?.solved === 'undefined'*/}
  //           pending={createFeedbackRequest.inProgress}
  //         >
  //           {t('common:next')}
  //         </LoadingButton>
  //       );
  //       break;
  //     }
  //     case WizardTypes.WIZARD_STEP: {
  //       for (let i = 0; i < wizard.data.options.length; i++) {
  //         const option = wizard.data.options[i];

  //         if (option && option.type === 'button') {
  //             const button = option;

  //             if (button?.name) {
  //               const value = typeof button.value === 'undefined' ? '' : button.value;
  //               const buttonComponent = (
  //                 <WizardButton
  //                   key={button.name}
  //                   confirm={button.confirm ?? false}
  //                   disabled={button.side === 'right' && !enableRightNavigation && step.results?.option?.name !== button.name}
  //                   onClick={() => handleOptionButtonClick(value, button.name, button.type)}
  //                   pending={button.value === clickedButtonValue && readWizardStepRequest.inProgress}
  //                   confirmDialogTitle={button.name}
  //                 >
  //                   {button?.name}
  //                 </WizardButton>
  //               )

  //               if (button.side === 'left') {
  //                 leftButtons.push(buttonComponent);
  //               // } else if (button.side === 'right_outer') {
  //               //   rightOuterButtons.push(buttonComponent);
  //               } else {
  //                 // If side is missing, show button on right
  //                 rightButtons.push(buttonComponent);
  //               }
  //             }
  //           }
  //         }
  //       }
  //       break;
  //     }
  //   }
  // }

  return (
    <CardActions sx={{ width: '100%' }}>
      {left}
      <Box sx={{ flex: '1 1 auto' }} />
      {right}
      {/* {stepId === '-2' && (
              <LoadingButton
                size="small"
                onClick={handleWizardEnd}
                disabled={!feedback}
                pending={createFeedbackRequest.inProgress}
              >
                {t('common:end')}
              </LoadingButton>
            )} */}
    </CardActions>
  );
}
