import React, { useEffect } from 'react';
import ExpandMoreIcon from '@mitch528/mdi-material-ui/ChevronDown';
import CircleSmall from '@mitch528/mdi-material-ui/CircleSmall';
import MenuRight from '@mitch528/mdi-material-ui/MenuRight';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Case, CaseEscalation, CaseInteraction } from '../../../../entities/Case';
import { useCase } from '../../../../hooks/useCase';
import LoaderView from '../../../LoaderView';
import StatusCircle from '../../../status/StatusCircle';

export interface HubDetailsDialogCaseProps {
  customerId?: string;
  visible: boolean;
  size: { width: number; height: number };
}

export default function HubDetailsDialogCase(props: HubDetailsDialogCaseProps): JSX.Element {
  const { cases, readCases, readCasesRequest } = useCase();

  useEffect(() => {
    if (props.visible) {
      const fetchData = async () => {
        if (!props.customerId) return;
        await readCases(props.customerId);
      };
      fetchData();
    }
  }, [props.customerId, props.visible, readCases]);

  const renderCasesList = (list: Array<Case>, closed?: boolean) => {
    return list.map((caseData) => (
      <div key={caseData.case_id} style={{ margin: 8 }}>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={
              caseData.escalations || caseData.interactions || caseData.children ? <ExpandMoreIcon /> : <CircleSmall />
            }
            sx={{
              transition: '0.3s',
              ':hover': {
                background: (theme) => (closed ? theme.palette.success.main + '0f' : theme.palette.warning.main + '0f'),
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                fontSize: 12,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2, flex: 1 }}>
                <StatusCircle status={closed ? 0 : 1} sx={{ marginRight: 4 }} />
                <b>{caseData.case_id}</b>
                {(caseData.date_opening || caseData.date_closure) && <span style={{ marginInline: 12 }}>-</span>}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {caseData.date_opening && (
                    <i>
                      {'Apertura: '}
                      {new Date(caseData.date_opening * 1000).toLocaleDateString()}
                    </i>
                  )}
                  {caseData.date_closure && (
                    <i>
                      {'Chiusura: '}
                      {new Date(caseData.date_closure * 1000).toLocaleDateString()}
                    </i>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 3 }}>
                <Box sx={{ marginInline: 8, flex: 1, display: 'flex', flexDirection: 'column' }}>
                  {caseData.category && (
                    <span>
                      <span style={{ marginRight: 6 }}>{'Categoria:'}</span>
                      <b>{caseData.category}</b>
                    </span>
                  )}
                  {caseData.sub_category && (
                    <span>
                      <span style={{ marginRight: 6 }}>{'Sotto-categoria:'}</span>
                      <b>{caseData.sub_category}</b>
                    </span>
                  )}
                </Box>
                <Box sx={{ marginInline: 8, flex: 1, display: 'flex', flexDirection: 'column' }}>
                  {caseData.motive && (
                    <span>
                      <span style={{ marginRight: 6 }}>{'Motivo:'}</span>
                      <b>{caseData.motive}</b>
                    </span>
                  )}
                  {caseData.motive_details && (
                    <span>
                      <span style={{ marginRight: 6 }}>{'Dettaglio motivo:'}</span>
                      <b>{caseData.motive_details}</b>
                    </span>
                  )}
                </Box>
                <Box sx={{ marginInline: 8, flex: 1, display: 'flex', flexDirection: 'column' }}>
                  {caseData.status && (
                    <span>
                      <span style={{ marginRight: 6 }}>{'Stato:'}</span>
                      <b>{caseData.status}</b>
                    </span>
                  )}
                  {caseData.sub_status && (
                    <span>
                      <span style={{ marginRight: 6 }}>{'Sotto-stato:'}</span>
                      <b>{caseData.sub_status}</b>
                    </span>
                  )}
                </Box>
                <Box sx={{ marginInline: 8, flex: 1 }}>
                  {caseData.global_status && (
                    <>
                      <span style={{ marginRight: 6 }}>{'Stato globale:'}</span>
                      <b>{caseData.global_status}</b>
                    </>
                  )}
                </Box>
                <Box sx={{ marginInline: 8, flex: 1 }}>
                  {caseData.outcome && (
                    <>
                      <span style={{ marginRight: 6 }}>{'Esito:'}</span>
                      <Typography
                        fontWeight="bold"
                        color={(theme) => (closed ? theme.palette.success.main : theme.palette.warning.main)}
                        sx={{ display: 'inline-block' }}
                      >
                        {caseData.outcome}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </AccordionSummary>

          {(caseData.escalations || caseData.interactions || caseData.children) && (
            <AccordionDetails>
              {/* Escalations */}
              {caseData.escalations && (
                <div style={{ marginTop: 8 }}>
                  <Accordion
                    disableGutters
                    sx={{
                      p: 1,
                      border: 0,
                      backgroundColor: (theme) => theme.palette.primary.main + '0a',
                      borderRadius: 1,
                      transition: '0.3s',
                      ':hover': { backgroundColor: (theme) => theme.palette.primary.main + '14' },
                    }}
                  >
                    <AccordionSummary>
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Escalations</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      {caseData.escalations.map((escalationData: CaseEscalation) => (
                        <Box
                          key={escalationData.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBlock: 12,
                            fontSize: 12,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                            <MenuRight />
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {escalationData.id && <b>{escalationData.id}</b>}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {escalationData.team && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Team:'}</span>
                                  <b>{escalationData.team}</b>
                                </>
                              )}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {escalationData.category && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Categoria:'}</span>
                                  <b>{escalationData.category}</b>
                                </>
                              )}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {escalationData.impact && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Impatti:'}</span>
                                  <b>{escalationData.impact}</b>
                                </>
                              )}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {escalationData.olo_id && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Olo id:'}</span>
                                  <b>{escalationData.olo_id}</b>
                                </>
                              )}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {escalationData.status && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Stato:'}</span>
                                  <b>{escalationData.status}</b>
                                </>
                              )}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {escalationData.sub_status && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Sotto-stato:'}</span>
                                  <b>{escalationData.sub_status}</b>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {/* Interactions */}
              {caseData.interactions && (
                <div style={{ marginTop: 8 }}>
                  <Accordion
                    disableGutters
                    sx={{
                      p: 1,
                      border: 0,
                      backgroundColor: (theme) => theme.palette.primary.main + '0a',
                      borderRadius: 1,
                      transition: '0.3s',
                      ':hover': { backgroundColor: (theme) => theme.palette.primary.main + '14' },
                    }}
                  >
                    <AccordionSummary>
                      <span style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Interactions</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      {caseData.interactions.map((interactionData: CaseInteraction) => (
                        <Box
                          key={interactionData.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginBlock: 12,
                            fontSize: 12,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                            <MenuRight />
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {interactionData.id && <b>{interactionData.id}</b>}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {interactionData.status && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Stato:'}</span>
                                  <b>{interactionData.status}</b>
                                </>
                              )}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {interactionData.interaction_type && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Tipo interazione:'}</span>
                                  <b>{interactionData.interaction_type}</b>
                                </>
                              )}
                            </Box>
                            <Box sx={{ paddingInline: 8, flex: 1 }}>
                              {interactionData.appointment_id && (
                                <>
                                  <span style={{ marginRight: 6 }}>{'Appuntamento:'}</span>
                                  <b>{interactionData.appointment_id}</b>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {/* Childrens */}
              {caseData.children && renderCasesList(caseData.children)}
            </AccordionDetails>
          )}
        </Accordion>
      </div>
    ));
  };

  return (
    <Box
      sx={{
        p: 3,
        width: props.size.width,
        height: props.size.height,
        overflowY: 'auto',
      }}
    >
      <LoaderView
        condition={!readCasesRequest.inProgress}
        minHeight={props.size.height - 44}
        text="Caricamento storico case..."
      >
        {cases.length > 0 ? (
          <>
            {cases.filter((caseData) => !caseData.closed).length > 0 && (
              <>
                <Box
                  sx={{
                    mx: 4,
                    py: 2,
                    px: 8,
                    display: 'inline-block',
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                    borderBottom: 'none',
                    background: (theme) => theme.palette.warning.main,
                  }}
                >
                  <Typography fontWeight="bold" color="white">
                    Case Aperti
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: (theme) => `2px solid ${theme.palette.warning.main}`,
                    borderRadius: 1,
                  }}
                >
                  {renderCasesList(cases.filter((caseData) => !caseData.closed))}
                </Box>
              </>
            )}

            {cases.filter((caseData) => caseData.closed).length > 0 && (
              <>
                <Box
                  sx={{
                    mt: 4,
                    mx: 4,
                    py: 2,
                    px: 8,
                    display: 'inline-block',
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                    borderBottom: 'none',
                    background: (theme) => theme.palette.success.main,
                  }}
                >
                  <Typography fontWeight="bold" color="white">
                    Case Chiusi
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: (theme) => `2px solid ${theme.palette.success.main}`,
                    borderRadius: 1,
                  }}
                >
                  {renderCasesList(
                    cases.filter((caseData) => caseData.closed),
                    true
                  )}
                </Box>
              </>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: props.size.height - 44,
              opacity: 0.5,
            }}
          >
            {readCasesRequest.error ? 'Si è verificato un problema interno, riprovare.' : 'Nessun dato da mostrare.'}
          </Box>
        )}
      </LoaderView>
    </Box>
  );
}
