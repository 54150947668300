import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';

export default function LocalizedDataGrid(props: DataGridProps): JSX.Element {
  const { t } = useTranslation();

  const localeText = useMemo(() => {
    const prefix = 'components:dataGrid:';
    return {
      noRowsLabel: t(`${prefix}noRowsLabel`),
      errorOverlayDefaultLabel: t(`${prefix}errorOverlayDefaultLabel`),
      columnHeaderSortIconLabel: t(`${prefix}columnHeaderSortIconLabel`),
      // footerRowSelected: (count: number) => `${t(`${prefix}footerRowSelected`, { count })}`,
      footerTotalRows: t(`${prefix}footerTotalRows`),
      footerPaginationRowsPerPage: t(`${prefix}footerPaginationRowsPerPage`),
      ...props.localeText,
    };
  }, [t, props.localeText]);

  return (
    <>
      <DataGrid {...props} localeText={localeText} />
    </>
  );
}
