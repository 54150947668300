export enum Signal {
  Excellent = 'excellent',
  Good = 'good',
  Fair = 'fair',
  Poor = 'poor',
  None = 'none',
  null = 'calculating', // TODO: remove in future (Wait for BE deploy)
}

export enum DeviceCategory {
  Notebook = 'notebook',
  Smartphone = 'smartphone',
  Tablet = 'tablet',
  SmartWatch = 'smartwatch',
  Probe = 'probe',
  VoiceAssistant = 'voice_assistant',
  VideoStreaming = 'video_streaming',
  Pod = 'pod',
  Router = 'cpe',
  Generic = 'generic',
  Pda = 'pda',
  SkyGlass = 'sky_glass',
  SkyStream = 'sky_stream',
  SkyQ = 'sky_q',
  SkyQBlack = 'sky_q_black',
  SkyQMini = 'sky_q_mini',
  SkyQTitan = 'sky_q_titan',
  SkyQPlatinum = 'sky_q_platinum',
  SkyBooster = 'sky_booster',
  SkySpot = 'sky_spot',
  SkySuperSpot = 'sky_superspot',
  SkyHomeHub4 = 'sky_homehub4',
  SkyHomeHub6 = 'sky_homehub6',
}

export enum DeviceType {
  POD_DEVICE = 'Pod',
  HUB_DEVICE = 'Router',
  CLIENT_DEVICE = 'Device cliente',
  Smartphone = 'Smartphone', // TODO: remove in future (Wait for BE deploy)
  null = 'nessun dato', // TODO: remove in future (Wait for BE deploy)
}

export default function SVGDevice(
  type: DeviceType | null,
  category: DeviceCategory | null,
  signal: Signal | null
): string {
  const dimensions = {
    genericDeviceDimensions: 96,
    mainDeviceDimensions: 128,
  };
  const colors = {
    none: 'gray',
    poor: '#b71c1c',
    fair: '#ff8f00',
    good: '#1976d2',
    excellent: '#2e7d32',
  };

  let currentDimension = 96;
  let currentColor = 'gray';

  if (signal === Signal.None) currentColor = colors.none;
  if (signal === Signal.Poor) currentColor = colors.poor;
  if (signal === Signal.Fair) currentColor = colors.fair;
  if (signal === Signal.Good) currentColor = colors.good;
  if (signal === Signal.Excellent) currentColor = colors.excellent;

  switch (type) {
    case DeviceType.HUB_DEVICE:
      currentDimension = dimensions.mainDeviceDimensions;
      if (category === DeviceCategory.SkyHomeHub6) {
        return `<?xml version="1.0"?>
          <svg
            width="${currentDimension}"
            height="${currentDimension}" 
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg" 
            xmlns:svg="http://www.w3.org/2000/svg" version="1.1">
            <g class="layer">
              <g id="layer1">
              <g id="g899" transform="matrix(1.34771 0 0 1.34182 -89.015 -103.086)">
                <rect fill="#bfbfbf" height="235.64" id="rect847" ry="22.48" stroke-width="0.55" width="368.56" x="72.72" y="149.79"/>
                <path d="m284.65,244.06c-1.36,3.02 -3.2,4.31 -6.57,4.23c-1.97,-0.05 -3.53,-0.52 -4.3,-0.91l7.67,-16.13l-12.58,-19.72l-7.67,8.13l10.82,16.27c-0.89,0.42 -2.83,0.97 -5.16,0.97c-3.55,0 -5.73,-1.32 -7.4,-3.89l-8.98,-14.12l11.17,-12.19c2.35,-2.55 4.61,-4.04 8.68,-4.04c1.32,0 3.13,0.32 3.87,0.55l11.49,18.68l5.86,-13.8c1.99,-4.76 5.11,-5.23 7.45,-5.18c1.33,0.03 3,0.47 3.74,0.87l-18.09,40.28zm-34.6,-11.92c0,2.98 -1.17,4.69 -6.98,4.69c-0.77,0 -1.44,-0.06 -2.08,-0.15l0,-39.39c0,-3 1.01,-6.12 6.87,-6.12c0.75,0 1.49,0.07 2.19,0.22l0,40.75zm-22.98,4.44c-5.33,0.57 -13.05,-0.1 -16.72,-0.52c-0.14,-0.64 -0.21,-1.29 -0.22,-1.94c0,-4.83 2.6,-5.94 5.06,-5.94c2.55,0 6.57,0.44 9.55,0.44c3.3,0 4.3,-1.11 4.3,-2.17c0,-1.39 -1.32,-1.96 -3.88,-2.46l-6.99,-1.34c-5.81,-1.11 -8.91,-5.12 -8.91,-9.38c0,-5.18 3.67,-9.52 10.89,-10.31c5.46,-0.6 12.11,0.08 15.76,0.52c0.12,0.62 0.2,1.21 0.2,1.86c0,4.83 -2.56,5.87 -5.01,5.87c-1.89,0 -4.82,-0.35 -8.17,-0.35c-3.4,0 -4.81,0.94 -4.81,2.08c0,1.22 1.34,1.71 3.47,2.08l6.68,1.24c6.85,1.27 9.8,5.16 9.8,9.94c0,5.55 -3.63,9.58 -11,10.38z" fill="#f9f9f9" fill-rule="evenodd" id="path2" stroke-width="1.9"/>
                <path d="m284.15,244.33c-1.36,3.02 -3.2,4.31 -6.57,4.23c-1.97,-0.05 -3.53,-0.52 -4.3,-0.91l7.67,-16.13l-12.58,-19.72l-7.67,8.13l10.82,16.27c-0.89,0.42 -2.83,0.97 -5.16,0.97c-3.55,0 -5.73,-1.32 -7.4,-3.89l-8.98,-14.12l11.17,-12.19c2.35,-2.55 4.61,-4.04 8.68,-4.04c1.32,0 3.13,0.32 3.87,0.55l11.49,18.68l5.86,-13.8c1.99,-4.76 5.11,-5.23 7.45,-5.18c1.33,0.03 3,0.47 3.74,0.87l-18.09,40.28zm-34.6,-11.92c0,2.98 -1.17,4.69 -6.98,4.69c-0.77,0 -1.44,-0.06 -2.08,-0.15l0,-39.39c0,-3 1.01,-6.12 6.87,-6.12c0.75,0 1.49,0.07 2.19,0.22l0,40.75zm-22.98,4.44c-5.33,0.57 -13.05,-0.1 -16.72,-0.52c-0.14,-0.64 -0.21,-1.29 -0.22,-1.94c0,-4.83 2.6,-5.94 5.06,-5.94c2.55,0 6.57,0.44 9.55,0.44c3.3,0 4.3,-1.11 4.3,-2.17c0,-1.39 -1.32,-1.96 -3.88,-2.46l-6.99,-1.34c-5.81,-1.11 -8.91,-5.12 -8.91,-9.38c0,-5.18 3.67,-9.52 10.89,-10.31c5.46,-0.6 12.11,0.08 15.76,0.52c0.12,0.62 0.2,1.21 0.2,1.86c0,4.83 -2.56,5.87 -5.01,5.87c-1.89,0 -4.82,-0.35 -8.17,-0.35c-3.4,0 -4.81,0.94 -4.81,2.08c0,1.22 1.34,1.71 3.47,2.08l6.68,1.24c6.85,1.27 9.8,5.16 9.8,9.94c0,5.55 -3.63,9.58 -11,10.38z" fill="#3f3f3f" fill-rule="evenodd" id="svg_1" stroke-width="1.9"/>
                <ellipse cx="253" cy="355" fill="#b0ff32" id="svg_3" rx="5" ry="5"/>
              </g>
              </g>
            </g>
          </svg>`;
      }
      return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        width="${currentDimension}"
        height="${currentDimension}"
        viewBox="0 0 512 512"
        version="1.1"
        id="svg8"
        inkscape:version="1.0.2 (e86c870879, 2021-01-15)">
        <g
          inkscape:label="Layer 1"
          inkscape:groupmode="layer"
          id="layer1">
          <g
            id="g899"
            transform="matrix(1.3477148,0,0,1.3418157,-89.014981,-103.08551)">
            <rect
              style="fill:#333333;stroke-width:0.547432"
              id="rect847"
              width="368.55957"
              height="235.64331"
              x="71.720215"
              y="149.78999"
              ry="22.479778" />
            <path
              fill="currentColor"
              d="m 284.6527,244.05853 c -1.36541,3.02219 -3.2031,4.30953 -6.57759,4.23526 -1.96147,-0.0495 -3.52303,-0.51989 -4.29429,-0.91409 l 7.66878,-16.12976 -12.58197,-19.71754 -7.66688,8.12392 10.81856,16.27639 c -0.89123,0.42086 -2.82795,0.9655 -5.16076,0.9655 -3.54969,0 -5.73207,-1.31399 -7.39455,-3.88866 l -8.98468,-14.12068 11.16705,-12.18778 c 2.35757,-2.55181 4.61612,-4.0372 8.68569,-4.0372 1.314,0 3.12693,0.31993 3.87153,0.54464 l 11.49079,18.67777 5.85584,-13.79694 c 1.98623,-4.75705 5.11316,-5.22742 7.44597,-5.17981 1.33875,0.0267 3.00124,0.47037 3.74584,0.86838 l -18.09123,40.2806 z m -34.60567,-11.91736 c 0,2.97458 -1.16736,4.68468 -6.97369,4.68468 -0.76935,0 -1.43968,-0.0514 -2.08525,-0.14854 v -39.38937 c 0,-2.99934 1.01691,-6.12055 6.87467,-6.12055 0.74269,0 1.48728,0.0743 2.18427,0.22281 v 40.75288 z m -22.97205,4.43712 c -5.33596,0.56939 -13.05425,-0.10093 -16.72582,-0.52179 -0.1354,-0.63579 -0.20999,-1.283 -0.22281,-1.93291 0,-4.82941 2.60514,-5.94344 5.06174,-5.94344 2.55562,0 6.57568,0.44371 9.55407,0.44371 3.30022,0 4.29429,-1.11404 4.29429,-2.17857 0,-1.38826 -1.3159,-1.95766 -3.87153,-2.45279 l -6.99845,-1.33875 c -5.80823,-1.11404 -8.9085,-5.12839 -8.9085,-9.3884 0,-5.17599 3.67157,-9.51218 10.89283,-10.30438 5.45974,-0.59606 12.1116,0.0743 15.75841,0.51798 0.12378,0.62081 0.19805,1.21497 0.19805,1.85864 0,4.83131 -2.55562,5.87108 -5.01222,5.87108 -1.8853,0 -4.81417,-0.34659 -8.16391,-0.34659 -3.40115,0 -4.81417,0.94264 -4.81417,2.08144 0,1.21306 1.33875,1.70819 3.47352,2.08144 l 6.67471,1.23782 c 6.84991,1.26258 9.80354,5.15315 9.80354,9.93304 0,5.54925 -3.62396,9.58836 -10.99375,10.38247 z"
              fill-rule="evenodd"
              id="path2"
              sodipodi:nodetypes="cccccccscccsccccccccscssccccccsssccsccssssccsc"
              style="fill:#f9f9f9;stroke-width:1.90434" />
            <g
              id="g890"
              transform="translate(496.33288,88.20609)">
              <circle
                style="fill:#00aa00;stroke-width:0.240537"
                id="path857"
                cx="-288.27893"
                cy="256"
                r="5.0189738" />
              <circle
                style="fill:#00aa00;stroke-width:0.240537"
                id="path857-9"
                cx="-256.31491"
                cy="256"
                r="5.0189738" />
              <circle
                style="fill:#00aa00;stroke-width:0.240537"
                id="path857-2"
                cx="-224.35088"
                cy="256"
                r="5.0189738" />
              <circle
                style="fill:#00aa00;stroke-width:0.240537"
                id="path857-0"
                cx="-192.38683"
                cy="256"
                r="5.0189738" />
            </g>
          </g>
        </g>
      </svg>`;
    case DeviceType.POD_DEVICE:
      currentDimension = dimensions.mainDeviceDimensions;
      if (category === DeviceCategory.SkySuperSpot) {
        return `
          <?xml version="1.0" encoding="utf-8"?>
          <svg 
            viewBox="-0.0065 25 500.0003 449.9999" 
            width="${currentDimension}" height="${currentDimension * 0.9}" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#5b5b5b" stroke-width="6.136" transform="matrix(0.7809399366378784, 0, 0, 1.3351349830627441, -42.3023796081543, -134.50685119628906)" style="">
              <path d="M 624.103 203.906 C 717.852 298.571 717.852 277.411 624.103 372.076 C 530.353 466.741 561.788 456.16 374.288 456.16 C 186.787 456.16 218.222 466.741 124.472 372.076 C 30.723 277.411 30.723 298.571 124.472 203.906 C 218.222 109.24 186.787 119.822 374.288 119.822 C 561.788 119.822 530.353 109.24 624.103 203.906 Z" fill="#e6e6e6" style="stroke-width: 5px;"/>
              <path d="M 501.717 417.016 C 357.497 465.493 389.736 465.493 245.514 417.016 C 101.292 368.543 117.414 384.8 117.414 287.848 C 117.414 190.898 101.292 207.154 245.514 158.679 C 389.735 110.202 357.495 110.202 501.717 158.679 C 645.939 207.154 629.821 190.898 629.821 287.848 C 629.821 384.8 645.939 368.543 501.717 417.016 Z" fill="#f9f9f9" style="stroke-width: 10px; transform-origin: 53.582px 100.629px;"/>
              <text style="line-height: 1.25; white-space: pre; transform-origin: -815.409px 88.6553px;" x="-705" y="560" font-weight="400" font-size="177.474" font-family="sans-serif" fill="#333" stroke="none" stroke-width="27.225" transform="matrix(0.902826, 0, 0, 0.575, 869.577698, 10.350382)"><tspan x="-705" y="560">SPOT</tspan></text>
              <text style="line-height: 1.25; white-space: pre; transform-origin: -844.715px 211.659px;" x="-705" y="560" font-weight="400" font-size="177.474" font-family="sans-serif" fill="#333" stroke="none" stroke-width="27.225" transform="matrix(0.713455, 0, 0, 0.455375, 898.883667, -112.653664)">SUPER</text>
            </g>
          </svg>        
          `;
      }
      return `
        <?xml version="1.0" encoding="utf-8"?>
        <svg 
          viewBox="0 25.1587 500.0003 449.9999" 
          width="${currentDimension}" 
          height="${currentDimension * 0.9}" xmlns="http://www.w3.org/2000/svg">
          <g stroke="#5b5b5b" stroke-width="6.136" transform="matrix(0.7809399366378784, 0, 0, 1.3351349830627441, -42.295894622802734, -134.34811401367188)" style="">
            <path d="M 624.103 203.906 C 717.852 298.571 717.852 277.411 624.103 372.076 C 530.353 466.741 561.788 456.16 374.288 456.16 C 186.787 456.16 218.222 466.741 124.472 372.076 C 30.723 277.411 30.723 298.571 124.472 203.906 C 218.222 109.24 186.787 119.822 374.288 119.822 C 561.788 119.822 530.353 109.24 624.103 203.906 Z" fill="#e6e6e6" style="stroke-width: 5px;"/>
            <path d="M 501.717 417.016 C 357.497 465.493 389.736 465.493 245.514 417.016 C 101.292 368.543 117.414 384.8 117.414 287.848 C 117.414 190.898 101.292 207.154 245.514 158.679 C 389.735 110.202 357.495 110.202 501.717 158.679 C 645.939 207.154 629.821 190.898 629.821 287.848 C 629.821 384.8 645.939 368.543 501.717 417.016 Z" fill="#f9f9f9" style="stroke-width: 10px; transform-origin: 53.582px 100.629px;"/>
            <text style="line-height: 1.25; white-space: pre; transform-origin: -815.409px 88.6553px;" x="-705" y="560" font-weight="400" font-size="177.474" font-family="sans-serif" fill="#333" stroke="none" stroke-width="27.225" transform="matrix(0.902826, 0, 0, 0.575, 869.577698, -39.306557)"><tspan x="-705" y="560">SPOT</tspan></text>
          </g>
        </svg>
      `;
    default:
      currentDimension = dimensions.genericDeviceDimensions;
      switch (category) {
        case DeviceCategory.Notebook:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            width="${currentDimension}"
            height="${currentDimension}"
            viewBox="0 0 512 512"
            version="1.1"
            id="svg8"
            inkscape:version="1.0.1 (0767f8302a, 2020-10-17)"
            sodipodi:docname="laptop.svg">
            <g
              inkscape:label="Layer 1"
              inkscape:groupmode="layer"
              id="layer1">
              <g
                id="g872"
                transform="matrix(0.651888,0,0,0.651888,556.01872,-70.518903)"
                style="stroke:#5b5b5b;stroke-opacity:1;stroke-width:6.13602337;stroke-miterlimit:4;stroke-dasharray:none">
                <rect
                  style="fill:#f2f2f2;stroke:${currentColor};stroke-width:10.1975;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  id="rect910"
                  width="560.11017"
                  height="331.72339"
                  x="-740.28552"
                  y="259.21652"
                  ry="11.949945" />
                <path
                  id="rect910-1"
                  style="fill:#ffffff;stroke:${currentColor};stroke-width:11.6381;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  d="m -732.43468,590.93994 h 542.54 c 6.62026,0 8.34397,6.39792 11.94995,11.94994 l 82.476628,126.98717 c 3.605975,5.55202 -5.329688,11.94994 -11.949948,11.94994 h -705.64262 c -6.62026,0 -15.49843,-6.36102 -11.94994,-11.94994 l 80.62599,-126.98717 c 3.54848,-5.58893 5.32967,-11.94994 11.94994,-11.94994 z"
                  sodipodi:nodetypes="sssssssss" />
                <path
                  id="rect1021"
                  style="fill:${currentColor}71;fill-opacity:1;stroke:none;stroke-width:16.6378;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  d="m -535.42971,672.35284 c -4.77717,0 -7.63654,2.89026 -8.6228,6.57346 l -10.18675,38.03555 c -0.98624,3.68315 3.8456,6.57344 8.62279,6.57344 h 79.06121 3.06801 79.06124 c 4.77717,0 9.60902,-2.89029 8.62278,-6.57344 l -10.18676,-38.03555 c -0.98626,-3.68321 -3.84562,-6.57346 -8.62279,-6.57346 h -68.87447 -3.06801 z" />
                <circle
                  style="fill:${currentColor};fill-opacity:1;stroke:none;stroke-width:14.5263;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  id="path1024"
                  cx="-460.23047"
                  cy="277.80154"
                  r="5.6706815" />
                <path
                  id="rect910-1-8"
                  style="fill:${currentColor}71;stroke:none;stroke-width:6.37361;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  d="m -720.33484,605.01146 h 518.20811 c 5.41537,0 7.73586,1.2681 9.77503,4.38145 l 30.49564,46.55998 c 2.03918,3.11337 -4.35966,4.38146 -9.77502,4.38146 H -748.8444 c -5.41537,0 -11.70486,-1.22977 -9.77502,-4.38146 l 28.50957,-46.55998 c 1.92982,-3.15165 4.35965,-4.38145 9.77501,-4.38145 z"
                  sodipodi:nodetypes="sssssssss" />
                <rect
                  style="fill:${currentColor};fill-opacity:1;stroke:none;stroke-width:14.5263;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  id="rect1060"
                  width="2.5000482"
                  height="1.5828922"
                  x="-829.6535"
                  y="738.2829"
                  ry="0.79144609" />
              </g>
            </g>
          </svg>`;
        case DeviceCategory.Smartphone:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            width="${currentDimension}"
            height="${currentDimension}"
            viewBox="0 0 512 512"
            version="1.1"
            id="svg8"
            inkscape:version="1.0.1 (0767f8302a, 2020-10-17)"
            sodipodi:docname="smartphone.svg">
            <defs
              id="defs2" />
            <sodipodi:namedview
              id="base"
              pagecolor="#ffffff"
              bordercolor="#666666"
              borderopacity="1.0"
              inkscape:pageopacity="0.0"
              inkscape:pageshadow="2"
              inkscape:zoom="1.4"
              inkscape:cx="228.65927"
              inkscape:cy="490.60054"
              inkscape:document-units="px"
              inkscape:current-layer="g872"
              inkscape:document-rotation="0"
              showgrid="false"
              units="px"
              inkscape:window-width="3755"
              inkscape:window-height="2078"
              inkscape:window-x="2005"
              inkscape:window-y="44"
              inkscape:window-maximized="1" />
            <g
              inkscape:label="Layer 1"
              inkscape:groupmode="layer"
              id="layer1">
              <g
                id="g872"
                transform="matrix(0.651888,0,0,0.651888,556.01872,-70.518903)"
                style="stroke:#5b5b5b;stroke-opacity:1;stroke-width:6.13602337;stroke-miterlimit:4;stroke-dasharray:none">
                <rect
                  style="fill:#f2f2f2;stroke:${currentColor};stroke-width:7.11353;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  id="rect910"
                  width="214.65335"
                  height="421.20343"
                  x="-567.55713"
                  y="285.7739"
                  ry="15.17336" />
                <path
                  id="rect916"
                  style="fill:${currentColor};fill-opacity:1;stroke:none;stroke-width:7.11353;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  d="m -520.1847,288.02586 v 10.24484 5.60262 c 0,2.34742 1.8884,4.23583 4.23582,4.23583 h 114.1156 c 2.34741,0 4.23756,-1.88841 4.23756,-4.23583 v -5.60262 -10.24484 z" />
                <rect
                  style="fill:${currentColor};fill-opacity:1;stroke:none;stroke-width:7.11353;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                  id="rect932"
                  width="123.01178"
                  height="7.104774"
                  x="-521.73633"
                  y="680.01221"
                  ry="3.3776937" />
              </g>
            </g>
          </svg>`;
        case DeviceCategory.Tablet:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
            xml:space="preserve"
            width="${currentDimension}"
            height="${currentDimension}"
            version="1.1"
            style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision"
            viewBox="0 0 348 460"
            id="svg10"
            sodipodi:docname="tablet.svg"
            inkscape:version="1.1 (c68e22c387, 2021-05-23)"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg">
          <defs
            id="defs4">
            <style
            type="text/css"
            id="style2">
            </style>
          </defs>
          <g
            id="Layer_x0020_1"
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            transform="translate(-76,-20)">
            <metadata
            id="CorelCorpID_0Corel-Layer" />
            <path
            class="fil1"
            d="m 142.38185,82.755582 h 215.2363 c 10.18009,0 18.90589,8.7258 18.90589,18.905888 v 296.67706 c 0,10.90724 -8.7258,18.90589 -18.90589,18.90589 h -215.2363 c -10.1801,0 -18.90589,-7.99865 -18.90589,-18.90589 V 101.66147 c 0,-10.180088 8.72579,-18.905888 18.90589,-18.905888 z M 250,383.79554 c 5.8172,0 10.90724,5.09004 10.90724,10.90724 0,6.54435 -5.09004,11.6344 -10.90724,11.6344 -5.8172,0 -10.90725,-5.09005 -10.90725,-11.6344 0,-5.8172 5.09005,-10.90724 10.90725,-10.90724 z m 0,-293.768458 c 2.18145,0 3.63575,2.18145 3.63575,3.63575 0,2.18145 -1.4543,3.63575 -3.63575,3.63575 -2.18145,0 -3.63575,-1.4543 -3.63575,-3.63575 0,-1.4543 1.4543,-3.63575 3.63575,-3.63575 z M 135.11035,112.56872 H 364.88964 V 372.88829 H 135.11035 Z"
            id="path7"
            style="stroke-width:0.72715;fill:${currentColor}" />
          <path
            style="fill:#f2f2f2;stroke:#ff0000;stroke-width:0"
            d="M 134.42473,242.35577 V 111.6842 H 250.27884 366.13296 V 242.35577 373.02734 H 250.27884 134.42473 Z"
            id="path979" /><path
            style="fill:#f2f2f2;stroke:none;stroke-width:0"
            d="m 246.19006,406.38498 c -2.40597,-1.48188 -5.93847,-5.14303 -7.046,-7.3026 -1.9176,-3.73911 -1.01265,-8.01153 2.56885,-12.12802 4.57574,-5.25926 9.24823,-5.45173 15.34266,-0.63201 4.32607,3.42123 5.72865,8.46758 3.59998,12.95235 -2.72552,5.7422 -10.49013,9.55877 -14.46549,7.11028 z"
            id="path1694" /><path
            style="fill:#ececec;stroke:none;stroke-width:0"
            d="m 248.91236,97.801546 c -0.88632,-0.104805 -1.39638,-0.331603 -1.86122,-0.827595 -0.33604,-0.358562 -0.61733,-0.919092 -0.80519,-1.604533 -0.14633,-0.533924 -0.18414,-0.803159 -0.18392,-1.30965 3.4e-4,-0.802617 0.18087,-1.473027 0.57941,-2.151697 0.30432,-0.518226 0.83329,-1.091011 1.34226,-1.453434 0.32752,-0.233222 0.89507,-0.520661 1.26271,-0.63951 1.88062,-0.607965 3.54597,0.0406 4.3523,1.694977 0.59211,1.214863 0.57877,2.526288 -0.0383,3.758452 -0.24469,0.488642 -0.54762,0.893322 -0.97391,1.301037 -0.61982,0.5928 -1.38978,0.997485 -2.22817,1.171097 -0.204,0.04224 -0.38812,0.05665 -0.80861,0.06327 -0.29945,0.0047 -0.58629,0.0036 -0.63741,-0.0024 z"
            id="path2285" /></g>
          </svg>`;
        case DeviceCategory.SmartWatch:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            width="${currentDimension}"
            height="${currentDimension}"
            viewBox="0 0 182.04139 273.05756"
            xml:space="preserve"
            inkscape:version="1.1 (c68e22c387, 2021-05-23)"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg">
            <g id="g18" fill="${currentColor}" transform="matrix(6.0128527,0,0,6.0128527,-60.128527,0)">
            <g id="g2465" transform="matrix(0.38373314,0,0,0.38373314,13.625696,11.194355)">
              <path
                d="M 47.521,25 H 46 V 15.294 C 46,12.375 43.625,10 40.706,10 H 40.579 L 39.894,8.807 C 39.252,7.688 38.913,6.439 38.913,5.194 38.913,2.33 36.306,0 33.101,0 H 22.899 c -3.205,0 -5.813,2.33 -5.813,5.193 0,1.245 -0.34,2.494 -0.981,3.613 L 15.421,10 H 15.294 C 12.375,10 10,12.375 10,15.294 V 44.706 C 10,47.625 12.375,50 15.294,50 h 0.127 l 0.685,1.193 c 0.642,1.119 0.981,2.368 0.981,3.613 0,2.863 2.607,5.193 5.813,5.193 h 10.201 c 3.205,0 5.813,-2.33 5.813,-5.193 0,-1.245 0.34,-2.494 0.981,-3.613 L 40.579,50 h 0.127 C 43.625,50 46,47.625 46,44.706 V 35 h 1.521 C 48.888,35 50,33.888 50,32.521 V 27.478 C 50,26.112 48.888,25 47.521,25 Z M 44,44.706 C 44,46.522 42.522,48 40.706,48 H 15.294 C 13.478,48 12,46.522 12,44.706 V 15.294 C 12,13.478 13.478,12 15.294,12 H 40.706 C 42.522,12 44,13.478 44,15.294 V 25 35 Z"
                id="path2" />
              <path style="fill:none;stroke-width:0"
                d="M 27.834186,287.83519 C 22.087871,286.48089 16.434852,281.84801 13.938975,276.4475 L 12.692308,273.75 V 180.28846 86.826923 l 1.41376,-2.884615 c 2.079387,-4.24275 6.600178,-8.512058 10.596916,-10.007429 l 3.321069,-1.242571 h 80.149027 80.14902 l 3.33469,1.247669 c 4.56364,1.707476 9.33334,6.482131 11.04472,11.056197 l 1.25453,3.353051 -0.1511,92.988845 -0.15109,92.98885 -1.54312,2.88462 c -2.94185,5.4993 -7.13343,8.8766 -12.85694,10.35926 -3.02304,0.78311 -12.85203,0.88373 -81.34616,0.83279 -42.921445,-0.0319 -78.880238,-0.28718 -80.073444,-0.5684 z"
                id="path1717" transform="matrix(0.16631041,0,0,0.16631041,10,0)" />
              <path
                style="fill:#ececec;fill-opacity:1;stroke:none;stroke-width:0;stroke-opacity:1"
                d="m 14.462073,47.994624 c -1.109192,-0.29928 -1.956787,-1.099696 -2.338898,-2.20871 l -0.15565,-0.451746 V 30.02197 c 0,-14.638892 0.0055,-15.330231 0.124595,-15.722344 0.315498,-1.038572 1.072867,-1.834745 2.129331,-2.238426 l 0.403304,-0.154105 13.047681,-0.01907 c 8.927572,-0.01305 13.198285,0.0038 13.524618,0.05329 1.308569,0.198539 2.395837,1.168396 2.723123,2.429061 0.09232,0.355609 0.104443,2.168231 0.104443,15.617417 0,13.266238 -0.01301,15.265259 -0.101634,15.606593 -0.301466,1.16121 -1.211774,2.085995 -2.345662,2.382962 -0.416423,0.109063 -1.386299,0.117399 -13.598284,0.116879 -11.267213,-5.02e-4 -13.202438,-0.01474 -13.516967,-0.09961 z"
                id="path1928" />
            </g>
            </g>
          </svg>`;
        case DeviceCategory.Probe:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
             width="${currentDimension}"
             height="${currentDimension}"
             viewBox="0 0 8.372643 9.6722671"
             version="1.1"
             id="svg5"
             inkscape:version="1.1 (c68e22c387, 2021-05-23)"
             xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
             xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:svg="http://www.w3.org/2000/svg">
            <defs
               id="defs2">
              <inkscape:path-effect
                 effect="circle_3pts"
                 id="path-effect1845"
                 is_visible="true"
                 lpeversion="1" />
              <inkscape:path-effect
                 effect="circle_3pts"
                 id="path-effect1786"
                 is_visible="true"
                 lpeversion="1" />
            </defs>
            <g
               inkscape:label="Livello 1"
               inkscape:groupmode="layer"
               id="layer1"
               transform="translate(-72.131211,-108.31816)">
              <g
                 id="g9024"
                 transform="matrix(1.2711431,0,0,1.2711431,-19.55788,-29.369719)">
                <g
                   id="g922"
                   transform="matrix(0.32595944,0,0,0.32595944,49.726097,74.289358)">
                  <path
                     style="fill:#ececec;fill-opacity:1;stroke:${currentColor};stroke-width:0.712611;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                     d="m 76.222581,113.40524 5.240048,-4e-5 v 7.53743 c 0,0 -0.09328,2.51826 -2.620025,2.51826 -2.526746,0 -2.620023,-2.51826 -2.620023,-2.51826 z"
                     id="path857" />
                  <ellipse
                     style="fill:#ececec;fill-opacity:1;stroke:${currentColor};stroke-width:0.712579;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                     id="path1603"
                     cx="78.842606"
                     cy="116.22871"
                     rx="0.93129992"
                     ry="0.972646" />
                  <path
                     style="fill:none;stroke:${currentColor};stroke-width:0.713;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0.5"
                     d="m 72.894618,117.52772 c 0,0 -1.049661,-3.12191 0.24632,-5.7502 1.295981,-2.6283 3.515783,-3.09818 3.515783,-3.09818"
                     id="path3494-6"/>
                  <path
                     style="fill:none;stroke:${currentColor};stroke-width:0.712579;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0.8"
                     d="m 74.620183,116.06328 c 0,0 -0.613757,-1.82544 0.144028,-3.36226 0.757784,-1.53681 2.055745,-1.81156 2.055745,-1.81156"
                     id="path3494-6-3"/>
                  <g
                     id="g6362"
                     transform="matrix(0.0712579,0,0,0.0712579,70.976634,106.41148)">
                    <path
                       style="fill:none;stroke:${currentColor};stroke-width:10;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0.5"
                       d="m 193.77332,155.93139 c 0,0 14.73045,-43.81134 -3.45674,-80.695642 C 172.12939,38.351453 140.97773,31.757413 140.97773,31.757413"
                       id="path3494-6-7"/>
                    <path
                       style="fill:none;stroke:${currentColor};stroke-width:10;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0.8"
                       d="m 169.55756,135.38008 c 0,0 8.61317,-25.61733 -2.02123,-47.18428 C 156.90194,66.628849 138.68697,62.773187 138.68697,62.773187"
                       id="path3494-6-3-6"/>
                  </g>
                </g>
              </g>
            </g>
          </svg>`;
        case DeviceCategory.VoiceAssistant:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
             width="${currentDimension}"
             height="${currentDimension}"
             viewBox="0 0 24 24"
             version="1.1"
             id="svg4"
             inkscape:version="1.1 (c68e22c387, 2021-05-23)"
             xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:svg="http://www.w3.org/2000/svg">
            <defs
               id="defs8" />
            <g
               id="g987"
               transform="translate(-1e-7,0.2307691)">
              <path
                 d="m 4.2375002,10.443236 v 4.443182 c 0,1.616248 3.4521611,3.117188 7.7624998,3.117188 4.310339,0 7.7625,-1.50094 7.7625,-3.117188 v -4.443182 c -1.401666,1.347389 -4.36196,2.21662 -7.7625,2.21662 -3.4005391,0 -6.3608337,-0.869231 -7.7624998,-2.21662 z m -0.8625,-1.7911927 c 0,-2.3187809 3.8847726,-4.0078125 8.6249998,-4.0078125 4.740228,0 8.625,1.6890316 8.625,4.0078125 v 6.2343747 c 0,2.318781 -3.884772,4.007813 -8.625,4.007813 -4.7402273,0 -8.6249998,-1.689032 -8.6249998,-4.007813 z M 12,11.769231 c 4.310339,0 7.7625,-1.50094 7.7625,-3.1171877 0,-1.6162479 -3.452161,-3.1171875 -7.7625,-3.1171875 -4.3103387,0 -7.7624999,1.5009396 -7.7624999,3.1171875 0,1.6162477 3.4521612,3.1171877 7.7624999,3.1171877 z M 6.2373788,16.118273 C 6.0158545,16.027937 5.9071932,15.769268 5.9946765,15.54052 c 0.087483,-0.228747 0.3379834,-0.340952 0.5595076,-0.250616 0.7215457,0.294242 1.5695146,0.528924 2.49871,0.689073 0.2349229,0.04049 0.3935789,0.269965 0.354368,0.512549 C 9.3680513,16.734109 9.1458219,16.897939 8.910899,16.857449 7.9241915,16.687388 7.0179016,16.436565 6.2373788,16.118273 Z M 8.5500002,9.9879808 c -0.9245519,0 -1.725,-0.5510332 -1.725,-1.3359375 0,-0.7849044 0.8004481,-1.3359375 1.725,-1.3359375 0.9245519,0 1.7249998,0.5510331 1.7249998,1.3359375 0,0.7849043 -0.8004479,1.3359375 -1.7249998,1.3359375 z m 0,-0.890625 c 0.5044849,0 0.8625,-0.2464597 0.8625,-0.4453125 0,-0.1988529 -0.3580151,-0.4453125 -0.8625,-0.4453125 -0.5044849,0 -0.8625,0.2464596 -0.8625,0.4453125 0,0.1988528 0.3580151,0.4453125 0.8625,0.4453125 z M 15.45,9.9879808 c -0.924552,0 -1.725,-0.5510332 -1.725,-1.3359375 0,-0.7849044 0.800448,-1.3359375 1.725,-1.3359375 0.924553,0 1.725,0.5510331 1.725,1.3359375 0,0.7849043 -0.800447,1.3359375 -1.725,1.3359375 z m 0,-0.890625 c 0.504485,0 0.8625,-0.2464597 0.8625,-0.4453125 0,-0.1988529 -0.358015,-0.4453125 -0.8625,-0.4453125 -0.504485,0 -0.8625,0.2464596 -0.8625,0.4453125 0,0.1988528 0.358015,0.4453125 0.8625,0.4453125 z"
                 id="path2"
                 style="fill:${currentColor};stroke-width:0.87645" />
              <path
                 style="fill:#f2f2f2;stroke-width:0.136261;stroke-linecap:round;stroke-linejoin:round"
                 d="M 10.979927,17.956074 C 9.5340866,17.826561 8.8034525,17.712416 7.8898905,17.473324 6.4239545,17.08967 5.3126576,16.519196 4.6999157,15.83578 4.2513956,15.335528 4.2592128,15.391518 4.2742885,12.787248 l 0.01297,-2.240627 0.3573102,0.270281 c 0.8827554,0.667742 2.1606396,1.178461 3.7976176,1.517757 1.1953584,0.247761 2.0527817,0.32643 3.5578127,0.32643 1.505031,0 2.362455,-0.07867 3.557813,-0.32643 1.636978,-0.339296 2.914861,-0.850015 3.797617,-1.517757 l 0.357311,-0.270281 0.01297,2.240627 c 0.01508,2.60427 0.02289,2.54828 -0.425628,3.048532 -0.888178,0.990621 -2.784916,1.720545 -5.25994,2.024188 -0.619876,0.07605 -2.592531,0.137999 -3.060216,0.09611 z M 9.1612945,16.834728 C 9.5850992,16.651878 9.5067646,16.034279 9.0495861,15.954009 8.4893969,15.855653 7.6422065,15.637431 7.0682371,15.443648 6.6687763,15.308782 6.3644656,15.233507 6.2834034,15.249508 c -0.3088082,0.06095 -0.4441575,0.552248 -0.213727,0.775785 0.1212982,0.117669 0.8773453,0.400552 1.5514769,0.580502 0.3349118,0.0894 1.2013986,0.282717 1.332668,0.297326 0.021405,0.0024 0.1147678,-0.02839 0.2074732,-0.06839 z"
                 id="path1366" />
              <path
                 style="fill:#f9f9f9;stroke-width:0.136261;stroke-linecap:round;stroke-linejoin:round"
                 d="M 11.105729,11.71094 C 8.792491,11.612528 6.5994376,11.021881 5.343642,10.159057 4.1827526,9.3614395 3.9640319,8.5250072 4.7013185,7.7026815 c 0.9139604,-1.0193771 2.863034,-1.74959 5.4838575,-2.054506 0.739644,-0.086053 2.892808,-0.086053 3.632453,0 2.112207,0.2457418 3.805074,0.7732223 4.908291,1.5293746 0.402784,0.2760703 0.838201,0.7859876 0.939156,1.0998444 0.209919,0.6526142 -0.122716,1.2748397 -1.005913,1.8816625 -1.592135,1.093913 -4.461438,1.683423 -7.553434,1.551883 z M 9.080492,9.9444049 C 9.4665161,9.8504289 9.6351495,9.7624759 9.8973687,9.5183523 10.449202,9.0046001 10.414826,8.1728746 9.822586,7.7089558 9.5040542,7.4594405 9.1860873,7.3462129 8.7116125,7.3133394 7.6559318,7.2401975 6.8033275,7.8390016 6.8018,8.6546456 6.8000734,9.5767165 7.9401585,10.222014 9.080492,9.9444049 Z m 6.805462,0.021594 C 17.063066,9.7417965 17.583631,8.6586091 16.888505,7.8798959 16.513359,7.4596395 15.971339,7.267 15.291192,7.3121948 c -0.893489,0.059371 -1.51615,0.5447476 -1.563358,1.2186667 -0.02627,0.3750433 0.05296,0.6268559 0.278236,0.8843472 0.426828,0.487853 1.129467,0.6937203 1.879884,0.5507902 z"
                 id="path1405" />
            </g>
            <path
               style="fill:#f9f9f9;stroke-width:0.0842126;stroke-linecap:round;stroke-linejoin:round"
               d="M 8.2103172,9.2640501 C 8.1197416,9.2396649 8.005401,9.198908 7.956227,9.1734791 7.8437867,9.115334 7.703125,8.9504635 7.703125,8.8768166 c 0,-0.1610682 0.3163604,-0.3574292 0.6484375,-0.4024772 0.4860023,-0.065929 1.0390625,0.1536602 1.0390625,0.412553 0,0.073134 -0.1496365,0.2342995 -0.2798085,0.3013669 -0.2377502,0.1224939 -0.6104979,0.1538664 -0.9004993,0.075791 z"
               id="path1063" />
            <path
               style="fill:#f9f9f9;stroke-width:0.0842126;stroke-linecap:round;stroke-linejoin:round"
               d="M 15.131797,9.2803783 C 14.87402,9.2153389 14.609375,9.0160096 14.609375,8.8868924 c 0,-0.2588928 0.55306,-0.4784817 1.039062,-0.412553 0.33335,0.045221 0.648438,0.2414283 0.648438,0.4037875 0,0.1026146 -0.182182,0.2716032 -0.369073,0.3423445 -0.201336,0.076209 -0.609611,0.1069359 -0.796005,0.059907 z"
               id="path1102" />
          </svg>`;
        case DeviceCategory.VideoStreaming:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
            width="${currentDimension}"
            height="${currentDimension}"
            viewBox="0 0 24 24"
            version="1.1"
            id="svg8"
            sodipodi:docname="streaming_video_device.svg"
            inkscape:version="1.1 (c68e22c387, 2021-05-23)"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg">
            <g
               id="g1133">
              <g
                 id="g912"
                 transform="matrix(0.91841076,0,0,0.75743738,0.97907088,2.7213921)">
                <path
                   clip-rule="evenodd"
                   d="M 1.5,5.5 C 1.5,4.11929 2.61929,3 4,3 h 16 c 1.3807,0 2.5,1.11929 2.5,2.5 v 11 C 22.5,17.8807 21.3807,19 20,19 H 4 C 2.61929,19 1.5,17.8807 1.5,16.5 Z M 4,4 C 3.17157,4 2.5,4.67157 2.5,5.5 v 11 C 2.5,17.3284 3.17157,18 4,18 h 16 c 0.8284,0 1.5,-0.6716 1.5,-1.5 V 5.5 C 21.5,4.67157 20.8284,4 20,4 Z"
                   fill="${currentColor}"
                   id="path2" />
                <path
                   clip-rule="evenodd"
                   d="M 9.76909,7.05651 C 9.93446,6.97041 10.134,6.98347 10.2867,7.09038 l 5,3.50002 C 15.4204,10.6839 15.5,10.8368 15.5,11 c 0,0.1632 -0.0796,0.3161 -0.2133,0.4096 l -5,3.5 C 10.134,15.0165 9.93446,15.0296 9.76909,14.9435 9.60373,14.8574 9.5,14.6864 9.5,14.5 v -7 C 9.5,7.31356 9.60373,7.14261 9.76909,7.05651 Z M 10.5,8.46033 V 13.5397 L 14.1281,11 Z"
                   fill="${currentColor}"
                   id="path4" />
                <path
                   clip-rule="evenodd"
                   d="M 18,21.5 H 6 v -1 h 12 z"
                   fill="${currentColor}"
                   id="path6" />
              </g>
              <path
                 style="fill:#f9f9f9;stroke-width:0.0777347;stroke-linecap:round;stroke-linejoin:round"
                 d="M 4.4789935,16.325844 C 4.3722475,16.313261 4.2359495,16.287321 4.1761088,16.2682 3.8131831,16.152234 3.4732117,15.856922 3.3627492,15.561685 l -0.060412,-0.161465 0.00748,-4.390014 0.00749,-4.3900137 0.071002,-0.1442308 C 3.5427159,6.1623051 3.9502714,5.871604 4.3380718,5.7985152 c 0.2149333,-0.040508 15.1089232,-0.040508 15.3238562,0 0.387801,0.073089 0.795356,0.3637899 0.949763,0.6774463 l 0.071,0.1442308 0.0075,4.3900137 0.0075,4.390014 -0.06041,0.161465 c -0.118475,0.31665 -0.465529,0.603804 -0.879985,0.728102 -0.161458,0.04842 -0.575595,0.05141 -7.627458,0.05507 -4.1012022,0.0021 -7.5440686,-0.0064 -7.6508145,-0.01901 z M 12.691165,12.72903 c 1.285725,-0.741033 2.379869,-1.388647 2.431431,-1.439143 0.08138,-0.07969 0.09375,-0.111117 0.09375,-0.23811 0,-0.12726 -0.0122,-0.158077 -0.09375,-0.236802 -0.05156,-0.04978 -1.060817,-0.648314 -2.242788,-1.3300818 -2.836572,-1.6361524 -2.532156,-1.4746216 -2.752575,-1.4605864 -0.2261527,0.0144 -0.3732571,0.1115219 -0.4191372,0.2767235 -0.040127,0.1444854 -0.041309,5.3453607 -0.00125,5.4895737 0.054694,0.196889 0.2809916,0.32827 0.5179712,0.300718 0.103098,-0.01199 0.593194,-0.282692 2.466346,-1.362292 z"
                 id="path969" />
              <path
                 style="fill:#f9f9f9;stroke-width:0.0777347;stroke-linecap:round;stroke-linejoin:round"
                 d="m 10.629808,11.065818 c 0,-1.041008 0.0073,-1.8927411 0.01618,-1.8927411 0.03152,0 3.2431,1.8608711 3.243265,1.8792321 9.3e-5,0.01026 -0.704039,0.424759 -1.564736,0.921108 -0.860697,0.496349 -1.59411,0.921057 -1.629807,0.943797 l -0.0649,0.04134 z"
                 id="path1008" />
            </g>
          </svg>`;
        case DeviceCategory.Generic:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
            width="${currentDimension}"
            height="${currentDimension}"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            viewBox="0 0 512 512"
            version="1.1"
            id="svg4"
            sodipodi:docname="generic_g.svg"
            inkscape:version="1.0.2 (e86c870879, 2021-01-15)">
            <path
              d="M 91.658291,145.81241 H 418.93101 V 109.25513 H 91.658291 c -19.999999,0 -36.363636,16.45077 -36.363636,36.55728 v 219.34375 c 0,20.10651 16.363637,36.55729 36.363636,36.55729 H 164.38556 V 365.15616 H 91.658291 V 145.81241 M 273.47647,255.48429 h -72.72728 v 32.53599 c -11.0909,10.05325 -18.18182,24.31061 -18.18182,40.57859 0,16.268 7.09092,30.52533 18.18182,40.57858 v 32.536 h 72.72728 v -32.536 c 11.09092,-10.05325 18.18182,-24.49338 18.18182,-40.57858 0,-16.08521 -7.0909,-30.52534 -18.18182,-40.57859 v -32.53599 m -36.36364,100.53255 c -15.0909,0 -27.27272,-12.2467 -27.27272,-27.41797 0,-15.17127 12.18182,-27.41796 27.27272,-27.41796 15.09091,0 27.27273,12.24669 27.27273,27.41796 0,15.17127 -12.18182,27.41797 -27.27273,27.41797 M 437.11284,182.3697 H 328.02193 c -9.09092,0 -18.18182,9.13933 -18.18182,18.27865 v 182.78646 c 0,9.13931 9.0909,18.27864 18.18182,18.27864 h 109.09091 c 9.0909,0 18.18181,-9.13933 18.18181,-18.27864 V 200.64835 c 0,-9.13932 -9.09091,-18.27865 -18.18181,-18.27865 M 418.93101,365.15616 H 346.20375 V 218.927 h 72.72726 z"
              id="path2"
              style="fill:${currentColor};stroke-width:18.2302;fill-opacity:1" />
          </svg>`;
        case DeviceCategory.SkyGlass:
          return `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="${currentDimension}"
          height="${currentDimension}">
            <path d="M 365.096 353.056 C 359.581 365.262 352.162 370.459 338.534 370.16 C 330.612 369.961 324.306 368.06 321.192 366.469 L 352.162 301.333 L 301.352 221.707 L 270.392 254.514 L 314.079 320.242 C 310.48 321.941 302.658 324.141 293.238 324.141 C 278.904 324.141 270.09 318.834 263.377 308.437 L 227.094 251.416 L 272.191 202.198 C 281.71 191.893 290.83 185.893 307.265 185.893 C 312.572 185.893 319.893 187.185 322.9 188.092 L 369.303 263.518 L 392.949 207.802 C 400.97 188.593 413.598 186.693 423.018 186.885 C 428.426 186.993 435.139 188.785 438.145 190.393 L 365.089 353.056 L 365.096 353.056 Z M 225.35 304.932 C 225.35 316.943 220.635 323.848 197.188 323.848 C 194.081 323.848 191.374 323.642 188.767 323.249 L 188.767 164.184 C 188.767 152.073 192.874 139.467 216.529 139.467 C 219.527 139.467 222.533 139.767 225.35 140.368 L 225.35 304.939 L 225.35 304.932 Z M 132.582 322.849 C 111.034 325.149 79.866 322.442 65.038 320.743 C 64.493 318.175 64.193 315.559 64.139 312.937 C 64.139 293.435 74.659 288.935 84.578 288.935 C 94.899 288.935 111.133 290.728 123.162 290.728 C 136.489 290.728 140.502 286.228 140.502 281.929 C 140.502 276.323 135.188 274.025 124.867 272.024 L 96.607 266.619 C 73.15 262.119 60.632 245.907 60.632 228.706 C 60.632 207.802 75.457 190.292 104.619 187.093 C 126.667 184.686 153.53 187.393 168.257 189.185 C 168.755 191.691 169.057 194.092 169.057 196.69 C 169.057 216.201 158.736 220.399 148.815 220.399 C 141.202 220.399 129.374 218.999 115.847 218.999 C 102.113 218.999 96.406 222.806 96.406 227.405 C 96.406 232.305 101.813 234.303 110.433 235.812 L 137.388 240.811 C 165.049 245.907 176.977 261.62 176.977 280.921 C 176.977 303.331 162.342 319.643 132.582 322.849 Z" fill-rule="evenodd"  style="fill:${currentColor};"/>
            <g id="Layer_1" transform="matrix(10.371932, 0, 0, 10.591592, -9.072021, 10.237946)" style="">
              <path d="M1,38h23v3H12v2h26v-2H26v-3h23V8H1V38z M3,10h44v26H3V10z" style="fill:${currentColor};stroke-width:18.2302;fill-opacity:1"/>
            </g>
          </svg>
          `;
        case DeviceCategory.SkyStream:
          return `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" style="shape-rendering: geometricprecision;" width="${currentDimension}"
          height="${currentDimension}">
            <g id="Layer_x0020_1" transform="matrix(0.097786, 0, 0, 0.079667, -78.954637, 48.451791)" style="fill:${currentColor};">
              <g id="_378501112" transform="matrix(1, 0, 0, 1, 0, -615.060059)">
                <path class="fil0" d="M880 4127l4960 0c44,0 80,36 80,80l0 1380c0,44 -36,80 -80,80l-4960 0c-44,0 -80,-36 -80,-80l0 -1380c0,-44 36,-80 80,-80zm4880 160l-4800 0 0 1220 4800 0 0 -1220z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
                <path class="fil0" d="M1584 4817c-44,0 -80,36 -80,80 0,44 36,80 80,80l720 0c44,0 80,-36 80,-80 0,-44 -36,-80 -80,-80l-720 0z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
                <path class="fil0" d="M3000 4817c-44,0 -80,36 -80,80 0,44 36,80 80,80l720 0c44,0 80,-36 80,-80 0,-44 -36,-80 -80,-80l-720 0z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
                <path class="fil0" d="M4416 4817c-44,0 -80,36 -80,80 0,44 36,80 80,80l720 0c44,0 80,-36 80,-80 0,-44 -36,-80 -80,-80l-720 0z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
                <path class="fil0" d="M 2429.312 3260.397 C 2411.583 3284.945 2417.719 3319.04 2442.268 3336.087 C 2466.816 3353.816 2500.911 3347.679 2517.958 3323.131 C 2614.788 3187.433 2743.666 3075.603 2893.002 2998.548 C 3036.882 2924.222 3199.855 2882.626 3373.056 2882.626 C 3546.259 2882.626 3709.914 2924.222 3853.112 2998.548 C 4002.447 3075.603 4131.326 3187.433 4228.155 3323.131 C 4245.885 3347.679 4279.298 3353.134 4303.845 3336.087 C 4328.394 3318.358 4333.849 3284.945 4316.802 3260.397 C 4209.744 3110.379 4067.909 2986.956 3902.891 2901.719 C 3744.009 2819.891 3563.306 2773.523 3372.375 2773.523 C 3181.443 2773.523 3001.423 2819.891 2841.859 2901.719 C 2676.84 2986.956 2535.006 3110.379 2427.948 3260.397 L 2429.312 3260.397 Z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
                <path class="fil0" d="M 2687.068 3614.628 C 2669.339 3639.176 2675.477 3673.271 2700.025 3690.318 C 2724.573 3708.048 2758.668 3701.911 2775.715 3677.363 C 2843.223 3582.579 2933.234 3504.161 3037.563 3450.291 C 3137.802 3398.467 3251.679 3369.145 3373.056 3369.145 C 3494.435 3369.145 3608.311 3398.467 3708.55 3450.291 C 3812.88 3504.161 3902.891 3581.897 3970.399 3677.363 C 3988.128 3701.911 4021.541 3707.365 4046.089 3690.318 C 4070.637 3672.589 4076.092 3639.176 4059.045 3614.628 C 3981.309 3505.524 3878.343 3416.196 3758.328 3354.143 C 3642.406 3294.818 3511.482 3260.724 3373.056 3260.724 C 3234.632 3260.724 3103.707 3294.136 2987.785 3354.143 C 2867.771 3416.196 2764.805 3505.524 2687.068 3614.628 Z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
                <path class="fil0" d="M 2962.092 3917.036 C 2944.363 3941.584 2950.5 3975.679 2975.049 3992.726 C 2999.597 4010.455 3033.692 4004.318 3050.739 3979.77 C 3087.561 3928.628 3135.976 3886.35 3192.574 3857.029 C 3246.443 3829.071 3308.496 3813.387 3373.959 3813.387 C 3439.42 3813.387 3501.473 3829.071 3555.343 3857.029 C 3611.94 3886.35 3660.355 3928.628 3697.177 3979.77 C 3714.906 4004.318 3748.319 4009.774 3772.868 3992.726 C 3797.416 3974.997 3802.871 3941.584 3785.824 3917.036 C 3738.773 3851.573 3677.402 3797.704 3605.121 3760.881 C 3535.568 3724.741 3457.15 3704.966 3373.959 3704.966 C 3290.767 3704.966 3212.348 3725.423 3142.795 3760.881 C 3071.196 3797.704 3009.144 3851.573 2962.092 3917.036 Z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
              </g>
            </g>
            <rect class="fil1" width="6720" height="6720" style="fill: none;" x="-3139.732" y="-3073.606"/>
            <path d="M 371.031 227.614 C 365.516 239.82 358.097 245.017 344.469 244.718 C 336.547 244.519 330.241 242.618 327.127 241.027 L 358.097 175.891 L 307.287 96.265 L 276.327 129.072 L 320.014 194.8 C 316.415 196.499 308.593 198.699 299.173 198.699 C 284.839 198.699 276.025 193.392 269.312 182.995 L 233.029 125.974 L 278.126 76.756 C 287.645 66.451 296.765 60.451 313.2 60.451 C 318.507 60.451 325.828 61.743 328.835 62.65 L 375.238 138.076 L 398.884 82.36 C 406.905 63.151 419.533 61.251 428.953 61.443 C 434.361 61.551 441.074 63.343 444.08 64.951 L 371.024 227.614 L 371.031 227.614 Z M 231.285 179.49 C 231.285 191.501 226.57 198.406 203.123 198.406 C 200.016 198.406 197.309 198.2 194.702 197.807 L 194.702 38.742 C 194.702 26.631 198.809 14.025 222.464 14.025 C 225.462 14.025 228.468 14.325 231.285 14.926 L 231.285 179.497 L 231.285 179.49 Z M 138.517 197.407 C 116.969 199.707 85.801 197 70.973 195.301 C 70.428 192.733 70.128 190.117 70.074 187.495 C 70.074 167.993 80.594 163.493 90.513 163.493 C 100.834 163.493 117.068 165.286 129.097 165.286 C 142.424 165.286 146.437 160.786 146.437 156.487 C 146.437 150.881 141.123 148.583 130.802 146.582 L 102.542 141.177 C 79.085 136.677 66.567 120.465 66.567 103.264 C 66.567 82.36 81.392 64.85 110.554 61.651 C 132.602 59.244 159.465 61.951 174.192 63.743 C 174.69 66.249 174.992 68.65 174.992 71.248 C 174.992 90.759 164.671 94.957 154.75 94.957 C 147.137 94.957 135.309 93.557 121.782 93.557 C 108.048 93.557 102.341 97.364 102.341 101.963 C 102.341 106.863 107.748 108.861 116.368 110.37 L 143.323 115.369 C 170.984 120.465 182.912 136.178 182.912 155.479 C 182.912 177.889 168.277 194.201 138.517 197.407 Z" fill-rule="evenodd" style="fill:${currentColor};"/>
          </svg>
          `;
        case DeviceCategory.SkyQ:
        case DeviceCategory.SkyQBlack:
        case DeviceCategory.SkyQTitan:
        case DeviceCategory.SkyQPlatinum:
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" style="shape-rendering: geometricprecision;"
              width="${currentDimension}"
              height="${currentDimension}"
            >
              <g id="Layer_x0020_1" transform="matrix(0.097786, 0, 0, 0.079667, -78.140504, 41.710076)" style="fill:${currentColor};">
                <g id="_378501112">
                  <path class="fil0" d="M 880 3813.2 L 5840 3813.2 C 5884 3813.2 5920 3849.2 5920 3893.2 L 5920 5273.2 C 5920 5317.2 5884 5353.2 5840 5353.2 L 880 5353.2 C 836 5353.2 800 5317.2 800 5273.2 L 800 3893.2 C 800 3849.2 836 3813.2 880 3813.2 Z M 5760 3973.2 L 960 3973.2 L 960 5193.2 L 5760 5193.2 L 5760 3973.2 Z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
                </g>
              </g>
              <rect class="fil1" width="6720" height="6720" style="fill: none;" x="-3138.918" y="-3076.348"/>
              <path d="M 371.845 283.553 C 366.33 295.759 358.911 300.956 345.283 300.657 C 337.361 300.458 331.055 298.557 327.941 296.966 L 358.911 231.83 L 308.101 152.204 L 277.141 185.011 L 320.828 250.739 C 317.229 252.438 309.407 254.638 299.987 254.638 C 285.653 254.638 276.839 249.331 270.126 238.934 L 233.843 181.913 L 278.94 132.695 C 288.459 122.39 297.579 116.39 314.014 116.39 C 319.321 116.39 326.642 117.682 329.649 118.589 L 376.052 194.015 L 399.698 138.299 C 407.719 119.09 420.347 117.19 429.767 117.382 C 435.175 117.49 441.888 119.282 444.894 120.89 L 371.838 283.553 L 371.845 283.553 Z M 232.099 235.429 C 232.099 247.44 227.384 254.345 203.937 254.345 C 200.83 254.345 198.123 254.139 195.516 253.746 L 195.516 94.681 C 195.516 82.57 199.623 69.964 223.278 69.964 C 226.276 69.964 229.282 70.264 232.099 70.865 L 232.099 235.436 L 232.099 235.429 Z M 139.331 253.346 C 117.783 255.646 86.615 252.939 71.787 251.24 C 71.242 248.672 70.942 246.056 70.888 243.434 C 70.888 223.932 81.408 219.432 91.327 219.432 C 101.648 219.432 117.882 221.225 129.911 221.225 C 143.238 221.225 147.251 216.725 147.251 212.426 C 147.251 206.82 141.937 204.522 131.616 202.521 L 103.356 197.116 C 79.899 192.616 67.381 176.404 67.381 159.203 C 67.381 138.299 82.206 120.789 111.368 117.59 C 133.416 115.183 160.279 117.89 175.006 119.682 C 175.504 122.188 175.806 124.589 175.806 127.187 C 175.806 146.698 165.485 150.896 155.564 150.896 C 147.951 150.896 136.123 149.496 122.596 149.496 C 108.862 149.496 103.155 153.303 103.155 157.902 C 103.155 162.802 108.562 164.8 117.182 166.309 L 144.137 171.308 C 171.798 176.404 183.726 192.117 183.726 211.418 C 183.726 233.828 169.091 250.14 139.331 253.346 Z" fill-rule="evenodd" style="fill:${currentColor};"/>
              <path d="M 239.2 414.144 L 242.058 411.132 L 252.269 421.011 L 249.389 424.001 Z M 237.384 423.912 Q 232.954 423.912 229.498 421.919 Q 226.043 419.925 224.072 416.304 Q 222.1 412.682 222.1 407.787 Q 222.1 402.892 224.072 399.27 Q 226.043 395.649 229.498 393.655 Q 232.954 391.662 237.384 391.662 Q 241.814 391.662 245.269 393.655 Q 248.725 395.649 250.696 399.27 Q 252.667 402.892 252.667 407.787 Q 252.667 412.682 250.696 416.304 Q 248.725 419.925 245.269 421.919 Q 241.814 423.912 237.384 423.912 Z M 237.384 419.239 Q 240.751 419.239 243.01 417.732 Q 245.269 416.226 246.41 413.623 Q 247.551 411.021 247.551 407.787 Q 247.551 404.553 246.41 401.95 Q 245.269 399.348 243.01 397.842 Q 240.751 396.335 237.384 396.335 Q 234.017 396.335 231.758 397.842 Q 229.498 399.348 228.358 401.95 Q 227.217 404.553 227.217 407.787 Q 227.217 411.021 228.358 413.623 Q 229.498 416.226 231.758 417.732 Q 234.017 419.239 237.384 419.239 Z" transform="matrix(2.83804, 0, 0, 2.185428, -429.208618, -481.617584)" style="fill:${currentColor};"/>
            </svg>
          `;
        case DeviceCategory.SkyQMini:
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" style="shape-rendering: geometricprecision;" 
              width="${currentDimension}"
              height="${currentDimension}"
            >
              <g id="Layer_x0020_1" transform="matrix(0.097786, 0, 0, 0.079667, -78.140504, 41.710076)" style="fill:${currentColor};">
                <g id="_378501112">
                  <path class="fil0" d="M 880 3813.2 L 5840 3813.2 C 5884 3813.2 5920 3849.2 5920 3893.2 L 5920 5273.2 C 5920 5317.2 5884 5353.2 5840 5353.2 L 880 5353.2 C 836 5353.2 800 5317.2 800 5273.2 L 800 3893.2 C 800 3849.2 836 3813.2 880 3813.2 Z M 5760 3973.2 L 960 3973.2 L 960 5193.2 L 5760 5193.2 L 5760 3973.2 Z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
                </g>
              </g>
              <rect class="fil1" width="6720" height="6720" style="fill: none;" x="-3138.918" y="-3076.348"/>
              <path d="M 371.845 283.553 C 366.33 295.759 358.911 300.956 345.283 300.657 C 337.361 300.458 331.055 298.557 327.941 296.966 L 358.911 231.83 L 308.101 152.204 L 277.141 185.011 L 320.828 250.739 C 317.229 252.438 309.407 254.638 299.987 254.638 C 285.653 254.638 276.839 249.331 270.126 238.934 L 233.843 181.913 L 278.94 132.695 C 288.459 122.39 297.579 116.39 314.014 116.39 C 319.321 116.39 326.642 117.682 329.649 118.589 L 376.052 194.015 L 399.698 138.299 C 407.719 119.09 420.347 117.19 429.767 117.382 C 435.175 117.49 441.888 119.282 444.894 120.89 L 371.838 283.553 L 371.845 283.553 Z M 232.099 235.429 C 232.099 247.44 227.384 254.345 203.937 254.345 C 200.83 254.345 198.123 254.139 195.516 253.746 L 195.516 94.681 C 195.516 82.57 199.623 69.964 223.278 69.964 C 226.276 69.964 229.282 70.264 232.099 70.865 L 232.099 235.436 L 232.099 235.429 Z M 139.331 253.346 C 117.783 255.646 86.615 252.939 71.787 251.24 C 71.242 248.672 70.942 246.056 70.888 243.434 C 70.888 223.932 81.408 219.432 91.327 219.432 C 101.648 219.432 117.882 221.225 129.911 221.225 C 143.238 221.225 147.251 216.725 147.251 212.426 C 147.251 206.82 141.937 204.522 131.616 202.521 L 103.356 197.116 C 79.899 192.616 67.381 176.404 67.381 159.203 C 67.381 138.299 82.206 120.789 111.368 117.59 C 133.416 115.183 160.279 117.89 175.006 119.682 C 175.504 122.188 175.806 124.589 175.806 127.187 C 175.806 146.698 165.485 150.896 155.564 150.896 C 147.951 150.896 136.123 149.496 122.596 149.496 C 108.862 149.496 103.155 153.303 103.155 157.902 C 103.155 162.802 108.562 164.8 117.182 166.309 L 144.137 171.308 C 171.798 176.404 183.726 192.117 183.726 211.418 C 183.726 233.828 169.091 250.14 139.331 253.346 Z" fill-rule="evenodd" style="fill:${currentColor};"/>
              <text style="white-space: pre; fill:${currentColor}; font-family: Arial, sans-serif; font-size: 44.3px;" x="399.068" y="405.554" transform="matrix(2.973193, 0, 0, 1.666664, -802.642407, -245.872578)">-</text>
            </svg>
            `;
        case DeviceCategory.SkyBooster:
          return `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" 
            width="${currentDimension}"
            height="${currentDimension}"
          >
            <g id="Layer_x0020_1" transform="matrix(0.097786, 0, 0, 0.079667, -79.01571, 30.696488)" style="fill:${currentColor};">
              <g id="_378501112">
                <path class="fil0" d="M 880 3813.2 L 5840 3813.2 C 5884 3813.2 5920 3849.2 5920 3893.2 L 5920 5273.2 C 5920 5317.2 5884 5353.2 5840 5353.2 L 880 5353.2 C 836 5353.2 800 5317.2 800 5273.2 L 800 3893.2 C 800 3849.2 836 3813.2 880 3813.2 Z M 5760 3973.2 L 960 3973.2 L 960 5193.2 L 5760 5193.2 L 5760 3973.2 Z" style="stroke-width: 18.2302; fill-opacity: 1; fill-rule: nonzero;"/>
              </g>
            </g>
            <path d="M 370.97 272.54 C 365.455 284.746 358.036 289.943 344.408 289.644 C 336.486 289.445 330.18 287.544 327.066 285.953 L 358.036 220.817 L 307.226 141.191 L 276.266 173.998 L 319.953 239.726 C 316.354 241.425 308.532 243.625 299.112 243.625 C 284.778 243.625 275.964 238.318 269.251 227.921 L 232.968 170.9 L 278.065 121.682 C 287.584 111.377 296.704 105.377 313.139 105.377 C 318.446 105.377 325.767 106.669 328.774 107.576 L 375.177 183.002 L 398.823 127.286 C 406.844 108.077 419.472 106.177 428.892 106.369 C 434.3 106.477 441.013 108.269 444.019 109.877 L 370.963 272.54 L 370.97 272.54 Z M 231.224 224.416 C 231.224 236.427 226.509 243.332 203.062 243.332 C 199.955 243.332 197.248 243.126 194.641 242.733 L 194.641 83.668 C 194.641 71.557 198.748 58.951 222.403 58.951 C 225.401 58.951 228.407 59.251 231.224 59.852 L 231.224 224.423 L 231.224 224.416 Z M 138.456 242.333 C 116.908 244.633 85.74 241.926 70.912 240.227 C 70.367 237.659 70.067 235.043 70.013 232.421 C 70.013 212.919 80.533 208.419 90.452 208.419 C 100.773 208.419 117.007 210.212 129.036 210.212 C 142.363 210.212 146.376 205.712 146.376 201.413 C 146.376 195.807 141.062 193.509 130.741 191.508 L 102.481 186.103 C 79.024 181.603 66.506 165.391 66.506 148.19 C 66.506 127.286 81.331 109.776 110.493 106.577 C 132.541 104.17 159.404 106.877 174.131 108.669 C 174.629 111.175 174.931 113.576 174.931 116.174 C 174.931 135.685 164.61 139.883 154.689 139.883 C 147.076 139.883 135.248 138.483 121.721 138.483 C 107.987 138.483 102.28 142.29 102.28 146.889 C 102.28 151.789 107.687 153.787 116.307 155.296 L 143.262 160.295 C 170.923 165.391 182.851 181.104 182.851 200.405 C 182.851 222.815 168.216 239.127 138.456 242.333 Z" fill-rule="evenodd" style="fill:${currentColor};"/>
            <ellipse style="fill:${currentColor};" cx="431.955" cy="396.726" rx="20.064" ry="18.459"/>
            <rect x="345.938" y="392.063" width="35.313" height="10.433" style="fill:${currentColor};"/>
            <rect x="304.061" y="392.063" width="35.313" height="10.433" style="fill:${currentColor};"/>
            <rect x="261.862" y="392.063" width="35.313" height="10.433" style="fill:${currentColor};"/>
          </svg>
            `;

        default:
          return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          <svg
            width="${currentDimension}"
            height="${currentDimension}"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            viewBox="0 0 512 512"
            version="1.1"
            id="svg4"
            sodipodi:docname="generic_g.svg"
            inkscape:version="1.0.2 (e86c870879, 2021-01-15)">
            <path
              d="M 91.658291,145.81241 H 418.93101 V 109.25513 H 91.658291 c -19.999999,0 -36.363636,16.45077 -36.363636,36.55728 v 219.34375 c 0,20.10651 16.363637,36.55729 36.363636,36.55729 H 164.38556 V 365.15616 H 91.658291 V 145.81241 M 273.47647,255.48429 h -72.72728 v 32.53599 c -11.0909,10.05325 -18.18182,24.31061 -18.18182,40.57859 0,16.268 7.09092,30.52533 18.18182,40.57858 v 32.536 h 72.72728 v -32.536 c 11.09092,-10.05325 18.18182,-24.49338 18.18182,-40.57858 0,-16.08521 -7.0909,-30.52534 -18.18182,-40.57859 v -32.53599 m -36.36364,100.53255 c -15.0909,0 -27.27272,-12.2467 -27.27272,-27.41797 0,-15.17127 12.18182,-27.41796 27.27272,-27.41796 15.09091,0 27.27273,12.24669 27.27273,27.41796 0,15.17127 -12.18182,27.41797 -27.27273,27.41797 M 437.11284,182.3697 H 328.02193 c -9.09092,0 -18.18182,9.13933 -18.18182,18.27865 v 182.78646 c 0,9.13931 9.0909,18.27864 18.18182,18.27864 h 109.09091 c 9.0909,0 18.18181,-9.13933 18.18181,-18.27864 V 200.64835 c 0,-9.13932 -9.09091,-18.27865 -18.18181,-18.27865 M 418.93101,365.15616 H 346.20375 V 218.927 h 72.72726 z"
              id="path2"
              style="fill:${currentColor};stroke-width:18.2302;fill-opacity:1" />
          </svg>`;
      }
  }
}
