import { Customer } from '../entities/Customer';
import { Glossary } from '../entities/Glossary';
import { InterceptorRepository } from './InterceptorRepository';

class GlossaryRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '',
    });
  }

  async getGlossary(customerId: Customer['id'], area: string | null, product: string | null): Promise<Glossary> {
    let url = `frontend/dashboard/glossary`;
    const params = [];
    if (area) {
      params.push(`area=${area}`);
    }
    if (product) {
      params.push(`product=${product}`);
    }
    if (customerId) {
      params.push(`customer_name=${customerId}`);
    }
    if (params.length) {
      url += '?' + params.join('&');
    }
    const { data } = await this.axios.get(url);
    return data;
  }
}

export default new GlossaryRepository();
