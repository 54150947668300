import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Action, actionId, ActionInputType } from '../entities/Action';
import { RequestError } from '../entities/RequestError';
import { useCustomer } from '../hooks/useCustomer';
import { useHubActions } from '../hooks/useHubActions';
import LoaderView from './LoaderView';

export interface OnDemandActionsButtonProps {
  onClick: (e: React.MouseEvent) => void;
  children: React.ReactNode;
}

interface OnDemandActionsProps {
  customerId: string;
  action: Action;
  ButtonComponent: (props: OnDemandActionsButtonProps) => JSX.Element;
  closeAfterRun?: boolean;
}

export default function OnDemandActionsDialog(props: OnDemandActionsProps): JSX.Element {
  const { action, customerId, ButtonComponent } = props;

  const { t } = useTranslation();
  const { readCustomerActionListRequest: actionsRequest } = useCustomer();
  const { runHubAction, runHubActionRequests } = useHubActions();

  const runHubActionRequest = runHubActionRequests[actionId(action.action_id, undefined)];
  const title = action.action_description || action.action_title;

  const [open, setOpen] = useState(false);
  const [actionRunning, setActionRunning] = useState(false);
  const [actionAlert, setActionAlert] = useState<string | undefined>();
  const [checked, setChecked] = React.useState(true);

  const populateFormData = useCallback(() => {
    const obj = Object.create({});
    action.input_variables?.forEach((el) => {
      obj[el.name] = {
        value: '',
        errors: false,
        helperText: '',
        label: '',
      };
    });
    return obj;
  }, [action.input_variables]);
  const [formData, setFormData] = useState(populateFormData);

  const handleClickOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    setFormData(populateFormData);
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    if (!runHubActionRequest?.inProgress) {
      setActionAlert(undefined);
      setOpen(false);
    }
  }, [runHubActionRequest?.inProgress]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const validateInputs = useCallback(() => {
    let errors = false;
    action.input_variables?.forEach((inputVariable) => {
      if (inputVariable.required && formData[inputVariable.name].value === '') {
        setFormData({
          ...formData,
          [inputVariable.name]: {
            value: formData[inputVariable.name].value,
            errors: true,
            helperText: inputVariable.required ? 'Valore obbligatorio' : '',
            label: '',
          },
        });
        errors = true;
      }
    });
    return !errors;
  }, [formData, action.input_variables]);

  const run = useCallback(async () => {
    if (customerId) {
      setActionRunning(true);

      const inputVarsPayload = Object.create({});
      action.input_variables?.forEach((inputVariable) => {
        inputVarsPayload[inputVariable.name] = formData[inputVariable.name].value;
      });

      const actionResponse = await runHubAction(customerId, action.action_id, undefined, {
        action_id: action.action_id,
        input_variables: inputVarsPayload,
      });

      if (props.closeAfterRun) {
        setActionRunning(false);
        handleClose();
        return;
      }

      if (actionResponse) {
        if (Number((actionResponse as RequestError).status) >= 400) {
          setActionAlert((actionResponse as RequestError).message);
          setActionRunning(false);
          return;
        }
      }
    } else {
      throw new Error('customer.id is not defined');
    }

    handleClose();
    setActionRunning(false);
  }, [formData, action.action_id, customerId, props.closeAfterRun, runHubAction, action.input_variables, handleClose]);

  return (
    <React.Fragment>
      <ButtonComponent onClick={handleClickOpen}>{action.action_title}</ButtonComponent>
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose} aria-labelledby="action-dialog" scroll="paper">
        <LoaderView condition={!actionsRequest.inProgress} minHeight={400}>
          <DialogTitle id="action-dialog">{title}</DialogTitle>
          {actionAlert ? (
            <DialogContent>
              <Alert severity="warning">{actionAlert}</Alert>
            </DialogContent>
          ) : null}
          {action.action_description && !action.frontend_modal_options ? (
            <DialogContent>
              <DialogContentText>{action.action_description}</DialogContentText>
            </DialogContent>
          ) : null}
          <Box
            sx={{
              width: '100%',
              pl: 8,
              pr: 8,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            {action.input_variables &&
              action.input_variables.map((inputVariable) => {
                switch (inputVariable.type) {
                  case ActionInputType.Text:
                    return (
                      <TextField
                        key={inputVariable.name}
                        required={inputVariable.required}
                        variant="standard"
                        size="small"
                        placeholder={`${inputVariable.alias} ${inputVariable.required ? ' *' : ''}`}
                        error={formData[inputVariable.name].errors}
                        helperText={formData[inputVariable.name].helperText}
                        value={formData[inputVariable.name].value}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [inputVariable.name]: {
                              value: e.target.value,
                              errors: false,
                              helperText: '',
                            },
                          });
                        }}
                      />
                    );
                  case ActionInputType.Select:
                    return (
                      inputVariable.possible_values?.length && (
                        <FormControl key={inputVariable.name} sx={{ minWidth: 170, maxWidth: 'fit-content' }}>
                          <InputLabel id="demo-simple-select-autowidth-label">{inputVariable.name}</InputLabel>
                          <Select
                            label={inputVariable.alias}
                            required={inputVariable.required}
                            value={formData[inputVariable.name].value}
                            error={formData[inputVariable.name].errors}
                            defaultValue={inputVariable.default_value}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                [inputVariable.name]: {
                                  value: e.target.value,
                                  error: false,
                                  helperText: '',
                                },
                              });
                            }}
                          >
                            {inputVariable.possible_values.map((possibleValue, index) => (
                              <MenuItem key={`${possibleValue}-item-${index}`} value={possibleValue}>
                                {possibleValue}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            <Typography color="error" fontSize={10}>
                              {formData[inputVariable.name].helperText}
                            </Typography>
                          </FormHelperText>
                        </FormControl>
                      )
                    );
                  case ActionInputType.Checkbox:
                    return (
                      <FormGroup key={inputVariable.name}>
                        <Typography>{inputVariable.alias}</Typography>
                        <FormControlLabel
                          sx={{ mt: 1 }}
                          control={<Checkbox name="opzione" id="opzione1" onChange={handleChange} checked={checked} />}
                          label="Si"
                        />
                        <FormControlLabel
                          sx={{ mt: 1 }}
                          control={<Checkbox name="opzione" id="opzione2" disabled={checked} />}
                          label="No"
                        />
                      </FormGroup>
                    );
                  default:
                    return <></>;
                }
              })}
            <DialogActions sx={{ width: '100%', mt: 4 }}>
              <Button disabled={runHubActionRequest?.inProgress || actionRunning} onClick={handleClose}>
                {t('actions:cancel')}
              </Button>
              <LoadingButton
                onClick={() => {
                  if (validateInputs()) run();
                }}
                loading={runHubActionRequest?.inProgress || actionsRequest.inProgress || actionRunning}
                disabled={Boolean(action.frontend_modal_options?.length)}
              >
                {action.action_title || t('actions:confirm')}
              </LoadingButton>
            </DialogActions>
          </Box>
        </LoaderView>
      </Dialog>
    </React.Fragment>
  );
}
