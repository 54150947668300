import { Theme, createTheme } from '@mui/material/styles';

const primaryMain = '#6500FF';
const secondaryMain = '#FF00A5';
const tertiaryMain = '#9201E4';

declare module '@mui/material/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Palette {
    tertiary: Palette['primary'];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

export const theme = (darkMode: boolean): Theme =>
  createTheme(
    {
      spacing: 4,
      mixins: {
        toolbar: {
          height: 56,
          minHeight: 56,
        },
      },
      components: {
        MuiTextField: {
          defaultProps: {
            variant: 'outlined',
          },
        },
        MuiPaper: {
          defaultProps: {
            variant: 'outlined',
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              '&::before': {
                display: 'none',
              },
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              padding: 8,
            },
          },
        },
      },
      palette: {
        mode: darkMode ? 'dark' : 'light',
        primary: {
          main: darkMode ? '#9147ff' : primaryMain,
        },
        secondary: {
          main: darkMode ? '#ff33b8' : secondaryMain,
        },
        tertiary: {
          main: darkMode ? '#bb49fd' : tertiaryMain,
        },
        background: {
          default: darkMode ? '#202124' : '#eff0f2',
          paper: darkMode ? '#303134' : '#ffffff',
        },
        text: {
          primary: darkMode ? '#ffffff' : '#000000',
          secondary: darkMode ? '#96919e' : '#959396',
          disabled: darkMode ? '#595859' : '#cbc8cf',
        },
      },
      typography: {
        fontSize: 12,
      },
    }
    // , locale
  );

export const primaryColor = primaryMain;
