import React from 'react';
import AlertCircle from '@mitch528/mdi-material-ui/AlertCircle';
import CheckboxBlankCircle from '@mitch528/mdi-material-ui/CheckboxBlankCircle';
import CheckCircle from '@mitch528/mdi-material-ui/CheckCircle';
import CloseCircle from '@mitch528/mdi-material-ui/CloseCircle';
import PlayCircleOutline from '@mitch528/mdi-material-ui/PlayCircleOutline';
import RecordCircleOutline from '@mitch528/mdi-material-ui/RecordCircleOutline';
import Box, { BoxProps } from '@mui/material/Box';
import { Status } from '../../entities/Status';
import statusColor from './statusColor';

interface StatusCircleProps extends BoxProps {
  status: Status | undefined;
  color?: string;
  size?: number;
}

export default function StatusCircle(props: StatusCircleProps): JSX.Element {
  let Component;
  let colorString;

  switch (props.status) {
    case Status.Success: {
      Component = CheckCircle;
      colorString = statusColor(Status.Success);
      break;
    }
    case Status.Warning: {
      Component = AlertCircle;
      colorString = statusColor(Status.Warning);
      break;
    }
    case Status.Error: {
      Component = CloseCircle;
      colorString = statusColor(Status.Error);
      break;
    }
    case Status.Active: {
      Component = PlayCircleOutline;
      colorString = statusColor(Status.Active);
      break;
    }
    case Status.Pending: {
      Component = RecordCircleOutline;
      colorString = statusColor(Status.Pending);
      break;
    }
    case Status.Grey: {
      Component = CloseCircle;
      colorString = statusColor(Status.Grey);
      break;
    }

    default: {
      Component = CheckboxBlankCircle;
      colorString = statusColor(Status.Grey);
    }
  }

  return (
    <Box
      component={Component ?? 'span'}
      sx={{
        color: props.color ?? colorString,
        stroke: 'black',
        strokeOpacity: 0.1,
        width: props.size || 20,
        ...props.sx,
      }}
    />
  );
}
