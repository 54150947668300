import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Status } from '../../entities/Status';
import statusColor from './statusColor';

interface StatusBadgeProps extends BoxProps {
  status: Status | undefined;
  count: number;
}

export default function StatusBadge(props: StatusBadgeProps): JSX.Element {
  const { status } = props;
  const filledStatus = status === Status.Error || status === Status.Warning || status === Status.Null;
  const theme = useTheme();
  const color = statusColor(status, theme);
  return (
    <Box
      className={props.className}
      sx={{
        backgroundColor: filledStatus ? color : undefined,
        border: `2px solid ${color}`,
        color: filledStatus ? 'white' : 'grey.800',
        textAlign: 'center',
        borderRadius: '50%',
        width: 22,
        height: 22,
        marginRight: 1,
        lineHeight: '12px',
        padding: 0.5,
      }}
    >
      {props.count}
    </Box>
  );
}
