import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Play from '@mitch528/mdi-material-ui/Play';
import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { Action, actionId, ActionStatus } from '../../../../../entities/Action';
import { colorStringToStatus, Status, worstStatus, wizardStatusStringToStatus } from '../../../../../entities/Status';
import { useHubActions } from '../../../../../hooks/useHubActions';
import OnDemandActionsDialog from '../../../../OnDemandActionsDialog';
import StatusCircle from '../../../../status/StatusCircle';
import StatusText from '../../../../status/StatusText';

interface WizardActionSectionProps {
  customerId: string;
  wizardId: string | undefined;
  action: Action;
  wizard_action_results: Array<ActionStatus>;
  sidebar?: boolean;
}

export default function WizardActionSection(props: WizardActionSectionProps): JSX.Element {
  const { customerId, action, wizardId, wizard_action_results } = props;

  const schema = useMemo(() => {
    const fields = action.input_variables
      ? action.input_variables.reduce(
          (fields, variable) => ({
            ...fields,
            [variable.name]: variable.required ? z.string().min(1) : z.string().optional(),
          }),
          {}
        )
      : {};
    return z.object(fields);
  }, [action]);

  const { runHubAction, resultsById, runHubActionRequests, clearRunHubActionRequestError } = useHubActions();

  const runHubActionRequest = runHubActionRequests[actionId(action.action_id, props.wizardId)];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const actionResult = useMemo(() => {
    const action_result = resultsById[actionId(action.action_id, props.wizardId)];
    if (action_result) {
      return action_result;
    } else if (wizard_action_results && wizard_action_results.length) {
      const action_results_reversed = [] as Array<ActionStatus>;

      wizard_action_results.forEach((result) => {
        action_results_reversed.push(result);
      });
      return action_results_reversed
        .reverse()
        .find(
          (actionResult) => actionResult.action_id === action.action_id && actionResult.wizard_id === props.wizardId
        );
    }

    return undefined;
  }, [action.action_id, wizard_action_results, props.wizardId, resultsById]);

  const handlePlayClick = useCallback(
    async (data: z.infer<typeof schema>) => {
      const { request_id } = (await runHubAction(customerId, action.action_id, wizardId, {
        action_id: action.action_id,
        wizard_id: wizardId,
        input_variables: data,
      })) as { request_id?: string };

      if (!request_id) {
        return;
      }
    },
    [action.action_id, customerId, runHubAction, wizardId]
  );

  let actionStatus;

  if (!actionResult || actionResult.status === 'ACTIVE' || runHubActionRequest?.inProgress) {
    actionStatus = Status.Null;
  } else if (actionResult?.conditions.length > 0) {
    actionStatus = worstStatus(actionResult.conditions.map(({ color }) => colorStringToStatus(color)));
  } else {
    actionStatus = wizardStatusStringToStatus(actionResult.status);
  }

  const runButtonloading = runHubActionRequest?.inProgress;
  const actionAlert =
    (!runButtonloading &&
      runHubActionRequest?.error &&
      Number(runHubActionRequest?.error?.code) >= 400 &&
      runHubActionRequest?.error.message) ??
    null;

  // Clear action run errors after 20 seconds
  useEffect(() => {
    if (actionAlert !== null) {
      setTimeout(() => {
        clearRunHubActionRequestError(action.action_id, props.wizardId);
      }, 20000);
    }
  }, [actionAlert, clearRunHubActionRequestError, action.action_id, props.wizardId]);

  return (
    <>
      <ListItem
        sx={{
          paddingLeft: '14px',
          '&:last-child': {
            borderBottomWidth: 0,
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box
          component="form"
          sx={{ display: 'flex', flex: '1 1 0', minWidth: 0, width: '100%' }}
          onSubmit={handleSubmit(handlePlayClick)}
        >
          <>
            <ListItemIcon sx={{ minWidth: 32, alignItems: 'center' }}>
              <StatusCircle status={actionStatus} />
            </ListItemIcon>
            <ListItemText
              secondary={props.sidebar ? undefined : action.action_description}
              title={action.action_title}
              sx={{
                mt: props.sidebar ? 1.5 : 1,
                display: 'flex',
                flexDirection: 'column',
                span: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              {action.action_title}
            </ListItemText>
          </>
          {!props.sidebar && (
            <Box
              sx={{
                flex: '1 1 auto',
                display: 'flex',
                paddingX: 2,
                justifyContent: 'flex-end',
                height: '32px',
                overflow: 'visible',
              }}
            >
              {action.input_variables?.map((inputVariable) => (
                <Box key={inputVariable.name} sx={{ margin: 1 }}>
                  <TextField
                    {...register(inputVariable.name, { required: inputVariable.required })}
                    variant="outlined"
                    size="small"
                    sx={{ maxWidth: props.sidebar ? '45%' : '90%', mb: 5 }}
                    label={`${inputVariable.alias} ${inputVariable.required ? ' *' : ''}`}
                    error={errors[inputVariable.name]}
                    helperText={errors[inputVariable.name] && inputVariable.required ? 'Valore obbligatorio' : ' '}
                  />
                </Box>
              ))}
            </Box>
          )}
          {props.sidebar ? (
            // Play action button for sidebar that can't pass input variables data. Clicking on button opens RunTestsDialog
            <OnDemandActionsDialog
              customerId={customerId}
              action={action}
              closeAfterRun
              ButtonComponent={({ onClick }) => (
                <LoadingButton
                  loading={runButtonloading}
                  endIcon={props.sidebar ? null : <Play />}
                  onClick={onClick}
                  sx={{ minWidth: 32 }}
                >
                  {props.sidebar ? <Play /> : 'Avvia azione'}
                </LoadingButton>
              )}
            />
          ) : (
            // Play action button for wizard that can pass input variables data. submit calls handlePLayClick
            <LoadingButton loading={runButtonloading} type="submit" endIcon={props.sidebar ? null : <Play />}>
              {props.sidebar ? <Play /> : 'Avvia azione'}
            </LoadingButton>
          )}
        </Box>
        {/* Action results Box */}
        {!runHubActionRequest?.inProgress && actionResult && (
          <>
            {actionResult?.conditions.map(({ color, description, variables }, i) => (
              <Box
                key={i}
                sx={{
                  pl: 1,
                  lineHeight: 2,
                }}
              >
                <StatusText icon status={colorStringToStatus(color)}>
                  <div style={{ paddingLeft: 5, lineHeight: 1.4 }}>{description}</div>
                </StatusText>
                {variables
                  ? variables.map((variable: Record<string, string | number | null>, i: number) => (
                      <Box sx={{ width: '100%', pl: 7, lineHeight: 2 }} key={i}>
                        {`${Object.keys(variable)[i]}: ${
                          Object.values(variable)[i] ? Object.values(variable)[i] : 'N/A'
                        }`}
                      </Box>
                    ))
                  : null}
              </Box>
            ))}
          </>
        )}
      </ListItem>
      {actionAlert && (
        <Alert severity="warning" sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: 0 }}>
          {actionAlert}
        </Alert>
      )}
    </>
  );
}
