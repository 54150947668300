import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useWizard } from '../../../../../hooks/useWizard';
import LoaderView from '../../../../LoaderView';

export function WizardPrimaryCard(props: BoxProps): JSX.Element {
  return (
    <Box
      {...props}
      sx={{
        border: `2px solid`,
        borderColor: `primary.main`,
        background: (theme) => theme.palette.background.paper,
        paddingX: 2,
        paddingY: 1,
        borderRadius: 1,
        height: 'calc(100% - 6px)',
        ...props.sx,
      }}
    />
  );
}

export function WizardInnerCard(props: CardProps & { actions?: React.ReactNode }): JSX.Element {
  return (
    <Card
      {...props}
      sx={{
        background: (theme) => theme.palette.background.paper,
        ...props.sx,
      }}
    >
      <CardContent>{props.children}</CardContent>
      {props.actions && <CardActions>{props.actions}</CardActions>}
    </Card>
  );
}

export function WizardLoader(props: { children?: React.ReactNode | React.ReactNodeArray }): JSX.Element {
  const { readWizardStepRequest } = useWizard();
  return (
    <LoaderView condition={!readWizardStepRequest.inProgress} minHeight="66vh">
      {props.children}
    </LoaderView>
  );
}
