import React from 'react';
import { WizardMarkdown } from './WizardFeedbackContent';

interface WizardDescriptionProps {
  description?: string;
  sx?: Record<string, string>;
}

export function WizardDescription(props: WizardDescriptionProps): JSX.Element | null {
  const { description } = props;

  if (!description) {
    return null;
  }

  return <WizardMarkdown sx={{ ...props?.sx, p: 1, '& td, th': { p: 3 } }}>{description}</WizardMarkdown>;
}
