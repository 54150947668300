import React, { useEffect, useMemo, useRef } from 'react';
import {
  BarElement,
  Chart,
  ChartDataset,
  Filler,
  Legend,
  BarController,
  PointElement,
  Title,
  Tooltip,
  ChartConfiguration,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import pattern from 'patternomaly';
import { useTheme } from '@mui/system';
import { useMaterialColors } from '../../utils/materialColors';
import 'chartjs-adapter-date-fns';

Chart.register(BarElement, Filler, Legend, BarController, PointElement, Title, Tooltip, TimeScale, TimeSeriesScale);

export type ChartCoordinates = {
  x: string;
  y: number;
};

export interface TimeHistogramChartData {
  data: Array<ChartCoordinates>;
  labels: Array<string>;
  formatLabel?: (value: number) => string;
  formatTitle?: (value: number) => string;
  chartTitle?: string | null;
  unit?: string;
  dateTimeUnit?: string | null;
  dateTimeFormat?: string | null;
  yLabel?: string;
  monochrome?: boolean;
  thresholds?: Array<{ name: string; value: number; type_upper: boolean | null }>;
  timeEvents?: Array<{
    start: { name: string; value: string | number | null };
    end: { name: string; value: string | number | null };
  }>;
}

interface TimeHistogramChartProps {
  visible: boolean;
  data: TimeHistogramChartData;
}

export default function TimeHistogramChart(props: TimeHistogramChartProps): JSX.Element {
  const {
    visible,
    data: { data, labels, formatLabel, formatTitle, chartTitle, unit, yLabel, monochrome, thresholds, timeEvents },
  } = props;

  const theme = useTheme();
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart<'bar', Array<ChartCoordinates>> | undefined }>({ chart: undefined });
  const colors = useMaterialColors(labels.length);
  const yMax = useMemo(() => {
    if (!thresholds || !thresholds.length) {
      if (data.length) return data.reduce((p, v) => Math.max(p, v.y), 0);
    } else {
      let tMax = 0;
      thresholds.forEach((t) => {
        if (!tMax || t.value > tMax) tMax = t.value;
      });
      return Math.max(
        tMax,
        data.reduce((p, v) => Math.max(p, v.y), 0)
      );
    }
    return 0;
  }, [data, thresholds]);

  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    if (visible && ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        // Bar coloring
        const barColors: Array<CanvasPattern | string> = [];
        data.forEach((value) => {
          let color: string | CanvasPattern = '#6500FF';
          if (thresholds)
            thresholds.forEach((threshold) => {
              if (
                (threshold.type_upper === true && value.y > threshold.value) ||
                (threshold.type_upper === false && value.y < threshold.value)
              )
                color = pattern.draw('diagonal-right-left', '#ff63846a');
            });
          barColors.push(color);
        });

        // Dataset definition
        const dataset: ChartDataset<'bar', Array<ChartCoordinates>> = {
          data,
          backgroundColor: barColors,
          minBarLength: 5,
          type: 'bar' as const,
        };

        const annotations: Record<string, AnnotationOptions> = {};
        // Thresholds
        if (thresholds && thresholds.length) {
          for (const threshold of thresholds) {
            annotations[threshold.name] = {
              type: 'line',
              yMax: threshold.value,
              yMin: threshold.value,
              borderColor: '#ff6384',
              label: {
                enabled: true,
                content: threshold.name,
                backgroundColor: '#ff6384',
                width: 1000, // ?
                height: 15, // ?
              },
            };
          }
        }
        // TimeEvents
        if (timeEvents && timeEvents.length) {
          timeEvents.forEach((timeEvent, i) => {
            const offset = yMax / (20 / (i + 1));
            for (let a = 0; a < 2; a++) {
              // We need 2 annotation objects to display one timeEvent because chartjs doesn't support multiple labels for one annotation
              annotations[timeEvent.start.name + a.toString()] = {
                type: 'line',
                borderWidth: a === 0 ? 4 : 0,
                yMax: yMax - offset,
                yMin: yMax - offset,
                xMin: timeEvent.start.value || Number.MIN_SAFE_INTEGER,
                xMax: timeEvent.end.value || Number.MAX_SAFE_INTEGER,
                label: {
                  enabled: a === 0 ? !!timeEvent.start.value : !!timeEvent.end.value,
                  content: a === 0 ? timeEvent.start.name : timeEvent.end.name,
                  width: 1000,
                  height: 15,
                  backgroundColor: '#666',
                  position: a === 0 ? 'start' : 'end',
                },
              };
            }
          });
        }

        // Time scales
        const dateTimeUnit = props.data.dateTimeUnit ?? 'day';
        const dateTimeFormat = props.data.dateTimeFormat ?? 'dd/MM/yyyy';

        const scalesTime = {
          unit: dateTimeUnit,
          displayFormats: {
            [dateTimeUnit]: dateTimeFormat,
          },
        };

        // Chart
        const chartOptions = {
          type: 'bar',
          data: {
            datasets: [dataset],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                right: 48,
                bottom: 24,
                left: 36,
              },
            },
            plugins: {
              title: {
                display: chartTitle ? true : false,
                text: chartTitle,
                font: {
                  size: 24,
                },
                padding: {
                  bottom: 24,
                },
                color: theme.palette.text.primary + '9a',
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  title: ([context]) => {
                    const rawContext = context?.raw as { x: number; y: number; label: string | undefined } | undefined;
                    if (rawContext?.label) {
                      return rawContext.label;
                    }
                    if (typeof formatTitle == 'function' && context?.parsed.x) {
                      return formatTitle(context?.parsed.x);
                    }
                    return '';
                  },
                  label: (context) => {
                    if (typeof formatLabel == 'function') {
                      return formatLabel(context.parsed.y);
                    }
                    return '';
                  },
                },
              },
              legend: {
                display: false,
              },
              annotation: {
                annotations: {
                  ...annotations,
                },
              },
            },
            scales: {
              x: {
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                bounds: 'ticks',
                ticks: {
                  color: theme.palette.text.primary + '9a',
                  source: 'auto',
                },
                type: 'time',
                time: scalesTime,
              },
              y: {
                title: {
                  display: yLabel,
                  text: (yLabel ? yLabel : '') + (unit ? ` [${unit}]` : ''),
                  font: { size: 18, weight: 'bold' },
                  color: theme.palette.text.primary + '9a',
                },
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                },
                suggestedMax: yMax + Math.floor(yMax / 10),
              },
            },
          },
        } as ChartConfiguration<'bar', Array<ChartCoordinates>>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart<'bar', Array<ChartCoordinates>>(ctx, chartOptions);
      }
    }
  }, [
    chartTitle,
    colors,
    data,
    formatLabel,
    labels,
    visible,
    unit,
    yLabel,
    monochrome,
    thresholds,
    timeEvents,
    yMax,
    theme.palette.text.primary,
    props.data.dateTimeUnit,
    props.data.dateTimeFormat,
    formatTitle,
  ]);

  return <canvas style={{ paddingTop: 24 }} ref={ref} />;
}
