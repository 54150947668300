import React, { useMemo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { grey, purple } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { Status } from '../../entities/Status';
import statusColor from './statusColor';

interface SplitStatusCircleProps extends BoxProps {
  firstStatus: Status | undefined;
  lastStatus: Status | undefined;
}

export default function SplitStatusCircle(props: SplitStatusCircleProps): JSX.Element {
  const theme = useTheme();

  const firstStatusColor = statusColor(props.firstStatus, theme);
  const lastStatusColor = statusColor(props.lastStatus, theme);
  const borderGrey = theme.palette.grey[800];

  const greyStatusDefs = useMemo(
    () => (
      <defs>
        <pattern id="pattern_L9mTR6" patternUnits="userSpaceOnUse" width="8" height="8" patternTransform="rotate(45)">
          <line x1="2" y="0" x2="2" y2="8" stroke={purple['A400']} strokeWidth="4" />Z
          <line x1="6" y="0" x2="6" y2="8" stroke={grey[300]} strokeWidth="4" />
        </pattern>
      </defs>
    ),
    []
  );

  return (
    <Box
      className="SplitStatusCircle-root"
      sx={{
        width: '1em',
        height: '1em',
        minWidth: '1em',
        minHeight: '1em',
        display: 'flex',
        position: 'relative',
        svg: {
          position: 'absolute',
          width: '.5em',
          height: '1em',
          fontSize: 'inherit',
          overflow: 'hidden',
          strokeWidth: 2,
        },
        '.separator': {
          stroke: borderGrey,
        },
        ...props.sx,
      }}
    >
      <Box
        component="svg"
        viewBox="0 0 12 24"
        sx={{
          left: '-.25em',
          circle: {
            fill: props.firstStatus === Status.Grey ? 'url(#pattern_L9mTR6)' : firstStatusColor,
            stroke:
              props.firstStatus === Status.Grey
                ? borderGrey
                : props.firstStatus === Status.Pending
                ? borderGrey
                : firstStatusColor,
          },
        }}
      >
        {greyStatusDefs}
        <circle cx="11" cy="11" r="10" />
        <line className="separator" x1="11.5" y1="0" x2="11.5" y2="22" strokeWidth="1" />
      </Box>
      <Box
        component="svg"
        viewBox="0 0 12 24"
        sx={{
          right: '.25em',
          circle: {
            fill: props.lastStatus === Status.Grey ? 'url(#pattern_L9mTR6)' : lastStatusColor,
            stroke:
              props.lastStatus === Status.Grey
                ? borderGrey
                : props.lastStatus === Status.Pending
                ? borderGrey
                : lastStatusColor,
          },
        }}
      >
        {greyStatusDefs}
        <circle cx="0" cy="11" r="10" />
        <line className="separator" x1="0" y1="0" x2="0" y2="22" strokeWidth="1" />
      </Box>
    </Box>
  );
}
