import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Close from '@mitch528/mdi-material-ui/Close';
import copy from 'copy-to-clipboard';
import { format } from 'date-fns';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { RequestError } from '../../entities/RequestError';
import { RequestParseError } from '../../utils/RequestParseError';
import { ApplicationErrorContextProvider } from './ApplicationError';
import { useGlobalErrorCatcher } from './useGlobalErrorCatcher';

const formatTimestamp: () => string = () => `[${format(new Date(), 'dd/MMM/yyyy:HH:mm:ss xx')}]`;

interface ApplicationErrorProviderProps {
  children: React.ReactNode | React.ReactNodeArray;
}

export default function ApplicationErrorProvider(props: ApplicationErrorProviderProps): JSX.Element | null {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, Array<Error | RequestError | null>>>({});

  const globalErrors = useGlobalErrorCatcher();

  const definedErrors = useMemo(
    () =>
      Object.values(errors)
        .flat()
        .filter<Error | RequestError>(function (error): error is Error | RequestError {
          return error !== null;
        }),
    [errors]
  );

  const formattedError = useMemo(() => {
    const timestamp = formatTimestamp();

    return definedErrors
      .reduce((errors, error) => {
        const stack = error.stack || '';

        if ((error as RequestParseError).originalZodError) {
          const zodError = error as RequestParseError;

          if ((zodError as RequestParseError).requestName) {
            return errors.concat((zodError as RequestParseError).toStringArray());
          }

          return errors.concat(
            zodError
              .toString()
              .replace(/^(\s)*\n$/gm, '')
              .split('\n')
              .filter((s: string) => s.length > 0)
          );
        } else {
          const requestError = error as RequestError;

          if (requestError.method && requestError.message) {
            return errors.concat([
              `"${requestError.method.toUpperCase()} ${requestError.url}" ${requestError.code}`,
              requestError.message,
              ...stack.split('\n'),
            ]);
          }
        }

        return errors.concat([error.message, stack]);
      }, [] as string[])
      .map((row) => `${timestamp}\t${row}`)
      .join('\n');
  }, [definedErrors]);

  const copyError = useCallback(() => {
    copy(formattedError);
  }, [formattedError]);

  const handleClose = useCallback(() => {
    setErrors({});
  }, []);

  useEffect(() => {
    setErrors((otherErrors) => ({
      ...otherErrors,
      GlobalErrors: globalErrors,
    }));
  }, [globalErrors]);

  const open = useMemo(() => definedErrors.length > 0, [definedErrors]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0',
        minHeight: 0,
      }}
    >
      <Box
        sx={{
          paddingX: 2,
          paddingY: 1,
          flex: '0 1 auto',
          background: (theme) => theme.palette.background.default,
        }}
      >
        <Collapse in={open}>
          <Alert
            variant="filled"
            severity="warning"
            sx={{ mt: 1 }}
            action={
              <>
                <Button color="inherit" size="small" onClick={copyError} sx={{ mr: 2 }}>
                  {t('errorAlert:copyErrorDetails')}
                </Button>
                <IconButton color="inherit" size="small" onClick={handleClose}>
                  <Close />
                </IconButton>
              </>
            }
          >
            <AlertTitle>{t('errorAlert:errorOccured')}</AlertTitle>
            <Box>
              {process.env.NODE_ENV === 'production' ? (
                t('errorAlert:tryReloadingPage')
              ) : (
                <Box
                  component="pre"
                  sx={{
                    overflow: 'auto',
                    maxHeight: 100,
                    width: '100%',
                    padding: 2,
                    border: `1px solid`,
                    borderColor: `divider`,
                    borderRadius: 1,
                  }}
                >
                  <code>{formattedError}</code>
                </Box>
              )}
            </Box>
          </Alert>
        </Collapse>
      </Box>
      <Box
        sx={{
          flex: '1 1 auto',
          minHeight: 0,
          display: 'flex',
        }}
      >
        <ApplicationErrorContextProvider value={{ setErrors }}>{props.children}</ApplicationErrorContextProvider>
      </Box>
    </Box>
  );
}
