import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grow from '@mui/material/Grow';
import Skeleton from '@mui/material/Skeleton';

const DASHBOARD_HEIGHT = window.innerHeight - 56 - 48 - 2;

export default function TabSkeleton(): JSX.Element {
  const [show, setShow] = useState(true);
  const [templateN, setTemplateN] = useState(1);
  const animation = useRef<'pulse' | 'wave'>('pulse').current;

  useEffect(() => {
    setTimeout(
      () => {
        if (!show) {
          const t = Math.ceil(Math.random() * 3);
          setTemplateN(t);
        }
        setShow(!show);
      },
      show ? 1600 : 800
    );
  }, [show]);

  const template1 = (
    <>
      <Box height="10%" display="flex" flexDirection="row" paddingBottom={1.25}>
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1, mr: 1.25 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>

        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 2 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>

      <Box height="30%" marginBottom={1.25}>
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%' }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>

      <Box height="10%" display="flex" flexDirection="row" paddingBottom={1.25}>
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>

      <Box height="50%" display="flex" flexDirection="row" paddingBottom={1.25}>
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 3, mr: 1.25 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>

        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>
    </>
  );

  const template2 = (
    <>
      <Grow in={show} timeout={Math.random() * 1000 + 200}>
        <Card sx={{ height: '15%', flex: 1, mb: 1.25 }}>
          <Skeleton animation={animation} variant="rectangular" height="100%" />
        </Card>
      </Grow>

      <Box height="70%" display="flex" flexDirection="row" paddingBottom={1.25}>
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 3, mr: 1.25 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>

        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>

      <Box height="15%" display="flex" flexDirection="row" paddingBottom={1.25}>
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1, mr: 1.25 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>

        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 2 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>
    </>
  );

  const template3 = (
    <>
      <Box height="40%" display="flex" flexDirection="row" paddingBottom={1.25}>
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1, mr: 1.25 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>

        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>

      <Box height="10%" display="flex" flexDirection="row" paddingBottom={1.25}>
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1, mr: 1.25 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>

        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 2 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>

      <Box height="50%" display="flex" flexDirection="row">
        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 3, mr: 1.25 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>

        <Grow in={show} timeout={Math.random() * 1000 + 200}>
          <Card sx={{ height: '100%', flex: 1 }}>
            <Skeleton animation={animation} variant="rectangular" height="100%" />
          </Card>
        </Grow>
      </Box>
    </>
  );

  const renderTemplate = () => {
    switch (templateN) {
      case 1:
        return template1;
      case 2:
        return template2;
      case 3:
        return template3;
      default:
        return template1;
    }
  };
  return (
    <Box height={DASHBOARD_HEIGHT} paddingY={1.25}>
      {renderTemplate()}
    </Box>
  );
}
