import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Alert from '@material-ui/core/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { colorStringToStatus } from '../../../../../entities/Status';
import { WizardTypes } from '../../../../../entities/Wizard';
import { useCustomerData } from '../../../../../hooks/useCustomerData';
import { useWizard } from '../../../../../hooks/useWizard';
import { Scroller } from '../../../../Scroller';
import StatusCircle from '../../../../status/StatusCircle';
import { WizardInnerCard, WizardLoader, WizardPrimaryCard } from '../components/WizardCommonComponents';
import WizardHeader from '../components/WizardHeader';
import WizardOutdatedAlert from '../components/WizardOutdatedAlert';
import WizardPageTitle from '../components/WizardPageTitle';
import WizardTitle from '../components/WizardTitle';

export default function ResultsStep(): JSX.Element | null {
  const { wizardStep, readWizardStep } = useWizard();
  const { customerId } = useCustomerData();
  const [caseAction, setCaseAction] = useState<string | null>('');

  const { t } = useTranslation();

  if (!wizardStep || wizardStep.type !== WizardTypes.RESULTS || !customerId) {
    return null;
  }

  const wizard = wizardStep;
  const title = t('dashboard:wizard:results:title');
  const problemsNotSolved = wizard.data.problems_not_solved;
  const cases = wizard.data.cases;
  const duplicatedCases = wizard.data.duplicated_cases;
  const escalations = wizard.data.escalations;

  const renderCaseCell = (
    column: { column_name: string; alias: string },
    row: { rule_id?: string; entries: { column_name: string; value?: string; possible_values?: string[] }[] }
  ) => {
    const cell = row.entries.find((e) => e.column_name === column.column_name);
    if (!cell) return null;

    return cell.value
      ? cell.value
      : cell.possible_values && (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Autocomplete
              options={cell.possible_values}
              renderInput={(params) => <TextField {...params} label={t('actions:selectAction')} size="small" />}
              sx={{ minWidth: 240, marginRight: 2 }}
              onChange={(_e, value) => setCaseAction(value)}
            />
            <Button
              variant="contained"
              disabled={!caseAction}
              onClick={() => {
                if (customerId && caseAction)
                  readWizardStep(customerId, {
                    type: 'case_result',
                    data: {
                      rule_id: row.rule_id,
                      action: caseAction,
                    },
                  });
              }}
            >
              {t('actions:execute')}
            </Button>
          </Box>
        );
  };

  return (
    <WizardPrimaryCard>
      <Scroller>
        <WizardHeader wizard={wizard} />
        <WizardPageTitle rightOuterButtons={[]} />
        <WizardOutdatedAlert wizard={wizard} />
        <WizardLoader>
          <WizardInnerCard>
            <WizardTitle title={title} />
            <Box sx={{ marginTop: 5 }}>
              <Typography fontWeight="bold" color={(theme) => theme.palette.tertiary.main} sx={{ m: 1.5 }}>
                {t('dashboard:wizard:results:subtitle')}
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>{t('dashboard:wizard:results:outcome')}</TableCell>
                      <TableCell>{t('dashboard:wizard:results:description')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {problemsNotSolved.map((problem) => {
                      return problem.problems.map((subProblem, j) => (
                        <TableRow key={`${problem.description}-${j}`}>
                          {j === 0 && (
                            <TableCell component="th" scope="row" rowSpan={problem.problems.length}>
                              <StatusCircle status={colorStringToStatus(problem.status)} />
                            </TableCell>
                          )}
                          {j === 0 && (
                            <TableCell component="th" scope="row" rowSpan={problem.problems.length}>
                              <Box
                                sx={{
                                  fontWeight: 'bold',
                                  '>p': {
                                    my: 1,
                                  },
                                }}
                              >
                                <ReactMarkdown>{problem.description}</ReactMarkdown>
                              </Box>
                            </TableCell>
                          )}
                          <TableCell>{subProblem}</TableCell>
                        </TableRow>
                      ));
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {cases && (
              <Box sx={{ marginTop: 5 }}>
                <Typography fontWeight="bold" color={(theme) => theme.palette.tertiary.main} sx={{ m: 1.5 }}>
                  {t('dashboard:wizard:results:caseHesitation')}
                </Typography>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {cases.columns.map((c) => {
                          return <TableCell key={c.column_name}>{c.alias}</TableCell>;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cases.rows.map((r, index) => (
                        <TableRow key={`cases-row-${index}`}>
                          {cases.columns.map((c, jndex) => (
                            <TableCell key={`cases-cell-${index}-${jndex}`} size="medium">
                              {renderCaseCell(c, r)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {cases.hesitation_result && (
                    <Alert
                      severity={
                        cases.hesitation_result.severity === 'loading' ? 'info' : cases.hesitation_result.severity
                      }
                      icon={
                        cases.hesitation_result.severity === 'loading' ? (
                          <CircularProgress size={22} sx={{ p: 1 }} color="info" />
                        ) : undefined
                      }
                      sx={{ alignItems: 'center' }}
                    >
                      <div style={{ marginTop: 2 }}>{cases.hesitation_result.text}.</div>
                    </Alert>
                  )}
                </TableContainer>
              </Box>
            )}
            {duplicatedCases && (
              <Box sx={{ marginTop: 5 }}>
                <Typography fontWeight="bold" color={(theme) => theme.palette.tertiary.main} sx={{ m: 1.5 }}>
                  {t('dashboard:wizard:results:duplicatedCases')}
                </Typography>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {duplicatedCases.columns.map((c) => {
                          return <TableCell key={c.column_name}>{c.alias}</TableCell>;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {duplicatedCases.rows.map((r, index) => (
                        <TableRow key={`duplicated-cases-row-${index}`}>
                          {duplicatedCases.columns.map((c, jndex) => (
                            <TableCell key={`duplicated-cases-cell-${index}-${jndex}`} size="medium">
                              {renderCaseCell(c, r)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            {escalations && (
              <Box sx={{ marginTop: 5 }}>
                <Typography fontWeight="bold" color={(theme) => theme.palette.tertiary.main} sx={{ m: 1.5 }}>
                  {t('dashboard:wizard:results:duplicatedEscalations')}
                </Typography>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {escalations.columns.map((c) => {
                          return <TableCell key={c.column_name}>{c.alias}</TableCell>;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {escalations.rows.map((r, index) => (
                        <TableRow key={`escalation-row-${index}`}>
                          {escalations.columns.map((c, jndex) => (
                            <TableCell key={`escalation-cell-${index}-${jndex}`} size="medium">
                              {renderCaseCell(c, r)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </WizardInnerCard>
        </WizardLoader>
      </Scroller>
    </WizardPrimaryCard>
  );
}
