import React, { Fragment, useState } from 'react';
import MenuRight from '@mitch528/mdi-material-ui/MenuRight';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { PickList, WizardPickList } from '../../../../../entities/Wizard';

interface PickListProps {
  pickList: WizardPickList;
  onChange: (values: string[]) => void;
  onEndReached?: () => void;
}

export default function WizardPickListSection(props: PickListProps): JSX.Element {
  const [choices, setChoices] = useState<Array<string>>([]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        mt: 2,
      }}
    >
      <FormControl>
        <Select
          value={choices[0] ?? ' '}
          onChange={(e) => {
            setChoices(() => {
              const newChoices = [];
              newChoices[0] = e.target.value || ' ';
              props.onChange(newChoices.filter((choice) => choice !== ' '));
              return newChoices;
            });
          }}
          sx={{ minWidth: 100, mt: 1, mb: 1 }}
        >
          <MenuItem value={' '}>
            <em>{'Seleziona una tipologia'}</em>
          </MenuItem>
          {props.pickList.value &&
            props.pickList.value.map((listItem) => (
              <MenuItem key={listItem.name} value={listItem.name}>
                {listItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {choices.map((_, i) => {
        let pickList: PickList = {
          ...props.pickList,
          picklist: props.pickList.value,
        };

        for (let j = 0; j <= i; j++) {
          const choice = choices[j];

          const nextPicklist = pickList.picklist?.find(({ name }) => name === choice);

          if (nextPicklist) {
            pickList = nextPicklist;
          } else {
            return null;
          }
        }

        if (!pickList || !pickList.picklist) {
          if (props.onEndReached) props.onEndReached();
          return null;
        }

        return (
          <Fragment key={choices[i]}>
            <MenuRight />
            <FormControl>
              <Select
                value={choices[i + 1] ?? ' '}
                onChange={(e) => {
                  setChoices((choices) => {
                    let newChoices = [...choices];
                    newChoices[i + 1] = e.target.value;
                    newChoices = newChoices.slice(0, i + 2);
                    props.onChange(newChoices.filter((choice) => choice !== ' '));
                    return newChoices;
                  });
                }}
                sx={{ minWidth: 100, mt: 1, mb: 1 }}
              >
                <MenuItem value={' '}>
                  <em>{'Seleziona una tipologia'}</em>
                </MenuItem>
                {pickList.picklist.map((listItem) => (
                  <MenuItem key={listItem.name} value={listItem.name}>
                    {listItem.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Fragment>
        );
      })}
    </Box>
  );
}
