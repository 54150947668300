import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Tooltip from '@mui/material/Tooltip';
import { WizardTypes } from '../../../../../entities/Wizard';
import { useCustomerData } from '../../../../../hooks/useCustomerData';
import { useWizard } from '../../../../../hooks/useWizard';
import { Scroller } from '../../../../Scroller';
import WizardButton from '../components/WizardButton';
import WizardCardActions from '../components/WizardCardActions';
import { WizardInnerCard, WizardLoader, WizardPrimaryCard } from '../components/WizardCommonComponents';
import { WizardDescription } from '../components/WizardDescription';
import WizardHeader from '../components/WizardHeader';
import WizardOutdatedAlert from '../components/WizardOutdatedAlert';
import WizardTitle from '../components/WizardTitle';

export default function WizardZeroViewStep(): JSX.Element | null {
  const [selected, setSelected] = React.useState<string[]>([]);
  const { wizardStep, readWizardStep, readWizardStepRequest } = useWizard();
  const { customerId } = useCustomerData();

  React.useEffect(() => {
    if (wizardStep && wizardStep.type === WizardTypes.WIZARD0_VIEW) {
      const preSelected: string[] = [];
      wizardStep?.data?.wizard0_list.forEach((item) => {
        if (item.checked) preSelected.push(item.wizard_id);
      });
      setSelected(preSelected);
    }
  }, [wizardStep]);

  if (!wizardStep || wizardStep.type !== WizardTypes.WIZARD0_VIEW || !customerId) {
    return null;
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const wizard = wizardStep;

  return (
    <WizardPrimaryCard>
      <Scroller>
        <WizardHeader wizard={wizard} />
        <WizardOutdatedAlert wizard={wizard} />
        <WizardLoader>
          <WizardInnerCard
            actions={
              <WizardCardActions
                left={[]}
                right={[
                  <WizardButton
                    key="button_text"
                    confirm={false}
                    disabled={false}
                    onClick={() =>
                      readWizardStep(customerId, {
                        type: 'wizard0_view',
                        data: {
                          wizard_list: wizard.data.wizard0_list.map(({ wizard_id }) => ({
                            wizard_id,
                            active: isSelected(wizard_id),
                          })),
                        },
                      })
                    }
                    loading={readWizardStepRequest.inProgress}
                  >
                    {wizard.data.button_text}
                  </WizardButton>,
                ]}
              />
            }
          >
            <WizardTitle title={wizard.data.title} />
            <WizardDescription description={wizard.data.description} sx={{ fontSize: '1rem' }} />
            <Paper sx={{ my: 3 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell>{'Nome'}</TableCell>
                    <TableCell>{'Descrizione'}</TableCell>
                    <TableCell>{'Consigli'}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wizard.data.wizard0_list.map((row) => {
                    const isItemSelected = isSelected(row.wizard_id);
                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          if (!row.checked || wizard.data.superuser) handleClick(row.wizard_id);
                        }}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.wizard_id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Tooltip
                            enterDelay={600}
                            enterNextDelay={400}
                            title={
                              row.checked && !wizard.data.superuser
                                ? 'Non possiedi i permessi sufficienti per deselezionare questo controllo.'
                                : ''
                            }
                          >
                            <span>
                              <Checkbox
                                checked={isItemSelected}
                                disabled={row.checked && !wizard.data.superuser}
                                sx={{ padding: 1.5 }}
                              />
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.hint}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </WizardInnerCard>
        </WizardLoader>
      </Scroller>
    </WizardPrimaryCard>
  );
}
