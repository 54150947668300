import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuggestionSchema } from '../entities/Suggestion';
import { RootState } from '../store/reducers';
import { suggestionActions } from '../store/reducers/suggestion';
import { RequestParseError } from '../utils/RequestParseError';

type SuggestionState = RootState['suggestion'];

type UseSuggestion = {
  suggestion: SuggestionState['suggestion'];
  parseSuggestionMessage: (response: unknown) => void;
  clearSuggestion: () => void;
};

export function useSuggestion(): UseSuggestion {
  const dispatch = useDispatch();

  const suggestion = useSelector((state: RootState) => state.suggestion.suggestion);

  const parseSuggestionMessage = useCallback(
    (response) => {
      const parseResult = SuggestionSchema.safeParse(response);
      if (parseResult.success === false) {
        dispatch(
          suggestionActions.readSuggestionParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCustomer.parseReadLastTestCustomerInfo'),
          })
        );
        return;
      }
      dispatch(suggestionActions.readSuggestionSuccess(parseResult.data));
    },
    [dispatch]
  );

  const clearSuggestion = useCallback(() => {
    dispatch(suggestionActions.clearSuggestion());
  }, [dispatch]);

  return {
    suggestion,
    parseSuggestionMessage,
    clearSuggestion,
  };
}
