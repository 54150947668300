import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import '@mui/lab/themeAugmentation';

import { i18n } from './i18n';
import Main from './Main';

import 'typeface-roboto';
import './index.css';

// eslint-disable-next-line
console.log(`🩺 ${process.env.REACT_APP_NAME} (v. ${process.env.REACT_APP_VERSION}) 🩺`)

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Main />
  </I18nextProvider>,
  document.querySelector('#root')
);
