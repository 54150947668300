import React, { useEffect, useRef } from 'react';
import {
  ArcElement,
  Chart,
  ChartConfiguration,
  ChartDataset,
  Filler,
  Legend,
  PieController,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useTheme } from '@mui/system';
import { useMaterialColors } from '../../utils/materialColors';

Chart.register(ArcElement, Filler, Legend, PieController, PointElement, Title, Tooltip);

export interface PieChartData {
  data: Array<number>;
  labels: Array<string>;
  formatLabel?: (value: number) => string;
  chartTitle: string;
}

interface PieChartProps {
  visible: boolean;
  data: PieChartData;
}

export default function PieChart(props: PieChartProps): JSX.Element {
  const {
    visible,
    data: { data, labels, formatLabel, chartTitle },
  } = props;
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });

  const colors = useMaterialColors(labels.length);
  const theme = useTheme();

  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    if (visible && ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        const dataset: ChartDataset<'pie'> = {
          data: data,
          backgroundColor: colors,
          type: 'pie' as const,
        };

        const chartOptions = {
          type: 'pie',
          data: {
            labels: labels,
            datasets: [dataset],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                right: 48,
                bottom: 24,
                left: 48,
              },
            },
            plugins: {
              title: {
                display: true,
                text: chartTitle,
                color: theme.palette.text.primary + '9a',
                font: {
                  size: 24,
                },
                padding: {
                  bottom: 24,
                },
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    if (typeof formatLabel == 'function') {
                      return ` ${formatLabel(context.parsed)}`;
                    }
                    return '';
                  },
                },
              },
              legend: {
                position: 'right',
                labels: {
                  color: theme.palette.text.primary + '9a',
                },
              },
            },
          },
        } as ChartConfiguration<'pie'>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart<'pie'>(ctx, chartOptions);
      }
    }
  }, [chartTitle, colors, data, formatLabel, labels, visible, theme.palette.text.primary]);

  return <canvas style={{ paddingTop: 24 }} ref={ref} />;
}
