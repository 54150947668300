import * as z from 'zod';

export const TestsSchema = z.object({});

export enum ActionInputType {
  Text = 'text',
  Select = 'select',
  Checkbox = 'checkbox',
}

export const ActionSchema = z.object({
  action_id: z.string(),
  action_title: z.string(),
  action_description: z.union([z.string(), z.null()]),
  frontend_modal_type: z.string().optional(),
  blocking: z.boolean().optional(),
  in_progress: z.boolean().optional(),
  enabled: z.boolean().optional(),
  frontend_modal_options: z
    .union([
      z.undefined(),
      z.null(),
      z.array(
        z.object({
          section_name: z.string(),
          rules: z.array(z.string()),
        })
      ),
    ])
    .optional(),
  input_variables: z
    .array(
      z.object({
        type: z.nativeEnum(ActionInputType),
        alias: z.string(),
        name: z.string(),
        required: z.boolean(),
        default_value: z.union([z.string(), z.boolean()]),
        possible_values: z.array(z.string()).optional(),
      })
    )
    .optional(),
  standalone: z.union([z.boolean(), z.undefined()]),
  section: z.union([z.undefined(), z.string()]),
});

export type Action = z.infer<typeof ActionSchema>;

// Trendlines
export const TrendlineSchema = z.object({
  chart_name: z.string(),
  chart_description: z.string().optional(),
  chart_type: z.union([
    z.literal('line_chart'),
    z.literal('line_filled_chart'),
    z.literal('pie_chart'),
    z.literal('gauge_chart'),
    z.literal('histogram_chart'),
    z.literal('time_histogram_chart'),
    z.literal('interference_chart'),
    z.literal('table_chart'),
  ]),
  standalone: z.boolean().optional(),
  chart_group: z.union([z.null(), z.undefined(), z.string()]),
});

export const CustomerActionsSchema = z.object({
  actions: z.array(ActionSchema),
  trendlines: z.array(TrendlineSchema),
});

export type CustomerActions = z.infer<typeof CustomerActionsSchema>;

export const ActionStatusSchema = z.object({
  action_id: z.union([z.undefined(), z.string()]),
  wizard_id: z.union([z.string(), z.undefined(), z.null()]),
  conditions: z.array(
    z.object({
      color: z.string(),
      description: z.string(),
      variables: z
        .array(z.record(z.union([z.string(), z.number(), z.null()])))
        .nullable()
        .optional(),
    })
  ),
  status: z.string(),
});

export type ActionStatus = z.infer<typeof ActionStatusSchema>;

export const actionId = (actionId: Action['action_id'], wizardId: string | undefined | null): string =>
  `${actionId}${wizardId ? `-${wizardId}` : ''}`;
