import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import pako from 'pako';
import L2DashboardRepository from '../api/L2DashboardRepository';
import {
  RequestRangeAndTimeFilter,
  RequestValueFilter,
} from '../components/pages/L2Dashboard/BoardElements/L2ControllerSection';
import {
  ChartElement,
  ElementsReponseSchema,
  ControllerElement,
  L2DashboardColumn,
  L2DashboardColumnSchema,
  L2Tab,
  L2TabsSchema,
  LegendElement,
  MarkdownElement,
  TableElement,
} from '../entities/L2Dashboard';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';
import { l2DashboardActions } from '../store/reducers/l2dashboard';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type L2DashboardState = RootState['l2Dashboard'];

type UseL2Dashboard = {
  tabs: L2DashboardState['tabs'];
  readTabs: (customerId: string) => Promise<L2Tab[] | void>;
  readTabsRequest: L2DashboardState['requests']['tabs'];

  readLayout: (customerId: string, dashboardId: string) => Promise<L2DashboardColumn | void>;
  readLayoutRequest: L2DashboardState['requests']['layout'];

  reloadingElementIds: L2DashboardState['reloadingElementIds'];

  readElements: (
    customer_id: string,
    dashboard_id: string,
    request_body?: {
      element_ids: string[];
      filters: Array<RequestValueFilter | RequestRangeAndTimeFilter>;
    }
  ) => Promise<Array<ControllerElement | LegendElement | ChartElement | TableElement | MarkdownElement> | void>;
  readElementsRequest: L2DashboardState['requests']['elements'];
};

export function useL2Dashboard(): UseL2Dashboard {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      L2DashboardRepository.updateAuthToken(accessToken);
      L2DashboardRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const tabs = useSelector((state: RootState) => state.l2Dashboard.tabs);
  const readTabsRequest = useSelector((state: RootState) => state.l2Dashboard.requests.tabs);
  const readLayoutRequest = useSelector((state: RootState) => state.l2Dashboard.requests.layout);
  const reloadingElementIds = useSelector((state: RootState) => state.l2Dashboard.reloadingElementIds);
  const readElementsRequest = useSelector((state: RootState) => state.l2Dashboard.requests.elements);

  const readTabs: UseL2Dashboard['readTabs'] = useCallback(
    async (customerId: string) => {
      dispatch(l2DashboardActions.readTabsRequest());
      let response: L2Tab[] | undefined;

      try {
        const data = await L2DashboardRepository.getTabs(customerId);
        response = data;
      } catch (error) {
        dispatch(l2DashboardActions.readTabsFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      const parseResult = L2TabsSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          l2DashboardActions.readTabsParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseL2Dashboard.readTabsRequest'),
          })
        );
        return;
      }
      dispatch(l2DashboardActions.readTabsSuccess(response));
    },
    [dispatch]
  );

  const readLayout: UseL2Dashboard['readLayout'] = useCallback(
    async (customerId: string, dashboardId: string) => {
      dispatch(l2DashboardActions.readLayoutRequest());
      let response: L2DashboardColumn;
      try {
        response = await L2DashboardRepository.getLayout(customerId, dashboardId);
      } catch (error) {
        dispatch(l2DashboardActions.readLayoutFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      const parseResult = L2DashboardColumnSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          l2DashboardActions.readLayoutParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseL2Dashboard.readLayoutRequest'),
          })
        );
        return;
      }
      dispatch(l2DashboardActions.readLayoutSuccess({ tabId: dashboardId, data: response }));
    },
    [dispatch]
  );

  const readElements: UseL2Dashboard['readElements'] = useCallback(
    async (
      customer_id: string,
      dashboard_id: string,
      request_body?: {
        element_ids: string[];
        filters: Array<RequestValueFilter | RequestRangeAndTimeFilter>;
      }
    ) => {
      dispatch(l2DashboardActions.readElementsRequest());
      let response: Array<ControllerElement | LegendElement | ChartElement | TableElement | MarkdownElement>;

      try {
        const data: string = await L2DashboardRepository.getElements(customer_id, dashboard_id, request_body);
        // Convert binary string to character-number array
        const binData = atob(data);
        const charData = binData.split('').map(function (x) {
          return x.charCodeAt(0);
        });
        // Turn number array into byte-array
        const zlibData = new Uint8Array(charData);
        const unzippedData = pako.inflate(zlibData, { to: 'string' });
        response = JSON.parse(unzippedData);
      } catch (e) {
        dispatch(l2DashboardActions.readElementsFailure({ error: new RequestError(e as AxiosError) }));
        return;
      }
      const parseResult = ElementsReponseSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          l2DashboardActions.readElementsParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseL2Dashboard.readElementsRequest'),
          })
        );
        return;
      }
      dispatch(l2DashboardActions.readElementsSuccess({ tabId: dashboard_id, data: response }));
    },
    [dispatch]
  );

  return {
    tabs,
    readTabs,
    readTabsRequest,
    readLayout,
    readLayoutRequest,
    reloadingElementIds,
    readElements,
    readElementsRequest,
  };
}
