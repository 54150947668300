import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import Box, { BoxProps } from '@mui/system/Box';

export default function L2MarkdownSection(props: ReactMarkdownOptions & Pick<BoxProps, 'sx'>): JSX.Element {
  const { sx, ...reactMarkdownProps } = props;
  return (
    <Box sx={{ height: '100%', overflow: 'auto', paddingInline: 8, ...sx }}>
      <ReactMarkdown {...reactMarkdownProps} rehypePlugins={[rehypeRaw]} />
    </Box>
  );
}
