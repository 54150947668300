import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProblemFound, ProblemResult, ProblemsApiResponse } from '../../entities/Problems';

import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  mutateParseErrorState,
  RequestParseFailureAction,
  requestParseState,
} from '../toolkitUtils';

const initialState = {
  found: [] as ProblemFound[],
  analyzed: [] as ProblemResult[],
  requests: {
    read: {
      ...requestParseState(),
      customerId: undefined as string | undefined,
    },
  },
};

export type WizardState = typeof initialState;

interface ReadProblemsRequestAction {
  customerId: string | undefined;
}

interface ReadProblemsSuccessAction {
  response: ProblemsApiResponse;
}

const problemsSlice = createSlice({
  name: 'problems',
  initialState,
  reducers: {
    readProblemsRequest(state, action: PayloadAction<ReadProblemsRequestAction>) {
      if (action.payload.customerId !== state.requests.read.customerId) {
        state.requests.read.customerId = action.payload.customerId;
        state.requests.read.lastUpdate = null;
        state.requests.read.error = null;
        state.found = [];
        state.analyzed = [];
      }
      mutateRequestState(state.requests.read);
    },
    readProblemsSuccess(state, action: PayloadAction<ReadProblemsSuccessAction>) {
      mutateSuccessState(state.requests.read);
      state.found = action.payload.response.problems_found;
      state.analyzed = action.payload.response.problems_analysed;
    },
    readProblemsFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.read, action.payload.error);
    },
    readProblemsParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.read, action.payload.parseError);
    },
  },
});

export const problemsActions = problemsSlice.actions;

export default problemsSlice.reducer;
