import React from 'react';
import { useTranslation } from 'react-i18next';
import Play from '@mitch528/mdi-material-ui/Play';
import AlertTitle from '@mui/material/AlertTitle';
import Box, { BoxProps } from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

import Tooltip from '@mui/material/Tooltip';
import { Status } from '../../entities/Status';
import StatusCircle from './StatusCircle';

const boxShadow = (color?: string) => `inset 0px 0px 0px ${color ? 2 : 0}px ${color || 'white'}`;

interface StatusAlertProps {
  status: Status | undefined;
  icon?: boolean | undefined;
  active?: boolean | undefined;
  result?: string | undefined;
  children: string | React.ReactNode;
  sx?: BoxProps['sx'];
}

export default function StatusAlert(props: StatusAlertProps): JSX.Element {
  const { palette } = useTheme();
  const { t } = useTranslation();

  let background = 'white';
  const color = palette.grey[800];
  let border = undefined;
  const iconColor = undefined;

  switch (props.status) {
    case Status.Error:
      border = palette.error.main;
      break;
    case Status.Warning:
      border = palette.warning.main;
      break;
    case Status.Success:
      border = palette.success.main;
      break;
    case Status.Active:
      border = palette.primary.main;
      break;
    case Status.Null:
      background = palette.grey[100];
      break;
  }

  let iconElement = null;

  if (props.icon) {
    iconElement = <StatusCircle status={props.status} color={iconColor} />;
  }

  return (
    <Paper
      className="StatusAlert-root"
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: 6,
        minHeight: 24,
        '& .MuiSvgIcon-root': {
          mx: 2,
          my: 1,
        },
        backgroundColor: background,
        color,
        boxShadow: boxShadow(border),
        border: border ? '0' : undefined,
        ...props.sx,
      }}
    >
      {iconElement}
      <AlertTitle
        sx={{
          marginBottom: 0,
          marginTop: 0,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'pre',
        }}
      >
        {props.children}
      </AlertTitle>
      <Box
        component="span"
        sx={{
          marginLeft: 'auto',
          marginRight: 2,
          typography: 'caption',
          textTransform: 'uppercase',
        }}
      >
        {props.result}
      </Box>
      {props.active ? (
        <Tooltip
          title={`${t('common:active')}`}
          placement="left"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any,
            ],
          }}
        >
          <Box
            component={Play}
            sx={{
              marginLeft: 'auto',
              marginRight: 2,
              color: 'primary.main',
            }}
          />
        </Tooltip>
      ) : null}
    </Paper>
  );
}
