import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { ProblemFound } from '../../../../entities/Problems';
import { colorStringToStatus, Status } from '../../../../entities/Status';
import { CompactAccordion } from '../../../CompactAccordion';
import StatusAlert from '../../../status/StatusAlert';
import StatusBadge from '../../../status/StatusBadge';

interface FoundProblemsAccordionProps {
  foundProblems: Array<ProblemFound>;
}

export default function FoundProblemsAccordion(props: FoundProblemsAccordionProps): JSX.Element {
  const [expanded, setExpanded] = useState(props.foundProblems.length > 0);
  const { t } = useTranslation();

  const alertElements = useMemo(() => {
    const elements = props.foundProblems.map(({ color, description, active }, i) => (
      <ListItem
        key={i}
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          '& .MuiAlert-icon': {
            padding: 0,
          },
          '& .MuiAlert-message': {
            paddingY: 0.25,
            paddingX: 0,
          },
        }}
      >
        <StatusAlert
          icon
          status={colorStringToStatus(color)}
          active={active}
          sx={{
            '* p': {
              margin: 0,
            },
            backgroundColor: 'transparent',
            color: (theme) => theme.palette.text.primary,
          }}
        >
          <ReactMarkdown>{description}</ReactMarkdown>
        </StatusAlert>
      </ListItem>
    ));

    return elements;
  }, [props.foundProblems]);

  const badges = useMemo(() => {
    let errors = 0;
    let warnings = 0;

    for (let i = 0; i < props.foundProblems.length; i++) {
      const alert = props.foundProblems[i];
      if (alert?.color) {
        if (colorStringToStatus(alert.color) === Status.Error) {
          errors++;
        }
        if (colorStringToStatus(alert.color) === Status.Warning) {
          warnings++;
        }
      }
    }

    return [
      errors > 0 ? <StatusBadge key={Status.Error} status={Status.Error} count={errors} /> : null,
      warnings > 0 ? <StatusBadge key={Status.Warning} status={Status.Warning} count={warnings} /> : null,
    ];
  }, [props.foundProblems]);

  useEffect(() => {
    if (props.foundProblems.length > 0) {
      setExpanded(true);
    }
  }, [props.foundProblems.length]);

  return (
    <CompactAccordion
      expanded={expanded}
      onChange={() => setExpanded((v) => !v)}
      summary={
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            paddingRight: 1,
            '& .StatusBadge-root': {
              marginLeft: 1,
              height: 20,
              width: 20,
              lineHeight: '22.5px',
            },
          }}
        >
          <Typography fontWeight="bold" variant="body1" sx={{ flex: '1 1 auto' }}>
            {t('dashboard:customerInfo:alert')}
          </Typography>
          {badges}
        </Box>
      }
    >
      <AccordionDetails
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          '& .MuiListItem-root': {
            paddingBottom: 2,
            paddingTop: 0,
          },
        }}
      >
        {alertElements}
      </AccordionDetails>
      {props.foundProblems.length === 0 ? (
        <AccordionDetails>
          <Typography textAlign="center" color={(theme) => theme.palette.text.secondary}>
            {t('dashboard:customerInfo:noAlert')}
          </Typography>
        </AccordionDetails>
      ) : null}
    </CompactAccordion>
  );
}
