import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Status } from '../../entities/Status';
import StatusCircle from './StatusCircle';
import statusColor from './statusColor';

interface StatusTextProps extends BoxProps {
  status: Status | undefined;
  icon?: boolean | undefined;
}

export default function StatusText(props: StatusTextProps): JSX.Element {
  const { status } = props;
  const theme = useTheme();
  let iconElement = null;

  if (props.icon) {
    iconElement = <StatusCircle status={props.status} sx={{ mr: 2.2, width: 14 }} />;
  }

  return (
    <Box
      sx={{
        color: statusColor(status, theme),
        display: iconElement ? 'flex' : 'inline',
        alignItems: 'center',
        marginBottom: 1,
        '& .StatusCircle': {
          marginRight: 2,
        },
      }}
    >
      {iconElement}
      {props.children}
    </Box>
  );
}
