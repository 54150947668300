import { System, SystemAttribute } from '../entities/System';
import { IColumn } from '../entities/Table';
import { InterceptorRepository } from './InterceptorRepository';

class SystemRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/',
    });
  }

  async getAttributes() {
    const { data } = await this.axios.get<Array<SystemAttribute>>('/sources/mso/frontend/advanced_search/attributes');
    return data;
  }
  //   async getAttributes(newUserLevel?: string) {   TODO: UNCOMMENT
  //   const { data } = await this.axios.get<Array<SystemAttribute>>(`/sources/mso/frontend/advanced_search/attributes&newUserLevel=${newUserLevel}`);
  //   return data;
  // }

  async getList(
    query: Record<string, string | number>,
    page: number,
    pageSize: number,
    payloadSize: number,
    misalignment: number
  ) {
    const { data } = await this.axios.post<{ systems: System[]; total_results: number; columns: IColumn[] }>(
      `/sources/mso/systems?from=${page * pageSize + misalignment}&size=${payloadSize}`,
      query
    );
    return data;
  }
}

export default new SystemRepository();
