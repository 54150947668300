import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

type LoaderViewProps = {
  condition: boolean;
  minHeight?: string | number;
  minWidth?: string | number;
  children: React.ReactNode | React.ReactNodeArray;
  text?: string | undefined;
};

const LoaderViewCircularProgress = React.memo(function LoaderViewCircularProgress(props: {
  minHeight?: string | number;
  minWidth?: string | number;
  text?: string | undefined;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={props.minWidth || '100%'}
      height={props.minHeight || '100%'}
    >
      <CircularProgress disableShrink size={Number(props.minHeight) < 48 ? props.minHeight : undefined} />
      {props.text && (
        <Typography
          sx={{
            marginTop: 2,
            opacity: 0.5,
          }}
        >
          {props.text}
        </Typography>
      )}
    </Box>
  );
});

export default function LoaderView(props: LoaderViewProps): JSX.Element {
  const [condition, setCondition] = useState(props.condition);

  useEffect(() => {
    if (condition !== props.condition) {
      setCondition(props.condition);
    }
  }, [condition, props.condition]);

  if (!condition) {
    return <LoaderViewCircularProgress minHeight={props.minHeight} minWidth={props.minWidth} text={props.text} />;
  }

  return <>{props.children}</>;
}
