import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { WizardTypes } from '../../../../entities/Wizard';
import { useWizard } from '../../../../hooks/useWizard';
import { useApplicationError } from '../../../ApplicationError/ApplicationError';
import CloseLoopStep from './steps/CloseLoopStep';
import NoWizardsStep from './steps/NoWizardsStep';
import ResultsStep from './steps/ResultsStep';
import { WizardFeedbackStep } from './steps/WizardFeedbackStep';
import WizardPicklistStep from './steps/WizardPicklistStep';
import WizardStepStep from './steps/WizardStepStep';
import WizardZeroViewStep from './steps/WizardZeroViewStep';

export default function Wizard(): JSX.Element | null {
  const { customer: customerId } = useParams() as { customer?: string | undefined };
  const { wizardStep, readWizardStep, readWizardStepRequest } = useWizard();

  const applicationErrors = useMemo(() => [readWizardStepRequest.parseError], [readWizardStepRequest]);
  useApplicationError('useWizard.readWizardStep', applicationErrors);

  useEffect(() => {
    if (customerId) {
      readWizardStep(customerId);
    }
  }, [readWizardStep, customerId]);

  switch (wizardStep?.type) {
    case WizardTypes.WIZARD_PICKLIST_VIEW: {
      return <WizardPicklistStep />;
    }
    case WizardTypes.CLOSELOOP: {
      return <CloseLoopStep />;
    }
    case WizardTypes.NO_WIZARDS: {
      return <NoWizardsStep />;
    }
    case WizardTypes.WIZARD_STEP: {
      return <WizardStepStep />;
    }
    case WizardTypes.WIZARD0_VIEW: {
      return <WizardZeroViewStep />;
    }
    case WizardTypes.WIZARD_FEEDBACK: {
      return <WizardFeedbackStep />;
    }
    case WizardTypes.RESULTS: {
      return <ResultsStep />;
    }
    default:
      return (
        <pre>
          <code>{JSON.stringify(wizardStep, null, 4)}</code>
        </pre>
      );
  }
}
