import React from 'react';
import { useTranslation } from 'react-i18next';
import Magnify from '@mitch528/mdi-material-ui/Magnify';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import useAuthentication from '../../../hooks/useAuthentication';
import { useCustomer } from '../../../hooks/useCustomer';
import {
  SEARCH_CUSTOMER_BB_GRANTS,
  SEARCH_CUSTOMER_GRANTS,
  SEARCH_CUSTOMER_TV_GRANTS,
  SEARCH_CUSTOMER_Q_GRANTS,
} from '../../../utils/auth';
import LoaderView from '../../LoaderView';

export type FilterSectiontProps = {
  onSubmit: (query: Record<string, string>) => Promise<void>;
};

export default function FilterSection(props: FilterSectiontProps): JSX.Element {
  const {
    customerAttributes,
    readCustomerListRequest,
    readCustomerAttributes,
    readCustomerAttributesRequest,
  } = useCustomer();
  const { t } = useTranslation();
  const { hasGrants } = useAuthentication();
  const [attributesSkyTv, setAttributesSkyTv] = React.useState<Record<string, string | undefined>>({});
  const [attributesBroad, setAttributesBroad] = React.useState<Record<string, string | undefined>>({});
  const [attributesSkyQ, setAttributesSkyQ] = React.useState<Record<string, string | undefined>>({});

  React.useEffect(() => {
    readCustomerAttributes();
  }, [readCustomerAttributes]);

  const attributeFiltersActiveSkyTv = React.useMemo(
    () => Object.values(attributesSkyTv).filter((a) => a && a?.length > 0).length > 0,
    [attributesSkyTv]
  );

  const attributeFiltersActiveBroad = React.useMemo(
    () => Object.values(attributesBroad).filter((a) => a && a?.length > 0).length > 0,
    [attributesBroad]
  );

  const attributeFiltersActiveSkyQ = React.useMemo(
    () => Object.values(attributesSkyQ).filter((a) => a && a?.length > 0).length > 0,
    [attributesSkyQ]
  );

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const query: Record<string, string> = {};
      let category = '';
      let attributesCategory: Record<string, string | undefined> = {};

      if (attributeFiltersActiveSkyTv) {
        category = 'TV_RDKV';
        attributesCategory = attributesSkyTv;
      } else if (attributeFiltersActiveSkyQ) {
        category = 'Q_LEGACY';
        attributesCategory = attributesSkyQ;
      } else {
        category = 'BB';
        attributesCategory = attributesBroad;
      }

      for (const [key, value] of Object.entries(attributesCategory)) {
        if (value) {
          query[key] = value;
        }
      }
      props.onSubmit({ ...query, category });
    },
    [props, attributeFiltersActiveSkyTv, attributesSkyTv, attributesBroad, attributesSkyQ, attributeFiltersActiveSkyQ]
  );

  const handleReset = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAttributesBroad({});
    setAttributesSkyTv({});
    setAttributesSkyQ({});
  }, []);

  if (!hasGrants(SEARCH_CUSTOMER_GRANTS)) {
    return <h3>Accesso Negato</h3>;
  }

  return (
    <Card
      sx={{
        marginBottom: 2,
        '& .MuiCardContent-root': {
          paddingBottom: 0,
          background: (theme) => theme.palette.background.paper,
        },
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        onReset={handleReset}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > div:first-of-type': {
            flex: '1 1 auto',
          },
        }}
      >
        <CardContent>
          <LoaderView condition={!readCustomerAttributesRequest.inProgress} minHeight="180px">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* First section*/}
              {hasGrants(SEARCH_CUSTOMER_BB_GRANTS) &&
              customerAttributes.filter((attribute) => attribute.category === 'BB').length ? (
                <>
                  <Typography sx={{ color: 'rgba(0, 0, 0, 0.8)', fontWeight: 500 }}>Broadband</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      '&>div': {
                        flex: '0 0 22%',
                        paddingX: 1,
                        paddingY: 1,
                        '&:last-child': {
                          flex: '0 0 auto',
                          minWidth: '22%',
                          marginLeft: '0',
                          marginRight: 'auto',
                        },
                      },
                    }}
                  >
                    {customerAttributes
                      .filter((attribute) => attribute.category === 'BB')
                      .map((attribute) => (
                        <div key={attribute.attribute_name}>
                          {attribute?.possible_values?.length ? (
                            <Autocomplete
                              disablePortal
                              disableClearable
                              disabled={attributeFiltersActiveSkyTv || attributeFiltersActiveSkyQ}
                              options={attribute.possible_values}
                              value={attributesBroad[attribute.attribute_name] ?? ''}
                              renderInput={(params) => (
                                <TextField {...params} label={attribute.alias} size="small" fullWidth />
                              )}
                              onInputChange={(_e, value) => {
                                setAttributesBroad((attributes) => ({
                                  ...attributes,
                                  [attribute.attribute_name]: value || undefined,
                                }));
                              }}
                            />
                          ) : (
                            <TextField
                              label={attribute.alias}
                              size="small"
                              fullWidth
                              disabled={attributeFiltersActiveSkyTv || attributeFiltersActiveSkyQ}
                              value={attributesBroad[attribute.attribute_name] ?? ''}
                              onChange={(e) => {
                                setAttributesBroad((attributes) => ({
                                  ...attributes,
                                  [attribute.attribute_name]: e.target.value || undefined,
                                }));
                              }}
                            />
                          )}
                        </div>
                      ))}
                  </Box>
                  <Box sx={{ padding: 2 }}>
                    <Divider />
                  </Box>
                </>
              ) : (
                ''
              )}
              {/*Second Section*/}
              {hasGrants(SEARCH_CUSTOMER_TV_GRANTS) &&
              customerAttributes.filter((attribute) => attribute.category === 'TV_RDKV').length ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.8)', fontWeight: 500 }}>Sky Tv</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        '&>div': {
                          flex: '0 0 22%',
                          paddingX: 1,
                          paddingY: 1,
                          '&:last-child': {
                            flex: '0 0 auto',
                            minWidth: '22%',
                            marginLeft: '0',
                            marginRight: 'auto',
                          },
                        },
                      }}
                    >
                      {customerAttributes
                        .filter((attribute) => attribute.category === 'TV_RDKV')
                        .map((attribute) => (
                          <div key={attribute.attribute_name}>
                            {attribute.possible_values.length ? (
                              <Autocomplete
                                disablePortal
                                disableClearable
                                options={attribute.possible_values}
                                value={attributesSkyTv[attribute.attribute_name] || ''}
                                disabled={attributeFiltersActiveBroad || attributeFiltersActiveSkyQ}
                                renderInput={(params) => (
                                  <TextField {...params} label={attribute.alias} size="small" fullWidth />
                                )}
                                onInputChange={(_e, value) => {
                                  setAttributesSkyTv((attributes) => ({
                                    ...attributes,
                                    [attribute.attribute_name]: value || undefined,
                                  }));
                                }}
                              />
                            ) : (
                              <TextField
                                label={attribute.alias}
                                size="small"
                                fullWidth
                                value={attributesSkyTv[attribute.attribute_name] || ''}
                                disabled={attributeFiltersActiveBroad || attributeFiltersActiveSkyQ}
                                onChange={(e) => {
                                  setAttributesSkyTv((attributes) => ({
                                    ...attributes,
                                    [attribute.attribute_name]: e.target.value || undefined,
                                  }));
                                }}
                              />
                            )}
                          </div>
                        ))}
                    </Box>
                  </Box>
                  <Box sx={{ padding: 2 }}>
                    <Divider />
                  </Box>
                </>
              ) : (
                ''
              )}

              {/*Third Section*/}
              {hasGrants(SEARCH_CUSTOMER_Q_GRANTS) &&
              customerAttributes.filter((attribute) => attribute.category === 'Q_LEGACY').length ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.8)', fontWeight: 500 }}>Sky Q</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        '&>div': {
                          flex: '0 0 22%',
                          paddingX: 1,
                          paddingY: 1,
                          '&:last-child': {
                            flex: '0 0 auto',
                            minWidth: '22%',
                            marginLeft: '0',
                            marginRight: 'auto',
                          },
                        },
                      }}
                    >
                      {customerAttributes
                        .filter((attribute) => attribute.category === 'Q_LEGACY')
                        .map((attribute) => (
                          <div key={attribute.attribute_name}>
                            {attribute.possible_values.length ? (
                              <Autocomplete
                                disablePortal
                                disableClearable
                                options={attribute.possible_values}
                                value={attributesSkyQ[attribute.attribute_name] || ''}
                                disabled={attributeFiltersActiveBroad || attributeFiltersActiveSkyTv}
                                renderInput={(params) => (
                                  <TextField {...params} label={attribute.alias} size="small" fullWidth />
                                )}
                                onInputChange={(_e, value) => {
                                  setAttributesSkyQ((attributes) => ({
                                    ...attributes,
                                    [attribute.attribute_name]: value || undefined,
                                  }));
                                }}
                              />
                            ) : (
                              <TextField
                                label={attribute.alias}
                                size="small"
                                fullWidth
                                value={attributesSkyQ[attribute.attribute_name] || ''}
                                disabled={attributeFiltersActiveBroad || attributeFiltersActiveSkyTv}
                                onChange={(e) => {
                                  setAttributesSkyQ((attributes) => ({
                                    ...attributes,
                                    [attribute.attribute_name]: e.target.value || undefined,
                                  }));
                                }}
                              />
                            )}
                          </div>
                        ))}
                    </Box>
                  </Box>
                  <Box sx={{ padding: 2 }}>
                    <Divider />
                  </Box>
                </>
              ) : (
                ''
              )}
            </Box>
          </LoaderView>
        </CardContent>

        {/*Reset and search section*/}
        <CardActions sx={{ background: (theme) => theme.palette.background.paper }}>
          <Button type="reset" variant="text" sx={{ marginRight: 2, marginLeft: 'auto' }}>
            {t('actions:reset')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Magnify />}
            disabled={!attributeFiltersActiveBroad && !attributeFiltersActiveSkyTv && !attributeFiltersActiveSkyQ}
            loading={readCustomerListRequest.inProgress}
          >
            {t('actions:search')}
          </LoadingButton>
        </CardActions>
      </Box>
    </Card>
  );
}
