import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { System, SystemAttribute } from '../../entities/System';
import { IColumn } from '../../entities/Table';
import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestState,
  RequestParseFailureAction,
  requestParseState,
  mutateParseErrorState,
} from '../toolkitUtils';

const initialState = {
  id: undefined as undefined | System['netktID'],
  list: [] as System[],
  attributes: [] as SystemAttribute[],
  requests: {
    read: requestState(),
    readList: {
      ...requestState(),
      total: 0 as number,
      columns: [] as IColumn[],
    },
    readAttributes: requestParseState(),
  },
};

interface ReadSystemListSuccessAction {
  response: Array<System>;
  total: number;
  columns: IColumn[];
}

interface readSystemAttributesSuccessAction {
  response: Array<SystemAttribute>;
}

const systemsSlice = createSlice({
  name: 'systems',
  initialState,
  reducers: {
    // List
    readSystemListRequest(state) {
      mutateRequestState(state.requests.readList);
    },
    readSystemListSuccess(state, action: PayloadAction<ReadSystemListSuccessAction>) {
      const { response, total, columns } = action.payload;

      state.list = [...state.list, ...response];

      mutateSuccessState(state.requests.readList);
      state.requests.readList.total = total;
      state.requests.readList.columns = columns;
    },
    readSystemListFailure(state, action: PayloadAction<RequestFailureAction>) {
      state.list = [];
      mutateErrorState(state.requests.readList, action.payload.error);
    },
    clearList(state) {
      state.list = [];
    },

    // Attributes
    readSystemAttributesRequest(state) {
      mutateRequestState(state.requests.readAttributes);
    },
    readSystemAttributesSuccess(state, action: PayloadAction<readSystemAttributesSuccessAction>) {
      const { response } = action.payload;

      state.attributes = [...response];

      mutateSuccessState(state.requests.readAttributes);
    },
    readSystemAttributesParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readAttributes, action.payload.parseError);
    },
    readSystemAttributesFailure(state, action: PayloadAction<RequestFailureAction>) {
      state.attributes = [];
      mutateErrorState(state.requests.readAttributes, action.payload.error);
    },

    reset(state) {
      state.id = initialState.id;
      state.list = initialState.list;
      state.attributes = initialState.attributes;
      state.requests = initialState.requests;
    },
  },
});

export const systemActions = systemsSlice.actions;

export default systemsSlice.reducer;
