import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '../../entities/Customer';
import { Wizard } from '../../entities/Wizard';

import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  mutateParseErrorState,
  RequestParseFailureAction,
  requestParseState,
} from '../toolkitUtils';

const initialState = {
  step: undefined as Wizard | undefined,
  stepError: undefined as undefined | unknown,
  outdatedAlertSuppressed: undefined as string | undefined,
  clickedButton: undefined as
    | {
        name: string;
        value: string | number;
        type: string;
      }
    | undefined,
  requests: {
    readStep: {
      ...requestParseState(),
      customerId: undefined as string | undefined,
    },
    readStatus: {
      ...requestParseState(),
      customerId: undefined as string | undefined,
    },
    createFeedback: requestParseState(),
    createCloseTheLoop: requestParseState(),
  },
};

export type WizardState = typeof initialState;

interface ReadWizardRequestAction {
  customerId: string | undefined;
}

interface ReadWizardSuccessAction {
  customerId: Customer['id'];
  response: Wizard;
}

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    readWizardRequest(state, action: PayloadAction<ReadWizardRequestAction>) {
      if (action.payload.customerId !== state.requests.readStep.customerId) {
        state.requests.readStep.customerId = action.payload.customerId;
        state.requests.readStep.lastUpdate = null;
        state.requests.readStep.error = null;
        state.step = undefined;
        state.outdatedAlertSuppressed = undefined;
        state.clickedButton = undefined;
      }
      mutateRequestState(state.requests.readStep);
    },
    readWizardSuccess(state, action: PayloadAction<ReadWizardSuccessAction>) {
      mutateSuccessState(state.requests.readStep);
      state.step = action.payload.response;
    },
    readWizardFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readStep, action.payload.error);
      if (action.payload.error.axiosError?.response?.data) {
        state.stepError = action.payload.error.axiosError.response.data;
        state.step = undefined;
      }
    },
    readWizardParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.readStep, action.payload.parseError);
    },
    createFeedbackRequest(state) {
      mutateRequestState(state.requests.createFeedback);
    },
    createFeedbackSuccess(state) {
      mutateSuccessState(state.requests.createFeedback);
    },
    createFeedbackFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.createFeedback, action.payload.error);
    },
    createCloseTheLoopRequest(state) {
      mutateRequestState(state.requests.createCloseTheLoop);
    },
    createCloseTheLoopSuccess(state) {
      mutateSuccessState(state.requests.createCloseTheLoop);
    },
    createCloseTheLoopFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.createCloseTheLoop, action.payload.error);
    },
    suppressOutdatedAlert(state, action: PayloadAction<{ wizardId: string }>) {
      state.outdatedAlertSuppressed = action.payload.wizardId;
    },
    setClickedButton(state, action: PayloadAction<WizardState['clickedButton']>) {
      state.clickedButton = action.payload;
    },
  },
});

export const wizardActions = wizardSlice.actions;

export default wizardSlice.reducer;
