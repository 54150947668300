import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  error: undefined as undefined | Error,
};

export type WebSocketeState = typeof initialState;

interface WebSocketFailureAction {
  error: Error;
}

const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState,
  reducers: {
    webSocketError(state, action: PayloadAction<WebSocketFailureAction>) {
      state.error = action.payload.error;
    },
    webSocketUnexpectedMessageError(state, action: PayloadAction<WebSocketFailureAction>) {
      state.error = action.payload.error;
    },
    webSocketUnexpectedCloseError(state, action: PayloadAction<WebSocketFailureAction>) {
      state.error = action.payload.error;
    },
  },
});

export const webSocketActions = webSocketSlice.actions;

export default webSocketSlice.reducer;
