const it = {
  common: {
    languageName: 'Italiano',
    appName: 'Sky Wifi Assurance',
    actions: 'Azioni',
    next: 'Avanti',
    back: 'Indietro',
    yes: 'Si',
    no: 'No',
    close: 'Chiudi',
    end: 'Fine',
    noInfoAvailable: 'Nessuna informazione disponibile.',
    active: 'Attivo',
    ok: 'Ok',
    ko: 'Ko',
    goBack: 'Torna indietro',
    genericConfirmMessage: "Confermare l'azione richiesta?",
    noData: '--',
    notAvailable: 'N/A',
    noCaseIdFound:
      'E’ stata lanciata una nuova diagnostica da Arcadia. <br />Riapri NGAS dal button “troubleshooting evoluto” del case per completare correttamente la gestione',
  },
  errorAlert: {
    errorOccured: 'Si è verificato un errore',
    tryReloadingPage: 'Prova a ricaricare la pagina.',
    contactSupport: "Se il problema persiste, contattare l'assistenza allegando i dettagli sull'errore:",
    copyErrorDetails: 'Copia dettagli errore',
    reloadThePage: 'Ricarica la pagina',
  },
  actions: {
    add: 'Aggiungi',
    apply: 'Applica',
    cancel: 'Annulla',
    cancelSelection: 'Annulla selezione',
    confirm: 'Conferma',
    clone: 'Clona',
    close: 'Chiudi',
    execute: 'Esegui',
    confirmChanges: 'Conferma modifiche',
    deleteSelected: 'Elimina selezionati',
    removeFilter: 'Rimuovi filtro',
    search: 'Cerca',
    selectAll: 'Seleziona tutto',
    send: 'Invia',
    update: 'Aggiorna',
    reset: 'Reset',
    inputRequired: 'Valore obbligatorio',
    show: 'Mostra tutto',
    startAction: 'Avvia azione',
    selectAction: 'Seleziona Azione',
  },
  components: {
    dataGrid: {
      noRowsLabel: 'Nessun risultato',
      errorOverlayDefaultLabel: 'Si è verificato un errore.',
      columnHeaderSortIconLabel: 'Ordina',
      footerRowSelected: '{{count}} riga selezionata',
      footerRowSelected_plural: '{{count}} righe selezionata',
      footerTotalRows: 'Righe totali:',
      footerPaginationRowsPerPage: 'Righe per pagina:',
    },
    buttonTooltip: {
      noDescription: '-- Descrizione non disponibile --',
    },
  },
  entities: {
    customer: {
      id: 'Codice cliente',
    },
    system: {
      id: 'ID MSO',
    },
    wizardStatus: {
      idle: 'Fermo',
      active: 'Attivo',
      success: 'Successo',
      suspended: 'Sospeso',
      failed: 'Fallito',
    },
  },
  navigation: {
    dashboard: 'Dashboard cliente {{id}}',
    testsHistory: 'Storico test',
  },
  navbar: {
    glossary: 'Glossario',
    reloadButton: 'Aggiorna informazioni cliente',
    timeline: 'Timeline',
    searchCustomer: 'Ricerca cliente',
    downDetector: 'Down Detector',
    l2Dashboard: 'L2 Dashboard',
    mso: 'MSO',
    drawer: {
      signOut: 'Log-out',
      level: 'Livello',
      selectLevel: 'Cambio Livello',
      selectLevelHelper: 'Seleziona il livello',
      selectLevelDialog: {
        title: 'Livello Utente',
        text: 'Sei sicuro di voler procedere al cambio di livello?',
      },
      settings: {
        title: 'Impostazioni',
        language: 'Lingua',
        selectLanguage: 'Seleziona Lingua',
        darkMode: 'Modalità scura',
      },
    },
  },
  authentication: {
    user: 'Utente',
    signIn: 'Accedi',
    signOut: 'Disconnettiti',
    invalidUsernameOrPassword: 'Nome utente o password non validi',
  },

  dashboard: {
    suggestions: 'Suggerimenti:',
    charts: {
      threshold: 'Soglia',
      testDate: 'Data di esecuzione test',
    },
    customerInfo: {
      customerId: `Cliente {{customerId}}`,
      info: 'Info',
      hub: 'HUB',
      pod: 'SPOT',
      configurations: 'Configurazioni',
      lineType: 'Tipo linea',
      POP: 'POP',
      OLT: 'OLT',
      KIT: 'KIT',
      BNG: 'BNG',
      activation: 'Attivazione',
      voice: 'Voce',
      firmwareVersion: 'Ver. firmware',
      lastUpgrade: 'Ultimo upgrade',
      lastReboot: 'Ultimo reboot',
      mac: 'MAC',
      active_plural: 'Attivi',
      xFiProvisionDevice: 'xFi Provision Device',
      xFiVoice: 'xFi Voice',
      xFiFtue: 'xFi FTUE',
      xFiMeshAgent: 'xFi MESH Agent',
      swap: 'SWAP',
      alert: 'Problemi riscontrati',
      wizardSteps: 'Esito problemi analizzati',
      noAlert: "Nessun problema riscontrato nell'ultimo test effettuato",
      customerInfo: 'Informazioni del Cliente',
      advancedAnalysis: 'Analisi avanzata',
      devices: 'Dispositivi connessi',
      graphPlotting: 'Disegno del grafico in corso...',
      actions: 'Azioni dispositive',
      dialogs: {
        connectedDevices: {
          connectedDevices: 'Device connessi',
          mainDetails: 'Dettagli Principali',
          additionalDetails: 'Additional Details',
          noDetails: 'Nessun dettaglio da mostrare',
          tooltip: 'Clicca su un dispositivo per visualizzare i dettagli.',
          signalQuality: {
            title: 'Qualità del segnale',
            excellent: 'Eccellente',
            good: 'Buona',
            fair: 'Debole',
            poor: 'Pessima',
            none: 'N/D',
          },
          connectionType: {
            title: 'Tipo di connessione',
            cable: 'Cavo',
            wifi2: '2.4 GHz Wi-Fi',
            wifi5: '5 GHz Wi-Fi',
          },
          lastUpdate: 'Ultimo aggiornamento',
          updateInfo: 'Aggiorna informazioni',
          viewOptions: 'Opzioni di visualizzazione',
          showInactive: 'Mostra device non attivi',
          warning: 'Canale Wi-Fi non visualizzabile per uno o più dispositivi',
          disconnectedSpots: 'SPOT Disconnessi',
          history: 'Storico',
          loading: 'Caricamento Configurazione Dispositivi...',
        },
        hubCpuRamTrend: {
          hubCpuRamTrend: 'Trend CPU & RAM HUB',
        },
        rssiSnrTrend: {
          rssiSnrTrend: 'Trend RSSI / SNR',
        },
        rxTxTrend: {
          rxTxTrend: 'Trend RX / TX',
        },
      },
      actionsButtons: {
        runManualTests: 'Esegui test manuali',
        restartHub: 'Riavvio HUB',
        resetHub: 'Reset HUB',
        riavvioMesh: 'Riavvio MESH',
        upgradeFw: 'Upgrade FW',
      },
    },
    test: {
      lastTest: 'Ultimo test',
      diagnosticsLog: 'Storico diagnostica',
      testOk: 'Test OK',
      testFailed: 'Test fallito: {{statusMessage}}',
      compare: 'Confronta',
      compareTests: 'Confronta test',
      tooManyItemsSelected: 'Puoi confrontare al massimo tre test',
      selectToCompare: 'Seleziona questo test per confrontarlo',
      noTestAvailable: 'Nessun test effettuato.',
      wizardinfo: 'Informazioni wizard',
      result: 'Esito:',
      inProgress: 'In corso',
      end: 'Fine: ',
      start: 'Inizio: ',
      test: 'Test: ',
      caseId: 'Case ID: ',
      autoClosingRilascioDialogMessage: "L'operatore ha correttamente rilasciato il case in oggetto",
      caseHistory: 'Storico Case',
      caseDialog: {
        conditionMessage: "L'operatore ha correttamente rilasciato il case in oggetto",
        releaseTitle: 'Esito rilascio:',
        releaseMessage:
          'Il rilascio è stato completato con successo! <br />Tra pochi secondi si verrà disconnessi dalla sessione in corso...',
        reserveTitle: 'Esito prenotazione case:',
        error: 'Errore.',
        success: 'Operazione riuscita.',
        warning: 'Attenzione.',
      },
    },
    hubDetailsDialogCase: {
      loadingMessage: 'Caricamento storico case...',
      openCases: 'Case Aperti',
      closedCases: 'Case Chiusi',
      errorMessage: 'Si è verificato un problema interno, riprovare.',
      noData: 'Nessun dato da mostrare.',
      opening: 'Apertura: ',
      closure: 'Chiusura: ',
      category: 'Categoria:',
      subCategory: 'Sotto-categoria:',
      motive: 'Motivo:',
      motiveDetails: 'Dettaglio motivo:',
      status: 'Stato:',
      subStatus: 'Sotto-stato:',
      globalStatus: 'Stato globale:',
      outcome: 'Esito:',
      escalations: {
        title: 'Escalations',
        team: 'Team:',
        category: 'Categoria:',
        impact: 'Impatti:',
        oloId: 'Olo id:',
        status: 'Stato:',
        subStatus: 'Sotto-stato:',
      },
      interactions: {
        title: 'Interactions',
        status: 'Stato:',
        interactionType: 'Tipo interazione:',
        appointmentId: 'Appuntamento:',
      },
    },
    hubTestDetails: {
      config: 'CONFIG',
      hubPod: 'HUB & SPOT',
      wifi: 'Wi-Fi',
      eth: 'ETH',
      runTestAgain: 'Riesegui test',
      details: 'Dettaglio',
      relevantInfo: 'Info rilevanti',
      testStatus: 'Stato test',
      testDate: 'Data test',
      issueDescription: 'Descrizione problema',
    },
    hubDetailsDialogMapT: {
      title: 'Storico Map T',
      fields: {
        date_time: 'Time',
        ticket_id: 'Ticket ID',
        status: 'Status',
        reason_text: 'Reason Text',
        cgnat_state: 'CGNAT State',
        caller: 'Caller',
        activation_completion_date_time: 'Activation Completion',
        mac_address: 'MAC Address',
      },
    },
    recommendedActions: {
      recommendedActions: 'Azioni raccomandate',
      noActionAvailable: 'Nessuna azione raccomandata.',
      oldWizard:
        'Questo wizard risale a tre giorni fa o più. Per evitare eventuali errori di analisi su una situazione modificata lato cliente si consiglia di rieseguire la Diagnostica.',
      wizard: 'Procedura Guidata',
    },
    wizard: {
      picklistSelect: 'Seleziona una tipologia',
      confirmButtonDialog: {
        closeTitle: 'Chiudi',
        closeMessage: 'Attenzione: tutte le procedure di risoluzione verranno annulate. Continuare?',
      },
      results: {
        title: 'Esiti Problemi Analizzati',
        subtitle: 'Esito Procedura Guidata',
        outcome: 'Esito',
        description: 'Descrizione Problema',
        caseHesitation: 'Esitazione Case',
        duplicatedCases: 'Case Duplicati',
        duplicatedEscalations: 'Escalations Duplicati',
      },
      feedback: {
        title: 'Procedura risoluzione guidata',
        description: 'Premere "Avanti" per continuare o terminare la procedura guidata.',
      },
      wizard0: {
        name: 'Nome',
        description: 'Descrizione',
        suggestions: 'Consigli',
        tooltip: 'Non possiedi i permessi sufficienti per deselezionare questo controllo.',
      },
    },
  },
  searchCustomer: {
    accessDenied: 'Accesso Negato',
    broadband: 'Broadband',
    tv: 'Sky TV',
  },
  landingPage: {
    problemOccurred: 'Si è verificato un problema',
    stateParameterError: 'Il parametro --state-- è invalido, la decodifica in base64 è fallita.',
    codeParameterError: 'Il parametro --code-- non è stato trovato.',
    redirectMessage: 'Ridirezionamento in <strong>{{timer}}</strong> secondi',
  },
  downDetector: {
    search: 'Ricerca un Servizio',
    loading: 'Caricamento servizi...',
    info:
      'I risultati con la mappa in sottoimpressione hanno <strong>abilitato</strong> il servizio di <strong> tracciatura geografica</strong> delle segnalazioni.',
    poweredBy: 'Powered by <strong>Downdetector®</strong>',
    chartLabel: 'Segnalazioni di interruzione servizio',
    heatMapDefaultError: 'Errore nel caricamento dati della Heatmap.',
    heatMapDisabled: `Il servizio di tracciatura geografica delle segnalazioni non è supportata per questo servizio. Per un'analisi più dettagliata puoi collegarsi al seguente link:`,
  },
  wizard: {
    backButtonAlert: {
      title: 'Alert',
      body: "Non è più possibile tornare indietro essendo già stata avviata l'azione dispositiva",
    },
    wizard: {
      picklistSelect: 'Seleziona una tipologia',
      confirmButtonDialog: {
        closeTitle: 'Chiudi',
        closeMessage: 'Attenzione: tutte le procedure di risoluzione verranno annulate. Continuare?',
      },
      results: {
        title: 'Esiti Problemi Analizzati',
        subtitle: 'Esito Procedura Guidata',
        outcome: 'Esito',
        description: 'Descrizione Problema',
        caseHesitation: 'Esitazione Case',
        duplicatedCases: 'Case Duplicati',
        duplicatedEscalations: 'Escalations Duplicati',
      },
      feedback: {
        title: 'Procedura risoluzione guidata',
        description: 'Premere "Avanti" per continuare o terminare la procedura guidata.',
      },
      wizard0: {
        name: 'Nome',
        description: 'Descrizione',
        suggestions: 'Consigli',
        tooltip: 'Non possiedi i permessi sufficienti per deselezionare questo controllo.',
      },
    },
  },
};

export default it;
