import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChartElement,
  ControllerElement,
  L2DashboardColumn,
  L2Tab,
  LegendElement,
  MarkdownElement,
  TableElement,
} from '../../entities/L2Dashboard';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  RequestParseFailureAction,
  requestParseState,
} from '../toolkitUtils';

const initialState = {
  tabs: [] as {
    dashboard: L2Tab;
    elements: Array<ControllerElement | LegendElement | ChartElement | TableElement | MarkdownElement>;
  }[],
  reloadingElementIds: [] as string[],
  requests: {
    tabs: requestParseState(),
    layout: requestParseState(),
    elements: requestParseState(),
  },
};

const l2DashboardSlice = createSlice({
  name: 'l2Dashboard',
  initialState,
  reducers: {
    // Tabs
    readTabsRequest(state) {
      mutateRequestState(state.requests.tabs);
    },
    readTabsSuccess(state, action: PayloadAction<L2Tab[]>) {
      const response = action.payload;
      state.tabs = response.map((dashboard) => {
        return {
          dashboard: dashboard,
          elements: [],
        };
      });
      mutateSuccessState(state.requests.tabs);
    },
    readTabsFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.tabs, action.payload.error);
    },
    readTabsParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.tabs, action.payload.parseError);
    },
    // Layout
    readLayoutRequest(state) {
      mutateRequestState(state.requests.layout);
    },
    readLayoutSuccess(state, action: PayloadAction<{ tabId: string; data: L2DashboardColumn }>) {
      const affectedTab = state.tabs.find((tab) => tab.dashboard.id === action.payload.tabId);
      if (affectedTab) affectedTab.dashboard.layout = action.payload.data;
      mutateSuccessState(state.requests.layout);
    },
    readLayoutFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.layout, action.payload.error);
    },
    readLayoutParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.layout, action.payload.parseError);
    },
    // Elements
    addReloadingElementIds(state, action: PayloadAction<string[]>) {
      state.reloadingElementIds = [...state.reloadingElementIds, ...action.payload];
    },
    removeReloadingElementIds(state, action: PayloadAction<string[]>) {
      state.reloadingElementIds = state.reloadingElementIds.filter((elId) => !action.payload.includes(elId));
    },
    resetElements(state, action: PayloadAction<{ tabId: string }>) {
      state.tabs
        .find((tab) => tab.dashboard.id === action.payload.tabId)
        ?.elements.splice(0, state.tabs.find((tab) => tab.dashboard.id === action.payload.tabId)?.elements.length);
    },
    readElementsRequest(state) {
      mutateRequestState(state.requests.elements);
    },
    readElementsSuccess(
      state,
      action: PayloadAction<{
        tabId: string;
        data: Array<ControllerElement | LegendElement | ChartElement | TableElement | MarkdownElement>;
      }>
    ) {
      const affectedTab = state.tabs.find((tab) => tab.dashboard.id === action.payload.tabId);
      if (affectedTab) {
        action.payload.data.forEach((newEl) => {
          let present = false;
          affectedTab.elements?.forEach((oldEl, index) => {
            if (oldEl.id === newEl.id) {
              affectedTab.elements[index] = newEl;
              present = true;
            }
          });
          if (!present) affectedTab.elements.push(newEl);
        });
      }
      mutateSuccessState(state.requests.elements);
    },
    readElementsFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.elements, action.payload.error);
    },
    readElementsParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.elements, action.payload.parseError);
    },
  },
});

export const l2DashboardActions = l2DashboardSlice.actions;

export default l2DashboardSlice.reducer;
