import React, { useEffect, useState } from 'react';
import ChevronUp from '@mitch528/mdi-material-ui/ChevronUp';
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import { useTheme } from '@mui/system';

export function CompactAccordion(
  props: PaperProps & {
    expanded?: boolean;
    onChange?: () => void;
    summary: React.ReactNode;
  }
): JSX.Element {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(props.expanded);
  const [chevronBackgroundColor, setChevronBackgroundColor] = useState('transparent');

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);

  return (
    <Paper
      sx={{
        mb: 2,
        background: (theme) => theme.palette.background.paper,
        ...props.sx,
      }}
    >
      <Box
        onClick={() => {
          if (props.onChange) {
            props.onChange();
          }
          setExpanded((e) => !e);
        }}
        sx={{
          display: 'flex',
          p: 2,
          alignItems: 'center',
          cursor: 'pointer',
          '&:first-of-type': {
            marginTop: 0,
          },
        }}
      >
        <Box sx={{ flex: '1 1 auto' }}>{props.summary}</Box>
        <Box
          sx={{
            display: 'flex',
            backgroundColor: chevronBackgroundColor,
            transition: '0.25s',
            alignItems: 'flex-end',
            borderRadius: 50,
          }}
          onMouseOver={() => setChevronBackgroundColor(theme.palette.text.disabled)}
          onMouseLeave={() => setChevronBackgroundColor('transparent')}
        >
          <ChevronUp style={{ transform: `rotate(${expanded ? 180 : 0}deg)`, transition: '0.1s' }} />
        </Box>
      </Box>
      <Box sx={{ maxHeight: expanded ? undefined : '0px', overflow: 'hidden' }}>
        <Box>{props.children}</Box>
      </Box>
    </Paper>
  );
}
