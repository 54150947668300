import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration } from 'chart.js';
import { useTheme } from '@mui/system';

export type L2PieChartSerie = {
  data_class: string;
  value: number;
  color: string;
  hidden?: boolean;
};

interface L2PieChartProps {
  series: L2PieChartSerie[];
}

export default function PieChart({ series }: L2PieChartProps): JSX.Element {
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });

  const theme = useTheme();

  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    if (ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        const data = {
          labels: series.map((serie) => serie.data_class),
          datasets: [
            {
              label: 'My First Dataset',
              data: series.filter((serie) => !serie.hidden).map((serie) => serie.value),
              backgroundColor: series.filter((serie) => !serie.hidden).map((serie) => serie.color),
              borderColor: series.filter((serie) => !serie.hidden).map((serie) => serie.color),
              hoverOffset: 6,
            },
          ],
        };

        const chartOptions = {
          type: 'pie',
          data: data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: 24,
            },
            plugins: {
              tooltip: {
                enabled: true,
              },
              legend: {
                display: false,
              },
            },
          },
        } as ChartConfiguration<'pie'>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart<'pie'>(ctx, chartOptions);
      }
    }
  }, [series, theme.palette.text.primary]);

  return <canvas ref={ref} />;
}
