import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CustomerArea, CustomerProduct } from '../../../entities/Customer';
import useAuthentication from '../../../hooks/useAuthentication';
import { useCustomer } from '../../../hooks/useCustomer';
import { UseCustomerDataContext } from '../../../hooks/useCustomerData';
// import { useDownDetector } from '../../../hooks/useDownDetector';
import { useGlossary } from '../../../hooks/useGlossary';
import { useHubActions } from '../../../hooks/useHubActions';
import { useHubTest } from '../../../hooks/useHubTest';
import { useWebSocket } from '../../../hooks/useWebSocket';
import ApplicationErrorProvider from '../../ApplicationError/ApplicationErrorProvider';
import ApplicationSuggestions from '../../ApplicationSuggestion';
import { useBreadcrumbs } from '../../Breadcrumbs';
//import DownDetectorCompanyCard from '../../DownDetectorCompanyCard';
//import LoaderView from '../../LoaderView';
import Navbar from '../../Navbar/Navbar';
import SearchCustomer from '../SearchCustomer';
import CaseSyntheticInfo from './CaseSyntheticInfo';
import CustomerInfo from './CustomerInfo';
import LastTestSyntheticInfo from './LastTestSyntheticInfo';
import UserInfo from './LeftColumn';
import TestsHistory from './TestsHistory';
import Wizard from './Wizard/Wizard';

export default function DashboardUserIdGuard(): JSX.Element {
  return (
    <Switch>
      <Route exact path={`/search-customer`} component={SearchCustomer} />
      <Route path={`/dashboard/:customer`} component={Dashboard} />
      <Redirect to="/search-customer" />
    </Switch>
  );
}

function Dashboard(): JSX.Element {
  const { customer } = useParams() as { customer?: string | undefined };
  const { user } = useAuthentication();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { connect, disconnect } = useWebSocket();
  const { readHidden, reset: resetCustomerState, readCustomerActionList, customerActionList } = useCustomer();
  const { reset: resetHubActionsState, autoRunTest } = useHubActions();
  const { reset: resetHubTestState, lastTest } = useHubTest();
  const { readGlossary } = useGlossary();
  // const {
  //   embeddedServiceDashboardList,
  //   readEmbeddedServiceDashboardList,
  //   readEmbeddedServiceDashboardListRequest,
  // } = useDownDetector();
  useBreadcrumbs({ label: t('navigation:dashboard', { id: customer }), path: url, showInTitle: false });

  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  const area = params.get('area') ?? CustomerArea.BB;
  const product = params.get('product') ?? CustomerProduct.WIFI;

  useEffect(() => {
    if (customer) {
      connect(customer); // Connects to WS
      readHidden(customer);
      readCustomerActionList(customer);
      readGlossary(customer, area, product);
      // readEmbeddedServiceDashboardList();
    }
  }, [connect, customer, area, product, readCustomerActionList, readGlossary, readHidden]);

  // START Handle Autorun Test

  const [autoRun, setAutorun] = useState(false);

  useEffect(() => {
    if (customer) {
      setAutorun(true);
    }
  }, [customer]);

  useEffect(() => {
    if (
      customer &&
      user?.level === 2 &&
      customerActionList &&
      lastTest?.in_progress !== true &&
      lastTest?.trigger_mode === 'automatic'
    ) {
      const rulesSections = customerActionList?.actions?.find((a) => a.action_id === 'execute-test')
        ?.frontend_modal_options;
      const rules = Object.values(rulesSections || [])
        .map((s) => s.rules)
        .flat();
      if (rules.length && autoRun) {
        setAutorun(false);
        autoRunTest(customer, {
          action_id: 'execute-test',
          options: rules,
        });
      }
    }
  }, [customer, user?.level, customerActionList, autoRunTest, lastTest, autoRun]);

  // END Handle Autorun Test

  useEffect(() => {
    return () => {
      disconnect();
      resetCustomerState();
      resetHubActionsState();
      resetHubTestState();
    };
  }, [disconnect, resetCustomerState, resetHubActionsState, resetHubTestState]);

  if (!customer) {
    return <Redirect to="/search-customer/" />;
  }

  return (
    <Fragment>
      <UseCustomerDataContext.Provider value={{ customerId: customer }}>
        <Navbar customerId={customer} />
        <ApplicationSuggestions />
        <ApplicationErrorProvider>
          <Switch>
            <Route path={`${url}/history`} render={() => <TestsHistory />} />
            <Route
              render={() => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: (theme) => theme.palette.background.default,
                    '&, & > .MuiGrid-root': {
                      display: 'flex',
                      flex: '1 1 0',
                      minHeight: 0,
                      alignContent: 'stretch',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', px: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }}>
                      <CustomerInfo />
                    </Box>

                    {/* <Box sx={{ flex: '0 1 auto', paddingBottom: 1 }}>
                      <LoaderView condition={!readEmbeddedServiceDashboardListRequest.inProgress} minWidth={256}>
                        {embeddedServiceDashboardList.map((service) => (
                          <DownDetectorCompanyCard
                            key={service.id.toString()}
                            name={service.name}
                            logo={service.logos.xsmall}
                            slug={service.slug}
                            stats_24={service.stats_24}
                            sx={{ mt: i === 0 ? 0 : 1, height: 'calc(50% - 2px)' }}
                          />
                        ))}
                      </LoaderView>
                    </Box> */}
                  </Box>

                  <Box sx={{ marginInline: 8, marginBlock: 6, display: 'flex', flexDirection: 'row' }}>
                    <LastTestSyntheticInfo />
                    {user?.level === 1 && area === CustomerArea.BB ? <CaseSyntheticInfo /> : ''}
                  </Box>

                  <Grid container paddingX={2} paddingY={0} spacing={2}>
                    <Grid item md={3} xl={3} sx={{ height: '100%' }}>
                      <UserInfo />
                    </Grid>
                    <Grid item md={9} xl={9} sx={{ height: '100%' }}>
                      <Wizard />
                    </Grid>
                  </Grid>
                </Box>
              )}
            />
          </Switch>
        </ApplicationErrorProvider>
      </UseCustomerDataContext.Provider>
    </Fragment>
  );
}
