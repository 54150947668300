import React, { useEffect, useRef, useState } from 'react';
import {
  ArcElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartDataset,
  ChartEvent,
  Filler,
  Legend,
  LegendItem,
  LinearScale,
  LinearScaleOptions,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import { differenceInMinutes, format, minutesToHours } from 'date-fns';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/system';

import { useMaterialColors } from '../../utils/materialColors';

Chart.register(
  ArcElement,
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  zoomPlugin
);

export type LabelMappingItem = {
  x?: number | undefined;
  y?: number | undefined;
  label: string;
};

export type MultiLineChartSerie = {
  data: Array<{ x: number; y: number }>;
  thresholds?: Array<{ name: string; value: number }>;
  label: string;
  axisId?: string | undefined;
  formatAxisLabel: (value: number) => string;
  formatTooltipLabel: (value: number) => string;
  y_min?: number | undefined;
  y_max?: number | undefined;
  axisUnit?: string | undefined;
  x_mappings?: LabelMappingItem[];
  y_mappings?: LabelMappingItem[];
  y_unit?: string;
  y_label?: string;
};

interface MultiLineChartProps {
  visible: boolean;
  series: MultiLineChartSerie[];
  reset?: boolean;
  onZoom?: (value: boolean) => void;
}

const MAX_TICKS = 18;

export default function MultiLineChart(props: MultiLineChartProps): JSX.Element {
  const [cursor, setCursor] = useState<string | undefined>();
  const { visible, series, reset, onZoom } = props;
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });

  const [hiddenSeries, setHiddenSeries] = useState<Record<number, boolean>>({});

  const colors = useMaterialColors(series.length);
  const theme = useTheme();

  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    if (visible && ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx && series[0]) {
        const datasets: ChartDataset[] = [];

        let minTimestamp;
        let maxTimestamp;

        for (let i = 0; i < series.length; i++) {
          const serie = series[i];

          if (serie) {
            datasets[i] = {
              label: serie.label,
              data: serie.data.map(({ x, y }) => ({
                x,
                y,
              })),
              fill: false,
              borderColor: colors[i],
              backgroundColor: colors[i],
              borderWidth: 1,
              pointBorderColor: colors[i],
              pointBackgroundColor: colors[i],
              pointBorderWidth: 0,
              pointRadius: 3,
              pointHoverBackgroundColor: 'white',
              pointHoverBorderColor: colors[i],
              pointHoverBorderWidth: 2,
              pointHoverRadius: 4,
              yAxisID: serie.axisUnit || 'nounit',
              type: 'line' as const,
            };

            minTimestamp = Math.min(
              minTimestamp || Number.MAX_SAFE_INTEGER,
              serie.data[0]?.x || Number.MAX_SAFE_INTEGER
            );
            maxTimestamp = Math.max(
              maxTimestamp || Number.MIN_SAFE_INTEGER,
              serie.data[serie.data.length - 1]?.x || Number.MIN_SAFE_INTEGER
            );
          }
        }

        const ticksIntervalInMinutes =
          Math.round(differenceInMinutes(maxTimestamp ?? 0, minTimestamp ?? 0) / MAX_TICKS / 15) * 15;

        const yScales: Record<string, DeepPartial<LinearScaleOptions>> = {};
        let allScalesMin = Number.MAX_SAFE_INTEGER;
        let allScalesMax = Number.MIN_SAFE_INTEGER;

        for (let i = 0; i < props.series.length; i++) {
          const serie = props.series[i];

          if (serie && typeof serie.axisUnit !== 'undefined') {
            if (serie.y_min || serie.y_min === 0) allScalesMin = Math.min(allScalesMin, serie.y_min);
            if (serie.y_max || serie.y_max === 0) allScalesMax = Math.max(allScalesMax, serie.y_max);

            yScales[serie.axisUnit || 'nounit'] = {
              // position: Object.keys(yScales).length > 1 && i === 1 ? 'left' : 'right',
              position: 'left',
              min: Math.round(allScalesMin - ((allScalesMax - allScalesMin) / 100) * 10 - 1),
              max: Math.round(allScalesMax + ((allScalesMax - allScalesMin) / 100) * 10 + 1),
              ticks: {
                callback: (value) => serie.formatAxisLabel(Number(value)),
                color: theme.palette.text.primary + '9a',
              },
              grid: {
                color: `${theme.palette.text.primary}1a`,
              },
              afterTickToLabelConversion: (axis) => {
                series.forEach((serie: MultiLineChartSerie, index: number) => {
                  if (serie.y_mappings) {
                    axis.ticks.forEach((altitude) => {
                      if (index === 0) altitude.label = '';
                      serie?.y_mappings?.forEach((item: LabelMappingItem) => {
                        if (altitude.value === item.y) {
                          altitude.label += item.label + `\n`;
                        }
                      });
                    });
                  }
                });
              },
              title: {
                display: true,
                text: `${serie.y_label ? serie.y_label : null} [ ${serie.y_unit ? serie.y_unit : null} ]`,
                font: { size: 18, weight: 'bold' },
                color: theme.palette.text.primary + '9a',
              },
            };
          }
        }

        const annotations: Record<string, AnnotationOptions> = {};

        for (let i = 0; i < series.length; i++) {
          const serie = series[i];

          if (serie?.thresholds) {
            for (let j = 0; j < serie.thresholds.length; j++) {
              const threshold = serie.thresholds[j];
              if (threshold) {
                annotations[threshold.name] = {
                  type: 'line',
                  xScaleID: 'x',
                  yScaleID: Object.keys(yScales)[0],
                  yMin: threshold.value,
                  yMax: threshold.value,
                  borderColor: '#ff6384',
                  borderWidth: 2,
                  label: {
                    enabled: true,
                    position: 'start',
                    content: threshold.name,
                    backgroundColor: '#ff6384',
                    width: 1000,
                    height: 15,
                  },
                };
              }
            }
          }
        }

        const chartOptions = {
          type: 'line',
          data: {
            datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            pointHitRadius: 20,
            layout: {
              padding: {
                right: 48,
                bottom: 24,
                left: 36,
              },
            },
            onHover: (e, elements) => {
              elements.length === 0 ? setCursor(undefined) : setCursor('pointer');
            },
            plugins: {
              tooltip: {
                enabled: true,
                intersect: true,
                mode: 'nearest',
                backgroundColor: '#e1e1e1e1',
                bodyColor: 'black',
                titleColor: 'black',
                footerColor: 'black',
                footerMarginTop: 10,
                footerFont: { weight: 'bold' },
                callbacks: {
                  title: ([context]) => {
                    return `${format(Number(context?.parsed.x), 'dd/MM/yyyy HH:mm:ss')}`;
                  },
                  footer: ([context]) => {
                    const hoveringXvalue = context?.parsed.x;
                    let label = '';
                    series.forEach((serie) => {
                      serie?.x_mappings?.forEach((item: LabelMappingItem) => {
                        if (hoveringXvalue === item.x) return (label += `\n` + item.label);
                      });
                    });

                    return label;
                  },
                  label: (context) => {
                    if (typeof context.datasetIndex !== 'undefined' && series[context.datasetIndex]) {
                      let label: string | number = Number(context.parsed.y);
                      series[context.datasetIndex]?.y_mappings?.forEach((item: LabelMappingItem) => {
                        if (item.y === Number(context.parsed.y)) {
                          label = item.label;
                        }
                      });
                      return label + ' ' + series[context.datasetIndex]?.y_unit;
                    }
                    return '';
                  },
                },
              },
              legend: {
                display: false,
              },
              annotation: {
                annotations: {
                  ...annotations,
                  scale: {
                    type: 'line',
                    xScaleID: 'x',
                    yScaleID: Object.keys(yScales)[0],
                    yMin: Math.round(allScalesMax + ((allScalesMax - allScalesMin) / 100) * 10 + 1),
                    yMax: Math.round(allScalesMax + ((allScalesMax - allScalesMin) / 100) * 10 + 1),
                    xMin: minTimestamp,
                    xMax: (minTimestamp ?? 0) + ticksIntervalInMinutes * 60 * 1000,
                    borderColor: 'rgb(95, 95, 95)',
                    borderWidth: 2,
                    label: {
                      enabled: true,
                      position: 'center',
                      content: `${ticksIntervalInMinutes > 60 ? `${minutesToHours(ticksIntervalInMinutes)}h` : ''} ${
                        ticksIntervalInMinutes % 60 ? ` ${ticksIntervalInMinutes % 60}m` : ''
                      }`,
                      color: 'rgb(95, 95, 95)',
                      backgroundColor: 'rgba(0,0,0,0)',
                      yAdjust: -5,
                      height: 15,
                      font: {
                        size: 10,
                      },
                    },
                  },
                },
              },
              zoom: {
                zoom: {
                  onZoomStart: () => {
                    if (onZoom) onZoom(true);
                  },
                  wheel: {
                    enabled: true,
                    speed: 0.3,
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: 'x',
                },
                pan: {
                  onPanStart: () => {
                    if (onZoom) onZoom(true);
                    setCursor('grab');
                  },
                  enabled: true,
                  mode: 'x',
                  onPanComplete: () => setCursor(undefined),
                },
                limits: {
                  x: { min: minTimestamp, max: maxTimestamp },
                },
              },
            },
            scales: {
              x: {
                type: 'linear' as const,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  minRotation: 50,
                  maxRotation: 50,
                  stepSize: ticksIntervalInMinutes * 60 * 1000,
                  color: theme.palette.text.primary + '9a',
                  callback: function (value) {
                    return `${format(Number(value), 'dd/MM/yy HH:mm')}`;
                  },
                },
                min: minTimestamp,
                max: maxTimestamp,
              },
              ...yScales,
            },
          },
        } as ChartConfiguration<'line'>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            position: 'bottom',
            align: 'center',
            labels: {
              color: theme.palette.text.primary + '9a',
              sort: (a: LegendItem, b: LegendItem) => {
                return a.text.localeCompare(b.text);
              },
            },
            onClick: (_e: ChartEvent, i: LegendItem) => {
              const datasetIndex = i.datasetIndex;
              if (typeof datasetIndex !== 'undefined') {
                setHiddenSeries((hiddenSeries) => {
                  return {
                    ...hiddenSeries,
                    [datasetIndex]: !hiddenSeries[datasetIndex],
                  };
                });
              }
            },
          };
        }

        chartRef.current.chart = new Chart(ctx, chartOptions);
      }
    }
  }, [colors, hiddenSeries, onZoom, props.series, series, visible, theme.palette.text.primary]);

  // Set hidden series
  useEffect(() => {
    if (chartRef.current.chart) {
      const chartData = chartRef.current.chart.data;

      if (chartData && chartData.datasets) {
        chartData.datasets = chartData.datasets.map((dataset, i) => ({
          ...dataset,
          hidden: hiddenSeries[i],
        }));
        chartRef.current.chart.update();
      }
    }
  }, [hiddenSeries]);

  // Zoom state handling
  useEffect(() => {
    if (reset === true) chartRef.current.chart?.resetZoom();
  }, [reset]);
  useEffect(() => {
    return () => {
      if (onZoom) onZoom(false);
    };
  }, [onZoom]);

  if (series.every((serie) => serie.data.length < 2)) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          typography: 'body1',
          color: 'grey.700',
        }}
      >
        <p>{'Dati non presenti'}</p>
      </Box>
    );
  }

  return <canvas ref={ref} style={{ cursor: cursor, paddingTop: 24 }} />;
}
