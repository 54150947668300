import * as z from 'zod';
import { DeviceCategory, DeviceType, Signal } from '../components/ConnectedDevices/SVGDevice';

export interface DevicesGraphTree {
  id: string;
  type: DeviceType | null;
  device_category?: DeviceCategory | null;
  device_label?: string | null;
  labels: string[];
  details_labels?: string[];
  children: DevicesGraphTree[];
  connection_active: boolean;
  connection_strength: Signal | null;
  connection_type: string | null;
  channel: number | null;
  capabilities: string[];
}

export const DevicesGraphTreeSchema: z.ZodSchema<DevicesGraphTree> = z.lazy(() =>
  z.object({
    id: z.string(),
    labels: z.array(z.string()),
    details_labels: z.union([z.array(z.string()), z.undefined()]),
    type: z.nativeEnum(DeviceType).nullable(),
    device_category: z.nativeEnum(DeviceCategory).nullable(),
    device_label: z.string().optional().nullable(),
    children: z.array(DevicesGraphTreeSchema),
    connection_strength: z.nativeEnum(Signal).nullable(),
    capabilities: z.array(z.string()),
    connection_active: z.boolean(),
    connection_type: z.union([z.null(), z.string()]),
    channel: z.union([z.null(), z.number()]),
  })
);

export const DevicesGraphTreeSchemaApiResponse = z.object({
  devices_map: z.array(DevicesGraphTreeSchema),
  last_timestamp_refresh: z.union([z.number(), z.string()]),
});
