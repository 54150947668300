import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import DevicesGraphRepository from '../api/DevicesGraphRepository';
import { Customer } from '../entities/Customer';
import { DevicesGraphTree, DevicesGraphTreeSchemaApiResponse } from '../entities/DevicesGraph';
import { RequestError } from '../entities/RequestError';
import { RequestState } from '../entities/RequestState';
import { RootState } from '../store/reducers';

import { devicesGraphActions } from '../store/reducers/devicesGraph';
import { RequestParseState } from '../store/toolkitUtils';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type UseDevicesGraph = {
  readDevicesGraph: (
    customerId: Customer['id'],
    timestamp?: number
  ) => Promise<{ devices_map: DevicesGraphTree[]; last_timestamp_refresh: number | string } | void>;
  readDevicesGraphRequest: RequestParseState;

  readHistory: (customerId: string, dateFrom?: number, dateTo?: number) => Promise<{ dates: number[] } | void>;
  readHistoryRequest: RequestState;
};

export function useDevicesGraph(): UseDevicesGraph {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      DevicesGraphRepository.updateAuthToken(accessToken);
      DevicesGraphRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const readDevicesGraphRequest = useSelector((state: RootState) => state.devicesGraph.requests.read);
  const readHistoryRequest = useSelector((state: RootState) => state.devicesGraph.requests.readHistory);

  const readDevicesGraph = useCallback(
    async (customerId: string, timestamp?: number) => {
      dispatch(devicesGraphActions.readDevicesGraphRequest());
      let response: unknown;

      try {
        if (timestamp) {
          response = await DevicesGraphRepository.getByDate(customerId, timestamp);
        } else response = await DevicesGraphRepository.get(customerId);
      } catch (error) {
        dispatch(devicesGraphActions.readDevicesGraphFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      const parseResult = DevicesGraphTreeSchemaApiResponse.safeParse(response);
      if (parseResult.success) {
        dispatch(devicesGraphActions.readDevicesGraphSuccess());
        return parseResult.data;
      } else {
        dispatch(
          devicesGraphActions.readDevicesGraphParseFailure(
            // { parseError: parseResult.error }

            { parseError: new RequestParseError(parseResult.error, 'UseDevicesGraph.readDevicesGraph') }
          )
        );
        return;
      }
    },
    [dispatch]
  );

  const readHistory = useCallback(
    async (customerId: string, dateFrom?: number, dateTo?: number) => {
      dispatch(devicesGraphActions.readHistoryRequest());
      let response: unknown;

      try {
        response = await DevicesGraphRepository.getHistory(customerId, dateFrom, dateTo);
      } catch (error) {
        dispatch(devicesGraphActions.readHistoryFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      dispatch(devicesGraphActions.readHistorySuccess());
      return response as { dates: number[] } | void;
    },
    [dispatch]
  );

  return {
    readDevicesGraph,
    readDevicesGraphRequest,
    readHistory,
    readHistoryRequest,
  };
}
