import { combineReducers } from 'redux';
import authentication from './authentication';
import caseInfo from './case';
import charts from './charts';
import customer from './customer';
import darkMode from './darkMode';
import devicesGraph from './devicesGraph';
import downDetector from './downDetector';
import glossary from './glossary';
import hubActions from './hubActions';
import hubTest from './hubTest';
import l2Dashboard from './l2dashboard';
import problems from './problems';
import suggestion from './suggestion';
import system from './system';
import webSocket from './webSocket';
import wizard from './wizard';

export const rootReducer = combineReducers({
  caseInfo,
  l2Dashboard,
  system,
  darkMode,
  authentication,
  customer,
  devicesGraph,
  hubActions,
  hubTest,
  charts,
  webSocket,
  wizard,
  problems,
  suggestion,
  downDetector,
  glossary,
});

export type RootState = ReturnType<typeof rootReducer>;
