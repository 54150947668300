import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WizardTypes } from '../../../../../entities/Wizard';
import { useCustomerData } from '../../../../../hooks/useCustomerData';
import { useHubTest } from '../../../../../hooks/useHubTest';
import { useWizard } from '../../../../../hooks/useWizard';
import { Scroller } from '../../../../Scroller';
import WizardButton from '../components/WizardButton';
import WizardCardActions from '../components/WizardCardActions';
import { WizardInnerCard, WizardLoader, WizardPrimaryCard } from '../components/WizardCommonComponents';
import { WizardDescription } from '../components/WizardDescription';
import WizardHeader from '../components/WizardHeader';
import WizardOutdatedAlert from '../components/WizardOutdatedAlert';
import WizardPageTitle from '../components/WizardPageTitle';
import WizardTitle from '../components/WizardTitle';

export default function CloseLoopStep(): JSX.Element | null {
  const [createCloseTheLoopInProgress, setCreateCloseTheLoopInProgress] = useState(false);
  const { wizardStep, createCloseTheLoop } = useWizard();
  const { customerId } = useCustomerData();
  const [hubTestLastUpdate, setHubTestLastUpdate] = useState(0);
  const {
    readHubTestListRequest: { lastUpdate: hubTestRequestLastUpdate },
    lastTest,
  } = useHubTest();
  const { t } = useTranslation();

  const wizard = wizardStep;

  const title = 'Chiusura procedura risoluzione guidata';
  const description = 'Procedura completata.';
  const description_subtitle_one =
    'È necessario rieseguire nuovamente il <b>TEST</b> per verificare la corretta risoluzione dei problemi.';
  const description_subtitle_two = 'Cliccare su <b>RIESEGUI TEST</b> per proseguire.';

  useEffect(() => {
    if (hubTestRequestLastUpdate !== hubTestLastUpdate) {
      setHubTestLastUpdate(Number(hubTestRequestLastUpdate ?? 0));

      if (createCloseTheLoopInProgress && !lastTest?.in_progress) {
        setCreateCloseTheLoopInProgress(false);
      }
    }
  }, [createCloseTheLoopInProgress, hubTestLastUpdate, hubTestRequestLastUpdate, lastTest?.in_progress]);

  if (!wizardStep || wizardStep.type !== WizardTypes.CLOSELOOP) {
    return null;
  }

  if (!customerId) {
    return null;
  }

  return (
    <WizardPrimaryCard>
      <Scroller>
        <WizardHeader wizard={wizard} />
        <WizardPageTitle rightOuterButtons={[]} />
        <WizardOutdatedAlert wizard={wizard} />
        <WizardLoader>
          <WizardInnerCard
            actions={
              <WizardCardActions
                left={[]}
                right={[
                  <WizardButton
                    key="button_text"
                    disabled={false}
                    confirm={false}
                    onClick={async () => {
                      if (customerId) {
                        setCreateCloseTheLoopInProgress(true);
                        await createCloseTheLoop(customerId);
                      }
                    }}
                    loading={createCloseTheLoopInProgress}
                  >
                    {t('dashboard:hubTestDetails:runTestAgain')}
                  </WizardButton>,
                ]}
              />
            }
          >
            <WizardTitle title={title} />
            <br />
            <WizardDescription description={description} />
            <br />
            <WizardDescription description={description_subtitle_one} />
            <br />
            <WizardDescription description={description_subtitle_two} />
          </WizardInnerCard>
        </WizardLoader>
      </Scroller>
    </WizardPrimaryCard>
  );
}
