import React, { useMemo } from 'react';
import { GridColDef } from '@material-ui/data-grid';
import { createStyles } from '@material-ui/styles';
import { Theme } from '@mui/material/';
import { makeStyles } from '@mui/styles';
import Box from '@mui/system/Box';
import { L2TableData } from '../../../../entities/L2Dashboard';
import LocalizedDataGrid from '../../../LocalizedDataGrid';
import { RequestValueFilter } from './L2ControllerSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDataGrid-columnsContainer': {
        color: theme.palette.text.primary,
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
          theme.palette.mode === 'light' ? '#f0f0f0' : theme.palette.background.default
        } !important`,
      },
      '& .MuiDataGrid-cell': {
        fontSize: 14,
        color: theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.background.default} !important`,
      },
      '& .MuiDataGrid-footerContainer': {
        display: 'none !important',
      },
    },
  })
);

export type L2TableSectionProps = {
  elementId: string;
  data: L2TableData;
  onRowSelected?: (affected_element_ids: string[], request_state_filter: RequestValueFilter) => void;
};

export default function L2TableSection({ elementId, data, onRowSelected }: L2TableSectionProps): JSX.Element {
  const classes = useStyles();
  const [rowSelectable, setRowSelectable] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<string>();

  const tableColumns: GridColDef[] = useMemo(() => {
    return data.columns.map((c: { column_name: string; alias: string }) => ({
      field: c.column_name,
      headerName: c.alias,
      flex: 1,
    }));
  }, [data.columns]);

  const tableRows = useMemo(() => {
    return data.rows.map((row, i) => {
      const tableRow: Record<string, string | number> = { id: `element-${elementId}-row-${i}`, index: i };
      row.data.forEach((c) => (tableRow[c.column_name] = c.value));
      return tableRow;
    });
  }, [data.rows, elementId]);

  return (
    <Box
      sx={{
        height: '100%',
        '& .MuiDataGrid-root': {
          display: 'flex',
          border: '0px solid',
        },
        '.MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row': {
          cursor: rowSelectable ? 'pointer' : 'auto',
        },
        '& .MuiDataGrid-row.Mui-selected': {
          backgroundColor: (theme) => `${theme.palette.primary.main + '1b'} !important`,
        },
      }}
    >
      <LocalizedDataGrid
        className={classes.root}
        columns={tableColumns}
        rows={tableRows}
        disableColumnFilter
        rowsPerPageOptions={[]}
        hideFooterPagination
        isRowSelectable={(params) => {
          return Boolean(data.rows[params.row['index'] as number]?.filter);
        }}
        onRowEnter={(params) => setRowSelectable(data.rows[params.row['index'] as number]?.filter ? true : false)}
        onRowClick={(params) => {
          const row = data.rows[params.row['index'] as number];
          if (row && row.filter) {
            if (onRowSelected && selectedRow !== params.id)
              onRowSelected(row.filter.element_ids_control, {
                id: row.filter.id ?? row.filter.name,
                name: row.filter.name,
                type: row.filter.type,
                allowedValues: [row.filter.value],
              });
            setSelectedRow(params.id.toString());
          }
        }}
      />
    </Box>
  );
}
