import { Customer } from '../entities/Customer';
import { InterceptorRepository } from './InterceptorRepository';

class HubTestRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/customers',
    });
  }

  async getStep(customerId: Customer['id'], result: unknown): Promise<unknown> {
    const { data } = await this.axios.post(`/${customerId}/wizard`, result);
    return data;
  }

  async postFeedback(customerId: Customer['id'], feedback: unknown) {
    const { data } = await this.axios.post(`/${customerId}/wizard/feedback`, feedback);
    return data;
  }

  async closeTheLoop(customerId: Customer['id']) {
    const { data } = await this.axios.post(`/${customerId}/closeloop`, {});
    return data;
  }
}

export default new HubTestRepository();
