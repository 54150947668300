import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import WizardRepository from '../api/WizardRepository';

import { Customer } from '../entities/Customer';
import { RequestError } from '../entities/RequestError';
import { RequestState } from '../entities/RequestState';
import { Wizard, WizardSchema, WizardTypes } from '../entities/Wizard';
import { RootState } from '../store/reducers';

import { wizardActions } from '../store/reducers/wizard';
import { RequestParseState } from '../store/toolkitUtils';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';
import { useHubActions } from './useHubActions';

type WizardState = RootState['wizard'];

type UseWizard = {
  wizardStep: WizardState['step'];
  wizardStepError: WizardState['stepError'];
  readWizardStep: (customerId: Customer['id'], result?: unknown) => Promise<Wizard | void>;
  parseReadWizardResponse: (customerId: string, response: unknown) => Promise<Wizard | void>;
  createFeedback: (customerId: Customer['id'], feedback: unknown) => Promise<void>;
  createCloseTheLoop: (customerId: Customer['id']) => Promise<void>;
  readWizardStepRequest: RequestParseState;
  createFeedbackRequest: RequestState;
  createCloseTheLoopRequest: RequestState;
  outdatedAlertSuppressed: WizardState['outdatedAlertSuppressed'];
  suppressOutdatedAlert: (wizardId: string) => void;
  clickedButton: WizardState['clickedButton'];
  setClickedButton: (button: WizardState['clickedButton']) => void;
};

export function useWizard(): UseWizard {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      WizardRepository.updateAuthToken(accessToken);
      WizardRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const wizardStep = useSelector((state: RootState) => state.wizard.step);
  const stepError = useSelector((state: RootState) => state.wizard.stepError);
  const readWizardStepRequest = useSelector((state: RootState) => state.wizard.requests.readStep);
  const createFeedbackRequest = useSelector((state: RootState) => state.wizard.requests.createFeedback);
  const createCloseTheLoopRequest = useSelector((state: RootState) => state.wizard.requests.createCloseTheLoop);
  const outdatedAlertSuppressed = useSelector((state: RootState) => state.wizard.outdatedAlertSuppressed);
  const clickedButton = useSelector((state: RootState) => state.wizard.clickedButton);

  const { resetByWizardId } = useHubActions();

  const parseReadWizardStepResponse: UseWizard['parseReadWizardResponse'] = useCallback(
    async (customerId, response) => {
      const parseResult = WizardSchema.safeParse(response);

      if (parseResult.success === false) {
        dispatch(
          wizardActions.readWizardParseFailure({
            parseError: new RequestParseError(parseResult.error, 'useWizard.readWizardStep'),
          })
        );
        return;
      } else {
        dispatch(wizardActions.readWizardSuccess({ customerId, response: parseResult.data }));
        return parseResult.data;
      }
    },
    [dispatch]
  );

  const readWizardStep: UseWizard['readWizardStep'] = useCallback(
    async (customerId, result) => {
      dispatch(wizardActions.readWizardRequest({ customerId }));
      let response: unknown;

      // if (result) {
      try {
        response = await WizardRepository.getStep(customerId, result);
      } catch (error) {
        dispatch(wizardActions.readWizardFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      // }

      const step = await parseReadWizardStepResponse(customerId, response);

      // Clean step action responses
      if (step?.type === WizardTypes.WIZARD_STEP && step.data.actions) {
        resetByWizardId(step.data.wizard_id);
      }

      return step;
    },
    [dispatch, parseReadWizardStepResponse, resetByWizardId]
  );

  const createFeedback: UseWizard['createFeedback'] = useCallback(
    async (customerId, feedback) => {
      dispatch(wizardActions.createFeedbackRequest());

      try {
        await WizardRepository.postFeedback(customerId, feedback);
      } catch (error) {
        dispatch(wizardActions.createFeedbackFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      dispatch(wizardActions.createFeedbackSuccess());
      return;
    },
    [dispatch]
  );

  const createCloseTheLoop: UseWizard['createCloseTheLoop'] = useCallback(
    async (customerId) => {
      dispatch(wizardActions.createCloseTheLoopRequest());

      try {
        await WizardRepository.closeTheLoop(customerId);
      } catch (error) {
        dispatch(wizardActions.createCloseTheLoopFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      dispatch(wizardActions.createCloseTheLoopSuccess());
      return;
    },
    [dispatch]
  );

  const suppressOutdatedAlert: UseWizard['suppressOutdatedAlert'] = useCallback(
    async (wizardId: string) => {
      dispatch(wizardActions.suppressOutdatedAlert({ wizardId }));
    },
    [dispatch]
  );

  const setClickedButton: UseWizard['setClickedButton'] = useCallback(
    async (button) => {
      dispatch(wizardActions.setClickedButton(button ? { ...button } : undefined));
    },
    [dispatch]
  );

  return {
    wizardStep,
    wizardStepError: stepError,
    readWizardStep,
    createFeedback,
    createCloseTheLoop,
    readWizardStepRequest,
    createFeedbackRequest,
    createCloseTheLoopRequest,
    parseReadWizardResponse: parseReadWizardStepResponse,
    outdatedAlertSuppressed,
    suppressOutdatedAlert,
    clickedButton,
    setClickedButton,
  };
}
