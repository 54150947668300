import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  activated: false as boolean,
};

const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    setDarkMode(state, action: PayloadAction<boolean>) {
      state.activated = action.payload;
      window.localStorage.setItem('@DarkMode', state.activated ? 'true' : 'false');
    },
  },
});

export const darkModeActions = darkModeSlice.actions;

export default darkModeSlice.reducer;
