import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { Action, ActionStatus } from '../../../../../entities/Action';
import { useCustomerData } from '../../../../../hooks/useCustomerData';
import WizardActionSection from './WizardActionSection';

interface WizardActionsProps {
  actions?: Action[];
  wizardId?: string;
  actionsResults: ActionStatus[];
}

export default function WizardActions(props: WizardActionsProps): JSX.Element | null {
  const { actions, wizardId, actionsResults } = props;
  const { customerId } = useCustomerData();

  if (!customerId || !actions || actions.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        padding: 1,
        marginY: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="caption" gutterBottom>
        {'Azioni dispositive'}
      </Typography>
      <Card sx={{ py: 0 }}>
        <List sx={{ padding: 0 }}>
          {actions.map((action) => {
            const actionId = action.action_id;
            if (customerId && actionId) {
              return (
                <WizardActionSection
                  key={actionId}
                  customerId={customerId}
                  wizardId={wizardId}
                  action={action}
                  wizard_action_results={actionsResults}
                />
              );
            }
            return null;
          })}
        </List>
      </Card>
    </Box>
  );
}
