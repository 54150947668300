import React from 'react';
import Box from '@mui/material/Box';
import { L2Chart, L2ChartType } from '../../../../entities/L2Dashboard';
import L2BarChart, { L2BarChartArea, L2BarChartSerie } from '../Charts/L2BarChart';
import L2BellsChart, { L2BellsChartSerie } from '../Charts/L2BellsChart';
import L2LineChart, { L2LineChartSerie } from '../Charts/L2LineChart';
import L2PieChart, { L2PieChartSerie } from '../Charts/L2PieChart';

export type L2ChartSectionProps = {
  data: L2Chart;
  legendItems: Array<{ dataClass: string; chartIds: string[]; display: boolean; color: string }>;
};

export default function L2ChartSection(props: L2ChartSectionProps): JSX.Element {
  const [chartType, setChartType] = React.useState<L2ChartType>(props.data.chart_type);
  const [trendlines, setTrendlines] = React.useState<
    Array<L2LineChartSerie | L2BarChartSerie | L2PieChartSerie | L2BellsChartSerie>
  >();
  const [areas, setAreas] = React.useState<Array<L2BarChartArea>>();

  React.useEffect(() => {
    const chartTrendlines: Array<L2LineChartSerie | L2BarChartSerie | L2PieChartSerie | L2BellsChartSerie> = [];
    props.data.trendlines.forEach((trendline) => {
      const currentLegendItem = props.legendItems.find((item) => item.dataClass === trendline.data_class);
      if (currentLegendItem) {
        chartTrendlines.push({
          ...trendline,
          color: currentLegendItem?.color ?? 'red',
          hidden: !currentLegendItem?.display,
        });
      }
    });
    // Update chart only if trendlines have actually changed
    if (JSON.stringify(trendlines) !== JSON.stringify(chartTrendlines)) setTrendlines(chartTrendlines);
  }, [props.data.trendlines, props.legendItems, trendlines]);

  React.useEffect(() => {
    if (props.data.chart_type !== L2ChartType.PIE && props.data.areas) {
      const chartAreas: Array<L2BarChartArea> = [];
      props.data.areas.forEach((area) => {
        const currentLegendItem = props.legendItems.find((item) => item.dataClass === area.data_class);
        if (currentLegendItem) {
          chartAreas.push({
            ...area,
            color: currentLegendItem?.color ?? 'red',
            hidden: !currentLegendItem.display,
          });
        }
      });
      // Update chart only if areas have actually changed
      if (JSON.stringify(areas) !== JSON.stringify(chartAreas)) setAreas(chartAreas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.chart_type, props.legendItems]);

  React.useEffect(() => {
    setChartType(props.data.chart_type);
  }, [props.data.chart_type]);

  const renderChart = () => {
    if (trendlines)
      switch (chartType) {
        case L2ChartType.LINE:
          return <L2LineChart series={trendlines as L2LineChartSerie[]} areas={areas} />;
        case L2ChartType.BAR:
          return props.data.chart_type === L2ChartType.BAR && props.data.labels ? (
            <L2BarChart
              series={trendlines as L2BarChartSerie[]}
              areas={areas}
              labels={props.data.labels}
              stacked={props.data.stacked}
              orientation={props.data.orientation}
            />
          ) : (
            <span></span>
          );
        case L2ChartType.PIE:
          return <L2PieChart series={trendlines as L2PieChartSerie[]} />;
        case L2ChartType.BELLS:
          return (
            <L2BellsChart
              series={trendlines as L2BellsChartSerie[]}
              areas={areas}
              x_label={props.data.x_label}
              y_label={props.data.y_label}
            />
          );
        default:
          return <span></span>;
      }
  };

  return trendlines ? (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {props.data.chart_name && (
        <Box sx={{ pt: 2, alignSelf: 'center', fontWeight: 'bold' }}>{props.data.chart_name}</Box>
      )}
      <Box sx={{ height: '100%' }}>{renderChart()}</Box>
    </Box>
  ) : (
    <span></span>
  );
}
