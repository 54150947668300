import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import GlossaryRepository from '../api/GlossaryRepository';
import { Glossary, GlossarySchema } from '../entities/Glossary';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';
import { glossaryActions } from '../store/reducers/glossary';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type GlossaryState = RootState['glossary'];

type UseGlossary = {
  glossary: GlossaryState['glossary'];
  readGlossary: (customer: string, area: string, product: string) => Promise<Glossary | void>;
  readGlossaryRequest: GlossaryState['requests']['readGlossary'];
};

export function useGlossary(): UseGlossary {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      GlossaryRepository.updateAuthToken(accessToken);
      GlossaryRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const glossary: UseGlossary['glossary'] = useSelector((state: RootState) => state.glossary.glossary);
  const readGlossaryRequest: UseGlossary['readGlossaryRequest'] = useSelector(
    (state: RootState) => state.glossary.requests.readGlossary
  );

  const readGlossary: UseGlossary['readGlossary'] = useCallback(
    async (customer: string, product: string, area: string) => {
      dispatch(glossaryActions.readGlossaryRequest());
      let response: Glossary;

      try {
        response = await GlossaryRepository.getGlossary(customer, product, area);
      } catch (error) {
        dispatch(glossaryActions.readGlossaryFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      const parseResult = GlossarySchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          glossaryActions.readGlossaryParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseGlossary.readGlossary'),
          })
        );
      } else {
        dispatch(glossaryActions.readGlossarySuccess(response));
      }
    },
    [dispatch]
  );

  return {
    readGlossary,
    readGlossaryRequest,
    glossary,
  };
}
