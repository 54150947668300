import React, { useContext, useEffect } from 'react';
import { RequestError } from '../../entities/RequestError';

const ApplicationErrorContext = React.createContext<{
  setErrors: React.Dispatch<React.SetStateAction<Record<string, (Error | RequestError | null)[]>>>;
}>({
  setErrors: () => {
    //
  },
});

export const ApplicationErrorContextProvider = ApplicationErrorContext.Provider;

export function useApplicationError(key: string, errors: Array<Error | RequestError | null>): void {
  const { setErrors } = useContext(ApplicationErrorContext);

  useEffect(() => {
    setErrors((errorsMap) => ({
      ...errorsMap,
      [key]: errors,
    }));
    return () => {
      setErrors((errorsMap) => {
        const map = { ...errorsMap };
        delete errorsMap[key];
        return map;
      });
    };
  }, [setErrors, errors, key]);
}
