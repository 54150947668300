import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import { format } from 'date-fns';
import L from 'leaflet';
import 'leaflet.heat';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Shadows } from '@mui/material/styles/shadows';
import { createStyles, makeStyles } from '@mui/styles';
import Box from '@mui/system/Box';
import { useDownDetector } from '../../../hooks/useDownDetector';

const useStyles = makeStyles(() =>
  createStyles({
    '@keyframes pulse': {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
    dot: {
      animation: `$pulse 3s ease-in-out infinite`,
      borderRadius: 20,
      width: 10,
      height: 10,
      backgroundColor: 'red',
    },
  })
);

type ServiceHeatMapProps = {
  serviceSlug?: string;
  serviceId?: number;
};

export default function ServiceHeatMap(props: ServiceHeatMapProps): JSX.Element {
  const { readHeatMap, readHeatMapRequest, readServiceRequest } = useDownDetector();
  const { mapDisabled } = useLocation().state as { mapDisabled: boolean };
  const classes = useStyles();
  const [map, setMap] = useState<L.Map>();
  const [mapMsg, setMapMsg] = useState<string>();
  const [lastUpdate, setLastUpdate] = useState<number>();

  useEffect(() => {
    if (!map) {
      const Lmap = L.map('map').setView([41.902782, 12.496366], 6).setMinZoom(6).setMaxZoom(12);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(Lmap);

      setMap(Lmap);
    }
  }, [map]);

  useEffect(() => {
    if (map && props.serviceId && props.serviceSlug) {
      const id = props.serviceId;
      const slug = props.serviceSlug;
      const feathHeatMap = async () => {
        const data = await readHeatMap(id, slug);

        if (data && data.heatmap) {
          if (data && data.last_update) {
            setLastUpdate(parseInt(data.last_update));
          }
          const points = data.heatmap.map((p) => {
            return {
              alt: p.count,
              lat: p.latitude,
              lng: p.longitude,
            } as L.LatLng;
          });

          L.heatLayer(points, {
            radius: 15,
            blur: 15,
            maxZoom: 12,
          }).addTo(map);
        }
        if (data) {
          if (data.heatmap === null && data.message) {
            setMapMsg(data.message);
          }
        } else {
          setMapMsg(readHeatMapRequest.error?.message ?? 'Errore nel caricamento dati della Heatmap.');
        }
      };

      if (!mapDisabled) feathHeatMap();
      else
        setMapMsg(
          `Il servizio di tracciatura geografica delle segnalazioni non è supportata per questo servizio. Per un'analisi più dettagliata puoi collegarsi al seguente link:`
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapDisabled, map, readHeatMap, props.serviceId, props.serviceSlug]);

  return (
    <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
      {/* Reset button */}
      {!mapDisabled && !mapMsg && (
        <Button
          variant="contained"
          onClick={() => map?.setView([41.902782, 12.496366], 6)}
          sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1000, m: 2 }}
        >
          Reset
        </Button>
      )}
      {/* Banner Ultimo aggiornamento */}
      {!mapDisabled && !mapMsg && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 1001,
            fontSize: 11,
            borderTopRightRadius: 3,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            minWidth: 210,
            px: 2,
            pt: 2,
            pb: 1,
            background: (theme) => theme.palette.background.paper,
            boxShadow: (theme) => (theme.shadows as Shadows)[2],
          }}
        >
          {readHeatMapRequest.inProgress || readServiceRequest.inProgress ? (
            <>
              <CircularProgress size={12} sx={{ mr: 2 }} />
              <span>Caricamento Heatmap</span>
            </>
          ) : (
            <>
              <Box className={classes.dot} sx={{ display: 'inline-block', mr: 2, mb: 0.5 }} />
              <span style={{ marginRight: 12 }}>Ultimo Aggiornamento:</span>
              <b>{lastUpdate && `${format(lastUpdate * 1000, 'dd/MM/yyyy HH:mm:ss')}`}</b>
            </>
          )}
        </Box>
      )}
      {/* map */}
      <div id="map" style={{ height: '100%' }}></div>
      {/* Map dialog */}
      {(mapDisabled || mapMsg) && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 1000,
            height: '100%',
            width: '100%',
            backgroundColor: '#000000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.7,
          }}
        />
      )}
      {(mapDisabled || mapMsg) && (
        <Alert
          severity={mapDisabled ? 'warning' : 'error'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            padding: '8px 8px',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            bottom: 0,
            top: 0,
            marginTop: 'auto',
            marginBottom: 'auto',
            maxWidth: 'fit-content',
            width: '90%',
            height: 'fit-content',
            zIndex: 1001,
          }}
        >
          {mapMsg}
          {mapDisabled && (
            <a href={`https://downdetector.it/problemi/${props.serviceSlug}/mappa/`}>
              {` https://downdetector.it/problemi/${props.serviceSlug}/mappa/`}
            </a>
          )}
        </Alert>
      )}
    </Box>
  );
}
