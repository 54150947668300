import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoUser } from '../../entities/CognitoUser';
import { RequestError } from '../../entities/RequestError';

import {
  mutateErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  requestState,
} from '../toolkitUtils';

const initialState = {
  signedIn: undefined as boolean | undefined,
  user: null as null | CognitoUser,
  login_type: 'standalone' as 'standalone' | 'federated',
  accessToken: null as null | string,
  idToken: null as null | string,
  tokenExpire: null as null | string,
  custom_user_level: null as null | string | number,
  requests: {
    signIn: requestState(),
    checkToken: requestState(),
    refreshToken: requestState(),
  },
};

export type AuthenticationState = typeof initialState;

interface GetUserSuccessAction {
  user: CognitoUser;
}

interface SignInSuccessAction {
  accessToken: string;
  idToken: string;
  tokenExpire: string;
}

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    signInRequest(state) {
      mutateRequestState(state.requests.signIn);
    },
    signInSuccess(state, action: PayloadAction<SignInSuccessAction>) {
      const { accessToken, idToken, tokenExpire } = action.payload;

      state.accessToken = accessToken;
      state.idToken = idToken;
      state.tokenExpire = tokenExpire;
      mutateSuccessState(state.requests.signIn);
    },
    signInFailure(state, action: PayloadAction<{ error: RequestError }>) {
      // state.signedIn = false;
      state.accessToken = null;
      state.idToken = null;
      state.tokenExpire = null;
      mutateErrorState(state.requests.signIn, action.payload.error);
    },
    signOut(state) {
      state.signedIn = false;
    },
    setUserInfo(state, action: PayloadAction<GetUserSuccessAction>) {
      const { user } = action.payload;

      state.user = user || null;
      state.signedIn = true;
    },
    setLoginType(state, action: PayloadAction<'standalone' | 'federated'>) {
      state.login_type = action.payload;
    },
    checkTokenRequest(state) {
      mutateRequestState(state.requests.checkToken);
    },
    checkTokenSuccess(state, action: PayloadAction<SignInSuccessAction>) {
      const { accessToken, idToken, tokenExpire } = action.payload;

      state.accessToken = accessToken;
      state.idToken = idToken;
      state.tokenExpire = tokenExpire;
      mutateSuccessState(state.requests.checkToken);
    },
    checkTokenExpiredFailure(state, action: PayloadAction<RequestFailureAction>) {
      state.accessToken = null;
      state.idToken = null;
      state.tokenExpire = null;
      mutateErrorState(state.requests.checkToken, action.payload.error);
    },
    checkTokenFailure(state, action: PayloadAction<RequestFailureAction>) {
      state.accessToken = null;
      state.idToken = null;
      state.tokenExpire = null;
      state.signedIn = false;
      mutateErrorState(state.requests.checkToken, action.payload.error);
    },
    refreshTokenRequest(state) {
      mutateRequestState(state.requests.refreshToken);
    },
    refreshTokenSuccess(state, action: PayloadAction<SignInSuccessAction>) {
      const { accessToken, idToken, tokenExpire } = action.payload;

      state.accessToken = accessToken;
      state.idToken = idToken;
      state.tokenExpire = tokenExpire;
      mutateSuccessState(state.requests.refreshToken);
    },
    refreshTokenFailure(state, action: PayloadAction<RequestFailureAction>) {
      state.accessToken = null;
      state.idToken = null;
      state.tokenExpire = null;
      state.signedIn = false;
      mutateErrorState(state.requests.refreshToken, action.payload.error);
    },
  },
});

export const authenticationActions = authenticationSlice.actions;

export default authenticationSlice.reducer;
