import React, { useState } from 'react';
import { WizardPicklistView, WizardTypes } from '../../../../../entities/Wizard';
import { useCustomerData } from '../../../../../hooks/useCustomerData';
import { useWizard } from '../../../../../hooks/useWizard';
import { Scroller } from '../../../../Scroller';
import WizardButton from '../components/WizardButton';
import WizardCardActions from '../components/WizardCardActions';
import { WizardInnerCard, WizardLoader, WizardPrimaryCard } from '../components/WizardCommonComponents';
import { WizardDescription } from '../components/WizardDescription';
import WizardHeader from '../components/WizardHeader';
import WizardOutdatedAlert from '../components/WizardOutdatedAlert';
import WizardPageTitle from '../components/WizardPageTitle';
import WizardPickList from '../components/WizardPickList';
import WizardTitle from '../components/WizardTitle';

export default function WizardPicklistStep(): JSX.Element | null {
  const { wizardStep, clickedButton, setClickedButton, readWizardStepRequest, readWizardStep } = useWizard();
  const { customerId } = useCustomerData();
  const [compiled, setCompiled] = useState(false);

  const [optionsValues, setOptionsValues] = useState<
    Record<
      string,
      {
        name: string;
        type: string;
        value: string | boolean | string[];
      }
    >
  >({});

  if (!wizardStep || wizardStep.type !== WizardTypes.WIZARD_PICKLIST_VIEW) {
    return null;
  }

  const wizard: WizardPicklistView = wizardStep;

  const pageTitle = wizard.data.title;
  const title = wizard.data.title_step;
  const description = wizard.data.description;

  const leftButtons = [];
  const rightButtons = [];
  const rightOuterButtons = [];
  const contentOptions = [];

  for (let i = 0; i < wizard.data.options.length; i++) {
    const option = wizard.data.options[i];

    if (option) {
      if (option.type === 'button') {
        const button = option;

        const { name, type } = button;

        if (button?.name) {
          const value = typeof button.value === 'undefined' ? '' : button.value;
          const buttonComponent = (
            <WizardButton
              key={button.name}
              confirm={button.confirm ?? false}
              disabled={
                (!compiled && !wizard.data.superuser && !button.side && !button.confirm) ||
                readWizardStepRequest.inProgress
              }
              onClick={() => {
                setClickedButton({ value: value, name: name, type: type });

                if (customerId) {
                  readWizardStep(customerId, {
                    type: 'picklist_view',
                    data: {
                      // wizard_id: wizardStep.data.wizard_id,
                      result: [{ value, name, type }, ...Object.values(optionsValues)],
                    },
                  });
                }
              }}
              loading={clickedButton?.value === button.value && readWizardStepRequest.inProgress}
              confirmDialogTitle={button.name}
            >
              {button?.name}
            </WizardButton>
          );

          if (button.side === 'left') {
            leftButtons.push(buttonComponent);
          } else if (button.side === 'right_outer') {
            rightOuterButtons.push(buttonComponent);
          } else {
            // If side is missing, show button on right
            rightButtons.push(buttonComponent);
          }
        }
      } else if (option.type === 'picklist') {
        const pickList = option;

        if (pickList) {
          contentOptions.push(
            <WizardPickList
              pickList={pickList}
              onChange={(values) => {
                setOptionsValues((v) => ({
                  ...v,
                  [pickList.name]: {
                    ...pickList,
                    value: values,
                  },
                }));
                setCompiled(false);
              }}
              onEndReached={() => setCompiled(true)}
            />
          );
        }
      }
    }
  }

  return (
    <WizardPrimaryCard>
      <Scroller>
        <WizardHeader wizard={wizard} />
        <WizardPageTitle title={pageTitle} rightOuterButtons={[]} />
        <WizardOutdatedAlert wizard={wizard} />
        <WizardLoader>
          <WizardInnerCard actions={<WizardCardActions left={leftButtons} right={rightButtons} />}>
            <WizardTitle title={title} buttons={rightOuterButtons} />
            <WizardDescription description={description} />
            {contentOptions}
          </WizardInnerCard>
        </WizardLoader>
      </Scroller>
    </WizardPrimaryCard>
  );
}
