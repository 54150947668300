import React, { useCallback, useEffect, useState } from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import ChevronDown from '@mitch528/mdi-material-ui/ChevronDown';
import ChevronUp from '@mitch528/mdi-material-ui/ChevronUp';
import { Button } from '@mui/material/';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { Customer } from '../../../../entities/Customer';
import { DevicesGraphTree } from '../../../../entities/DevicesGraph';
import { useDevicesGraph } from '../../../../hooks/useDevicesGraph';
import DevicesForceGraph from '../../../ConnectedDevices/DevicesForceGraph';
import LoaderView from '../../../LoaderView';

export interface HubDetailsDialogChartsProps {
  customerId: Customer['id'];
  size: {
    width: number;
    height: number;
  };
  visible: boolean;
  backButtonComponent?: () => JSX.Element;
  history?: Array<number>;
  defaultHistoryItem?: number;
}

export default function HubDetailsDialogCharts(props: HubDetailsDialogChartsProps): JSX.Element {
  const { readDevicesGraph, readDevicesGraphRequest } = useDevicesGraph();
  const [zoomed, setZoomed] = useState<boolean>(false);
  const [devicesGraph, setDevicesGraph] = useState<DevicesGraphTree[] | undefined>();
  const [timeStampValue, setTimeStampValue] = useState<number | undefined>();
  const [selectedTimestamp, setSelectedTimestamp] = useState<number | undefined>(props.defaultHistoryItem);
  const [groupedHistory, setGroupedHistory] = useState<Array<{ hours: number; timestamps: number[] }>>();
  const theme = useTheme();

  const readConnectedDevices = useCallback(async () => {
    if (props.customerId) {
      const data = await readDevicesGraph(props.customerId, selectedTimestamp);
      setTimeStampValue(undefined);
      if (data) {
        setDevicesGraph(data.devices_map);
        setTimeStampValue(
          typeof data.last_timestamp_refresh === 'string'
            ? parseInt(data.last_timestamp_refresh)
            : data.last_timestamp_refresh
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Something went wrong...`);
      }
    }
  }, [props.customerId, readDevicesGraph, selectedTimestamp]);

  const manageZoom = useCallback((value: boolean) => {
    setZoomed(value);
  }, []);

  useEffect(() => {
    if (!props.history) return;
    const filteredHistory = props.history?.filter((item) => {
      if (!selectedTimestamp) {
        return false;
      }
      const selectedItemHours = new Date(selectedTimestamp).getHours();
      const hours = new Date(item).getHours();
      if (Math.abs(hours - selectedItemHours) <= 1) return true;
      return false;
    });
    const groupedHistory: Array<{ hours: number; timestamps: number[] }> = [];
    filteredHistory.forEach((item) => {
      const _date = new Date(item);
      const alreadyStoredSection = groupedHistory.find((gItem) => gItem.hours === _date.getHours());
      if (alreadyStoredSection) {
        alreadyStoredSection.timestamps.push(item);
      } else {
        groupedHistory.push({
          hours: _date.getHours(),
          timestamps: [item],
        });
      }
    });
    setGroupedHistory(groupedHistory);
  }, [selectedTimestamp, props.history]);

  useEffect(() => {
    if (props.visible) {
      const fetchData = async () => {
        await readConnectedDevices();
      };
      fetchData();
    }
    return () => {
      setDevicesGraph(undefined);
    };
  }, [props.customerId, props.visible, readConnectedDevices]);

  return (
    <Box>
      <Box sx={{ height: props.size?.height, background: (theme) => theme.palette.background.paper }}>
        <Box
          sx={{
            padding: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            background: (theme) => theme.palette.background.default,
            borderBottom: `1px solid ${theme.palette.text.primary}20`,
          }}
        >
          <Box>
            {props.backButtonComponent && <props.backButtonComponent />}
            <Button variant="contained" disabled={!zoomed} onClick={() => setZoomed(false)}>
              Reset
            </Button>
          </Box>
          {props.history && groupedHistory && (
            <FormControl sx={{ mb: -1, mx: 1, minWidth: 120 }} size="small">
              <InputLabel>Storico</InputLabel>
              <Select
                value={selectedTimestamp}
                label="Storico"
                size="small"
                onChange={(e) =>
                  setSelectedTimestamp(typeof e.target.value === 'string' ? parseInt(e.target.value) : e.target.value)
                }
                MenuProps={{ PaperProps: { style: { maxHeight: props.size.height - 130 } } }}
              >
                <ChevronUp sx={{ display: 'block', margin: 'auto', opacity: 0.5 }} />
                {groupedHistory[0] && <ListSubheader>{groupedHistory[0]?.hours}:00</ListSubheader>}
                {groupedHistory[0]?.timestamps.map((timestamp) => (
                  <MenuItem key={timestamp} value={timestamp}>
                    {new Date(timestamp).toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })}
                  </MenuItem>
                ))}
                {groupedHistory[1] && <ListSubheader>{groupedHistory[1]?.hours}:00</ListSubheader>}
                {groupedHistory[1]?.timestamps.map((timestamp) => (
                  <MenuItem key={timestamp} value={timestamp}>
                    {new Date(timestamp).toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })}
                  </MenuItem>
                ))}
                {groupedHistory[2] && <ListSubheader>{groupedHistory[2]?.hours}:00</ListSubheader>}
                {groupedHistory[2]?.timestamps.map((timestamp) => (
                  <MenuItem key={timestamp} value={timestamp}>
                    {new Date(timestamp).toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })}
                  </MenuItem>
                ))}
                <ChevronDown sx={{ display: 'block', margin: 'auto', opacity: 0.5 }} />
              </Select>
            </FormControl>
          )}
        </Box>
        <LoaderView
          minHeight={props.size.height - 54}
          condition={!readDevicesGraphRequest.inProgress}
          text="Caricamento Configurazione Dispositivi..."
        >
          <DevicesForceGraph
            graph={devicesGraph}
            timeStampValue={timeStampValue}
            customerIdValue={props.customerId}
            visible={props.visible}
            error={readDevicesGraphRequest.error ?? readDevicesGraphRequest.parseError ?? null}
            size={{ width: props.size.width, height: props.size.height - 54 }}
            onRefresh={readConnectedDevices}
            reset={!zoomed}
            onZoom={manageZoom}
          />
        </LoaderView>
      </Box>
    </Box>
  );
}
