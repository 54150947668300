import React from 'react';
import { useTranslation } from 'react-i18next';
import Magnify from '@mitch528/mdi-material-ui/Magnify';
import { DateTimePicker, LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { useSystem } from '../../../hooks/useSystem';
import LoaderView from '../../LoaderView';

export type FilterSectiontProps = {
  onSubmit: (query: Record<string, string | number>) => Promise<void>;
};

export default function MsoFilterSection(props: FilterSectiontProps): JSX.Element {
  const { systemAttributes, readSystemsListRequest, readSystemAttributes, readSystemAttributesRequest } = useSystem();
  const { t } = useTranslation();
  const [systemId, setSystemId] = React.useState('');
  const [dateFrom, setDateFrom] = React.useState<Date | null | undefined>(null);
  const [dateTo, setDateTo] = React.useState<Date | null | undefined>(null);
  const [attributes, setAttributes] = React.useState<Record<string, string | undefined>>({});

  React.useEffect(() => {
    readSystemAttributes();
  }, [readSystemAttributes]);

  const handleClientIdChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSystemId(e.target.value);
  }, []);

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const query: Record<string, string | number> = {};

      if (systemId.trim()) {
        query['netktID'] = systemId.trim();
      }
      if (dateFrom) {
        query['start_time'] = dateFrom.getTime() / 1000;
      }
      if (dateTo) {
        query['end_time'] = dateTo.getTime() / 1000;
      }

      for (const [key, value] of Object.entries(attributes)) {
        if (value) {
          query[key] = value;
        }
      }
      props.onSubmit(query);
    },
    [attributes, systemId, dateFrom, dateTo, props]
  );

  const handleReset = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setAttributes({});

      if (dateFrom && isNaN(dateFrom.getTime())) {
        setDateFrom(new Date());
        setTimeout(() => {
          setDateFrom(null);
        }, 0);
      } else setDateFrom(null);

      if (dateTo && isNaN(dateTo.getTime())) {
        setDateTo(new Date());
        setTimeout(() => {
          setDateTo(null);
        }, 0);
      } else setDateTo(null);

      setSystemId('');
    },
    [dateFrom, dateTo]
  );

  const attributeFiltersActive = React.useMemo(
    () => Object.values(attributes).filter((a) => a && a?.length > 0).length === 0,
    [attributes]
  );

  return (
    <Card
      sx={{
        marginBottom: 2,
        paddingBottom: 0,
        padding: 1,
        '& .MuiCardContent-root': {
          paddingBlock: 8,
          background: (theme) => theme.palette.background.paper,
        },
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        onReset={handleReset}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > div:first-of-type': {
            flex: '1 1 auto',
          },
        }}
      >
        <CardContent>
          <LoaderView condition={!readSystemAttributesRequest.inProgress} minHeight="180px">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                '&>div': {
                  flex: '0 0 20%',
                  paddingX: 1,
                  paddingY: 1,
                  '&:last-child': {
                    flex: '0 0 40%',
                  },
                },
              }}
            >
              {/* Static Filters */}
              <Box>
                <TextField
                  label={t('entities:system:id')}
                  size="small"
                  value={systemId}
                  onChange={handleClientIdChange}
                  fullWidth
                  disabled={!attributeFiltersActive}
                />
              </Box>
              <Box>
                <DateTimePicker
                  label="Data e orario di inizio"
                  value={dateFrom}
                  onChange={(value) => setDateFrom(value)}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ width: '100%' }} />}
                />
              </Box>
              <Box>
                <DateTimePicker
                  label="Data e orario di fine"
                  value={dateTo}
                  onChange={(value) => setDateTo(value)}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ width: '100%' }} />}
                />
              </Box>
              <CardActions sx={{ background: (theme) => theme.palette.background.paper }}>
                <Button type="reset" variant="text" sx={{ marginRight: 2, marginLeft: 'auto' }}>
                  {t('actions:reset')}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<Magnify />}
                  loading={readSystemsListRequest.inProgress}
                >
                  {t('actions:search')}
                </LoadingButton>
              </CardActions>
            </Box>
            {/* Dynamic Filters */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                '&>div': {
                  flex: '0 0 20%',
                  paddingX: 1,
                  paddingY: 1,
                  '&:last-child': {
                    minWidth: '20%',
                    marginLeft: '0',
                    marginRight: 'auto',
                  },
                },
              }}
            >
              {systemAttributes.map((attribute) => (
                <div key={attribute.attribute_name}>
                  {attribute.possible_values.length ? (
                    <Autocomplete
                      disablePortal
                      disableClearable
                      options={attribute.possible_values}
                      value={attributes[attribute.attribute_name] || ''}
                      disabled={Boolean(systemId) && systemId.length > 0}
                      renderInput={(params) => <TextField {...params} label={attribute.alias} size="small" fullWidth />}
                      onInputChange={(_e, value) => {
                        setAttributes((attributes) => ({
                          ...attributes,
                          [attribute.attribute_name]: value || undefined,
                        }));
                      }}
                    />
                  ) : (
                    <TextField
                      label={attribute.alias}
                      size="small"
                      fullWidth
                      value={attributes[attribute.attribute_name] || ''}
                      disabled={Boolean(systemId) && systemId.length > 0}
                      onChange={(e) => {
                        setAttributes((attributes) => ({
                          ...attributes,
                          [attribute.attribute_name]: e.target.value || undefined,
                        }));
                      }}
                    />
                  )}
                </div>
              ))}
            </Box>
          </LoaderView>
        </CardContent>
      </Box>
    </Card>
  );
}
