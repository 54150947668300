import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import TrendlineRepository from '../api/TrendlineRepository';
import { Chart, ChartHistoryItem, ChartSchema } from '../entities/Chart';
import { Customer } from '../entities/Customer';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';

import { trendlineActions } from '../store/reducers/charts';
import { RequestParseState } from '../store/toolkitUtils';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type UseCharts = {
  readChart: (customerId: Customer['id'], trendlineName: string, requestId?: string) => Promise<Chart | void>;
  readChartRequest: RequestParseState;
  readHistory: (customerId: Customer['id'], trendlineName: string) => Promise<ChartHistoryItem[] | void>;
  readHistoryRequest: RequestParseState;
  invalidateCharts: () => Promise<Chart | void>;
  invalidate: number;
};

export function useCharts(): UseCharts {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      TrendlineRepository.updateAuthToken(accessToken);
      TrendlineRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const readChartRequest = useSelector((state: RootState) => state.charts.requests.read);
  const readHistoryRequest = useSelector((state: RootState) => state.charts.requests.readHistory);
  const invalidate = useSelector((state: RootState) => state.charts.invalidate);

  const readChart = useCallback(
    async (customerId, trendlineName, requestId?) => {
      dispatch(trendlineActions.readChartRequest());
      let response: unknown;

      try {
        response = requestId
          ? await TrendlineRepository.getByDate(customerId, trendlineName, requestId)
          : await TrendlineRepository.getLast(customerId, trendlineName);
      } catch (error) {
        dispatch(trendlineActions.readChartFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      // Replace the reserved Array.values with Array.chart_values
      if (
        response &&
        typeof response === 'object' &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (response as { trendlines: Array<any> }).trendlines &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Array.isArray((response as { trendlines: Array<any> }).trendlines)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        for (let i = 0; i < (response as { trendlines: Array<any> }).trendlines.length; i++) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const trendline = (response as { trendlines: Array<any> }).trendlines[i];
          if (trendline && trendline.values) {
            trendline.chart_values = trendline.values;
          }
        }
      }

      const parseResult = ChartSchema.safeParse(response);

      if (parseResult.success) {
        dispatch(trendlineActions.readChartSuccess());
        return parseResult.data;
      } else {
        dispatch(
          trendlineActions.readChartParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCharts.readChart'),
          })
        );
        return;
      }
    },
    [dispatch]
  );
  const invalidateCharts = useCallback(async () => {
    dispatch(trendlineActions.invalidateCharts());
  }, [dispatch]);

  const readHistory = useCallback(
    async (customerId, trendlineName) => {
      dispatch(trendlineActions.readHistoryRequest());
      let response: {
        dates: ChartHistoryItem[];
      };

      try {
        response = await TrendlineRepository.getDates(customerId, trendlineName);
      } catch (error) {
        dispatch(trendlineActions.readHistoryFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      dispatch(trendlineActions.readHistorySuccess());
      return response.dates;
    },
    [dispatch]
  );

  return {
    readChart,
    readChartRequest,
    readHistory,
    readHistoryRequest,
    invalidateCharts,
    invalidate,
  };
}
