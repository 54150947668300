import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import CaseRepository from '../api/CaseRepository';
import { Case, CasesAPIReponseSchema, CaseSynthInfo, CaseSynthInfoSchema } from '../entities/Case';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';
import { caseActions } from '../store/reducers/case';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type CaseState = RootState['caseInfo'];

type UseCase = {
  caseSynthInfo: CaseState['caseSynthInfo'];
  readCaseSynthInfoRequest: CaseState['requests']['readCaseSynthInfo'];
  readCaseSynthInfo: (customerId: string, username: string, userLevel: number) => Promise<CaseSynthInfo | void>;

  cases: CaseState['cases'];
  readCasesRequest: CaseState['requests']['readCases'];
  readCases: (customerId: string) => Promise<Case[] | void>;

  readReserveCase: (
    customerId: string,
    username: string,
    userLevel: number,
    reserveButtonValue: number
  ) => Promise<CaseSynthInfo | void>;
};

export function useCase(): UseCase {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      CaseRepository.updateAuthToken(accessToken);
      CaseRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const caseSynthInfo = useSelector((state: RootState) => state.caseInfo.caseSynthInfo);
  const cases = useSelector((state: RootState) => state.caseInfo.cases);
  const readCaseSynthInfoRequest = useSelector((state: RootState) => state.caseInfo.requests.readCaseSynthInfo);
  const readCasesRequest = useSelector((state: RootState) => state.caseInfo.requests.readCases);

  const readCaseSynthInfo: UseCase['readCaseSynthInfo'] = useCallback(
    async (customerId: string, username: string, userLevel: number) => {
      dispatch(caseActions.readCaseSynthInfoRequest());
      let response: unknown;

      try {
        const data = await CaseRepository.getCaseSynthInfo(customerId, username, userLevel);
        response = data;
      } catch (error) {
        dispatch(caseActions.readCaseSynthInfoFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      const parseResult = CaseSynthInfoSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          caseActions.readCaseSynthInfoParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCase.readCaseSynthInfo'),
          })
        );
        return;
      }
      dispatch(caseActions.readCaseSynthInfoSuccess(response as CaseSynthInfo));
    },
    [dispatch]
  );

  const readCases: UseCase['readCases'] = useCallback(
    async (customerId: string) => {
      dispatch(caseActions.readCasesRequest());
      let response: unknown;

      try {
        const data = await CaseRepository.getCases(customerId);
        response = data;
      } catch (error) {
        dispatch(caseActions.readCasesFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      const parseResult = CasesAPIReponseSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          caseActions.readCasesParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCase.readCases'),
          })
        );
        return;
      }
      dispatch(caseActions.readCasesSuccess(response as Case[]));
    },
    [dispatch]
  );

  const readReserveCase: UseCase['readReserveCase'] = useCallback(
    async (customerId: string, username: string, userLevel: number, reserveButtonValue: number) => {
      dispatch(caseActions.readCaseSynthInfoRequest());
      let response: unknown;

      try {
        const data = await CaseRepository.reserveCase(customerId, username, userLevel, reserveButtonValue);
        response = data;
      } catch (error) {
        dispatch(caseActions.readCaseSynthInfoFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      const parseResult = CaseSynthInfoSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          caseActions.readCaseSynthInfoParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCase.readCaseSynthInfo'),
          })
        );
        return;
      }

      dispatch(caseActions.readCaseSynthInfoSuccess(response as CaseSynthInfo));
      return response as CaseSynthInfo;
    },
    [dispatch]
  );

  return {
    caseSynthInfo,
    readCaseSynthInfo,
    readCaseSynthInfoRequest,
    cases,
    readCases,
    readCasesRequest,
    readReserveCase,
  };
}
