import * as z from 'zod';

export type ChartHistoryItem = {
  request_id: string;
  date: string;
};

export const ChartTimeEvent = z.object({
  start: z.object({
    name: z.string(),
    value: z.union([z.string(), z.number(), z.null()]),
  }),
  end: z.object({
    name: z.string(),
    value: z.union([z.string(), z.number(), z.null()]),
  }),
});

export const CommonChartSchema = z.object({
  chart_name: z.string(),
  chart_type: z.string(),
});
export type CommonChart = z.infer<typeof CommonChartSchema>;

export const TrendlineValueSchema = z.tuple([z.number(), z.number()]);
export type TrendlineValue = z.infer<typeof TrendlineValueSchema>;

export const LineChartSchema = CommonChartSchema.extend({
  chart_type: z.literal('line_chart'),
  series_name: z.string(),
  y_unit: z.string(),
  label_name: z.string(),
  label_value: z.string(),
  values: z.array(TrendlineValueSchema),
  y_min: z.union([z.number(), z.null(), z.undefined()]),
  y_max: z.union([z.number(), z.null(), z.undefined()]),
  x: z.unknown(), // ignore this, use zipped "values" property instead
  y: z.unknown(), // ignore this, use zipped "values" property instead
  x_label: z.unknown(),
  y_label: z.unknown(),
  thresholds: z.union([
    z.array(
      z.object({
        name: z.string(),
        value: z.union([z.string(), z.number()]),
      })
    ),
    z.undefined(),
  ]),
  x_mappings: z.union([
    z.array(
      z.object({
        x: z.number(),
        label: z.string(),
      })
    ),
    z.undefined(),
  ]),
  y_mappings: z.union([
    z.array(
      z.object({
        y: z.number(),
        label: z.string(),
      })
    ),
    z.undefined(),
  ]),
});
export type LineChart = z.infer<typeof LineChartSchema>;

export const LineFilledChartSchema = CommonChartSchema.extend({
  chart_type: z.literal('line_filled_chart'),
  y_min: z.union([z.number(), z.null()]),
  y_max: z.union([z.number(), z.null()]),
  y_unit: z.union([z.string(), z.null()]),
  series: z.array(
    z.object({
      name: z.string(),
      data: z.object({
        x_label: z.union([z.string(), z.null()]),
        y_label: z.union([z.string(), z.null()]),
        label_name: z.union([z.string(), z.null()]),
        label_value: z.union([z.string(), z.null()]),
        threshold: z.boolean(), //flag: is it this serie a threshold or not?
        values: z.array(TrendlineValueSchema),
        fill_area_to: z.union([z.string(), z.number(), z.null()]),
      }),
    })
  ),
  authorization_level: z.number(),
});
export type LineFilledChart = z.infer<typeof LineFilledChartSchema>;

export const PieChartSchema = CommonChartSchema.extend({
  chart_type: z.literal('pie_chart'),
  slices: z.array(
    z.object({
      label: z.string(),
      value: z.number(),
    })
  ),
  title: z.string(),
  unit: z.unknown(),
});
export type PieChart = z.infer<typeof PieChartSchema>;

export const GaugeChartSchema = CommonChartSchema.extend({
  chart_type: z.literal('gauge_chart'),
  intervals: z.array(
    z.object({
      color: z.string(),
      label: z.string(),
      max_value: z.number(),
      min_value: z.number(),
    })
  ),
  current_value: z.number(),
  min_value: z.number(),
  max_value: z.number(),
  title: z.string(),
  unit: z.unknown(),
});
export type GaugeChart = z.infer<typeof GaugeChartSchema>;

export const HistogramChartSchema = CommonChartSchema.extend({
  chart_name: z.string(),
  chart_type: z.literal('histogram_chart'),
  title: z.union([z.string(), z.null(), z.undefined()]),
  categories: z.array(
    z.object({
      label: z.string(),
      value: z.number(),
    })
  ),
  authorization_level: z.number(),
  unit: z.union([z.string(), z.undefined()]),
  y_label: z.union([z.string(), z.undefined()]),
  monochrome: z.union([z.boolean(), z.undefined()]),
  thresholds: z.union([
    z.array(
      z.object({
        name: z.string(),
        value: z.union([z.string(), z.number()]),
        type_upper: z.union([z.boolean(), z.null()]),
      })
    ),
    z.undefined(),
  ]),
  events: z.union([z.array(ChartTimeEvent), z.undefined()]),
});
export type HistogramChart = z.infer<typeof HistogramChartSchema>;

export const TimeHistogramChartSchema = CommonChartSchema.extend({
  chart_name: z.string(),
  chart_type: z.literal('time_histogram_chart'),
  title: z.union([z.string(), z.null(), z.undefined()]),
  series: z.array(
    z.object({
      label: z.string().optional().nullable(),
      date_time: z.number(),
      value: z.number(),
    })
  ),
  authorization_level: z.number(),
  unit: z.union([z.string(), z.undefined()]),
  y_label: z.union([z.string(), z.undefined()]),
  monochrome: z.union([z.boolean(), z.undefined()]),
  thresholds: z.union([
    z.array(
      z.object({
        name: z.string(),
        value: z.union([z.string(), z.number()]),
        type_upper: z.union([z.boolean(), z.null()]),
      })
    ),
    z.undefined(),
  ]),
  events: z.union([z.array(ChartTimeEvent), z.undefined()]),
  time_unit: z.string(),
  date_time_format: z.string().optional().nullable(),
});
export type TimeHistogramChart = z.infer<typeof TimeHistogramChartSchema>;

const InterferenceChartPoint = z.tuple([z.number(), z.number()]);
export const InterferenceChartValueSchema = z.tuple([
  InterferenceChartPoint,
  InterferenceChartPoint,
  InterferenceChartPoint,
]);
export type InterferenceChartValue = z.infer<typeof InterferenceChartValueSchema>;

export const InterferenceChartSchema = CommonChartSchema.extend({
  chart_type: z.literal('interference_chart'),
  values: InterferenceChartValueSchema,
  y_unit: z.string(),
  label_name: z.string(),
  label_value: z.string(),
  x_label: z.unknown(),
  y_label: z.unknown(),
  series_name: z.string(),
  band_width: z.string().optional(),
  device_type: z.string().optional(),
  thresholds: z.array(
    z.object({
      name: z.string(),
      value: z.union([z.string(), z.number()]),
    })
  ),
  user_ssid: z.boolean().optional(),
});
export type InterferenceChart = z.infer<typeof InterferenceChartSchema>;

export const TableChartSchema = CommonChartSchema.extend({
  chart_type: z.literal('table_chart'),
  column_names: z.array(z.string()),
  entries: z.array(z.record(z.union([z.string(), z.number()]))),
  unit: z.null(),
  title: z.string(),
});
export type TableChart = z.infer<typeof TableChartSchema>;

export const ChartSchema = z.object({
  trendlines: z.array(
    z.union([
      LineFilledChartSchema,
      TableChartSchema,
      InterferenceChartSchema,
      HistogramChartSchema,
      TimeHistogramChartSchema,
      LineChartSchema,
      PieChartSchema,
      GaugeChartSchema,
    ])
  ),
});
export type Chart = z.infer<typeof ChartSchema>;
