import { Customer } from '../entities/Customer';
import { InterceptorRepository } from './InterceptorRepository';

class ProblemsRepository extends InterceptorRepository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/customers',
    });
  }

  async get(customerId: Customer['id']): Promise<unknown> {
    const { data } = await this.axios.get(`/${customerId}/problems_states`);
    return data;
  }
}

export default new ProblemsRepository();
