import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useWizard } from '../../../../../hooks/useWizard';
import { Scroller } from '../../../../Scroller';
import { WizardPrimaryCard } from '../components/WizardCommonComponents';
import WizardHeader from '../components/WizardHeader';
import WizardOutdatedAlert from '../components/WizardOutdatedAlert';
import WizardPageTitle from '../components/WizardPageTitle';

export default function NoWizardsStep(): JSX.Element {
  const { t } = useTranslation();
  const { wizardStep: wizard } = useWizard();

  return (
    <WizardPrimaryCard>
      <Scroller>
        <WizardHeader wizard={wizard} />
        <WizardPageTitle rightOuterButtons={[]} />
        <WizardOutdatedAlert wizard={wizard} />
        <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
          {t('dashboard:recommendedActions:noActionAvailable')}
        </Typography>
      </Scroller>
    </WizardPrimaryCard>
  );
}
