import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ArcElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  ChartDataset,
  ChartEvent,
  Filler,
  Legend,
  LegendItem,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  ScatterDataPoint,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/system';
import { useMaterialColors } from '../../utils/materialColors';

/**
 * Chart.js doesn't support multplie legends, so let's add the Legend
 * plugin again. afterEvent and afterUpdate are stripped out in order
 * to prevent click issues. Side effects unknown.
 */
const BottomLegend = {
  ...Legend,
  id: 'bottomlegend',
  afterEvent: undefined,
  afterUpdate: undefined,
};

Chart.register(
  ArcElement,
  CategoryScale,
  Filler,
  Legend,
  BottomLegend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  Title,
  Tooltip,
  zoomPlugin,
  annotationPlugin
);

export type InterferenceChartSerie = {
  label: string;
  data: Array<{ x: number; y: number }>;
  thresholds: Array<{ name: string; value: number }>;
  axisUnit?: string | undefined;
  x_label?: string;
  y_label?: string;
  y_unit?: string;
  chartTitle?: string;
  band_width?: string;
  device_type?: string;
  customerSSID: boolean;
};

interface InterferenceChartProps {
  warning?: boolean;
  visible: boolean;
  series: InterferenceChartSerie[];
  reset?: boolean;
  onZoom?: (value: boolean) => void;
}

export default function InterferenceChart(props: InterferenceChartProps): JSX.Element {
  const { visible, series, reset, onZoom, warning } = props;
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });
  const theme = useTheme();
  const colors = useMaterialColors(series.length);

  const [hiddenSeries, setHiddenSeries] = useState<Record<number, boolean>>({});
  const [cursor, setCursor] = useState<string | undefined>();

  const xMax = useMemo(() => {
    let max = undefined as undefined | number;
    series.forEach((serie) => {
      const lastXvalue = serie.data[serie.data.length - 1]?.x;
      if (lastXvalue) {
        if (!max || lastXvalue > max) max = lastXvalue;
      }
    });
    return max;
  }, [series]);
  const xMin = useMemo(() => {
    let min = undefined as undefined | number;
    series.forEach((serie) => {
      const firstXvalue = serie.data[0]?.x;
      if (firstXvalue) {
        if (!min || firstXvalue < min) min = firstXvalue;
      }
    });
    return min;
  }, [series]);

  const annotations = useMemo(() => {
    const annotations: Record<string, AnnotationOptions> = {};

    for (let i = 0; i < series.length; i++) {
      const serie = series[i];

      if (serie?.thresholds) {
        for (let j = 0; j < serie.thresholds.length; j++) {
          const threshold = serie.thresholds[j];

          if (threshold && threshold.value) {
            // Guard on positive values
            threshold.value = threshold.value > 0 ? -threshold.value : threshold.value;

            annotations[threshold.name] = {
              type: 'line',
              xScaleID: 'x',
              // yScaleID: Object.keys(yScales)[0],
              yMin: threshold.value,
              yMax: threshold.value,
              borderColor: '#ff6384',
              borderWidth: 2,
              label: {
                enabled: true,
                position: 'start',
                content: threshold.name,
                backgroundColor: '#ff6384',
                width: 1000,
                height: 15,
              },
            };
          }
        }
      }
    }

    annotations['gap-box'] = {
      type: 'box',
      xMin: 14,
      xMax: 32,
      yMin: -101,
      yMax: 1,
      borderWidth: 1,
      borderColor: `${theme.palette.text.primary}1a`,
      backgroundColor: '#fff',
      drawTime: 'beforeDraw',
      // label: {
      //   enabled: true,
      //   content: '2,4/5Ghz gap',
      // },
    };

    return annotations;
  }, [series, theme.palette.text.primary]);

  const channelsLength = useMemo(() => {
    let min = 1;
    let max = 1;

    for (let i = 0; i < series.length; i++) {
      const serie = series[i];
      if (serie) {
        const channel = serie.data[(serie.data.length - 1) / 2]?.x;
        min = Math.max(1, Math.min(min, channel ?? min));
        max = Math.max(max, channel ?? max);
      }
    }

    return max - min + 1;
  }, [series]);

  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    if (visible && ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx && series[0]) {
        const datasets: ChartDataset[] = [];

        let min = 0;
        let max = 0;

        for (let i = 0; i < series.length; i++) {
          const serie = series[i];
          let color = colors[i] || '#000000';

          color = color.indexOf('#') === 0 ? `${color}26` : color.replace('hsl', 'hsla').replace(')', `,15%)`);

          if (serie) {
            datasets[i] = {
              label: serie.label,
              data: serie.data.map(({ x, y }) => ({
                x,
                y,
              })),
              fill: 'start',
              borderColor: colors[i],
              borderWidth: 2,
              backgroundColor: color,
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'monotone',
              tension: 0.4,
              pointHitRadius: 30,
              type: 'line' as const,
            };

            min = Math.min(min || Number.MAX_SAFE_INTEGER, serie.data[0]?.x || Number.MAX_SAFE_INTEGER);
            max = Math.max(max || 0, serie.data[serie.data.length - 1]?.x || 0);
          }
        }

        const chartOptions = {
          type: 'line',
          data: {
            datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              y: {
                duration: 1000,
                from: 800,
              },
            },
            layout: {
              padding: {
                right: 48,
                bottom: 24,
                left: 36,
              },
            },
            plugins: {
              tooltip: {
                enabled: true,
                mode: 'x',
                intersect: false,
                callbacks: {
                  title: ([item]) => {
                    // triggers hand cursor
                    setCursor(item ? 'pointer' : undefined);

                    if (item) {
                      const serie = item.dataset;

                      if (serie) {
                        const channel = (serie.data[(serie.data.length - 1) / 2] as ScatterDataPoint).x || 0;
                        return `Canale ${channel}`;
                      }
                    }
                    return '';
                  },
                  label: (item) => {
                    const label = [
                      ` ${item.dataset.label}  (${item.parsed.y.toFixed(1)}) ${props.series[0]?.axisUnit}`,
                    ];
                    if (
                      props.series[item.datasetIndex]?.device_type === undefined &&
                      props.series[item.datasetIndex]?.device_type === '_Sconosciuto_' &&
                      props.series[item.datasetIndex]?.band_width
                    ) {
                      label.push(`Ampiezza di banda: ${props.series[item.datasetIndex]?.band_width} `);
                    } else if (
                      props.series[item.datasetIndex]?.device_type !== undefined &&
                      props.series[item.datasetIndex]?.device_type !== '_Sconosciuto_' &&
                      props.series[item.datasetIndex]?.band_width
                    ) {
                      label.push(
                        `Ampiezza di banda: ${props.series[item.datasetIndex]?.band_width}, Dispositivo: ${
                          props.series[item.datasetIndex]?.device_type
                        }`
                      );
                    } else {
                      label.push(`Ampiezza di banda: ${props.series[item.datasetIndex]?.band_width}`);
                    }
                    return label;
                  },
                },
                filter: (tooltipItem) => {
                  // removes tooltip trigger from not-summit points
                  return tooltipItem.dataIndex === 4;
                },
              },
              legend: {
                position: 'right',
                align: 'start',
                labels: {
                  filter: (item) => {
                    const datasetIndex = item.datasetIndex;
                    if (typeof datasetIndex !== 'undefined') {
                      return props.series[datasetIndex]?.customerSSID;
                    }
                    return false;
                  },
                },
              },
              title: {
                display: false,
              },
              annotation: {
                annotations,
              },
              zoom: {
                zoom: {
                  onZoomStart: () => {
                    if (onZoom) onZoom(true);
                  },
                  wheel: {
                    enabled: true,
                    speed: 0.3,
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: 'x',
                },
                pan: {
                  onPanStart: () => {
                    if (onZoom) onZoom(true);
                    setCursor('grab');
                  },
                  enabled: true,
                  mode: 'x',
                  onPanComplete: () => setCursor(undefined),
                },
                limits: {
                  x: { min: xMin && xMin < 0 ? xMin : 0, max: 146 },
                },
              },
            },
            scales: {
              x: {
                type: 'linear' as const,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  autoSkip: false,
                  maxRotation: 0,
                  stepSize: 1,
                  precision: 1,
                  color: theme.palette.text.primary + '9a',
                  callback: (stringValue) => {
                    const value = Number(stringValue);

                    if ((value % 5 === 0 || value === 32 || value === 14) && (value >= 32 || value <= 14)) {
                      return value;
                    }
                  },
                },
                min: xMin && xMin < 0 ? xMin : 0,
                max: 146,
                title: {
                  text: props.series[0]?.x_label || '',
                  font: { size: 18, weight: 'bold' },
                  display: true,
                },
              },
              y: {
                max: 0,
                min: -100,
                type: 'linear' as const,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                title: {
                  display: true,
                  text: `${props.series[0]?.y_label} [ ${props.series[0]?.y_unit} ]`,
                  font: { size: 18, weight: 'bold' },
                  color: theme.palette.text.primary + '9a',
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                },
              },
            },
          },
        } as ChartConfiguration;

        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            position: 'bottom',
            align: 'start',
            labels: {
              color: theme.palette.text.primary + '9a',
              filter: (item: LegendItem) => {
                const datasetIndex = item.datasetIndex;
                if (typeof datasetIndex !== 'undefined') {
                  return !props.series[datasetIndex]?.customerSSID;
                }
                return true;
              },
              sort: (a: LegendItem, b: LegendItem) => {
                return a.text.localeCompare(b.text);
              },
            },
            onClick: (_e: ChartEvent, i: LegendItem) => {
              const datasetIndex = i.datasetIndex;
              if (typeof datasetIndex !== 'undefined') {
                setHiddenSeries((hiddenSeries) => {
                  return {
                    ...hiddenSeries,
                    [datasetIndex]: !hiddenSeries[datasetIndex],
                  };
                });
              }
            },
          };
        }

        chartRef.current.chart = new Chart(ctx, chartOptions);
      }
    }
  }, [
    annotations,
    channelsLength,
    colors,
    onZoom,
    props.series,
    hiddenSeries,
    series,
    visible,
    theme.palette.text.primary,
    xMin,
    xMax,
  ]);

  // Set hidden series
  useEffect(() => {
    if (chartRef.current.chart) {
      const chartData = chartRef.current.chart.data;

      if (chartData && chartData.datasets) {
        chartData.datasets = chartData.datasets.map((dataset, i) => ({
          ...dataset,
          hidden: hiddenSeries[i],
        }));
        chartRef.current.chart.update();
      }
    }
  }, [hiddenSeries]);

  // Zoom state handling
  useEffect(() => {
    if (reset && chartRef.current.chart && xMax) {
      const chart = chartRef.current.chart;
      setTimeout(() => {
        chart?.zoomScale('x', { min: xMin ?? 0, max: xMax + 5 });
        chart?.pan({ x: Number.MAX_SAFE_INTEGER });
      }, 0);
    }
  }, [reset, xMax, xMin]);
  useEffect(() => {
    return () => {
      if (onZoom) onZoom(false);
    };
  }, [onZoom]);

  if (warning) {
    return (
      <>
        <Box sx={{ py: 2, px: 4 }}>
          <Alert severity="warning">{`La frequenza 2.4Gh non può essere visualizzata`}</Alert>
        </Box>
        <div style={{ height: 550 }}>
          <canvas
            ref={ref}
            style={{
              cursor: cursor,
              paddingTop: 24,
              display: 'block',
              boxSizing: 'border-box',
              touchAction: 'none',
              userSelect: 'none',
            }}
          />
        </div>
      </>
    );
  } else {
    return <canvas ref={ref} style={{ cursor: cursor, paddingTop: 24 }} />;
  }
}
