import React, { useEffect, useRef } from 'react';
import {
  ArcElement,
  Chart,
  ChartConfiguration,
  ChartDataset,
  DoughnutController,
  Filler,
  Legend,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useTheme } from '@mui/system';

export interface GaugeChartData {
  data: Array<number>;
  colors: Array<string>;
  labels: Array<string>;
  chartTitle: string;
  currentValue: number;
  minValue: number;
  maxValue: number;
}

const rad = (x: number) => (x / 180) * Math.PI;

class Custom extends DoughnutController {
  draw() {
    // Call bubble controller method to draw all the points
    super.draw();

    // Now we can do some custom drawing for this dataset. Here we'll draw a red box around the first point in each dataset
    const meta = this.getMeta();
    const radius = ((meta.controller as unknown) as { outerRadius: number }).outerRadius * 1.05;
    const needle = rad(-((this.getDataset() as unknown) as { needle: number }).needle);

    const originX = meta.data[0]?.x || 0;
    const originY = meta.data[0]?.y || 0 - 2;

    const ctx = this.chart.ctx;
    ctx.save();
    ctx.strokeStyle = '#202020';
    ctx.lineCap = 'butt';

    ctx.beginPath(); //
    ctx.lineWidth = 4;
    ctx.moveTo(originX, originY);
    ctx.lineTo(-radius * Math.cos(needle) + originX, radius * Math.sin(needle) + originY);
    ctx.stroke();

    ctx.restore();
  }
}

Custom.id = 'gauge';
Custom.defaults = DoughnutController.defaults;

// Stores the controller so that the chart initialization routine can look it up
Chart.register(Custom);
Chart.register(ArcElement, Filler, Legend, Custom, PointElement, Title, Tooltip);

declare module 'chart.js' {
  interface ChartTypeRegistry {
    gauge: ChartTypeRegistry['doughnut'] & {
      datasetOptions: ChartTypeRegistry['doughnut']['datasetOptions'] & {
        needle: number;
      };
    };
  }
}

interface GaugeChartProps {
  visible: boolean;
  data: GaugeChartData;
}

export default function GaugeChart(props: GaugeChartProps): JSX.Element {
  const {
    visible,
    data: { data, labels, colors, chartTitle, currentValue, minValue, maxValue },
  } = props;
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });
  const theme = useTheme();

  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    if (visible && ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        const dataset: ChartDataset<'gauge'> = {
          data: data,
          backgroundColor: colors,
          needle: 0,
          type: 'gauge' as const,
        };

        const needleValue = ((currentValue / (maxValue - minValue)) * 180 + 360) % 180;

        const chartOptions = {
          type: 'gauge',
          data: {
            labels: labels,
            datasets: [dataset],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            circumference: 180,
            rotation: -90,
            layout: {
              padding: {
                right: 48,
                bottom: 24,
                left: 48,
              },
            },
            animation: {
              duration: 500,
              easing: 'easeOutQuart',
              onProgress: (context) => {
                if (context.chart.data.datasets[0]) {
                  (context.chart.data.datasets[0] as ChartDataset<'gauge'>).needle =
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (((context as any).currentStep as number) / context.numSteps) * needleValue;
                }
              },
              onComplete: (context) => {
                if (context.chart.data.datasets[0]) {
                  (context.chart.data.datasets[0] as ChartDataset<'gauge'>).needle = needleValue;
                  context.chart.draw();
                  if (context.chart.options.animation) {
                    context.chart.options.animation.onProgress = undefined;
                  }
                }
              },
            },
            plugins: {
              title: {
                display: true,
                text: chartTitle,
                font: {
                  size: 24,
                },
                padding: {
                  bottom: 24,
                },
                color: theme.palette.text.primary + '9a',
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: (context) => {
                    return ` ${context.label}`;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
          },
        } as ChartConfiguration<'gauge'>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart<'gauge'>(ctx, chartOptions);
      }
    }
  }, [chartTitle, colors, currentValue, data, labels, maxValue, minValue, visible, theme.palette.text.primary]);

  return <canvas style={{ paddingTop: 24 }} ref={ref} />;
}
