import * as z from 'zod';

// BOARD ELEMENTS
export enum BoardElementType {
  CONTROLLER = 'controller',
  TABLE = 'table',
  MARKDOWN = 'markdown',
  LEGEND = 'legend',
  CHART = 'chart',
  ELEMENT = 'element',
}

export type L2DashboardRow = {
  height?: number | 'auto';
  elements: Array<BoardElement | L2DashboardColumn>;
};
const L2DashboardRowSchema: z.ZodSchema<L2DashboardRow> = z.lazy(() =>
  z.object({
    height: z.union([z.literal('auto'), z.number().positive().max(100)]).optional(),
    elements: z.array(z.union([BoardElementSchema, L2DashboardColumnSchema])),
  })
);

const BoardElementSchema = z.object({
  type: z.nativeEnum(BoardElementType),
  id: z.string(),
  width: z.union([z.literal('auto'), z.number().positive().max(100)]).optional(),
});
export type BoardElement = z.infer<typeof BoardElementSchema>;

export const L2DashboardColumnSchema = z.object({
  type: z.literal('column').optional(),
  rows: z.array(L2DashboardRowSchema),
  width: z.union([z.literal('auto'), z.number().positive().max(100)]).optional(),
});
export type L2DashboardColumn = z.infer<typeof L2DashboardColumnSchema>;

// TAB
export const L2TabSchema = z.object({
  id: z.string(),
  name: z.string(),
  short_name: z.string(),
  disabled: z.boolean().optional(),
  layout: L2DashboardColumnSchema.optional(),
});
export type L2Tab = z.infer<typeof L2TabSchema>;
export const L2TabsSchema = z.array(L2TabSchema);

// Controller Element
export enum FilterType {
  VALUE = 'value',
  RANGE = 'range',
  TIME = 'time',
}
const ValueFilterSchema = z.object({
  id: z.string(),
  type: z.literal(FilterType.VALUE),
  name: z.string(),
  alias: z.string(),
  possible_values: z.array(z.string()).optional(),
  multiple_selection: z.boolean().optional(),
  element_ids_control: z.array(z.string()),
});
export type ValueFilter = z.infer<typeof ValueFilterSchema>;
const RangeFilterSchema = z.object({
  id: z.string(),
  type: z.literal(FilterType.RANGE),
  name: z.string(),
  alias: z.string(),
  start: z.number(),
  end: z.number(),
  step: z.number().optional(),
  helper_string: z.string().optional(),
  element_ids_control: z.array(z.string()),
});
export type RangeFilter = z.infer<typeof RangeFilterSchema>;
const TimeFilterSchema = z.object({
  id: z.string(),
  type: z.literal(FilterType.TIME),
  name: z.string(),
  alias: z.string(),
  interval: z.number(), // in milliseconds
  offset: z.number(), // in milliseconds
  element_ids_control: z.array(z.string()),
});
export type TimeFilter = z.infer<typeof TimeFilterSchema>;
const ControllerElementSchema = z.object({
  id: z.string(),
  type: z.literal(BoardElementType.CONTROLLER),
  board_element: z.object({
    orientation: z.union([z.literal('horizontal'), z.literal('vertical')]),
    title: z.string().optional().nullable(),
    filters: z.array(z.union([ValueFilterSchema, RangeFilterSchema, TimeFilterSchema])),
  }),
});
export type ControllerElement = z.infer<typeof ControllerElementSchema>;

// Markdown Element
const L2MarkdownSectionSchema = z.object({
  content: z.string(),
});
const MarkdownElementSchema = z.object({
  id: z.string(),
  type: z.literal(BoardElementType.MARKDOWN),
  board_element: L2MarkdownSectionSchema,
});
export type MarkdownElement = z.infer<typeof MarkdownElementSchema>;

// Table Element
const L2TableSectionSchema = z.object({
  columns: z.array(
    z.object({
      column_name: z.string(),
      alias: z.string(),
    })
  ),
  rows: z.array(
    z.object({
      data: z.array(
        z.object({
          column_name: z.string(),
          value: z.string(),
        })
      ),
      filter: z
        .object({
          id: z.string().optional(),
          name: z.string(),
          type: z.literal(FilterType.VALUE),
          value: z.string(),
          element_ids_control: z.array(z.string()),
        })
        .optional(),
    })
  ),
});
export type L2TableData = z.infer<typeof L2TableSectionSchema>;

const TableElementSchema = z.object({
  id: z.string(),
  type: z.literal(BoardElementType.TABLE),
  board_element: L2TableSectionSchema,
});
export type TableElement = z.infer<typeof TableElementSchema>;

// Legend Element
const L2LegendSectionSchema = z.object({
  orientation: z.union([z.literal('horizontal'), z.literal('vertical')]),
  title: z.string().optional().nullable(),
  chart_ids_control: z.array(z.string()).optional().nullable(),
  data_type_control: z.union([z.literal('trendline'), z.literal('area'), z.null()]).optional(),
});
const LegendElementSchema = z.object({
  id: z.string(),
  type: z.literal(BoardElementType.LEGEND),
  board_element: L2LegendSectionSchema,
});
export type LegendElement = z.infer<typeof LegendElementSchema>;

// Chart Element
export enum L2ChartType {
  LINE = 'line',
  PIE = 'pie',
  BAR = 'bar',
  BELLS = 'bells',
}
const L2CommonChartSchema = z.object({
  chart_name: z.string().optional(),
  x_label: z.string().optional(),
  y_label: z.string().optional(),
});
// line chart

const L2TrendlineStyleSchema = z.object({
  color: z.string().optional().nullable(),
});

const L2LineTrendlineSchema = z.object({
  data_class: z.string(),
  color: L2TrendlineStyleSchema.optional().nullable(),
  values: z.array(z.object({ x: z.number(), y: z.number() })),
});
export type L2LineTrendline = z.infer<typeof L2LineTrendlineSchema>;
const L2LineAreaSchema = z.object({
  data_class: z.string(),
  color: L2TrendlineStyleSchema.optional().nullable(),
  from: z.number().optional(),
  to: z.number().optional(),
});
const L2LineChartSchema = L2CommonChartSchema.extend({
  chart_type: z.literal(L2ChartType.LINE),
  trendlines: z.array(L2LineTrendlineSchema),
  areas: z.array(L2LineAreaSchema).optional().nullable(),
});
// bar chart
const L2BarTrendlineSchema = z.object({
  data_class: z.string(),
  color: L2TrendlineStyleSchema.optional().nullable(),
  values: z.array(z.number()),
});
export type L2BarTrendline = z.infer<typeof L2BarTrendlineSchema>;
const L2BarAreaSchema = z.object({
  data_class: z.string(),
  color: L2TrendlineStyleSchema.optional().nullable(),
  label_from: z.string().optional(),
  label_to: z.string().optional(),
});
export const L2BarChartSchema = L2CommonChartSchema.extend({
  chart_type: z.literal(L2ChartType.BAR),
  trendlines: z.array(L2BarTrendlineSchema),
  areas: z.array(L2BarAreaSchema).optional().nullable(),
  labels: z.array(z.string()),
  orientation: z.union([z.literal('horizontal'), z.literal('vertical')]).optional(), // default value is vertical
  stacked: z.boolean().optional(), // default is not stacked
});
// pie chart
const L2PieTrendlineSchema = z.object({
  data_class: z.string(),
  color: L2TrendlineStyleSchema.optional().nullable(),
  value: z.number().positive(),
});
export type L2PieTrendline = z.infer<typeof L2LineTrendlineSchema>;
const L2PieChartSchema = L2CommonChartSchema.extend({
  chart_type: z.literal(L2ChartType.PIE),
  trendlines: z.array(L2PieTrendlineSchema),
});
// bells chart
const L2BellsTrendlineSchema = z.object({
  data_class: z.string(),
  color: L2TrendlineStyleSchema.optional().nullable(),
  values: z.object({
    left_point: z.object({
      x: z.number(),
      y: z.number(),
    }),
    summit: z.object({
      x: z.number(),
      y: z.number(),
    }),
    right_point: z.object({
      x: z.number(),
      y: z.number(),
    }),
  }),
});
export type L2BellsTrendline = z.infer<typeof L2BellsTrendlineSchema>;
const L2BellsAreaSchema = z.object({
  data_class: z.string(),
  color: L2TrendlineStyleSchema.optional().nullable(),
  from: z.number().optional(),
  to: z.number().optional(),
});
export const L2BellsChartSchema = L2CommonChartSchema.extend({
  chart_type: z.literal(L2ChartType.BELLS),
  trendlines: z.array(L2BellsTrendlineSchema),
  areas: z.array(L2BellsAreaSchema).optional().nullable(),
});

const L2ChartSchema = z.union([L2BarChartSchema, L2LineChartSchema, L2PieChartSchema, L2BellsChartSchema]);
export type L2Chart = z.infer<typeof L2ChartSchema>;

const ChartElementSchema = z.object({
  id: z.string(),
  type: z.literal(BoardElementType.CHART),
  board_element: L2ChartSchema,
});
export type ChartElement = z.infer<typeof ChartElementSchema>;

const L2ElementSchema = z.union([
  ControllerElementSchema,
  LegendElementSchema,
  ChartElementSchema,
  TableElementSchema,
  MarkdownElementSchema,
]);

export const ElementsReponseSchema = z.array(L2ElementSchema);

export type L2DashboardTab = {
  dashboard: L2Tab;
  elements: Array<L2Element>;
};

export type L2Element = z.infer<typeof L2ElementSchema>;
