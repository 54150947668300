import * as z from 'zod';

export type Customer = {
  id: string;
  customer_name?: string;
  attributes: Array<{ name: string; value: string | number; status?: string }>;
};

const CustomerInfoValue = z.object({
  collapsed: z.union([z.boolean(), z.undefined()]),
  variable: z.string(),
  value: z.unknown(),
  lasting: z.boolean(),
  var_type: z.unknown(),
  last_update_timestamp: z.unknown(),
  alert_modification_data: z.string().optional().nullable(),
});

// Info displayed in top panels of the UI
export const CustomerInfoSectionSchema = z.object({
  section_name: z.string(),
  rules: z.array(
    z.object({
      priority_level: z.union([z.unknown(), z.number()]),
      rule_name: z.string(),
      value: z.array(CustomerInfoValue),
    })
  ),
});
export type CustomerInfoSection = z.infer<typeof CustomerInfoSectionSchema>;
export const CustomerInfoSchema = z.object({
  customer_name: z.string(),
  in_progress: z.union([z.boolean(), z.undefined()]),
  info: z.array(CustomerInfoSectionSchema),
});
export type CustomerInfo = z.infer<typeof CustomerInfoSchema>;

// Info displayed in Left column after action/rule have tirggered a latest_info msg from WS
export const LatestInfoSectionSchema = z.object({
  title: z.string(),
  expanded: z.union([z.boolean(), z.undefined()]),
  sub_info: z.array(
    z.object({
      priority_level: z.union([z.unknown(), z.number()]),
      sub_title: z.union([z.string(), z.null(), z.undefined()]),
      value: z.array(CustomerInfoValue),
    })
  ),
});
export type LatestInfoSection = z.infer<typeof LatestInfoSectionSchema>;
export const LatestInfoSchema = z.object({
  customer_name: z.string(),
  in_progress: z.union([z.boolean(), z.undefined()]),
  info: z.array(LatestInfoSectionSchema),
});
export type LatestInfo = z.infer<typeof LatestInfoSchema>;

export const CustomerLastTestInfoSchema = LatestInfoSchema.merge(
  z.object({
    request_id: z.unknown(),
    trigger_mode: z.unknown(),
    start_date: z.unknown(),
    end_date: z.unknown(),
    wizard: z.unknown(),
    req_num: z.union([z.unknown(), z.number(), z.undefined()]),
  })
);
export type CustomerLastTestInfo = z.infer<typeof CustomerLastTestInfoSchema>;

// Attributes
export const CustomerAttributeSchema = z.object({
  attribute_name: z.string(),
  possible_values: z.array(z.string()),
  alias: z.string(),
  category: z.string().optional(),
});

export const CustomerAttributeAPIResponseSchema = z.object({
  attributes: z.array(CustomerAttributeSchema),
});

export type CustomerAttribute = z.infer<typeof CustomerAttributeSchema>;

// MapT
const CustomerMapTItemSchema = z.object({
  date_time: z.string(),
  status: z.string(),
  bb_profile: z.string(),
  ticket_id: z.string(),
  reason_text: z.string(),
  activation_completion_date_time: z.string(),
  cgnat_state: z.string(),
  caller: z.string(),
  mac_address: z.string(),
});
export type CustomerMapTItem = z.infer<typeof CustomerMapTItemSchema>;

export const CustomerMapTSchema = z.object({
  events: z.array(CustomerMapTItemSchema),
});
export type CustomerMapT = z.infer<typeof CustomerMapTSchema>;

export enum CustomerArea {
  BB = 'BB',
  TV_RDKV = 'TV_RDKV',
  Q_LEGACY = 'Q_LEGACY',
}

export enum CustomerProduct {
  WIFI = 'WIFI',
}
