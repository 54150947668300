import { ZodError } from 'zod';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class RequestParseError<T = any> extends Error {
  requestName: string;
  originalZodError: ZodError;
  toStringArray: () => string[];

  constructor(error: ZodError<T>, requestName: string) {
    super(error.message);

    this.requestName = requestName;
    this.originalZodError = error;

    this.toStringArray = () => {
      return [`On request ${requestName}:`, ...this.originalZodError.message.split('\n')];
    };
  }
}
