import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration, ChartDataset, Legend, Title, Tooltip } from 'chart.js';
import { differenceInMinutes, format, set } from 'date-fns';
import { Box, useTheme } from '@mui/system';
import { useMaterialColors } from '../../utils/materialColors';

Chart.register(Legend, Title, Tooltip);

export interface LineFilledData {
  chartName: string;
  yMin: number | null;
  yMax: number | null;
  yUnit: string | null;
  series: {
    name: string;
    data: {
      x_label: string | null;
      y_label: string | null;
      label_name: string;
      label_value: string;
      threshold: boolean;
      values: Array<{ x: number; y: number }>;
      fillAreaTo: string | number | null;
    };
  }[];
}

interface LineFilledChartProps {
  visible: boolean;
  data: LineFilledData[];
}
const MAX_TICKS = 18;

export default function LineFilledChart(props: LineFilledChartProps): JSX.Element {
  const { visible, data } = props;

  const ref = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<{ chart: Chart | undefined }>({ chart: undefined });
  const theme = useTheme();

  const linesNumber = () => {
    let count = 0;
    data.forEach((trendline) => {
      for (let i = 0; i < trendline.series.length; i++) {
        count++;
      }
    });
    return count;
  };
  const colors = useMaterialColors(linesNumber.length);

  useEffect(() => {
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    if (visible && ref.current && ref.current.getContext) {
      const ctx = ref.current.getContext('2d');

      if (ctx) {
        const datasets: ChartDataset[] = [];

        let minTimestamp = 0;
        let maxTimestamp = 0;
        let allScalesMin = Number.MAX_SAFE_INTEGER;
        let allScalesMax = Number.MIN_SAFE_INTEGER;

        data.forEach((trendline: LineFilledData, i: number) => {
          if (trendline.yMin || trendline.yMin === 0) allScalesMin = Math.min(allScalesMin, trendline.yMin);
          if (trendline.yMax || trendline.yMax === 0) allScalesMax = Math.max(allScalesMax, trendline.yMax);

          for (let j = 0; j < trendline.series.length; j++) {
            const serie = trendline.series[j]?.data;
            if (serie) {
              datasets[j] = {
                label: serie.y_label ? serie.y_label : 'unknown',
                data: serie.values.map(({ x, y }) => ({
                  x,
                  y,
                })),
                type: 'line' as const,
                borderColor: serie.threshold ? '#ff6384' : colors[i + j],
                backgroundColor: serie.threshold ? '#ff63843a' : colors[i + j] + '3a', //Half opacity
                //Fill area to index of serie name or to an altitude
                fill:
                  serie.fillAreaTo !== null && serie.fillAreaTo !== undefined
                    ? typeof serie.fillAreaTo === 'string'
                      ? trendline.series.map((serie) => serie.name).indexOf(serie.fillAreaTo)
                      : typeof serie.fillAreaTo === 'number'
                      ? { value: serie.fillAreaTo }
                      : false // other types
                    : false,
              };

              minTimestamp = Math.min(
                minTimestamp || Number.MAX_SAFE_INTEGER,
                serie.values[0]?.x || Number.MAX_SAFE_INTEGER
              );
              maxTimestamp = Math.max(maxTimestamp || 0, serie.values[serie.values.length - 1]?.x || 0);
            }
          }
        });

        const roundedMinTimestamp = set(new Date(minTimestamp || 0), {
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }).valueOf();
        const roundedMaxTimestamp = set(new Date(maxTimestamp || 0), {
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }).valueOf();

        const ticksIntervalInMinutes =
          Math.round(differenceInMinutes(roundedMaxTimestamp, roundedMinTimestamp) / MAX_TICKS / 15) * 15;

        const chartOptions = {
          type: 'line',
          data: {
            datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            pointHitRadius: 15,
            pointRadius: 0,
            layout: {
              padding: {
                right: 48,
                bottom: 24,
                left: 36,
              },
            },
            plugins: {
              title: {
                display: true,
                text: data[0]?.chartName,
                color: theme.palette.text.primary + '9a',
                font: {
                  size: 24,
                },
                padding: {
                  bottom: 24,
                },
              },
              tooltip: {
                enabled: true,
                mode: 'index',
                backgroundColor: '#e1e1e1e1',
                bodyColor: 'black',
                titleColor: 'black',
                footerColor: 'black',
                footerMarginTop: 10,
                footerFont: { weight: 'bold' },
                callbacks: {
                  title: ([context]) => {
                    return `${format(Number(context?.parsed.x), 'dd/MM/yyyy HH:mm:ss')}`;
                  },
                },
              },
              legend: {
                position: 'bottom',
              },
            },
            scales: {
              x: {
                type: 'linear' as const,
                min: roundedMinTimestamp,
                max: roundedMaxTimestamp,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                ticks: {
                  minRotation: 50,
                  maxRotation: 50,
                  stepSize: ticksIntervalInMinutes * 60 * 1000,
                  color: theme.palette.text.primary + '9a',
                  callback: function (value) {
                    return `${format(Number(value), 'dd/MM HH:mm')}`;
                  },
                },
              },
              y: {
                suggestedMin: allScalesMin < Number.MAX_SAFE_INTEGER ? allScalesMin : undefined,
                suggestedMax: allScalesMax > Number.MIN_SAFE_INTEGER ? allScalesMax : undefined,
                grid: {
                  color: `${theme.palette.text.primary}1a`,
                },
                title: {
                  display: true,
                  text: `${data[0]?.series[0]?.data.y_label} [ ${data[0]?.yUnit} ]`,
                  font: { size: 18, weight: 'bold' },
                  color: theme.palette.text.primary + '9a',
                },
                ticks: {
                  color: theme.palette.text.primary + '9a',
                },
              },
            },
          },
        } as ChartConfiguration<'line'>;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (chartOptions?.options?.plugins) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (chartOptions.options.plugins as any).bottomlegend = {
            display: false,
          };
        }

        chartRef.current.chart = new Chart(ctx, chartOptions);
      }
    }
  }, [colors, data, visible, theme.palette.text.primary]);

  if (!data) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          typography: 'body1',
          color: 'grey.700',
        }}
      >
        <p>{'Dati non presenti'}</p>
      </Box>
    );
  }

  return <canvas ref={ref} style={{ paddingTop: 24 }} />;
}
