import { useContext, useEffect, useState } from 'react';
import { BreadcrumbsContext, Breadcrumb } from './BreadcrumbsProvider';

type UseBreadcrumbsArgs = Pick<Breadcrumb, 'path' | 'label'> & {
  bold?: Breadcrumb['bold'];
  showInTitle?: Breadcrumb['showInTitle'];
};

export default function useBreadcrumbs(breadcrumb: UseBreadcrumbsArgs): Record<string, Breadcrumb>[] {
  const { breadcrumbs, add } = useContext(BreadcrumbsContext);
  const [memoizedBreadcrumb, setMemoizedBreadcrumb] = useState(breadcrumb);

  useEffect(() => {
    if (memoizedBreadcrumb.label !== breadcrumb.label) {
      setMemoizedBreadcrumb(breadcrumb);
    }
  }, [breadcrumb, memoizedBreadcrumb.label]);

  useEffect(() => {
    if (memoizedBreadcrumb) {
      add({
        ...memoizedBreadcrumb,
        bold: typeof memoizedBreadcrumb.bold === 'undefined' ? false : memoizedBreadcrumb.bold,
        showInTitle: typeof memoizedBreadcrumb.showInTitle === 'undefined' ? true : memoizedBreadcrumb.showInTitle,
      });
    }
  }, [add, memoizedBreadcrumb]);

  return [breadcrumbs];
}
