import { useMemo } from 'react';
import { shuffle } from 'd3'; // Any other shuffle function is equivalent
import {
  red,
  indigo,
  green,
  yellow,
  purple,
  grey,
  pink,
  cyan,
  orange,
  deepPurple,
  blue,
  teal,
  lime,
  brown,
  blueGrey,
  deepOrange,
} from '@mui/material/colors';
import { getContrastRatio, darken, hslToRgb, rgbToHex } from '@mui/material/styles';

const materialColors = [
  indigo[400],
  red[800],
  green[500],
  blue[400],
  yellow[600],
  purple[400],
  grey[500],
  pink[400],
  lime[600],
  cyan[500],
  orange[500],
  deepPurple[400],
  teal[400],
  brown[400],
  blueGrey[400],
  deepOrange[500],
];

function generateMaterialColors(amount: number): string[] {
  let length = amount;

  // Use better, hand-picked shades
  if (length <= materialColors.length) {
    return materialColors;
  }

  // Algorithm to generate n shades as more different as possible

  const generateColor = (length: number, i: number) => {
    const hslColor = `hsl(${Math.floor((360 / length) * i)},98%,${i % 2 ? 45 : 55}%)`;
    const contrastRatio = getContrastRatio(hslColor, '#ffffff');

    // Darken colors that are too bright (not enough contrast with withe background)
    return contrastRatio < 1.5 ? darken(hslColor, 0.15) : hslColor;
  };

  // Some green shades look very similar, generate more shades than necessary, so the most similar
  // can be skipped
  let extraShades = Math.floor(length * 0.4);
  length += extraShades;

  // Find contrast between each shade and the following one
  const contrasts = [];
  for (let i = 1; i < length; i++) {
    const contrastWidthPreviousShade = getContrastRatio(generateColor(length, i - 1), generateColor(length, i));
    contrasts.push(contrastWidthPreviousShade);
  }

  // Find the threshold above which contrast ratios are more readable
  const minimumContrast = contrasts.sort().reverse()[length - 2] as number;

  const colors = [];

  for (let i = 0; i < length; i++) {
    const color = generateColor(length, i);

    // Keep a shade only if the contrast with the previous one is above the minimum allowed
    if (colors[colors.length - 1] && extraShades > 0) {
      const contrastWidthPreviousShade = getContrastRatio(color, colors[colors.length - 1] as string);

      if (contrastWidthPreviousShade >= minimumContrast) {
        colors.push(rgbToHex(hslToRgb(color)));
      } else {
        extraShades--;
      }
    } else {
      colors.push(rgbToHex(hslToRgb(color)));
    }
  }

  // Shuffle the resulting colors
  return shuffle(colors);
}

export function useMaterialColors(amount?: number): string[] {
  return useMemo(() => {
    return generateMaterialColors(amount ?? 0);
  }, [amount]);
}

export function getMaterialColors(amount?: number): string[] {
  return generateMaterialColors(amount ?? 0);
}
