import * as z from 'zod';

const EmbeddedServiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  logos: z.object({
    header: z.string(),
    large: z.string(),
    medium: z.string(),
    original: z.string(),
    small: z.string(),
    xsmall: z.string(),
  }),
  _heatmap_available: z.boolean(),
});
export type EmbeddedService = z.infer<typeof EmbeddedServiceSchema>;

export const ServiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  logos: z.object({
    header: z.string(),
    large: z.string(),
    medium: z.string(),
    original: z.string(),
    small: z.string(),
    xsmall: z.string(),
  }),
  stats_24: z.array(z.object({ x: z.number().nonnegative(), y: z.number().nonnegative() })),
  baseline: z.array(z.object({ x: z.number().nonnegative(), y: z.number().nonnegative() })),
  _last_update: z.number(),
  description: z.string(),
});
export const ServiceAPIResponseSchema = z.object({
  companies: ServiceSchema,
});
export type Service = z.infer<typeof ServiceSchema>;

export const HeatMapSchema = z.object({
  heatmap: z
    .array(
      z.object({
        count: z.number(),
        latitude: z.number(),
        longitude: z.number(),
      })
    )
    .nullable(),
  last_update: z.string().optional(),
  message: z.string().optional(),
});

export type HeatMap = z.infer<typeof HeatMapSchema>;
