import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';
import { RootState } from '../store/reducers';
import LoaderView from './LoaderView';

export default function AuthenticatedRoute(props: RouteProps): JSX.Element {
  const { signedIn } = useAuthentication();
  const loginType = useSelector((state: RootState) => state.authentication.login_type);

  useEffect(() => {
    if (signedIn === false) {
      const imInLocalhost = window.location.hostname === 'localhost';

      window.location.replace(
        `${process.env.REACT_APP_COGNITO_OAUTH_DOMAIN}/logout?client_id=${
          loginType === 'standalone'
            ? `${process.env.REACT_APP_COGNITO_STANDALONE_CLIENT_ID}`
            : `${process.env.REACT_APP_COGNITO_FEDERATED_CLIENT_ID}`
        }&response_type=code&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${
          imInLocalhost ? 'http://localhost:3000/landing-page' : process.env.REACT_APP_USER_POOL_DIRECT_DOMAIN
        }&logout_uri=${encodeURIComponent(
          `${process.env.REACT_APP_COGNITO_OAUTH_DOMAIN}/login?client_id=${
            loginType === 'standalone'
              ? `${process.env.REACT_APP_COGNITO_STANDALONE_CLIENT_ID}`
              : `${process.env.REACT_APP_COGNITO_FEDERATED_CLIENT_ID}`
          }`
        )}`
      );
    }
  }, [loginType, signedIn]);

  return (
    <LoaderView minHeight="100vh" condition={signedIn === true}>
      <Route {...props} />
    </LoaderView>
  );
}
