import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import AccordionDetails from '@mui/material/AccordionDetails';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Action, actionId } from '../entities/Action';
import { RequestError } from '../entities/RequestError';
import { useCustomer } from '../hooks/useCustomer';
import { useHubActions } from '../hooks/useHubActions';
import { CompactAccordion } from './CompactAccordion';
import LoaderView from './LoaderView';

export interface RunTestsButtonProps {
  onClick: (e: React.MouseEvent) => void;
  children: React.ReactNode;
}

interface RunTestsDialogProps {
  customerId: string;
  action: Action;
  wizardId: string | undefined;
  ButtonComponent: (props: RunTestsButtonProps) => JSX.Element;
  closeAfterRun?: boolean;
}

export default function RunTestsDialog(props: RunTestsDialogProps): JSX.Element {
  const { action, customerId, ButtonComponent } = props;
  const [open, setOpen] = useState(false);
  const [actionRunning, setActionRunning] = useState(false);

  // Record containing "<section_name>-<rule_name>" as key and "<rule_name>" as value if the rule is checked, undefined otherwise
  const [checked, setChecked] = useState<Record<string, string | undefined>>({});

  const [actionAlert, setActionAlert] = useState<string | undefined>();
  const { readCustomerActionListRequest: actionsRequest, readCustomerActionList } = useCustomer();
  const { giveUp, runHubAction, runHubActionRequests, reset: resetActionsResults } = useHubActions();

  const runHubActionRequest = runHubActionRequests[actionId(action.action_id, props.wizardId)];

  const title = action.action_description || action.action_title;

  const { t } = useTranslation();

  const handleSelectAll = useCallback(() => {
    const options = Object.values(action.frontend_modal_options || [])
      .map((option) => option.rules.map((rule) => [option.section_name, rule]))
      .flat()
      .reduce((checked, [section_name, rule]) => ({ ...checked, [`${section_name}-${rule}`]: rule }), {});

    setChecked(options);
  }, [action.frontend_modal_options]);

  const handleDeselectAll = useCallback(() => {
    setChecked((oldOptions) => {
      const newOptions = { ...oldOptions };
      Object.keys(newOptions).forEach((ruleId) => {
        newOptions[ruleId] = undefined;
      });
      return newOptions;
    });
  }, []);

  const handleClickOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    if (customerId) {
      readCustomerActionList(customerId);
    }

    handleSelectAll();
    setOpen(true);
  };

  const handleClose = () => {
    if (!runHubActionRequest?.inProgress) {
      setActionAlert(undefined);
      setOpen(false);
    }
  };

  const handleConfirm = async () => {
    if (customerId) {
      setActionRunning(true);
      const checkedOptions = [];

      for (const [_ruleId, rule] of Object.entries(checked)) {
        if (rule) {
          checkedOptions.push(rule);
        }
      }

      const actionResponse = await runHubAction(customerId, action.action_id, props.wizardId, {
        action_id: action.action_id,
        options: checkedOptions,
      });

      if (props.closeAfterRun) {
        setActionRunning(false);
        resetActionsResults();
        setOpen(false);
        return;
      }

      if (actionResponse) {
        if (Number((actionResponse as RequestError).status) >= 400) {
          setActionAlert((actionResponse as RequestError).message);
          setActionRunning(false);
          return;
        }
      }

      if (checkedOptions.length) {
        giveUp(action.action_id, props.wizardId);
      }
    } else {
      throw new Error('customer.id is not defined');
    }

    setOpen(false);
    resetActionsResults();
    setActionRunning(false);
  };

  const handleRuleClick = (section: string, rule: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    const ruleId = `${section}-${rule}`;
    const ruleChecked = Boolean(checked[ruleId]);
    const newChecked = { ...checked };
    if (ruleChecked) {
      newChecked[ruleId] = undefined;
    } else {
      newChecked[ruleId] = rule;
    }

    setChecked(newChecked);
  };

  const handleSectionClick = (section: string, rules: string[], checkAll: boolean) => (event: React.MouseEvent) => {
    event.stopPropagation();

    const newChecked = { ...checked };

    for (let i = 0; i < rules.length; i++) {
      const ruleId = `${section}-${rules[i]}`;
      const ruleChecked = Boolean(checked[ruleId]);
      if (ruleChecked) {
        if (!checkAll) {
          newChecked[ruleId] = undefined;
        }
      } else {
        if (checkAll) {
          newChecked[ruleId] = rules[i];
        }
      }

      setChecked(newChecked);
    }
  };

  const selectAllChecked = Object.values(checked).every((rule) => Boolean(rule));
  const selectAllIndeterminate = !selectAllChecked && Boolean(Object.values(checked).find((rule) => Boolean(rule)));

  const handleSelectAllClick = useCallback(() => {
    if (selectAllChecked) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  }, [handleDeselectAll, handleSelectAll, selectAllChecked]);

  return (
    <React.Fragment>
      <ButtonComponent onClick={handleClickOpen}>{action.action_title}</ButtonComponent>
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose} aria-labelledby="action-dialog" scroll="paper">
        <LoaderView condition={!actionsRequest.inProgress} minHeight={400}>
          <DialogTitle sx={{ background: (theme) => theme.palette.background.paper }} id="action-dialog">
            {title}
          </DialogTitle>
          {actionAlert ? (
            <DialogContent>
              <Alert severity="warning">{actionAlert}</Alert>
            </DialogContent>
          ) : null}
          {action.action_description && !action.frontend_modal_options ? (
            <DialogContent>
              <DialogContentText>{action.action_description}</DialogContentText>
            </DialogContent>
          ) : null}
          {action.frontend_modal_options ? (
            <DialogContent dividers sx={{ px: 0, background: (theme) => theme.palette.background.paper }}>
              <Box sx={{ paddingX: 2 }}>
                <FormControlLabel
                  sx={{
                    margin: `0 17px`,
                  }}
                  control={
                    <Checkbox
                      edge="start"
                      checked={selectAllChecked}
                      indeterminate={selectAllIndeterminate}
                      tabIndex={-1}
                      disableRipple
                      onClick={handleSelectAllClick}
                      disableFocusRipple
                    />
                  }
                  label={t('actions:selectAll')}
                />
                {action.frontend_modal_options.map((section) => {
                  const { section_name, rules } = section;
                  const labelId = `checkbox-list-label-${section_name}`;

                  const sectionChecked = rules.every((rule) => Boolean(checked[`${section_name}-${rule}`]));
                  const sectionIndeterminate =
                    !sectionChecked && Boolean(rules.find((rule) => Boolean(checked[`${section_name}-${rule}`])));

                  return (
                    <CompactAccordion
                      key={section_name}
                      sx={{ background: (theme) => theme.palette.background.paper }}
                      summary={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '.MuiCheckbox-root': {
                              paddingX: 4,
                              paddingY: 0,
                            },
                          }}
                        >
                          <Checkbox
                            edge="start"
                            checked={sectionChecked}
                            indeterminate={sectionIndeterminate}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={handleSectionClick(section_name, rules, !sectionChecked || sectionIndeterminate)}
                            disableFocusRipple
                          />
                          <Typography fontWeight="bold" variant="body1">
                            {section_name}
                          </Typography>
                        </Box>
                      }
                    >
                      <AccordionDetails
                        sx={{
                          padding: 0,
                          paddingLeft: 4,
                          ul: {
                            margin: 0,
                            paddingLeft: 6,
                          },
                          li: {
                            listStyle: 'none',
                          },
                        }}
                      >
                        <ul>
                          {rules.map((rule) => (
                            <Typography key={rule} variant="body1" gutterBottom component="li">
                              <Checkbox
                                edge="start"
                                checked={Boolean(checked[`${section_name}-${rule}`])}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                                onClick={handleRuleClick(section_name, rule)}
                                disableFocusRipple
                              />
                              {rule}
                            </Typography>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </CompactAccordion>
                  );
                })}
              </Box>
            </DialogContent>
          ) : null}

          <DialogActions sx={{ background: (theme) => theme.palette.background.paper }}>
            <Button disabled={runHubActionRequest?.inProgress || actionRunning} onClick={handleClose}>
              {t('actions:cancel')}
            </Button>
            <LoadingButton
              onClick={handleConfirm}
              loading={runHubActionRequest?.inProgress || actionsRequest.inProgress || actionRunning}
              disabled={Boolean(action.frontend_modal_options?.length && !selectAllChecked && !selectAllIndeterminate)}
            >
              {action.action_title || t('actions:confirm')}
            </LoadingButton>
          </DialogActions>
        </LoaderView>
      </Dialog>
    </React.Fragment>
  );
}
