import * as z from 'zod';

const GlossaryItemSchema = z.object({
  name: z.string(),
  description: z.string(),
});
export type GlossaryItem = z.infer<typeof GlossaryItemSchema>;

export const GlossarySchema = z.object({
  glossary: z.array(GlossaryItemSchema),
});
export type Glossary = z.infer<typeof GlossarySchema>;
