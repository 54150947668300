import * as z from 'zod';

export const HubTestWizardInfoSchema = z.record(
  z
    .object({
      customer_name: z.string(),
      rule_id: z.string(),
      status: z.string(),
    })
    .nonstrict()
);

export type HubTestWizardInfo = z.infer<typeof HubTestWizardInfoSchema>;

const TestColorsSchema = z.object({
  start_color: z.string().nullable(),
  end_color: z.string().nullable(),
});

export const TestRagSchema = TestColorsSchema.extend({
  description: z.string(),
});

export type TestRag = z.infer<typeof TestRagSchema>;

export const TestRuleSchema = TestColorsSchema.extend({
  id: z.string(),
  rags: z.array(TestRagSchema),
});

export type TestRule = z.infer<typeof TestRuleSchema>;

export const TestSectionSchema = TestColorsSchema.extend({
  id: z.string(),
  rules: z.array(TestRuleSchema),
});

export type TestSection = z.infer<typeof TestSectionSchema>;

export const TestCategorySchema = z.object({
  id: z.string(),
  sections: z.array(TestSectionSchema),
  start_color: z.string().nullable(),
  end_color: z.string().nullable(),
});

export type TestCategory = z.infer<typeof TestCategorySchema>;

export const TestSchema = z.object({
  result: z.union([z.undefined(), z.number()]),
  status: z.number().optional(),
  customer_name: z.string(),
  end_date: z.number().nullable(),
  in_progress: z.boolean(),
  categories: z.array(TestCategorySchema),
  request_id: z.string(),
  start_date: z.number().nullable(),
  trigger_mode: z.string().nullable(),
  wizard: z.object({ status: z.string() }).nullable(),
  wizard_feedback: z.union([z.number(), z.null(), z.undefined()]),
  start_color: z.string().nullable(),
  end_color: z.string().nullable(),
  color: z.string().nullable().optional(),
});

export type Test = z.infer<typeof TestSchema>;

export const TestAPIResponseSchema = z.object({
  next_pagination_token: z.string().optional(),
  historical_requests: z.array(TestSchema),
});

export type TestAPIResponse = z.infer<typeof TestAPIResponseSchema>;
