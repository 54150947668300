import * as z from 'zod';

export const SuggestionSchema = z.object({
  markdown_text: z.string(),
  severity: z.union([z.literal('success'), z.literal('error')]).optional(),
  buttons: z.array(
    z.union([
      z.object({
        type: z.literal('close-window'),
        label: z.string(),
      }),
      z.object({
        type: z.literal('copy-to-clipboard'),
        content: z.string(),
        label: z.string(),
      }),
    ])
  ),
});
export type Suggestion = z.infer<typeof SuggestionSchema>;
