import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import CustomerRepository from '../api/CustomerRepository';
import { CustomerActionsSchema } from '../entities/Action';
import {
  Customer,
  CustomerAttribute,
  CustomerAttributeAPIResponseSchema,
  CustomerInfoSchema,
  CustomerLastTestInfoSchema,
  CustomerMapT,
  CustomerMapTSchema,
} from '../entities/Customer';
import { RequestError } from '../entities/RequestError';
import { IColumn } from '../entities/Table';
import { RootState } from '../store/reducers';

import { customerActions } from '../store/reducers/customer';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';
import { useCharts } from './useCharts';

type CustomersState = RootState['customer'];

type UseCustomer = {
  customerId: CustomersState['id'];
  customerList: CustomersState['list'];
  customerAttributes: CustomersState['attributes'];
  customerLastTestInfo: CustomersState['lastTestInfo'];
  customerInfo: CustomersState['info'];
  customerActionList: CustomersState['actions'];
  readCustomerList: (
    query: Record<string, string>,
    page: number,
    pageSize: number,
    payloadSize: number,
    misaglignment: number,
    clearListAfterFetch: boolean
  ) => Promise<Customer[] | void>;
  readCustomerAttributes: () => Promise<CustomerAttribute[] | undefined>;
  readCustomerInfo: (customerId: Customer['id']) => Promise<void>;
  parseReadCustomerInfo: (customerId: Customer['id'], response: unknown) => Promise<void>;
  parseReadLastTestCustomerInfo: (customerId: Customer['id'], response: unknown) => void;
  readCustomerActionList: (customerId: Customer['id']) => Promise<void>;
  parseReadCustomerActionList: (customerId: Customer['id'], response: unknown) => void;
  readHidden: (customerId: Customer['id']) => Promise<void>;
  readCustomerRequest: CustomersState['requests']['read'];
  readCustomerListRequest: CustomersState['requests']['readList'];
  readCustomerInfoRequest: CustomersState['requests']['readInfo'];
  readCustomerActionListRequest: CustomersState['requests']['readActionList'];
  readHiddenRequest: CustomersState['requests']['readHidden'];
  readCustomerAttributesRequest: CustomersState['requests']['readAttributes'];
  //mapT
  customerMapT: CustomersState['mapT'];
  readCustomerMapT: (customerId: Customer['id']) => Promise<void>;
  readCustomerMapTRequest: CustomersState['requests']['readMapT'];
  parseReadCustomerMapT: (customerId: Customer['id'], response: unknown) => void;

  reset: () => void;
};

export function useCustomer(): UseCustomer {
  const dispatch = useDispatch();
  const { accessToken, refreshToken, userTerritory } = useAuthentication();
  const { invalidateCharts } = useCharts();

  useEffect(() => {
    if (accessToken) {
      CustomerRepository.updateAuthToken(accessToken);
      CustomerRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const customerId = useSelector((state: RootState) => state.customer.id);
  const customerList = useSelector((state: RootState) => state.customer.list);
  const customerAttributes = useSelector((state: RootState) => state.customer.attributes);
  const customerLastTestInfo = useSelector((state: RootState) => state.customer.lastTestInfo);
  const customerInfo = useSelector((state: RootState) => state.customer.info);
  const customerActionList = useSelector((state: RootState) => state.customer.actions);
  const readCustomerRequest = useSelector((state: RootState) => state.customer.requests.read);
  const readCustomerListRequest = useSelector((state: RootState) => state.customer.requests.readList);
  const readCustomerInfoRequest = useSelector((state: RootState) => state.customer.requests.readInfo);
  const readCustomerActionListRequest = useSelector((state: RootState) => state.customer.requests.readActionList);
  const readHiddenRequest = useSelector((state: RootState) => state.customer.requests.readHidden);
  const readCustomerAttributesRequest = useSelector((state: RootState) => state.customer.requests.readAttributes);
  // MapT
  const customerMapT = useSelector((state: RootState) => state.customer.mapT);
  const readCustomerMapTRequest = useSelector((state: RootState) => state.customer.requests.readMapT);

  const readCustomerAttributes = useCallback(async () => {
    dispatch(customerActions.readCustomerAttributesRequest());
    let response: CustomerAttribute[];

    try {
      response = await CustomerRepository.getAttributes(userTerritory);
    } catch (error) {
      dispatch(customerActions.readCustomerAttributesFailure({ error: new RequestError(error as AxiosError) }));
      return;
    }

    const parseResult = CustomerAttributeAPIResponseSchema.safeParse(response);
    if (parseResult.success === false) {
      dispatch(
        customerActions.readCustomerAttributesParseFailure({
          parseError: new RequestParseError(parseResult.error, 'UseCustomer.readCustomerAttributes'),
        })
      );
    } else {
      dispatch(customerActions.readCustomerAttributesSuccess({ response: parseResult.data.attributes }));
      return parseResult.data.attributes;
    }
  }, [dispatch, userTerritory]);

  const readCustomerList: UseCustomer['readCustomerList'] = useCallback(
    async (query, page, pageSize, payloadSize, misalignment, clearListAfterFetch) => {
      dispatch(customerActions.readCustomerListRequest());
      let response: {
        rows: Customer[];
        total_results: number;
        columns: IColumn[];
      };

      try {
        if (query.customer_id) {
          // TODO: use real search API
          const data = await CustomerRepository.getAsList(query.customer_id);
          response = {
            rows: data.rows,
            total_results: 1,
            columns: data.columns,
          };
        } else {
          response = await CustomerRepository.getList(query, page, pageSize, payloadSize, misalignment, userTerritory);
        }
      } catch (error) {
        dispatch(customerActions.readCustomerListFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      if (clearListAfterFetch) dispatch(customerActions.clearList());
      dispatch(
        customerActions.readCustomerListSuccess({
          response: response.rows,
          total: response.total_results,
          columns: response.columns,
        })
      );
    },
    [dispatch, userTerritory]
  );

  const parseReadCustomerInfo = useCallback(
    async (customerId, response) => {
      const parseResult = CustomerInfoSchema.safeParse(response);
      if (parseResult.success === false) {
        dispatch(
          customerActions.readInfoParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCustomer.parseReadCustomerInfo'),
          })
        );
      } else {
        dispatch(customerActions.readInfoSuccess({ response: parseResult.data, customerId }));
        return;
      }
    },
    [dispatch]
  );

  const readCustomerInfo = useCallback(
    async (customerId) => {
      dispatch(customerActions.readInfoRequest());
      let response: unknown;

      try {
        response = (await CustomerRepository.getInfo(customerId)) as unknown;
      } catch (error) {
        dispatch(customerActions.readInfoFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      return parseReadCustomerInfo(customerId, response);
    },
    [dispatch, parseReadCustomerInfo]
  );

  const parseReadLastTestCustomerInfo = useCallback(
    (customerId, response) => {
      const parseResult = CustomerLastTestInfoSchema.safeParse(response);
      if (parseResult.success === false) {
        dispatch(
          customerActions.readInfoParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCustomer.parseReadLastTestCustomerInfo'),
          })
        );
      } else {
        dispatch(customerActions.readLastTestInfoSuccess({ response: parseResult.data, customerId }));
        return;
      }
    },
    [dispatch]
  );

  const parseReadCustomerActionList = useCallback(
    async (customerId, response) => {
      const parseResult = CustomerActionsSchema.safeParse(response);
      if (parseResult.success === false) {
        dispatch(
          customerActions.readActionListParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCustomer.readCustomerActionList'),
          })
        );
      } else {
        dispatch(customerActions.readActionListSuccess({ response: parseResult.data, customerId }));
        await invalidateCharts();
        return;
      }
    },
    [dispatch, invalidateCharts]
  );

  const readCustomerActionList = useCallback(
    // Can remove it if at pageload actions message arrives in WS
    async (customerId) => {
      dispatch(customerActions.readActionListRequest());

      // eslint-disable-next-line no-restricted-globals
      const params = new URLSearchParams(location.search);
      const area = params.get('area') ?? '';
      const product = params.get('product') ?? '';

      let response: unknown;

      try {
        response = (await CustomerRepository.getActions(customerId, area, product)) as unknown;
      } catch (error) {
        dispatch(customerActions.readActionListFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      return parseReadCustomerActionList(customerId, response);
    },
    [dispatch, parseReadCustomerActionList]
  );

  const readHidden = useCallback(
    async (customerId) => {
      dispatch(customerActions.readHiddenRequest());

      // eslint-disable-next-line no-restricted-globals
      const params = new URLSearchParams(location.search);
      const area = params.get('area');
      const product = params.get('product');
      try {
        await CustomerRepository.getHidden(customerId, area, product);
      } catch (error) {
        dispatch(customerActions.readHiddenFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }

      dispatch(customerActions.readHiddenSuccess());
    },
    [dispatch]
  );

  // MapT
  const parseReadCustomerMapT = useCallback(
    (customerId, response) => {
      const parseResult = CustomerMapTSchema.safeParse(response);
      if (parseResult.success === false) {
        dispatch(
          customerActions.readMapTParseFailure({
            parseError: new RequestParseError(parseResult.error, 'UseCustomer.parseReadCustomerMapT'),
          })
        );
      } else {
        dispatch(customerActions.readMapTSuccess({ response: parseResult.data, customerId }));
        return;
      }
    },
    [dispatch]
  );

  const readCustomerMapT = useCallback(
    async (customerId) => {
      dispatch(customerActions.readMapTRequest());
      let response: CustomerMapT;
      try {
        response = (await CustomerRepository.getMapT(customerId)) as CustomerMapT;
      } catch (error) {
        dispatch(customerActions.readMapTFailure({ error: new RequestError(error as AxiosError) }));
        return;
      }
      return parseReadCustomerMapT(customerId, response);
    },
    [dispatch, parseReadCustomerMapT]
  );

  const reset = useCallback(() => {
    dispatch(customerActions.reset());
  }, [dispatch]);

  return {
    customerId,
    customerList,
    customerAttributes,
    customerActionList,
    customerInfo,
    customerLastTestInfo,
    readCustomerList,
    readCustomerAttributes,
    readCustomerActionList,
    parseReadCustomerActionList,
    readCustomerInfo,
    parseReadCustomerInfo,
    readCustomerRequest,
    readCustomerListRequest,
    readCustomerActionListRequest,
    readCustomerAttributesRequest,
    readCustomerInfoRequest,
    parseReadLastTestCustomerInfo,
    readHidden,
    readHiddenRequest,
    // Mapt
    customerMapT,
    readCustomerMapT,
    readCustomerMapTRequest,
    parseReadCustomerMapT,
    reset,
  };
}
