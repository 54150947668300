import React from 'react';
import jQuery from 'jquery';

const WINDOW_VAR_NAME = 'jiraIssueCollector';
const JIRAIssueCollector = (): JSX.Element => {
  const setCollector = () => {
    const appElement = document.querySelector('body');
    if (appElement) {
      const snippet = document.createElement('script');
      snippet.type = 'text/javascript';
      snippet.src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL ?? '';
      appElement.appendChild(snippet);
    }
  };
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  (window as any)['jQuery'] = jQuery;
  if (!(window as any)[WINDOW_VAR_NAME]) {
    setCollector();
    (window as any)[WINDOW_VAR_NAME] = this;
  }

  return <></>;
};

export default JIRAIssueCollector;
