import React, { Fragment, useMemo } from 'react';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { createStyles } from '@material-ui/styles';
import { Theme } from '@mui/material/';
import Card from '@mui/material/Card';
import { makeStyles } from '@mui/styles';
import Box from '@mui/system/Box';
import { Status, wizardStatusNumberToStatus } from '../../../entities/Status';
import { System } from '../../../entities/System';
import { IColumn } from '../../../entities/Table';
import { useSystem } from '../../../hooks/useSystem';
import ApplicationErrorProvider from '../../ApplicationError/ApplicationErrorProvider';
import LoaderView from '../../LoaderView';
import LocalizedDataGrid from '../../LocalizedDataGrid';
import Navbar from '../../Navbar/Navbar';
import StatusCircle from '../../status/StatusCircle';
import MsoFilterSection from './MsoFilterSection';

const PAGE_SIZE = 50;
const PAYLOAD_SIZE = 50;

function StateCell(params: GridCellParams) {
  return params.value === undefined || params.value === null ? (
    <span />
  ) : (
    <StatusCircle status={typeof params.value !== 'number' ? Status.Error : params.value} />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDataGrid-columnsContainer': {
        color: theme.palette.text.primary,
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
          theme.palette.mode === 'light' ? '#f0f0f0' : theme.palette.background.default
        } !important`,
      },
      '& .MuiDataGrid-cell': {
        color: theme.palette.text.primary,
        opacity: 0.9,
        borderBottom: `1px solid ${theme.palette.background.default} !important`,
      },
    },
  })
);

export default function Mso(): JSX.Element {
  const { systemsList, readSystemsList, readSystemsListRequest } = useSystem();
  const [searched, setSearched] = React.useState(false);
  const [payloadSectionCount, setPayloadSectionCount] = React.useState<number>(1);
  const [query, setQuery] = React.useState<Record<string, string | number>>({});
  const [page, setPage] = React.useState<number>(0);
  const classes = useStyles();

  const { rows, columnList } = useMemo(() => {
    const columnList = readSystemsListRequest.columns;
    const rows = systemsList.map((system: System) => {
      const row: Record<string, string | number | undefined> = { id: system.netktID };
      for (const systemAttribute of system.attributes) {
        const { name, value, status } = systemAttribute as {
          name: string;
          value: string | number;
          status?: number;
        };
        if (
          status !== undefined &&
          status !== null &&
          typeof status === 'number' &&
          columnList.find((col) => col.attribute_name === name)?.status
        ) {
          row[name] = wizardStatusNumberToStatus(status);
        } else {
          row[name] = `${value}`;
        }
      }
      return row;
    });
    return { rows, columnList };
  }, [systemsList, readSystemsListRequest.columns]);

  const columns: GridColDef[] = useMemo(() => {
    const attributesRows: GridColDef[] = columnList.map((column: IColumn) => ({
      field: column.attribute_name,
      headerName: column.alias,
      flex: 1,
      renderCell: column.status ? StateCell : undefined,
      sortable: false,
    }));

    return attributesRows;
  }, [columnList]);

  const handlePageChange = React.useCallback(
    async (page: number) => {
      setPage(page);
      if ((page + 1) * PAGE_SIZE > PAYLOAD_SIZE * payloadSectionCount) {
        const misalignment = PAYLOAD_SIZE * payloadSectionCount - page * PAGE_SIZE;
        await readSystemsList(query, page, PAGE_SIZE, PAYLOAD_SIZE, misalignment, false);
        setPayloadSectionCount(payloadSectionCount + 1);
      }
    },
    [query, readSystemsList, payloadSectionCount]
  );

  const search = React.useCallback(
    async (query: Record<string, string | number>) => {
      setSearched(false);
      setQuery(query);
      setPage(0);
      setPayloadSectionCount(1);
      await readSystemsList(query, 0, PAGE_SIZE, PAYLOAD_SIZE, 0, true);
      setTimeout(() => {
        setSearched(true);
      }, 0);
    },
    [readSystemsList]
  );

  return (
    <Fragment>
      <Navbar />
      <ApplicationErrorProvider>
        <Box
          sx={{
            padding: 2,
            paddingTop: 0,
            width: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            background: (theme) => theme.palette.background.default,
          }}
        >
          <MsoFilterSection onSubmit={search} />
          {searched && (
            <Card
              sx={{
                width: '100%',
                flex: '1 1 auto',
                maxHeight: 730,
                overflow: 'hidden',
                borderRadius: 1,
                background: (theme) => theme.palette.background.paper,
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-footer': {
                  minHeight: 40,
                  maxHeight: 40,
                  overflow: 'hidden',
                },
              }}
            >
              <LoaderView condition={!readSystemsListRequest.inProgress} minHeight={180}>
                {''}
              </LoaderView>
              <Box
                sx={{
                  minHeight: 180,
                  height: '100%',
                  opacity: readSystemsListRequest.inProgress ? 0 : 1,
                  '& .MuiDataGrid-root': {
                    display: 'flex',
                    border: '0px solid',
                  },
                  '.MuiDataGrid-cell:focus-within': {
                    outline: 'none !important',
                  },
                }}
              >
                <LocalizedDataGrid
                  className={classes.root}
                  rows={rows}
                  columns={columns}
                  pageSize={PAGE_SIZE}
                  disableSelectionOnClick
                  disableColumnFilter
                  page={page}
                  onPageChange={handlePageChange}
                  paginationMode={'client'}
                  rowCount={readSystemsListRequest.total}
                  rowHeight={38}
                  headerHeight={48}
                  rowsPerPageOptions={[PAGE_SIZE]}
                />
              </Box>
            </Card>
          )}
        </Box>
      </ApplicationErrorProvider>
    </Fragment>
  );
}
