import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  mutateRequestState,
  mutateSuccessState,
  RequestFailureAction,
  mutateErrorState,
  requestParseState,
  RequestParseFailureAction,
  mutateParseErrorState,
} from '../toolkitUtils';

const initialState = {
  invalidate: 0,
  reducedToIconCharts: [] as string[],
  requests: {
    read: requestParseState(),
    readHistory: requestParseState(),
  },
};

export type ChartsState = typeof initialState;

const trendlineSlice = createSlice({
  name: 'trendline',
  initialState,
  reducers: {
    readChartRequest(state) {
      mutateRequestState(state.requests.read);
    },
    readChartSuccess(state) {
      // state.trendlineList[`${action.payload.customerId}-${action.payload.trendlineName}`] = action.payload.response;

      mutateSuccessState(state.requests.read);
    },
    readChartFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.read, action.payload.error);
    },
    readChartParseFailure(state, action: PayloadAction<RequestParseFailureAction>) {
      mutateParseErrorState(state.requests.read, action.payload.parseError);
    },
    invalidateCharts(state) {
      state.invalidate = new Date().valueOf();
    },
    addChartReducedToIcon(state, action: PayloadAction<{ chart: string }>) {
      state.reducedToIconCharts.push(action.payload.chart);
    },
    removeChartReducedToIcon(state, action: PayloadAction<{ chart: string }>) {
      state.reducedToIconCharts.splice(state.reducedToIconCharts.indexOf(action.payload.chart), 1);
    },
    // Chart History
    readHistoryRequest(state) {
      mutateRequestState(state.requests.readHistory);
    },
    readHistorySuccess(state) {
      mutateSuccessState(state.requests.readHistory);
    },
    readHistoryFailure(state, action: PayloadAction<RequestFailureAction>) {
      mutateErrorState(state.requests.readHistory, action.payload.error);
    },
  },
});

export const trendlineActions = trendlineSlice.actions;

export default trendlineSlice.reducer;
