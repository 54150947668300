import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import HubActionRepository from '../api/HubActionRepository';
import { Action, ActionStatus, ActionStatusSchema } from '../entities/Action';
import { RequestError } from '../entities/RequestError';
import { RootState } from '../store/reducers';

import { hubActionsActions } from '../store/reducers/hubActions';
import { RequestParseError } from '../utils/RequestParseError';
import useAuthentication from './useAuthentication';

type UseHubActions = {
  runHubAction: (
    customerId: string,
    actionId: Action['action_id'],
    wizardId: string | undefined,

    payload: unknown
  ) => Promise<RequestError | undefined | unknown>;
  autoRunTest: (customerId: string, payload: unknown) => Promise<RequestError | undefined | unknown>;
  clearRunHubActionRequestError: (actionId: string, wizardId: string | undefined) => void;
  parseReadActionResultResponse: (response: unknown, customerId: string) => Promise<RequestParseError | ActionStatus>;
  resultsById: RootState['hubActions']['resultsById'];
  runHubActionRequests: RootState['hubActions']['requestsById'];
  requestError: RootState['hubActions']['requestError'];
  giveUp: (actionId: Action['action_id'], wizardId: string | undefined) => void;
  resetByWizardId: (wizardId: string | undefined) => void;
  reset: () => void;
};

export function useHubActions(): UseHubActions {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      HubActionRepository.updateAuthToken(accessToken);
      HubActionRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const runHubActionRequests = useSelector((state: RootState) => state.hubActions.requestsById);
  const resultsById = useSelector((state: RootState) => state.hubActions.resultsById);
  const requestError = useSelector((state: RootState) => state.hubActions.requestError);

  const runHubAction = useCallback(
    async (customerId, actionId, wizardId, payload) => {
      dispatch(hubActionsActions.actionRequest({ actionId, wizardId }));
      let result: unknown;
      try {
        result = await HubActionRepository.request(customerId, payload);
      } catch (error) {
        const requestError = new RequestError(error as AxiosError);
        dispatch(hubActionsActions.actionFailure({ error: requestError, wizardId, actionId }));
        return requestError;
      }

      dispatch(hubActionsActions.actionSuccess());

      // Give up waiting for results after 3 minutes
      setTimeout(() => {
        hubActionsActions.actionGiveUp({ actionId, wizardId });
      }, 3 * 60 * 1000);

      return result;
    },
    [dispatch]
  );

  const autoRunTest = useCallback(
    async (customerId, payload) => {
      dispatch(hubActionsActions.actionRequest({ actionId: 'execute-test', wizardId: undefined }));
      let result: unknown;
      try {
        result = await HubActionRepository.request(customerId, payload);
      } catch (error) {
        const requestError = new RequestError(error as AxiosError);
        return requestError;
      }

      dispatch(hubActionsActions.autoTestSuccess());

      // Give up waiting for results after 3 minutes
      setTimeout(() => {
        hubActionsActions.actionGiveUp({ actionId: 'execute-test', wizardId: undefined });
      }, 3 * 60 * 1000);

      return result;
    },
    [dispatch]
  );

  const clearRunHubActionRequestError = useCallback(
    async (actionId, wizardId) => {
      dispatch(hubActionsActions.clearRunHubActionError({ actionId, wizardId }));
    },
    [dispatch]
  );

  const parseReadActionResultResponse = useCallback(
    async (response) => {
      const parseResult = ActionStatusSchema.safeParse(response);
      if (parseResult.success) {
        dispatch(hubActionsActions.actionResultSuccess({ result: parseResult.data }));

        return parseResult.data;
      } else {
        const error = new RequestParseError(parseResult.error, 'UseHubActions.parseReadActionResultResponse');
        dispatch(hubActionsActions.actionResultError({ error: error }));
        return error;
      }
    },
    [dispatch]
  );

  const giveUp = useCallback(
    (actionId, wizardId) => {
      dispatch(hubActionsActions.actionGiveUp({ actionId, wizardId }));
    },
    [dispatch]
  );

  const resetByWizardId = useCallback(
    (wizardId) => {
      dispatch(hubActionsActions.actionResetByWizard({ wizardId }));
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch(hubActionsActions.reset());
  }, [dispatch]);

  return {
    runHubAction,
    autoRunTest,
    runHubActionRequests,
    clearRunHubActionRequestError,
    parseReadActionResultResponse,
    resultsById,
    requestError,
    giveUp,
    resetByWizardId,
    reset,
  };
}
