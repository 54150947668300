import React, { Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { L2DashboardTab } from '../../../entities/L2Dashboard';
import { useL2Dashboard } from '../../../hooks/useL2Dashboard';
import { useApplicationError } from '../../ApplicationError/ApplicationError';
import ApplicationErrorProvider from '../../ApplicationError/ApplicationErrorProvider';
import Navbar from '../../Navbar/Navbar';
import { L2Tab } from './L2Tab';

export default function L2Dashboard(): JSX.Element {
  const { tabs, readTabs, readTabsRequest, readLayoutRequest, readElementsRequest } = useL2Dashboard();
  const [selTabIndex, setSelTabIndex] = useState(0);

  const applicationErrors = useMemo(
    () => [readLayoutRequest.parseError, readTabsRequest.parseError, readElementsRequest.parseError],
    [readLayoutRequest, readTabsRequest, readElementsRequest]
  );
  useApplicationError('UseL2Dashboard.readLayoutRequest', applicationErrors);
  useApplicationError('UseL2Dashboard.readTabsRequest', applicationErrors);
  useApplicationError('UseL2Dashboard.readElementsRequest', applicationErrors);
  const { customer } = useParams() as { customer?: string | undefined };

  React.useEffect(() => {
    if (customer) {
      readTabs(customer);
    }
  }, [readTabs, customer]);

  const manageChangeTab = (index: number) => {
    setSelTabIndex(index);
  };

  return (
    <Fragment>
      <Navbar />
      <ApplicationErrorProvider>
        <Fade in={tabs && Boolean(tabs.length)}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ background: (theme) => theme.palette.background.paper }}>
              <AppBar position="static" color="transparent">
                <Tabs
                  value={selTabIndex}
                  onChange={(e, index: number) => manageChangeTab(index)}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                >
                  {tabs.map((tab: L2DashboardTab) =>
                    !tab.dashboard.disabled ? (
                      <Tooltip key={tab.dashboard.id} title={tab.dashboard.name} placement="top" enterDelay={300} arrow>
                        <Tab label={tab.dashboard.short_name} />
                      </Tooltip>
                    ) : (
                      <Tab label={tab.dashboard.short_name} disabled={tab.dashboard.disabled} />
                    )
                  )}
                </Tabs>
              </AppBar>
            </Box>
            {tabs.map((tab: L2DashboardTab, index: number) => (
              <div
                key={tab.dashboard.id}
                role="tabpanel"
                hidden={selTabIndex !== index}
                id={`full-width-tabpanel-${index}`}
              >
                <Box sx={{ paddingInline: 5 }}>
                  <L2Tab tabId={tab.dashboard.id} currentTabIndex={selTabIndex} />
                </Box>
              </div>
            ))}
          </Box>
        </Fade>
      </ApplicationErrorProvider>
    </Fragment>
  );
}
